import React, { Component } from 'react';
import BackIcon from '../../assets/images/previousBtn.png'

const UsTOC = () => {
    return (
        <>
            <section className="section search-bg2 section-height-800 parallax-container context-dark bg-gray-darkest text-xl-left" data-parallax-img="./images/home/resized/about-news.jpg">
                <div className="parallax-content">
                    <div className="bg-overlay-black">
                        <div className="container section-80 section-md-30 section-lg-top-10 section-lg-bottom-30" style={{ paddingTop: 6 }}>
                            <div className="d-none d-lg-block">
                                <h1 className='mt-4'>Terms of Service</h1>
                            </div>
                            <div className='d-flex d-lg-block'>
                                <img src={BackIcon} alt="Back" className="d-lg-none d-md-none" onClick={() => { window.location.href = '/#/' }} style={{ width: '8%', height: "8%", marginTop: '97px' }} />
                                {/* <ul className="list-inline list-inline-dashed list-white text-big p offset-md-top-8">
                    <li>Learn More about the DNI team</li>
                
                </ul> */}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <div className='col-md-7 mt-5' style={{ textAlign: 'justify', color: 'black', margin: 'auto' }}>
                <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>1. These terms of service govern your use of the Drug Nutrition Interactions Website and the information contained therein (<strong>hereinafter “the Website”</strong>). The terms of service form a contract between you and Elbe’s Drug Nutrition Interactions Ltd. (<strong>hereinafter “the Company”),</strong> and you should read the terms of service carefully. These terms of service are effective upon your acceptance of the terms of service and use of the Website and remain in effect until these terms of service are terminated.</p>
                <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>2. By your login to the Website, you agree to the terms of service. You further agree that the terms of service form a legally binding contract between you the Company and that this contract constitutes a writing signed by you under any applicable law or regulation. If you are an organization or governmental agency, you agree this contract will govern the use of the Website by your officials, officers, agents and employees users.</p>
                <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>3. These terms of service constitute the entire agreement between you and the Company and replace all prior and contemporaneous agreements and communications with respect to the Website. You may not use the Website on behalf of different legal entities or function as a service bureau.</p>
                <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>4. Subject to the terms and conditions of these terms of service, you are granted a limited, non-transferable, nonexclusive, world-wide license to use the Website. You may not distribute the Website contents or make the Website contents available over a network where it could be used by multiple devices at the same time. You may not rent, lease, sell, transfer, redistribute, or sublicense the Website content. The Company retains all title and intellectual property rights to the Website. The Company may, at its sole discretion, provide maintenance and support of the Website. Unless a separate maintenance and support agreement is provided, in writing and clearly identified as a maintenance and support agreement, by the Company to you, the terms and conditions of these terms of service solely govern maintenance and support.</p>
                <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>5. You may not and you agree not to, or to enable others to, copy, decompile, reverse engineer, disassemble, attempt to derive the source code of, decrypt, modify, or create derivative works of the Website or any services provided by the Website, or any part thereof except as and only to the extent any foregoing restriction is prohibited by applicable law. You may not use the Website, including the Company content, to train artificial intelligence programs or services.</p>
                <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>6. It is your responsibility to ensure compliance with all applicable statutes, rules, and policies related to the use of the Website. You may not use or otherwise export or re-export the Website information except as authorized by United States law. It is your responsibility to correctly enter data and select appropriate parameters and options to meet your needs. The tools, modules and data provided by the Website are for research and estimation purposes only, and may not reflect or meet your particular situation and needs. Information provided by the Website should not be solely relied upon for the purpose of making a decision on your operations. The Website does not constitute tax, legal, or investment advice and you should seek the services of a professional for such advice.</p>
                <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>7. Reference to any specific commercial products, process, or service by trade name, trademark, manufacturer, or otherwise, does not constitute or imply its endorsement, recommendation, or favoring by the Company. The views and opinions expressed in app do not state or reflect those of the Company and shall not be used for advertising or product endorsement purposes.</p>
                <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>8. You acknowledge that the Website may be unavailable due to regularly scheduled maintenance or unexpected system problems. The Company makes no representations or warranties that the Website will work uninterrupted or error free.</p>
                <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>9. You are required to take all reasonable steps to ensure that your use of the Website does not delay or harm function of the Website or the Company.</p>
                <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>10. You agree that the Company and its agents may collect and use information based on your use of the Website to allow the Company to improve, update, support, and invoice Website use, and the Company and its agents may use your data in an anonymized, aggregated form for other Website users, research, and education. The Company may audit your use of the Website to investigate any possible violation of these terms of use.</p>
                <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>11. The Company reserves the right to modify or discontinue any services provided on the Website and any conditions or terms of service without notice. The Company will make reasonable efforts to try to notify you of substantial changes in the conditions or terms of service. However, you are responsible for checking the Website for updates. The Company encourages you to check on a regular basis. You are responsible for reading and understanding those changes. If you do not agree to the changes, then you must stop using the Website. Your continued use of the Website constitutes acceptance of changes.</p>
                <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>12. The Company may, under certain circumstances and without notice, immediately terminate your access to the Website. Cause for such termination includes, but is not limited to: (a) breach of the Website terms of service, (b) requests by government entities, including law enforcement, (c) requests by a vendor, (d) discontinuance of services or modifications of the Website, (e) technical problems or errors, and (f) extended periods of inactivity.</p>
                <p style={{ lineHeight: 'normal', margin: '0cm 0cm 8pt', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>13. Disclaimer: All use of the Website is at your own risk. The information found on the Website and provided through any services offered on the Website is provided by the Company or one of its affiliates and is intended for use by professional registered dietitians, registered pharmacists or other healthcare professionals, is not patient-specific, and is not intended to be a substitute for professional medical advice, diagnosis, or treatment. To the extent any conflict exists between the Information and the United States Prescribing Information (the “PI”), the PI shall prevail. The information found on this Website are an extraction from the PI, and published literature and thus do not contain all warnings or information contained in the PI. While the Information is updated on a regular basis, the Website may sometimes lag behind PI changes and thus may have outdated information.</p>
                <p style={{ lineHeight: 'normal', margin: '0cm 0cm 8pt', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>The information is provided “as-is” and “as available” and we expressly disclaim, to the fullest extent permitted by applicable law, any warranties and conditions of any kind, whether express or implied, including the warranties or conditions of merchantability, fitness for a particular purpose, accuracy, or non-infringement. Without limiting the foregoing, we make no warranty that the information: (a) will meet your requirements; (b) will be available on an uninterrupted, timely, secure, or error-free basis; or (c) will be accurate, reliable, free of viruses or other harmful code, complete, legal, or safe. Certain jurisdictions do not allow limitations on implied warranties, so portions of the foregoing exclusion may not apply to you.&nbsp;</p>
                <p style={{ lineHeight: 'normal', margin: '0cm 0cm 8pt', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>14. Limitation of Liability: To the fullest extent permitted by applicable law, in no event shall we be liable to you or any third party for any loss, consequence, effect, adverse reaction, injury, claim, liability, or damage of any kind resulting from you using or accessing the Website information (collectively, “damages”). Without limiting the generality of the foregoing, in no event shall we be liable to you or any third party for any lost profit or any indirect, consequential, exemplary, incidental, special, or punitive damages arising from or relating to the Website, even if we have been advised of the possibility of such damages. Notwithstanding anything to the contrary contained herein, our liability to you for any damages arising from or related to the Website (for any cause whatsoever and regardless of the form of the action), will at all times be limited to the aggregate amount that you paid us in the prior 12 months (if any). The existence of more than one claim will not increase this limit.</p>
                <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>15. Indemnities: subject to constitutional and statutory limitations and restrictions, you shall indemnify and defend the Company and its officers, employees and agents from and against all claims, suits, actions, losses, damages, liabilities, costs and expenses of any nature whatsoever arising out of or relating to your acts or omissions or those of your officers, employees, subcontractors, or agents under the Website terms of service.</p>
                <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>16. These terms of service are governed by and will be construed in accordance with the laws of the province of British Columbia and the laws of Canada applicable therein, without regard to conflict of laws principles. By using or accessing the information, you attorn to the jurisdiction of the courts of competent jurisdiction located in British Columbia, Canada.</p>
                <p style={{ margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}>17. Miscellaneous: all rights and obligations under the Website terms of service intended by their nature to survive termination of this agreement, such as confidentiality obligations, survive the terms granted in the termination of this agreement. If a court of competent jurisdiction finds any part of the Website terms of service are unenforceable, then that part of the terms of service will be enforced to the maximum extent possible so as to effect the intent of the parties and the validity of the remaining terms of service shall not be affected. You and the Company are the only parties to the Website terms of service and are the only parties entitled to enforce its terms; there are no intended beneficiaries of the Website terms of use. By use of the Website, you hereby acknowledge that you have read these terms of use, understand them, and agree to be bound by them.</p>
                <p style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Any Questions?</strong> Get in touch at
                    <strong>
                        <span className='ml-2' style={{ cursor: 'pointer', color: '#A7CA56' }} onClick={(e) => { window.open(`https://web.drugnutritioninteractions.com/#/contact`, '_blank'); }}>
                            dniteam@drugnutritioninteractions.com
                        </span>
                    </strong>
                </p>
            </div>
        </>
    );
}

export default UsTOC;