// import {auth} from "./auth";
import { useLocation } from 'react-router-dom'
import { useNavigate, Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Moment from 'moment-timezone';
import moment from 'moment';
import { toast } from 'react-toastify';
export default function RequireAuth({ children }) {
  var isAuthenticated
  if (localStorage.getItem("setUser")) {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }
  const navigate = useNavigate();
  const location = useLocation();
  return isAuthenticated === true ? (
    children
  ) : (
    // navigate("/signin")
    <Navigate to="/" replace state={{ path: location.pathname }} />
  );
}


function getToken() {
  // debugger
  if (localStorage.getItem('setUser') != null) {
    var t = localStorage.getItem("setUser");
    t = JSON.parse(t)
    if (t !== null) {
      if (t === undefined || t == null) {
        return "InvalidUser";
      }
      const decoded = jwt_decode(t?.token);
      let servertime = moment(decoded?.exp * 1000).format('MM/DD HH:mm')
      const time = Moment.tz(servertime)
      const localtime = moment(time?._d).format('MM/DD HH:mm');
      if (localtime >= servertime) {
        // toast.error('Unauthorized User', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        setTimeout(() => {
          // localStorage.clear();
          // window.location.href = "/#/signin"
        }, 2000)
      }
    }
  }
}