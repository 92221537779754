import React from 'react'
import "./PricePlan.scss";


function PricePlan(props) {
  const featureList = [...props.feature]
  return (
    <div className="pricing-plan">
      <span className={`icon novi-icon icon-lg fl-great-icon-set-ico text-primary ${props.icon} mb-5`}></span>
      <p className="pricing-header text-big font-weight-bold text-primary">{props.plan}</p>
      {props.plan == "Student" ?
        <ul className="pricing-features">
          {featureList.map((item, i) => <li className="pricing-features-item" style={{ fontSize: 15 }} key={i}>{item}</li>)}
        </ul>
        : <><br /><br /><br /><br /><br /><br /></>}

      <span className="pricing-price mb-3">{props.price}</span>
      <a href={props.href} className="pricing-button btn btn-primary">{props.btnTxt}</a>
    </div>
  )
}

export default PricePlan;