import React ,{useState,useEffect} from 'react'
import { DummyImage } from "react-simple-placeholder-image";
import { Link } from "react-router-dom";
import AccountNav from '../AccountNav';
import SearchBar from '../SearchBar';


function AccountHome() {
    const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;
    const [userDetail, setuserDetail] = useState("");
	useEffect(() => {

        if(reloadCount<1) {
            sessionStorage.setItem('reloadCount', String(reloadCount + 1));
            window.location.reload();
          } else {
            sessionStorage.removeItem('reloadCount');
          }

		if (localStorage.getItem("setUser") != null) {
			let info = localStorage.getItem("setUser")
			let userInfo = JSON.parse(info);
			setuserDetail(userInfo?.userInfo)
		}
	}, []);

    return (
        <div>
            <section className="section bg-gray-lighter mb-5 novi-background bg-cover">
            <section class="section section-height-800 parallax-container context-dark bg-gray-darkest text-xl-left" data-parallax-img="./images/banner/banner5.jpg" data-preset='{"title":"Breadcrumbs","category":"main","id":"breadcrumbs"}'>
          <div class="parallax-content">
            <div class="bg-overlay-black">
              <div class="container section-80 section-md-95 section-lg-top-120 section-lg-bottom-150">
                <div class="d-none d-lg-block">
                  <h1>Account</h1>
                </div>
                {/* <!-- List Inline--> */}
                <ul class="list-inline list-inline-dashed list-white text-big p offset-md-top-13">
                  <li><a href="#">Home</a></li>
                  <li>Account</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
                <div className="container">
                    {/* <h2 >DNI Account Information</h2> */}
                    <h2 style={{color:'#F3F3F3'}}>DNI Account Information</h2>
                    <div className='row account mb-5 mt-4'>
                        {/* <div className='col-lg-3 mb-3'>
                            <AccountNav />
                        </div> */}
                        <div className='col-lg-8' style={{ margin: 'auto' }}>
                       
                            <div className='bg-white shadow p-4'>
                            {/* <DummyImage width={150} height={150} placeholder='Dummy Image' className='rounded-circle border-bottom mb-4' bgColor='#f9f9f9  ' fgColor='#999999' /> */}
                                <h2 className=''>Hello, {userDetail?.first_name+ "  "+ userDetail?.last_name}!</h2>
                                <h5 className='mb-5'>Email: {userDetail?.email_id}</h5>
                                
                                {
                                    userDetail?.payment_status=="succeeded"?<>
                                <p className='mb-5'>Vivamus ultricies non odio eu rutrum. Praesent ac urna ut risus tristique condimentum. Tincidunt sem. Aliquam erat volutpat. Quisque nulla lacus, varius convallis nisl nec, ultricies bibendum sem. Quisque vitae tempor ante. </p>

                                    <div className='row'>
                                    
                                    <div id="myAccount" className='col-md-6' onClick={(e) => {
                                        window.location.href = `#/account/my-account`
                                    }}>
                                        <div style={{ color: 'whitesmoke', fontFamily: 'sans-serif', fontSize: 25, fontWeight: 'bold' }}>
                                            My Account
                                        </div>
                                    </div>
                                    <div id="myPlan" className='col-md-6' onClick={(e) => {
                                        window.location.href = `#/account/search`
                                    }}>
                                        <div style={{ color: 'whitesmoke', fontFamily: 'sans-serif', fontSize: 25, fontWeight: 'bold' }}>My Search</div>
                                    </div>
                                   
                                </div>
                                <div className='row'>
                                <div className='col-md-6' id="myDrugList" onClick={(e) => {
                                        window.location.href = `#/account/drug-list`
                                    }}>
                                        <div style={{ color: 'whitesmoke', fontFamily: 'sans-serif', fontSize: 25, fontWeight: 'bold' }}>My Drug List</div>
                                    </div>
                                    <div id="myPaymentHistory" className='col-md-6' onClick={(e) => {
                                        window.location.href = `#/account/payment-history`
                                    }}>
                                        <div style={{ color: 'whitesmoke', fontFamily: 'sans-serif', fontSize: 25, fontWeight: 'bold' }}>My Payment History</div>
                                    </div>
                                </div></>:<>
                                <div className='row'>
                                    <div className='col-lg-4' style={{margin:'auto'}}>
                                        <strong style={{color:'#eda53a',fontFamily:'unset'}}>Your Payment Is Pending Please Complete Your Paymet.</strong>
                                        <button className='btn btn-primary mt-4' onClick={(e)=>{
                                            window.location.href="#/plan-selection"
                                        }}>Proceed</button>
                                    </div>
                                </div>
                                </>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div><br/>
            </section>
        </div>
    )
}

export default AccountHome  