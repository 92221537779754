import React from "react";
import PageHeader from "../../../components/PageHeader/PageHeader";
import Table from 'react-bootstrap/Table';
const Phytate = () => {

    return (
        <div style={{textAlign:'initial'}}>
         <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                    bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                    title="Phytic&nbsp;Acid&nbsp;(Phytate)&nbsp;Content&nbsp;Of&nbsp;Foods"  />
            <div className="container mt-4">
            <p style={{fontWeight:'700px',fontSize:15,color:'#484343',marginLeft:280}}><b>FOOD ITEM</b></p>
<Table striped bordered hover style={{ width: '50%', margin: 'auto', marginTop: 20 }}>

    <tbody>
        
    <tr >
        <td ><b>&nbsp;Grain, Grain Products</b></td>
        <td><b>&nbsp;Phytic Acid <br/>&nbsp;(mg)</b></td>
    </tr>
    <tr >
        <td >&nbsp;wheat bran, crude - 1 oz (28 gm)</td>
        <td >&nbsp;843</td>
    </tr>
     <tr >
        <td >&nbsp;wheat germ 1 T (6 gm)</td>
        <td>&nbsp;244</td>
        
    </tr>
     <tr >
        <td >&nbsp;barley, whole grain 3.5 oz (100 gm)</td>
        <td>&nbsp;970</td>
       
    </tr>
     <tr >
        <td >&nbsp;barley, pearl, raw - 3.5 oz 100 gm</td>
        <td>&nbsp;491</td>
       
    </tr>
     <tr >
        <td >&nbsp;oats, raw - 3.5 oz (100 gm)</td>
        <td>&nbsp;943</td>
        
    </tr>
     <tr >
        <td >&nbsp;com chips - 1 oz (28 gm)</td>
        <td>&nbsp;178</td>
        
    </tr>
     <tr >
        <td ><b>&nbsp; Cereals (per ounce)</b></td>
        <td>&nbsp;</td>
        
    </tr>
     <tr >
        <td >&nbsp;All Bran</td>
        <td>&nbsp;679</td>
        
    </tr>
     <tr >
        <td >&nbsp;Shredded Wheat</td>
        <td>&nbsp;415</td>
        
    </tr>
     <tr >
        <td >&nbsp;wheat flakes</td>
        <td>&nbsp;411</td>
        
    </tr>
     <tr >
        <td >&nbsp;oatmeal (cooked) half-cup</td>
        <td>&nbsp;113</td>
        
    </tr>
     <tr >
        <td >&nbsp;Bread, rye 1 slice</td>
        <td>&nbsp;235</td>
        
    </tr>
     <tr >
        <td ><b>&nbsp;Nuts, Nut products and Seeds 3.5 oz &nbsp;(100 gm)</b></td>
        <td>&nbsp;</td>
        
    </tr>
   
     <tr >
        <td >&nbsp;almonds</td>
        <td>&nbsp;1280</td>
        
    </tr>
     <tr >
        <td >&nbsp;brazil nuts</td>
        <td>&nbsp;1799</td>
        
    </tr>
     <tr >
        <td >&nbsp;coconut, raw</td>
        <td>&nbsp;270</td>
        
    </tr>
     <tr >
        <td >&nbsp;hazelnuts</td>
        <td>&nbsp;604</td>
        
    </tr>
     <tr >
        <td >&nbsp;peanuts</td>
        <td>&nbsp;748</td>
        
    </tr>
   

     <tr >
        <td >&nbsp;walnuts</td>
        <td>&nbsp;760</td>
        
    </tr>
     <tr >
        <td >&nbsp;peanut butter 1T (16 gm)</td>
        <td>&nbsp;200</td>
        
    </tr>
     <tr >
        <td >&nbsp;sesame seeds, raw - 1 oz (28 gm)</td>
        <td>&nbsp;1319</td>
        
    </tr>
   
     <tr >
        <td ><b>&nbsp;Vegetables 3.5 oz (100 gm)</b></td>
        <td>&nbsp;</td>
        
    </tr>

     <tr >
        <td >&nbsp;rice, wild, raw</td>
        <td>&nbsp;2200</td>
        
    </tr>
     <tr >
        <td >&nbsp;rice, brown, raw</td>
        <td>&nbsp;890</td>
        
    </tr>
     <tr >
        <td >&nbsp;rice, white, long-grain, raw</td>
        <td>&nbsp;340</td>
        
    </tr>

     <tr >
        <td >&nbsp;rice, white, short-grain, raw</td>
        <td>&nbsp;140</td>
        
    </tr>
     <tr >
        <td >&nbsp;kidney beans, raw</td>
        <td>&nbsp;1200-2060</td>
        
    </tr>
     <tr >
        <td >&nbsp;garbanzo beans, raw</td>
        <td>&nbsp;280</td>
        
    </tr>
     <tr >
        <td >&nbsp;navy beans, raw</td>
        <td>&nbsp;280</td>
        
    </tr>

     <tr >
        <td >&nbsp;peas, dried, raw</td>
        <td>&nbsp;851</td>
        
    </tr>
     <tr >
        <td >&nbsp;pinto beans, raw</td>
        <td>&nbsp;620-1950</td>
        
    </tr>

     <tr >
        <td >&nbsp;white beans, raw</td>
        <td>&nbsp;1030</td>
        
    </tr>

     <tr >
        <td ><b>&nbsp;Miscellaneous 3.5 oz (100 gm)</b></td>
        <td>&nbsp;</td>
        
    </tr>






     <tr >
        <td >&nbsp;cocoa, dry powder 1 T (5 gm)</td>
        <td>&nbsp;94</td>
        
    </tr>

     <tr >
        <td >&nbsp;soybean meal</td>
        <td>&nbsp;1200-1600</td>
        
    </tr>
     <tr >
        <td >&nbsp;soybean protein concentrate</td>
        <td>&nbsp;1240-2170</td>
        
    </tr>

     <tr >
        <td >&nbsp;soybean protein isolate</td>
        <td>&nbsp;430-1170</td>
        
    </tr>



    </tbody>
   
</Table>
<br/>
<br/>
<Table>
    
<p><b>Reference: </b>
<a target={"_blank"} href="https://pubmed.ncbi.nlm.nih.gov/19774556/">
<br/>Schlemmer U, et al. Phytate in foods and significance for humans: food sources, intake, processing, bioavailability,<br/>protective role and analysis.
Mol Nutr Food Res 2009; 53 Suppl 2: S330-S375.
  </a></p>
</Table>


            </div>
        </div>
    );

}

export default Phytate;