import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import NewsComp from '../../components/News';
import Sidebar from '../../components/Sidebar';
import BackIcon from '../../assets/images/previousBtn.png'
import { AUTENTICATE_API } from '../../services/Api/authenticationApi';
const styles = {
    marginTop: 0,
    marginBottom: 0,
    borderTop: '1px solid #9F9F9F',
    marginTop: 2
};

class News extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newsData: [],
            newsDataPopular: [],
            currentPage: 1,
            todosPerPage: 3,
            ids: ''
        }
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.guestnews_list();
        if (localStorage.getItem('setUser') != null) {
            let info = localStorage.getItem("setUser")
            let userInfo = JSON.parse(info);
            this.guestnews_listByUser(userInfo?.userInfo?.user_id);
        }
    }

    handleClick(event) {
        this.setState({
            currentPage: Number(event.target.id),
            ids: Number(event.target.id)
        });
    }


    guestnews_list() {
        AUTENTICATE_API?.guestnews_list().then(res => {
            if (res && res?.data?.length) {
                AUTENTICATE_API.areaInterestList().then((response) => {
                    let ds = res?.data?.map(newsVal => {
                        response?.data?.map(val => {
                            newsVal?.tags?.map(tagVal => {
                                let ids = [];
                                ids.push(parseInt(tagVal));
                                if (ids?.includes(val.opt_id) == true) {
                                    newsVal.tags.push(val?.opt_name);
                                }
                            });
                        });
                        return newsVal
                    })
                    this.setState({
                        newsData: ds?.map(val => {
                            const middleIndex = Math.ceil(val?.tags.length / 2);
                            const secondHalf = val?.tags.splice(-middleIndex);
                            let obj = {
                                Banner_img: val?.Banner_img,
                                author: val?.author,
                                created_dt: val?.created_dt,
                                description: val?.description,
                                image: val?.image,
                                news_id: val?.news_id,
                                title: val?.title,
                                source: val?.source,
                                tags: secondHalf
                            }
                            return (obj);
                        })
                    })
                })
            } else {
                history.back()
            }
        })
    }

    guestnews_listByUser = (id) => {
        AUTENTICATE_API?.guestnews_listByUser(id).then(res => {
            if (res && res?.count > 0) {
                var fl = [];
                this.setState({ newsDataPopular: res?.data }, () => {
                    let id = [];
                    let otherNews = [];
                    this.state.newsDataPopular?.map(val => {
                        id.push(val.news_id)
                    });
                    this.state.newsData?.map(vals => {
                        if (id?.includes(vals?.news_id) == false) {
                            otherNews?.push(vals)
                        }
                    });
                    let final = this.state.newsDataPopular.concat(otherNews);
                    this.setState({ newsData: final })
                });
            }
        });
    }

    render() {
        const { newsData, currentPage, todosPerPage } = this.state;
        // Logic for displaying todos
        const indexOfLastTodo = currentPage * todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        const currentTodos = newsData.slice(indexOfFirstTodo, indexOfLastTodo);
        const renderTodos = currentTodos.map((val, index) => {
            return <div>
                <NewsComp
                    key={index}
                    image={val?.Banner_img}
                    title={val?.title}
                    date={moment(val?.created_dt).format('MMMM DD, YYYY')}
                    author={val?.author}
                    desc={val?.description}
                    newsTag={val?.tags}
                    source={val?.source}
                    id={val?.news_id}
                />
                {index !== currentTodos?.length - 1 ? <hr
                    style={{
                        marginTop: 15,
                        marginBottom: 0,
                        borderTop: '1px solid #9F9F9F',
                    }}
                /> : ''}
            </div>;
        });

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(newsData.length / todosPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map(number => {
            return (
                <li style={{ background: (this.state.currentPage == number) ? '#a6c550' : '', color: (this.state.currentPage == number) ? 'white' : '', cursor: 'pointer' }}
                    key={number}
                    id={number}
                    onClick={this.handleClick}
                >
                    {number}
                </li>
            );
        });


        return (
            <div>
                {/* <!-- Parallax--> */}
                <section className="section search-bg2 section-height-800 parallax-container context-dark bg-gray-darkest text-xl-left" data-parallax-img="./images/banner/banner5.jpg">
                    <div className="parallax-content">
                        <div className="bg-overlay-black">
                            <div className="container section-80 section-md-30 section-lg-top-10 section-lg-bottom-30" style={{ paddingTop: 45 }}>
                                <div className="d-none d-lg-block">
                                    <h1 className='mt-4'>DNI News</h1>
                                </div>
                                <div className='d-flex d-lg-block'>
                                    <img src={BackIcon} alt="Back" className="d-lg-none d-md-none" onClick={() => { window.location.href = '/#/' }} style={{ width: '8%', height: "8%", marginTop: '18%' }} />
                                    <ul className="list-inline list-inline-dashed list-white text-big p offset-md-top-8">
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Blog Classic-->*/}
                {
                    this.state.newsData?.length > 0 ?
                        <section id="section-10" className="section section-10 section-md-bottom-30 text-left novi-background bg-cover" data-preset='{"title":"Blog Classic","category":"blog","id":"blog-classic"}'>
                            <div className="container">
                                <div className="row row-fix justify-content-sm-center">
                                    <div className="col-md-10 col-lg-8 row-16 article-wrapper">
                                        <div >
                                            {renderTodos}
                                        </div><br />
                                        <ul id="page-numbers">

                                            {renderPageNumbers}
                                        </ul>
                                    </div>
                                    <div className="col-md-10 col-lg-4 offset-top-90 offset-md-top-0">
                                        <div>
                                            <aside className="inset-lg-left-20 row-50 aside-wrapper">
                                                <div className="aside-item">
                                                    <h3 className='mt-3'>Popular posts</h3><br /><br />
                                                    {this.state.newsData?.map((val, i) => <div key={i}>
                                                        {i <= 10 ? <div className="post-wrapper row-30 offset-top-13">
                                                            <div className="post-item" style={{ cursor: 'pointer', marginTop: '-20px' }} onClick={(e) => {
                                                                window.location.href = `#/news/news-details/${val.news_id}`
                                                            }}>
                                                                <div className="unit align-items-center flex-row unit-spacing-sm" style={{ marginTop: '-45px' }}>
                                                                    {/* <div className="unit-left">
                                                              <a className="thumbnail-classic">
                                                                  <span className="thumbnail-classic-img-wrap">
                                                                      <img className="img-responsive center-block" src={val?.Banner_img} width="80" height="80" alt="" style={{ height: 70 }} /></span></a>
                                                          </div> */}
                                                                    <div className="unit-body">
                                                                        <p className="font-weight-bold">
                                                                            {val?.title?.substring(0, 70) + '...'}
                                                                        </p>
                                                                        <p style={{ marginTop: '6px' }}>
                                                                            <span id="grapft" dangerouslySetInnerHTML={{ __html: val?.description?.substring(0, 100) + '...' }}></span>
                                                                        </p>
                                                                        {i !== this.state.newsData?.length - 1 ? <hr
                                                                            style={{
                                                                                marginTop: 0,
                                                                                marginBottom: 0,
                                                                                borderTop: '1px solid #9F9F9F',
                                                                            }}
                                                                        /> : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : ''}
                                                    </div>)}
                                                </div>
                                            </aside>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        <div className="row">
                            <div className='col-md-2 py-5' style={{ margin: 'auto' }}>
                                <button style={{ color: 'white', background: '#A6C550', border: '1px solid #A6C550' }}>Coming soon</button>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default News