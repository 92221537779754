import React, { useEffect, useState, CSSProperties } from 'react';
import { HoverBox, PostBox } from './../../components/Box/Box';
import { Swiper, OwlCarousel } from './../../components/Slider/Slider';
import Counter from './../../components/Counter/Counter'
import PricePlan from '../../components/Priceplan';
import Search from './../Search/index';
import { PLAN_SELECTION } from "../../services/Api/PlanselectionApi";
import MySearch from '../Search/mySearch';
import { AUTENTICATE_API } from '../../services/Api/authenticationApi';
import Carasual from '../../components/cau';
import logoHome from "../../assets/images/banner/welcomeImg2.jpg"
import { Link } from 'react-router-dom';
import firstImg from "../../assets/home/first_slider.jpg";
import SecondImg from "../../assets/home/second_slider.jpg";
import thirdImg from "../../assets/home/third_slider.jpg";
import Loader from "react-loader";
import { ThreeDots } from 'react-loader-spinner'
import YouTubePlayer from './youtubePlayer';

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};



function Home() {
  const plansType = [{ 2: 'Yearly', 1: 'Monthly' }]
  const reloadCount111 = Number(sessionStorage.getItem('reloadCount111')) || 0;
  const reloadCount111nv = Number(sessionStorage.getItem('reloadCount111nv')) || 0;
  const [plans, setPlans] = useState([]);
  const [userDetail, setuserDetail] = useState("");

  let [loading, setLoading] = useState(false);

  const getPlans = () => {
    PLAN_SELECTION.rootPlanSelectionList().then(res => {
      if (res && res?.data?.length > 0) {
        setPlans(res?.data);
      }
    })
  }

  useEffect(() => {
    if (reloadCount111nv < 1) {
      if (localStorage.getItem("setUser") !== null && localStorage.getItem("homeLoad") != null) {
        // localStorage.setItem('homeLoad', true)
        setLoading(true)
      }
      if (localStorage.getItem("setUser") !== null && localStorage.getItem("homeLoad") == null) {
        // localStorage.setItem('homeLoad',true)
        setTimeout(() => {
          window.location.reload();
        }, 200);
      }
      sessionStorage.setItem('reloadCount111nv', String(reloadCount111nv + 1));
    } else {
      sessionStorage.removeItem('reloadCount111nv');
    }
    setTimeout(() => {
      setLoading(true)
    }, 1000);
  }, [])


  useEffect(() => {
    if (reloadCount111 < 1) {
      if (localStorage.getItem("setUser") == null && localStorage.getItem("homeLoad") == null) {
        window.location.reload()
      }
      sessionStorage.setItem('reloadCount111', String(reloadCount111 + 1));
      // getPlans();
    } else {
      sessionStorage.removeItem('reloadCount111');
    }
    getPlans();
    if (localStorage.getItem("setUser") != null) {
      let info = localStorage.getItem("setUser")
      let userInfo = JSON.parse(info);
      setuserDetail(userInfo?.userInfo)
    }
  }, [firstImg])

  return (
    <>

      {!loading && localStorage.getItem('setUser') != null ?
        <section style={{ marginTop: 300, display: loading ? 'none' : '' }} className="section" data-autoplay="5000" data-min-height="400px" data-simulate-touch="false" data-slide-effect="fade" data-preset='{"title":"Slider classNameic","category":"sliders","id":"slider-classNameic"}'>
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#A7CA56"
            ariaLabel="three-dots-loading"
            wrapperStyle={{ display: 'auto' }}
            wrapperClassName=""
            visible={true}
            style={{ display: "inline" }}
          />
        </section>
        :
        <>
          {userDetail?.payment_status == "succeeded" ?

            <section className="section" data-autoplay="5000" data-min-height="400px" data-simulate-touch="false" data-slide-effect="fade" data-preset='{"title":"Slider classNameic","category":"sliders","id":"slider-classNameic"}'>

              <MySearch />
            </section>
            :
            <section className="section swiper-container swiper-slider swiper-slider-variant-1" data-autoplay="5000" data-min-height="400px" data-simulate-touch="false" data-slide-effect="fade" data-preset='{"title":"Slider classNameic","category":"sliders","id":"slider-classNameic"}'>
              <div className="swiper-wrapper">
                <Swiper
                  bgImg={firstImg}
                  sliderHeading="Simply Search"
                  sliderTxt="Drugs are complicated. Let DNI simplify your search"
                  btnText="Subscribe"
                  btnHref="#/register"
                />
                <Swiper
                  bgImg={SecondImg}
                  sliderHeading="Always With You"
                  sliderTxt="As a busy professional, you’re on the move. Anywhere you practice, DNI is at your fingertips."
                  btnText="Subscribe"
                  btnHref="#/register"
                />
                <Swiper
                  bgImg={thirdImg}
                  sliderHeading="Expert Advice"
                  sliderTxt="Rely on DNI expert dietitians and pharmacists and spend more time with your patient."
                  btnText="Subscribe"
                  btnHref="#/register"
                />
              </div>
              <div className="container section-relative">
                <div className="swiper-pagination swiper-pagination-right"></div>
              </div>
            </section>}

          <section className="section section-30 novi-background bg-cover" data-preset='{"title":"Welcome","category":"about","id":"welcome"}'>
            <div className="container">
              <div className="row row-30 justify-content-sm-center">
                <div className="col-md-8 col-lg-6 order-lg-1 col-12">
                  {localStorage.getItem('setUser') == null ? <YouTubePlayer url="https://youtu.be/3eZsuPvT8EQ?si=EdanoWqEK9Jq6kLT" /> : <>
                    {loading ? <img className="img-responsive center-block" src={logoHome} width="570" height="350" alt="" /> : ""}
                  </>}
                </div>
                <div className="col-md-8 col-lg-6 offset-top-34 offset-md-top-0">
                  {/* <div className="inset-lg-left-50 inset-lg-right-50 inset-xl-left-50 inset-xl-right-50"> */}
                  <div className="">
                    <h1>Welcome to DNI!</h1>
                    <p className="offset-lg-top-20" style={{ fontSize: 17, color: '#585858' }}>Expertly curated and up to date, supporting busy dietitians to make sense of the nutritional impact of increasingly complicated medication regimens.</p>
                    <p className="text-big font-weight-bold text-gray offset-lg-top-20">Drug information for dietetics professionals accessible anytime, anywhere.</p>
                    {localStorage.getItem('setUser') != null ? "" : <a className="btn btn-primary offset-top-20" href="#/register">Subscribe</a>}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section section-60 novi-background bg-cover" id="sub-plan-caontainer" data-preset='{"title":"Welcome","category":"about","id":"welcome"}'>
            {userDetail?.payment_status == "succeeded" ? <div style={{ marginTop: 100 }}></div> :
              <div className="container" style={{ marginTop: 100 }}>
                <div className="row row-fix justify-content-sm-center">
                  <div className="col-md-15 m-5">
                    <br />
                    <Link to="quest/drug-details" target='_blank' className='btn-success p-3 mt-2 shadow-none' style={{ fontSize: 28, fontWeight: "bold" }}>View a sample DNI entry</Link>
                    <br />
                    <br />
                    <h2 className='mb-0 mt-3'>Subscription Plans</h2>
                    <p className='mb-3' style={{ fontSize: 17, color: '#585858' }}>Unlock access today to the best drug information site for dietetics professionals</p>
                  </div>
                </div>
                <div className="row row-30 justify-content-sm-center">
                  {plans?.map((val, i) => <div key={i} className="col-md-8 col-lg-3">
                    <PricePlan
                      icon="fl-great-icon-set-sportive64"
                      plan={val?.plan_name}
                      feature={[`${val?.plan_name == "Student" ? 'With School Promo Code' : ""}`]}
                      price={`US $${val?.plan_price_us}/${plansType[0][val?.plan_type]}`}
                      btnTxt="Subscribe"
                      href={localStorage.getItem('setUser') == null ? "#/register" : `#/module-selection/${val?.plan_id}`}
                    />
                  </div>)}
                </div>
              </div>}

          </section>
          <section className="section section-20 novi-background bg-cover" data-preset='{"title":"News Classic","category":"news","id":"news-classic"}'>
            <div className="container">

              <Carasual />
            </div>
          </section>
        </>}
    </>
  )
}

export default Home