import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { toast } from 'react-toastify'
import { AUTENTICATE_API } from '../../services/Api/authenticationApi'
import { SEARCH_API } from '../../services/Api/searching'
import { setLocatState } from '../../redux/actions/product'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
function Signin() {
  const dispatch = useDispatch();
  const reloadCount1 = Number(sessionStorage.getItem('reloadCount1')) || 0;
  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")
  const [isVerified, setVerfied] = useState(true)
  const [verficationcode, setVerificationCode] = useState("")
  const [setUser, setUsers] = useState("");
  const [tremTitle, setTermTitle] = useState("");
  const [trem, setTerm] = useState(false);
  const [openTOS, setTOS] = useState(false);
  const [globalRes, setGlobalRes] = useState("");

  const [urlTOC, setUrlTOS] = useState("");

  const [tremContinue, setTermContinue] = useState(false);

  useEffect(() => {
    if (reloadCount1 == 0 && localStorage.getItem('setUser') == null) {
      window.location.reload();
      sessionStorage.setItem('reloadCount1', String(reloadCount1 + 1));

    } else {
      sessionStorage.removeItem('reloadCount1');
    }
    localStorage.removeItem('state');
    localStorage.removeItem('setUser');
    localStorage.removeItem('setPlan')
    localStorage.clear();
    // window.location.reload()
  }, [])

  const handleChange = (e) => {
    // if (e.target.name === "verficationcode") { setVerificationCode('') }
    if (e.target.name === "email") { setemail('') }
    if (e.target.name === "password") { setpassword('') }
    if (e && e.target && e.target.value) {
      if (e.target.name === "email") {
        setemail(e.target.value)
      } else if (e.target.name === "password") {
        setpassword(e.target.value)
      } else if (e.target.name === "verficationcode") {
        if (e?.target.value?.length < 5) {
          setVerificationCode(e.target.value)
        } else {

        }
      }
    }
  }

  const handleVerify = (e) => {
    e.preventDefault()
    let obj = {
      email_id: email,
      verfication_code: parseInt(verficationcode)
    }
    AUTENTICATE_API.userVerification(obj).then(res => {
      if (res?.status == 200) {

        let setUser = {
          userInfo: res.data,
          token: res?.token
        }
        let c_view = (setUser.userInfo.country_view_id !== undefined || setUser.userInfo.country_view_id !== "" || setUser.userInfo.country_view_id !== 'undefined') ? setUser.userInfo.country_view_id : setUser.userInfo.country_id == 2 ? 2 : 1
        setUser.userInfo.country_view_id = c_view > 2 ? 1 : c_view;
        setUsers(setUser)
        localStorage.setItem('setUser', JSON.stringify(setUser));

        toast.success(res?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        let obj1 = {
          "pharm_class": 1,
          "thera_class": 1,
          "routes": 1,
          "dose_forms": 1,
          "natural_products": 1,
          "renal_warning": 1,
          "protein_binding": 1,
          "dialysis": 1,
          "hepatic_warning": 1,
          "pregnancy_warnings": 1,
          "lactation_warnings": 1,
          "oral_adverse_effects": 1,
          "lab_parameters": 1,
          "cross_references": 1,
          "citations": 1,
          "user_id": res?.data?.user_id
        }

        if (obj1) {
          SEARCH_API.user_drugDetail_visibility(obj1).then(res => {
            if (res && (res?.status == 201 || res?.status == 200)) {
              // toast.success(res?.message, { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
              this.getView(this.state.userInfo?.user_id)
            } else {
              // toast.error('Not updated !', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
            }
          })
        }
        setTimeout(() => {
          setVerfied(false);
          window.location.href = "#/plan-selection"
        }, 2000)
      } else {
        toast.error(res?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT })

      }
    })
  }

  const resetCode = (e) => {
    e.preventDefault()
    AUTENTICATE_API.otpResend(email).then(res => {
      if (res?.status === 201) {
        toast.success(res?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        setTimeout(() => {
          setVerfied(true);
          setTerm(false);
          setTOS(false)
        }, 2000)
      }
    })
  }

  const handleLogin = (e) => {
    e.preventDefault();

    // if (trem == false) {
    //   toast.error('Please I accept the DNI Website Terms of Service ', { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
    //   return false
    // }

    if (email == "" || password == "") {
      toast.error('Invalid Email Id or Password', { autoClose: 2000, position: toast.POSITION.TOP_RIGHT });
    }
    var emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
    if ((email != "") && (!(emailfilter.test(email)))) {
      toast.error('Please enter the valid email', { autoClose: 2000, position: toast.POSITION.TOP_RIGHT });
      return false;
    }
    if (email !== '' && password !== '') {
      let obj = {
        email_id: email,
        password: password,
      }
      AUTENTICATE_API.UserLogin(obj).then(res => {
        if (res && res.status === 200) {
          setGlobalRes(res)
          if (res.data.Otp_Verify == 1 && res?.data?.payment_status=="succeeded") {
            setVerfied(false);
            setTerm(false);
            setTOS(true)
            let setUser = {
              userInfo: res.data,
              token: res?.token
            }
            let c_view = (setUser.userInfo.country_view_id !== undefined || setUser.userInfo.country_view_id !== "" || setUser.userInfo.country_view_id !== 'undefined') ? setUser.userInfo.country_view_id : setUser.userInfo.country_id == 2 ? 2 : 1
            setUser.userInfo.country_view_id = c_view > 2 ? 1 : c_view;
            setUsers(setUser)
            let postal = res?.data?.zip_code[0];
            if (res?.data?.country_id == 2 && (postal == "G" || postal == "H" || postal == "J")) {
              setTermTitle(' [Canada-French]')
              setUrlTOS("#/can-f-toc")
            } else if (res?.data?.country_id == 2) {
              setTermTitle(' [Canada-English]')
              setUrlTOS("#/can-toc")
            }
            if (res?.data?.country_id != 2) {
              setTermTitle(' [US-English]')
              setUrlTOS("#/us-toc")
            }
            setTimeout(() => {
              setTOS(true)
            }, 1000);
            return false
          } 
          if(res.data.Otp_Verify !== 1){
            setVerificationCode('')
            toast.success(res?.message, { autoClose: 2000, position: toast.POSITION.TOP_RIGHT });
            setTimeout(() => {
              setVerfied(false);
              setTerm(false);
              setTOS(false)
            }, 1000)
            return false
          } else {
              setVerfied(true);
              setTerm(false);
              setTOS(false)
              res.data.country_view_id=res.data.country_id
            let setUser = {
              userInfo: res.data,
              token: res?.token
            }
            let c_view = (setUser.userInfo.country_view_id !== undefined || setUser.userInfo.country_view_id !== "" || setUser.userInfo.country_view_id !== 'undefined') ? setUser.userInfo.country_view_id : setUser.userInfo.country_id == 2 ? 2 : 1
            setUser.userInfo.country_view_id = c_view > 2 ? 1 : c_view;
            setUsers(setUser)
            toast.success(res?.message, { autoClose: 2000, position: toast.POSITION.TOP_RIGHT });
            localStorage.setItem('setUser', JSON.stringify(setUser));
            localStorage.setItem('state', JSON.stringify(setUser));
            setTimeout(() => {
              dispatch(setLocatState());
              window.location.href = "#/plan-selection"
            }, 2000)
            return false
          }
        }
      })
    }
  }


  return (
    <React.Fragment>
      <section className="section section-50 bgNews section-lg-40 novi-background bg-cover" >
        <div className='container'>
          <div className="row align-items-center justify-content-center align-items-center offset-top-40 ">
            {isVerified === true ? <>
              <div className='col-lg-6 p-5 bg-white card-bs' style={{ height: 380 }}>
                <h2 className='text-left'>Login</h2>
                <form className="rd-mailform text-left offset-top-20 mb-0" data-form-output="form-output-global" data-form-type="contact">
                  <div className="row row-fix row-narrow-12 row-12 justify-content-sm-center">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <input className="form-input" id="contact-us-email" type="text" name="email" placeholder='Email' onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-wrap">
                        <input className="form-input" id="contact-us-password" maxLength={12} type="password" name="password" placeholder='password' onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className=" ">
                        <div className="w-100 text-left">
                          <div className="form-check float-right">
                            {/* <input type="checkbox" className="form-check-input mt-1" id="exampleCheck1" onChange={(e) => {
                              setTerm(e.target.checked)
                            }} /> */}
                            {/* <label className="form-check-label" htmlFor="exampleCheck1" style={{ fontSize: 14 }}>
                              I accept the DNI Website
                              <span style={{ color: '#A7CA56' }}>

                                <b>
                                  <a href={'#/us-toc'} target='_blank' id="c-toc"> Terms of Service</a>
                                </b></span>
                            </label> */}
                            <a href="#/forgot-password" style={{ fontSize: 16 }} className='ml-5'>Forgot Password?</a>
                            {/* <a href="#/forgot-password" style={{fontSize:16,marginLeft:230}} >Forgot Password?</a> */}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="offset-top-10 mt-4" >
                    <button className="btn btn-primary" onClick={handleLogin}>Login</button>
                  </div>
                </form>
              </div>
            </> : openTOS === true ?
              <div style={{ marginTop: 100 }}>
                <div className="col-sm-12">
                  <div className="col-lg-12 p-3 bg-white card-bs ">
                    <div className="w-100 text-left">
                      <div className="form-check mt-2">
                        <input type="checkbox" className="form-check-input mt-1" id="exampleCheck1" onChange={(e) => {
                          setTermContinue(e.target.checked)
                        }} />
                        <label className="form-check-label" htmlFor="exampleCheck1" style={{ fontSize: 14 }}>
                          I accept the DNI Website
                          {/* Remember me */}
                          <span style={{ color: '#A7CA56' }}>

                            <b>
                              <a href={urlTOC} target='_blank' id="c-toc"> Terms of Service</a>
                            </b></span>
                          <span style={{ color: '#000000ba', fontWeight: 'bold', marginLeft: 1 }}>
                            {tremTitle}
                          </span>
                        </label>
                        <br />
                        <button className='btn-btn-primary'
                          style={{
                            background: '#A7CA56',
                            border: '1px solid #A7CA56',
                            color: 'white',
                            cursor: 'pointer',
                            marginLeft: '-20px',
                            marginTop: '15px'
                          }}
                          onClick={() => {
                            if (!tremContinue) {
                              toast.error('Please I accept the DNI Website Terms of Service ', { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
                            } else {
                              toast.success(globalRes?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
                              localStorage.setItem('setUser', JSON.stringify(setUser));
                              localStorage.setItem('state', JSON.stringify(setUser));
                              setTimeout(() => {
                                dispatch(setLocatState());
                                window.location.href = "#/"
                              }, 2000)
                            }
                          }}
                        >Continue</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              : !isVerified ? <div className='col-lg-6 p-5 bg-white card-bs'>
                <h2 className='text-left mb-4'>Login verification</h2>
                <form className="rd-mailform text-left offset-top-20 mb-5">
                  <div className="row row-fix row-narrow-12 row-12 justify-content-sm-center">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <input className="form-input" type="text" maxLength={4} name="verficationcode" value={verficationcode} placeholder='Enter Verification Code' onChange={(e) => {
                          if (isNaN(e.target.value)) {
                          } else {
                            setVerificationCode(e.target.value)
                          }
                        }} />
                      </div>
                    </div>
                  </div>
                  <div className="offset-top-10">
                    <button className="btn btn-primary" onClick={handleVerify}>Verify</button>
                    &nbsp;<button className="btn btn-primary" onClick={resetCode}>Reset Code</button>
                  </div>
                </form>
              </div> : ''
            }
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Signin