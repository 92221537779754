import React, { Component } from 'react';
import BackIcon from '../../assets/images/previousBtn.png'

const CanadaTOC = () => {
    return (
        <>
            <section className="section search-bg2 section-height-800 parallax-container context-dark bg-gray-darkest text-xl-left" data-parallax-img="./images/home/resized/about-news.jpg">
                <div className="parallax-content">
                    <div className="bg-overlay-black">
                        <div className="container section-80 section-md-30 section-lg-top-10 section-lg-bottom-30" style={{ paddingTop: 6 }}>
                            <div className="d-none d-lg-block">
                                <h1 className='mt-4'>Terms of Service</h1>
                            </div>
                            <div className='d-flex d-lg-block'>
                                <img src={BackIcon} alt="Back" className="d-lg-none d-md-none" onClick={() => { window.location.href = '/#/' }} style={{ width: '8%', height: "8%", marginTop: '97px' }} />
                                {/* <ul className="list-inline list-inline-dashed list-white text-big p offset-md-top-8">
                            <li>Learn More about the DNI team</li>
                        
                        </ul> */}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <div className='col-md-7 mt-5' style={{ textAlign: 'justify', color: 'black', margin: 'auto' }}>
                <p style={{ margin: '0cm 0cm 10pt', textAlign: 'center', lineHeight: '115%', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Terms of Service</strong></p>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><em>Last updated:</em> February 1, 2023</p>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>THESE TERMS AND CONDITIONS CONTAIN LEGAL OBLIGATIONS AND FORM A LEGALLY BINDING AGREEMENT BETWEEN YOU AND ELBE’S DRUG NUTRITION INTERACTIONS LTD. PLEASE READ THESE TERMS CAREFULLY, AS THEY CONTAIN IMPORTANT INFORMATION ‎REGARDING YOUR ‎LEGAL RIGHTS, REMEDIES AND OBLIGATIONS, INCLUDING, BUT NOT LIMITED TO, ‎VARIOUS LIMITATIONS AND ‎EXCLUSIONS ON DAMAGES YOU MAY CLAIM AGAINST US AND INDEMNIFICATION OBLIGATIONS YOU OWE TO ‎US.‎ IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, YOU MAY NOT ACCESS OR USE THE SERVICES. BY CLICKING “I AGREE TO THE TERMS AND CONDITIONS”, OR BY USING OR ACCESSING THE SERVICES, YOU ‎REPRESENT THAT YOU UNDERSTAND, ACCEPT, AND AGREE TO BE BOUND AND COMPLY WITH THESE TERMS ‎AND OUR PRIVACY POLICY, WHICH FORMS AN ESSENTIAL PART OF THESE TERMS. ‎</strong></p>
                <ol style={{ marginBottom: '0cm', marginTop: '0px' }}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Your Relationship with Us</strong></li>
                </ol>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>Welcome to Elbe’s<strong> </strong>Drug Nutrition Interactions, which is provided by Company or one of its affiliates (“Company”, “<strong>we</strong>”, “<strong>our</strong>” or “<strong>us</strong>”).</p>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>These Terms and Conditions (the “<strong>Terms</strong>”) govern the relationship, and serve as a legally binding agreement, between you and us and set forth the terms and conditions on which you may access and use the internet sites operated by Company, the software imbedded therein, the features and functions thereof and our related websites, services, applications, products and content<strong> </strong>(collectively, the “<strong>Services</strong>”). Any reference to the “<strong>Services</strong>” includes a reference to any part of the Services. For purposes of these Terms, “<strong>you</strong>”, “<strong>your</strong>”, “<strong>user</strong>” means you as the user of the Services.</p>
                <ol style={{ marginBottom: '0cm', marginTop: '0px' }} start={2}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Age and Acceptance of Terms.</strong></li>
                </ol>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>Access to certain Services or features of the Services may be subject to age restrictions and not available to all users of the Services. The Services are only for users 18 years old and over. By accessing or using our Services, you confirm that: (a) you are over 18 and can form a binding contract with Company; (b) you accept these Terms, including all Supplemental Terms; and (c) you agree to comply with these Terms. If you are over 18 but under the legal age to enter into a contract in your jurisdiction, your parents or legal guardian must read and agree to these Terms, as well as supervise your use of the Services. If you do not have consent from your parents or legal guardians, you must cease accessing or using the Services. If we learn that someone under the relevant age is using the Services, we will terminate access to the Services for such user.</p>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>You understand and agree that we will treat your access or use of the Services or any part thereof, including your creation of an account, as acceptance of the Terms and the Privacy Policy <a style={{ color: '#002b5c', textDecoration: 'underline' }} href="https://web.drugnutritioninteractions.com/#/account/legal">https://web.drugnutritioninteractions.com/#/account/legal</a> You should print off or save a local copy of the Terms for your records.</p>
                <ol style={{ marginBottom: '0cm', marginTop: '0px' }} start={3}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><a name="_Ref73102232" /><strong>Changes to the Terms</strong></li>
                </ol>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>We reserve the right in our sole and absolute discretion to revise and update these Terms from time to time. ‎All changes are effective immediately upon posting and apply to your continued access to and use of the ‎Service. We may post reminders and summary information about material changes to these Terms, including ‎where there are substantial amendments that affect your rights and obligations, but it is your responsibility to ‎review them. You agree to periodically review these Terms in order to be aware of any such changes and your ‎continued use shall mean your acceptance of any such changes, whether given notice or not. ‎</p>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>For clarity, if any of the terms and conditions of these Terms, or any future modifications thereto, are ‎unacceptable to you, you may: (i) discontinue your use of the Services; and/or (ii) if you ‎have set up an account, contact the Company via the contact page at www.drugnutritioninteractions.com/#/contact&nbsp; or by email (<a style={{ color: '#002b5c', textDecoration: 'underline' }} href="mailto:dniteam@drugnutritioninteractions.com">dniteam@drugnutritioninteractions.com</a>) to delete your account. Your use of the Services now, or your continued use of the Services following the implementation of updated ‎Terms, will indicate acceptance by you of such Terms or modifications. If you do not agree to the Terms as applicable from time to time, you must stop accessing or using the Services.</p>
                <ol style={{ marginBottom: '0cm', marginTop: '0px' }} start={5}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><a name="_Ref73435972" /><strong>Your Account and Security</strong></li>
                </ol>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>In order to use our Service, you will need to create a “<strong>User Account</strong>”. In consideration of your use of the Services, you hereby agree to: (a) provide true, ‎accurate, current ‎and complete information about yourself as requested by any registration forms on the Service ‎‎‎(“<strong>Registration Data</strong>”); and (b) maintain and promptly update the Registration Data to keep it true, ‎accurate, ‎current and complete. By providing Registration Data to us through the Services, you will ‎provide personal account ‎information. You hereby agree to be fully responsible ‎for: (w) all use of your User ‎Account; (x) any action that takes place using your account by any ‎person or automated process; (y) ‎maintaining the confidentiality and security of your account ‎and passwords; and (z) immediately notifying ‎us upon any unauthorized use of your password or ‎account, or if you know of any other breach of security in relation to the Services. ‎We will not be liable for any loss or ‎damage arising from any unauthorized use of your ‎account(s).‎</p>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>We have provided ways for you to contact us about various issues on our website at www.drugnutritioninteractions.com/#/contact. You acknowledge and understand that these are the only authorized ways ‎to contact us. Third parties may advertise or publish alternative ways to contact us or use other measures to ‎pose as Company. We cannot verify the authenticity of any such alternative contacts, and they pose a risk of ‎fraud and other malfeasance, so we strongly recommend you do not attempt to contact us using these ‎methods. You agree that we cannot be held liable for unauthorized access to your account or other loss ‎resulting from such fraud or other malfeasance by third parties.‎</p>
                <ol style={{ marginBottom: '0cm', marginTop: '0px' }} start={5}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><a name="_Ref73436063" /><a name="_Ref73102249" /><strong>Fees and Refunds</strong><strong> </strong><strong>&nbsp;</strong>
                        <ol style={{ listStyleType: 'lower-alpha', marginBottom: '0cm', marginTop: '0px' }}>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><u>Payment Information</u>: You agree to pay any fees applicable to your use of the Services and <strong>‎</strong>Company Content (as defined in Section 7.a)), including fees and charges applicable to your purchases. You must provide and at all times must maintain accurate, complete, and current billing ‎information, including your postal/zip code, credit card number, and credit ‎card expiration date.&nbsp; We may suspend or terminate your account ‎and/or access to the Services and <strong>‎</strong>Company Content if your offered payment method (e.g., credit ‎card) cannot be processed. In addition, orders may not be accepted from certain jurisdictions. By providing a payment method, you expressly authorize us and/or ‎our third party payment processor to charge the applicable fees on said payment method, as well ‎as taxes and other charges related thereto, all of which depend on the Services you utilize. You agree that we may charge any unpaid amounts to your provided ‎payment method and/or send you a bill for such unpaid fees.‎ If you provide a payment method and our charge results in an overdraft, chargeback, or other ‎fee from your bank, you alone are responsible for that fee.‎ If you become aware of a potential breach of security to your billing ‎information (such as credit card loss or theft), you must notify us immediately.‎</li>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><u>Automatically Renewing Subscriptions</u>: IF YOU SUBSCRIBE TO THE SERVICES (A “<strong>SUBSCRIPTION</strong>”), WE WILL AUTOMATICALLY RENEW YOUR SUBSCRIPTION AT THE END OF THE ‎INITIAL SUBSCRIPTION PERIOD AND, AS AUTHORIZED BY YOU ‎DURING THE REGISTRATION PROCESS, WE WILL CHARGE YOUR CREDIT ‎CARD WITH THE APPLICABLE SUBSCRIPTION FEE AND ANY SALES ‎OR SIMILAR TAXES THAT MAY BE IMPOSED ON YOUR PAYMENT (UNLESS YOU ‎CANCEL PRIOR TO THE LAST DAY OF YOUR THEN-CURRENT SUBSCRIPTION ‎PERIOD). EACH RENEWAL PERIOD WILL BE FOR THE SAME LENGTH AS YOUR ‎IMMEDIATELY PRECEEDING SUBSCRIPTION PERIOD (I.E., IF THE PRECEDING SUBSCRIPTION PERIOD WAS A YEAR, YOUR SUBSCRIPTION WILL AUTO-RENEW FOR A FURTHER YEAR, AND SO ON AND SO FORTH). WE REQUIRE A REASONABLE AMOUNT OF TIME TO PROCESS YOUR ‎CANCELLATION REQUEST. FOR GREATER CERTAINTY, IF YOU CANCEL YOUR SUBSCRIPTION <u>AFTER</u> THE ‎FIRST DAY OF THE OF YOUR RENEWAL TERM, YOU WILL NOT RECEIVE A ‎REFUND, BUT YOU WILL CONTINUE TO ENJOY YOUR MEMBERSHIP BENEFITS ‎UNTIL THE EXPIRATION OF THE THEN-CURRENT SUBSCRIPTION PERIOD FOR WHICH YOU ‎HAVE PAID, AND YOUR MEMBERSHIP BENEFITS WILL EXPIRE AT THE END OF ‎THAT PERIOD. <span style={{ textTransform: 'uppercase' }}>To check your next renewal date, log on to your Account at https://wWW.drugnutritioninteractions.com/#/account/payment-history&nbsp; and ‎look at the last payment date for your Subscription.</span></li>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><u>Modifications to Services and Pricing</u>:
                                <ol style={{ listStyleType: 'lower-roman', marginBottom: '0cm', marginTop: '0px' }}>
                                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>We reserve the right at any time and from time to time to change, modify, add, remove, ‎suspend, cancel, or discontinue any aspect of the Services in our sole discretion. If ‎the Services you paid for are materially altered and you are accessing the Services under a Subscription, you will receive notice by email and ‎will have the opportunity to receive a pro rata refund if you do not agree to the changes / cancel your Subscription.</li>
                                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>In addition, we may modify our fees from time to time. In the event you are accessing the Services under a Subscription and the Subscription fee for the Services you are using has been modified such that you are required to pay a ‎different fee than at the time you entered into these Terms, you will (a) be notified at the email address for you on file at least thirty ‎‎(30) days before you are billed at the different rate, and (b) have ‎the opportunity to cancel your Subscription. If you do not cancel your Subscription before the ‎next billing renewal date, you will be deemed to have accepted the new fees.‎</li>
                                </ol>
                            </li>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><u>Subscription Cancellation</u>: To cancel a Subscription, simply go to your account page at <a style={{ color: '#002b5c', textDecoration: 'underline' }} href="https://web.drugnutritioninteractions.com/#/contact"><strong>https://web.drugnutritioninteractions.com/#/contact</strong></a><strong> </strong>and send a message with the Topic selected as <strong>Cancel Subscription</strong>. If you have trouble cancelling for any reason, please email <strong><span style={{ background: 'yellow' }}>‎</span>dniteam@drugnutritioninteractions.com</strong> and request help with cancellation. Your Subscription will terminate at the end ‎of the then-current Subscription period for which you have paid (i.e., your Subscription cancellation will be effective on the next ‎renewal date of your Subscription).</li>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><u>REFUNDS</u>: SUBJECT TO SECTION 10 OF THESE TERMS, UNLESS PROHIBITED BY APPLICABLE LAW, ALL PURCHASES, INCLUDING RECURRING CHARGES, ARE FINAL AND NON-REFUNDABLE.<span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman", serif' }}> </span></li>
                        </ol>
                    </li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><a name="_Ref73102260" /><strong>Intellectual Property Rights; Limited Licence</strong>
                        <ol style={{ listStyleType: 'lower-alpha', marginBottom: '0cm', marginTop: '0px' }}>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><a name="_Ref73532498" /><u>Intellectual Property Rights</u>: As between you and <strong>‎</strong>Company, Company owns any and all rights, title, and interest in and to the Services and any and all data and content made available in and through the Services, including all software, computer code, tools, patches, updates, images, text, graphics, illustrations, logos, photographs, images, pictures, audio, sound effects, sound recordings, features, functionality, design, presentation<strong>, </strong>and “look and feel” of the Services, and all intellectual property rights related to the foregoing and the Services (the “<strong>‎</strong><strong>Company</strong> <strong>Content</strong>”). Put simply, the Services and the Company Content (including all components thereof) are the property of their respective owners and are protected by copyright, trademark, patent, trade secret, and any other proprietary rights.</li>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><u>Limited Licence</u>: You acknowledge and agree that you have no ownership rights in the Services or the Company Content. Subject to all of the Terms, we grant you a non-exclusive, non-transferable, non-sublicensable, revocable, limited licence to access and use the Services and to access the <strong>‎</strong>Company Content. We<strong> </strong>reserve all rights not expressly granted herein in the Services and Company Content.</li>
                        </ol>
                    </li>
                </ol>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>You acknowledge that your use of the Company Content for any purpose not expressly permitted by these Terms is strictly prohibited. Without limiting the restrictions in Section 11: (a) neither Company Content nor the design or layout of the Services, nor any part or component of any of the foregoing, may be downloaded, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any purpose whatsoever without our or, where applicable, our licensors’ prior written consent; and (b) you cannot create any work of authorship or proprietary right based on the Services (including Company Content). You will only use the Services as may be expressly provided in this Agreement or to the extent permitted by law or (if applicable) relevant open source licences. We and our licensors reserve all rights not expressly granted in and to their content.</p>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>Where our Services may contain links to other sites and resources provided by third parties, these links are provided for your information only. We have no control over the contents of those sites or resources. Such links should not be interpreted as approval or endorsement by us of those linked websites or information you may obtain from them, and we are not responsible for any consequences, losses, or damages that may arise from your access or use of such sites or resources. For clarity, if you choose to access any such sites or resources, you do so at your own risk.</p>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>We have the right, without notice, to insert advertising data into the Services, so long as this does ‎not ‎involve our transmission of any of your personal information in contravention of the Privacy Policy.&nbsp; ‎If you ‎elect to have any business dealings with any party whose products or services may be ‎advertised on the ‎Services, you acknowledge and agree that such dealings are solely between you and ‎such advertiser and we will ‎not be a party to, or have any responsibility or liability related thereto.&nbsp; You ‎acknowledge and agree that no ‎such advertising may be construed as an endorsement by us of any ‎such products or services advertised.&nbsp; ‎</p>
                <ol style={{ marginBottom: '0cm', marginTop: '0px' }} start={5}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Feedback</strong></li>
                </ol>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>While we are continually working to develop and evaluate our own product ideas and features, we also pay attention to the interests, feedback, comments, and suggestions we receive from our user community. If you choose to contribute by sending us any ideas for products, services, features, modifications, enhancements, content, refinements, technologies, content offerings (such as audio, visual or other types of content), promotions, strategies, or product/feature names, or any related documentation, artwork, computer code, diagrams, or other materials (collectively, “<strong>Feedback</strong>”), then regardless of what your accompanying communication may say, in order to avoid any misunderstandings the following terms will apply to the Feedback. Accordingly, by sending Feedback to us, you agree that:</p>
                <ul style={{ marginBottom: '0cm', marginTop: '0px' }}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>Company has no obligation to review, consider, or implement your Feedback, or to return to you all or part of any Feedback for any reason;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>Feedback is provided on a non-confidential and non-proprietary basis, and we are not under any obligation to keep any Feedback you send confidential or to refrain from using or disclosing it in any way; ‎</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>You irrevocably grant us an irrevocable, non-exclusive, worldwide, perpetual, royalty-free, sub-licensable (including via multiple tiers of sublicensing), transferrable licence to reproduce, distribute, create derivative works of, modify, communicate to the public, make available, publicly display, and otherwise use and exploit the Feedback and derivatives thereof for any purpose and without restriction, free of charge and without attribution of any kind, including by making, using, selling, offering for sale, importing, and promoting commercial products and services that incorporate or embody Feedback, whether in whole or in part, and whether as provided or as modified;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>You have been deemed to have warranted to us that you have or own all the necessary legal rights to upload, post, or submit such Feedback and grant us (and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns) the licence to the Feedback, and that the Feedback does not and will not violate any law or the intellectual property, privacy, publicity, or other rights of any person;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>You understand and agree that you are fully responsible for any Feedback you submit or contribute, ‎and you are fully responsible and legally liable, including to any third party, for such content, its accuracy, and ‎your rights to use it; and</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>You waive any moral rights or other rights of authorship in and to any Feedback in favour of the Company, including any rights you may have in the altered or changed Feedback even if it is no longer ‎agreeable to you.</li>
                </ul>
                <ol style={{ marginBottom: '0cm', marginTop: '0px' }} start={5}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Equipment Requirement</strong></li>
                </ol>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>To use the Services, you may need certain hardware, software, capabilities and other resources (including a suitable connection to the Internet). You are solely responsible for procuring and maintaining such resources at your own cost.</p>
                <ol style={{ marginBottom: '0cm', marginTop: '0px' }} start={5}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><a name="_Ref70509940" /><strong>Updates, Interruption, and Termination</strong>
                        <ol style={{ listStyleType: 'lower-alpha', marginBottom: '0cm', marginTop: '0px' }} start={6}>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><u>Updates</u>: From time to time, we have the right (but not obligation) to provide updates (patches, new features, etc.) to the Services. You understand that your access to certain features of the Services might be affected by such updates. You also understand that such updates may affect the necessary system specification required to use the Services. In such case, you are responsible for any necessary equipment to continue to access the Services.</li>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><u>Interruption</u>: You acknowledge that the Services or any part thereof may be interrupted for maintenance or reasons beyond our control, and we cannot guarantee that the Services will be uninterrupted. We will not be liable for any interruption of the Services, delay, or failure to perform resulting from any causes whatsoever. Additionally, the Services may be unavailable depending on geographic location. To the maximum extent permitted by applicable law, we reserve the right to discontinue the Services at any time in our sole discretion, for any reason, or for no reason, with or without notice.</li>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><u>Suspension or Termination</u>: To the extent allowable by applicable law, we reserve the right to suspend or terminate your access to the Services at any time for any reason, including if you have failed to comply with any of the provisions of these Terms, or if activities occur on your account which would or might (i) cause damage to or impair the Services or infringe or violate any third party rights (including intellectual property rights), or (ii) violate any applicable laws or regulations. Termination or suspension of your account also entails the termination or suspension of your licence to use the Services, or any part thereof. If we suspend or terminate your account, we will notify you by email. If we terminate your account and you purchased a Subscription, we will refund you the prorated portion of your prepayment for the greater of (i) the then-current year or (ii) ‎other applicable time period for your Subscription.‎</li>
                        </ol>
                    </li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><a name="_Ref73102926" /><strong>Code of Conduct</strong></li>
                </ol>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>As a condition of your access to and use of the Services, you agree that you will use the Services only for lawful purposes in accordance with these Terms and all applicable laws and regulations. You may not:</p>
                <ul style={{ marginBottom: '0cm', marginTop: '0px' }}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>access or use the Services if you are not fully able and legally competent to agree to these Terms;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>violate, or promote the violation of, any applicable federal, provincial, local, foreign, or international law or regulation, ‎including any laws regarding the export of data or software, patent, trademark, trade secret, ‎copyright, or other intellectual property or legal rights (including the rights of publicity and privacy of ‎others);</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>violate the terms of use of any third-party website or service that is linked to the Services, including any third-party social media websites or payment processors‎;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>except as expressly permitted by these Terms or applicable law, directly or indirectly make unauthorized copies, modify, adapt, translate, reverse engineer, disassemble, decompile, publicly display, republish, download, store, transmit or create any derivative works of the Services or any content included therein, including any software ‎(except that your computer and browser may temporarily store or cache copies of materials being accessed ‎and viewed)‎, or determine or attempt to determine any source code, algorithms, methods, or techniques embodied by the Services or any derivative works thereof;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>use the Services, including the Company Content, to train artificial intelligence programs or services;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>share access to the Services between multiple users (e.g. multiple simultaneous use of the same User Account from different IP addresses);</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>distribute, license, transfer, or sell, in whole or in part, any of the Services or any derivative works thereof;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>modify copies of any materials from the Services nor delete or alter any copyright, trademark, or other ‎proprietary rights notices from copies of materials from the Services‎;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>market, sale, rent, or lease the Services for a fee or charge, or use the Services to advertise or perform any commercial solicitation;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>use the Services or any part thereof, without our express written consent, for any commercial, political, or unauthorized purpose, including communicating or facilitating any commercial advertisement or solicitation or spamming;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>interfere with or attempt to interfere with the proper working of the Services, disrupt our website (if any), or any networks connected to the Services, or bypass any measures we may use to prevent or restrict access to the Services;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>incorporate the Services or any portion thereof into any other program or product;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>use automated scripts, software, code, or systems to collect information from or otherwise interact with the Services;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>impersonate any person or entity, or falsely state or otherwise misrepresent you or your affiliation with any person or entity, including giving the impression that any content you upload, post, transmit, distribute, or otherwise make available emanates from the Services;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>encourage any conduct that restricts or inhibits anyone's use or enjoyment of the ‎Services, or which, as determined by us, may harm us or users of the Services or expose us or them ‎to liability;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>involve, provide, or contribute any false, inaccurate, or misleading information‎;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>impersonate or attempt to impersonate us, one of our employees, another ‎user, or any other person or entity (including by using email addresses, or screen names ‎associated with any of the foregoing);‎</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>use or attempt to use another user’s account, service, or system without authorization from ‎Company;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>use the Services in a manner that may create a conflict of interest or undermine the purposes of the Services;</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>use the Services to upload, transmit, distribute, store, or otherwise make available in any way:
                        <ul style={{ listStyleType: 'circle', marginBottom: '0cm', marginTop: '0px' }}>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>files that contain viruses, trojans, worms, logic bombs, or other material that is malicious or technologically harmful; any unsolicited or unauthorized advertising, solicitations, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” or any other prohibited form of solicitation; any private information of any third party, including addresses, phone numbers, email addresses, number and feature in the personal identity document (e.g., social security numbers, passport numbers, etc.), or credit card numbers; any material which does or may infringe any copyright, trade mark, or other intellectual property or privacy rights of any other person; any material which is defamatory of any person, obscene, violent, sexually explicit, offensive, pornographic, hateful, or inflammatory; any material that would constitute, encourage, or provide instructions for a criminal offence, dangerous activities, or self-harm; any material that is deliberately designed to provoke or antagonize people, especially trolling and bullying, or is intended to harass, harm, intimidate, hurt, scare, distress, embarrass, or upset people; any material that contains a threat of any kind, including threats of physical violence; any material that is racist or discriminatory, including discrimination on the basis of someone’s race, religion, age, gender, disability, or sexuality;</li>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>any answers, responses, comments, opinions, analysis, or recommendations that you are not properly licensed or otherwise qualified to provide;</li>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>any material that could give rise to any civil or criminal liability under applicable ‎laws or regulations or that otherwise may be in conflict with these Terms and our Privacy Policy found ‎at https://www.drugnutritioninteractions.com/#/account/legal; or</li>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>material that restricts or inhibits any other person from using the Services, or which may expose <strong>‎</strong> Company, the Services, or its users to any harm or liability of any type.</li>
                        </ul>
                    </li>
                </ul>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>We reserve the right, at any time and without prior notice, to remove or disable your access to the Services, including the <strong>‎</strong>Company Content, at our discretion for any reason or no reason. Some of the reasons for which we may remove or disable your access to the Services may include finding that you have violated these Terms, or that your actions are harmful to the Services or our users. You agree and understand that your continued use of the Services is at our sole and absolute discretion and ‎that you will not seek to hold us liable for any suspension, restriction, or termination of our use of the Services.‎</p>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>While we will take steps to monitor content and Feedback in the Services, we make no representations ‎or guarantees that we will be able to review all material that you or other users submit to the Services or to do ‎so in a timely manner. We cannot ensure prompt removal of objectionable material after it has been posted ‎and we have no liability for any action or inaction regarding transmissions, communications, or content ‎provided by any user or third party, subject to applicable laws.‎</p>
                <ol style={{ marginBottom: '0cm', marginTop: '0px' }} start={5}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Location</strong></li>
                </ol>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>We administer and operate the Services from Canada. Although the Services are ‎accessible in many territories throughout the world, not all features, products or ‎Services discussed, referenced, provided or offered are available to all ‎persons or in all geographic locations, or appropriate or available for use outside Canada. We reserve the right to limit, in our sole discretion, the provision and quantity of ‎any feature, product or Service to any person or geographic area. Any offer for any ‎feature, product or Service made is void where prohibited. If you choose to ‎access the Services from outside Canada, you do so on your own initiative and you are ‎solely responsible for complying with applicable local laws.‎</p>
                <ol style={{ marginBottom: '0cm', marginTop: '0px' }} start={5}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><a name="_Ref73102968" /><strong>Use of Professional Judgement </strong></li>
                </ol>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>The Services provided by the Company are intended for use by professional registered dietitians, registered pharmacists or other healthcare professionals and are for informational purposes only. The Services and Company Content are not intended to be medical advice or instructional for medical diagnosis or treatment, not intended to be complete or to replace personal consultation with a qualified healthcare ‎professional, and not a substitute for the advice of a physician or other qualified ‎health provider(s)‎.</p>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>The Company Content provided are general in nature and are not intended to be a substitute for individual patient assessment based upon the healthcare provider’s examination of each patient, consideration of laboratory data and other factors unique to the patient.</p>
                <ol style={{ marginBottom: '0cm', marginTop: '0px' }} start={5}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Indemnity</strong></li>
                </ol>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>To the maximum extent permitted by law, you agree to, at your sole cost, defend, indemnify, and hold harmless <strong>‎</strong>Company, its parents, subsidiaries, and affiliates, and each of their respective officers, directors, employees, agents, suppliers, and advisors, from and against any and all claims, liabilities, costs, fines, penalties, and expenses, including legal fees and expenses, arising out of or in any way connected with: (i) a breach by you, or any user of your account, of any applicable obligation, representation, or warranty under these Terms; (ii) the content of or any inaccuracy in your Feedback; (iii) our use or publication of your Feedback infringes any third party intellectual property rights; (iv) your access to or use of, or activities in connection with, the Services; (v) your violation of any applicable laws, rules, regulations, or contracts; or (vi) any misrepresentation made by you (all of the foregoing, “<strong>Claims</strong> <strong>and Losses</strong>”). You will cooperate as fully required by us in the defense of any Claims and Losses. Notwithstanding the foregoing, we retain the exclusive right to settle, compromise, and pay any and all Claims and Losses. We reserve the right to assume the exclusive defense and control of any Claims and Losses at our own cost. You will not settle any Claims and Losses without, in each instance, our prior written consent or the consent of an individual whom we authorize, in writing, to approve such settlement.</p>
                <ol style={{ marginBottom: '0cm', marginTop: '0px' }} start={5}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><a name="_Ref73102975" /><strong>EXCLUSION OF WARRANTIES</strong></li>
                </ol>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES. ‎NOTHING IN THESE TERMS WILL AFFECT</strong> <strong>ANY STATUTORY RIGHTS THAT YOU CANNOT CONTRACTUALLY AGREE TO ALTER OR WAIVE AND ARE LEGALLY ALWAYS ENTITLED TO AS A CONSUMER.</strong></p>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>TO THE MAXIMUM EXTENT PERMITTED BY LAW: (I) THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS FOR YOUR USE; AND (II) </strong><strong>WE EXPRESSLY DISCLAIM AND EXCLUDE ALL WARRANTIES, INCLUDING ALL CONDITIONS, WARRANTIES, OR OTHER TERMS, WHETHER STATUTORY, EXPRESS, OR IMPLIED (INCLUDING ANY IMPLIED WARRANTIES AS TO MERCHANTABILITY, NON-INFRINGEMENT, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, OR CONFORMANCE WITH DESCRIPTION), EXCEPT TO THE EXTENT THAT THEY ARE EXPRESSLY SET OUT IN THESE TERMS. </strong></p>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>IN PARTICULAR WE DO NOT</strong> <strong>REPRESENT OR WARRANT TO YOU THAT:</strong></p>
                <ul style={{ marginBottom: '0cm', marginTop: '0px' }}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS;</strong></li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR FREE FROM ERROR;</strong></li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>THE SERVICES ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS;</strong></li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE; </strong></li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE PROVIDED TO YOU AS PART OF THE SERVICES WILL BE CORRECTED; OR</strong></li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>THE CONTENT OBTAINED THROUGH THE SERVICES IS ACCURATE, COMPLETE, OR RELIABLE.</strong></li>
                </ul>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>WE MAY CHANGE, SUSPEND, WITHDRAW, OR RESTRICT THE AVAILABILITY OF ALL OR ANY PART OF THE SERVICES FOR BUSINESS AND OPERATIONAL REASONS AT ANY TIME WITHOUT NOTICE. YOU ARE RESPONSIBLE FOR VERIFYING ANY INFORMATION OBTAINED THROUGH THE SERVICES BEFORE RELYING ON IT. YOU FREELY ACCEPT AND VOLUNTARILY AGREE TO ASSUME ALL ‎RISKS, INCLUDING OF PERSONAL INJURY, DEATH, AND ‎PROPERTY DAMAGE OR LOSS, CONNECTED WITH YOUR USE ‎OF THE SERVICES HOWSOEVER ARISING</strong></p>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>YOUR USE OF THE SERVICES MAY DEPEND ON THE INTERNET, INCLUDING NETWORKS, CABLING, FACILITIES, AND ‎EQUIPMENT THAT IS NOT IN OUR CONTROL. ACCORDINGLY: (I) WE CANNOT GUARANTEE ANY MINIMUM LEVEL REGARDING ‎SUCH PERFORMANCE, SPEED, RELIABILITY, AVAILABILITY, USE, OR CONSISTENCY; AND (II) YOU ACKNOWLEDGE AND AGREE THAT DATA, MESSAGES, ‎INFORMATION, OR MATERIALS SENT OVER THE INTERNET MAY NOT BE COMPLETELY PRIVATE, AND YOUR ANONYMITY IS ‎NOT GUARANTEED.‎</strong></p>
                <ol style={{ marginBottom: '0cm', marginTop: '0px' }} start={5}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><a name="_Ref73103930" /><strong>LIMITATION OF LIABILITY</strong></li>
                </ol>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>NOTHING IN THESE TERMS WILL EXCLUDE OR LIMIT OUR LIABILITY FOR LOSSES WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE LAW. </strong><strong>SUBJECT TO THE FOREGOING, IN NO EVENT WILL WE, OUR AFFILIATES AND OUR AND THEIR ‎RESPECTIVE SHAREHOLDERS, DIRECTORS, OFFICERS, ‎EMPLOYEES, ADVERTISERS, SUPPLIERS, CONTENT PROVIDERS ‎AND LICENSORS ("COMPANY PARTIES") BE LIABLE (JOINTLY OR ‎SEVERALLY) TO YOU OR ANY OTHER PERSON FOR DIRECT, INDIRECT, ‎CONSEQUENTIAL, SPECIAL, INCIDENTAL, PUNITIVE, OR ‎EXEMPLARY DAMAGES, INCLUDING LOST ‎PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOST SAVINGS, LOST REVENUES, LOST GOODWILL, OR LOST OPPORTUNITY ‎‎(COLLECTIVELY, THE "EXCLUDED DAMAGES"). THESE LIMITATIONS ‎APPLY WHETHER THE ALLEGED LIABILITY IS BASED ON TORT ‎‎(INCLUDING NEGLIGENCE), CONTRACT, OR OTHER THEORY OF ‎LIABILITY, EVEN IF ANY OF THE COMPANY PARTIES HAVE BEEN ‎ADVISED OF THE POSSIBILITY OF OR COULD HAVE FORESEEN ANY ‎OF THE EXCLUDED DAMAGES, AND IRRESPECTIVE OF ANY ‎FAILURE OF AN ESSENTIAL PURPOSE OF A LIMITED REMEDY, AND YOU HEREBY WAIVE, RELEASE, AND FOREVER DISCHARGE THE COMPANY PARTIES FROM&nbsp; AND AGAINST ALL OF THE EXCLUDED DAMAGES. IF ‎ANY APPLICABLE AUTHORITY HOLDS ANY PORTION OF THIS ‎SECTION TO BE UNENFORCEABLE, THEN THE COMPANY PARTIES' ‎LIABILITY WILL BE LIMITED TO THE FULLEST POSSIBLE EXTENT ‎PERMITTED BY APPLICABLE LAW.‎</strong></p>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, THE EXCLUDED DAMAGES INCLUDE ANY LOSSES OR DAMAGES WHICH MAY BE INCURRED BY YOU AS A RESULT OF:</strong></p>
                <ul style={{ marginBottom: '0cm', marginTop: '0px' }}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY, OR EXISTENCE OF ANY ADVERTISING, OR AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY ADVERTISER OR SPONSOR WHOSE ADVERTISING APPEARS ON THE SERVICES;</strong></li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>ANY CHANGES WHICH WE MAY MAKE TO THE SERVICES, OR FOR ANY PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE SERVICES (OR ANY FEATURES WITHIN THE SERVICES);</strong></li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>THE DELETION OF, CORRUPTION OF, OR FAILURE TO STORE ANY CONTENT OR OTHER COMMUNICATIONS DATA MAINTAINED OR TRANSMITTED BY OR THROUGH YOUR USE OF THE SERVICES;</strong></li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>YOUR FAILURE TO PROVIDE US WITH ACCURATE ACCOUNT INFORMATION; OR</strong></li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND CONFIDENTIAL.</strong></li>
                </ul>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>YOU ARE RESPONSIBLE FOR ANY INTERNET OR MOBILE CHARGES THAT MAY APPLY TO YOUR USE OF OUR SERVICES, INCLUDING TEXT-MESSAGING AND DATA CHARGES. IF YOU’RE UNSURE WHAT THOSE CHARGES MAY BE, YOU SHOULD ASK YOUR SERVICE PROVIDER BEFORE USING THE SERVICE.</strong></p>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>TO THE FULLEST EXTENT PERMITTED BY LAW, ANY DISPUTE YOU HAVE WITH ANY THIRD PARTY ARISING OUT OF YOUR USE OF THE SERVICES, INCLUDING, BY WAY OF EXAMPLE AND NOT LIMITATION, ANY CARRIER, COPYRIGHT OWNER, OR OTHER USER, IS DIRECTLY BETWEEN YOU AND SUCH THIRD PARTY, AND YOU IRREVOCABLY RELEASE US AND OUR AFFILIATES FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES.</strong></p>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>IN NO EVENT WILL COMPANY PARTIES’ TOTAL, AGGREGATE ‎LIABILITY EXCEED, WITH RESPECT TO THE SERVICES, ‎THE TOTAL AMOUNT PAID BY YOU TO ‎COMPANY IN THE TWELVE-MONTH PERIOD IMMEDIATELY PRIOR TO ‎THE EVENT GIVING RISE TO THE CLAIM.‎</strong></p>
                <ol style={{ marginBottom: '0cm', marginTop: '0px' }} start={5}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Privacy Policy</strong></li>
                </ol>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>You also acknowledge that our Privacy Policy found at https://web.drugnutritioninteractions.com/#/account/privacy will also apply to your use of our Services<strong>.</strong> Our Privacy Policy can be found directly on the Services, or, if applicable, where the Services are made available for download on your mobile device’s applicable app store, and are incorporated herein by reference.</p>
                <ol style={{ marginBottom: '0cm', marginTop: '0px' }} start={17}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Copyright Claims</strong></li>
                </ol>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>If you believe that anything on the Services infringes upon any copyright which you own or control, you may file a notification of such infringement to the following contact information: dniteam@drugnutritioninteractions.com</p>
                <p style={{ margin: '0cm 0cm 12pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>​The notification must be a written communication that includes the following:‎</p>
                <ol style={{ marginBottom: '0cm', marginTop: '0px' }} start={5}>
                    <li style={{ listStyle: 'none', margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>
                        <ol style={{ listStyleType: 'lower-alpha', marginBottom: '0cm', marginTop: '0px' }}>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>a physical or electronic signature of a person authorized to act on behalf of the owner of an ‎exclusive right that is allegedly infringed;‎</li>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>if the complaint is about copyright or other intellectual property rights, identification of the ‎copyrighted work or other intellectual property right claimed to have been infringed, or, if ‎multiple infringements are covered by a single notification, a representative list of such ‎infringements;‎</li>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>if the complaint is about objectionable content, the reasons for the objection;‎</li>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>identification of the material that you are objecting to and that is to be removed or access to which ‎is to be disabled, and information reasonably sufficient to permit us to locate the material;‎</li>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>information reasonably sufficient to permit us to contact the complaining party, such as an ‎address, telephone number and, if available, an electronic mail address at which the ‎complaining party may be contacted;‎</li>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>a statement that the information in the notification is accurate; and</li>
                            <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>if the complaint is about copyright or other intellectual property rights, a statement under penalty of ‎perjury, that (i) the complaining party is authorized to act on behalf of the owner of an exclusive right ‎that is allegedly infringed, and (ii) the complaining party has a good-faith belief that use of the material ‎in the manner complained of is not authorized by the owner thereof, its agent or the law.‎</li>
                        </ol>
                    </li>
                </ol>
                <ol style={{ marginTop: '0cm', marginBottom: '0cm' }} start={18}>
                    <li style={{ margin: '0cm 0cm 0cm 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><a name="_Ref73104110" /><strong>Other Terms</strong></li>
                </ol>
                <p style={{ margin: '0cm 0cm 0cm 36pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>&nbsp;</p>
                <ul style={{ marginTop: '0cm', marginBottom: '0cm' }}>
                    <li style={{ margin: '0cm 0cm 0cm 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Applicable Law and Jurisdiction.</strong> These Terms, their subject matter and their formation, are governed by the laws of the Province of<strong> </strong>British Columbia and the laws of Canada applicable therein, without giving effect to any choice or conflict of law provisions or rules. You and we agree that the UN Convention on Contracts for the International Sale of Goods (Vienna, ‎‎1980) will not apply to these Terms, the Services, or to any dispute or transaction arising out of these ‎Terms or the use of the Services. <strong><em><span style={{ background: 'yellow' }}>‎</span></em></strong></li>
                </ul>
                <p style={{ margin: '0cm 0cm 0cm 36pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>&nbsp;</p>
                <p style={{ margin: '0cm 0cm 12pt 36pt', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}>Most customer concerns regarding the Services can be resolved quickly and to a ‎customer’s satisfaction by emailing us at dniteam@drugnutritioninteractions.com.‎ However, any dispute arising out of or in connection with these Terms that cannot be resolved by email, including any question regarding existence, validity or termination of these Terms, will be referred to and finally resolved by arbitration in<strong> </strong>Vancouver, British Columbia administered by the<strong> </strong>Canadian Arbitration Association in accordance with the general Canadian Arbitration Association Rules for Arbitration then in force, which rules are deemed to be incorporated by reference in this clause. The place of the arbitration will be<strong> </strong>Vancouver, British Columbia. The tribunal will consist of three (3) arbitrators. The language of the arbitration will be English. Notwithstanding the foregoing, we may institute a proceeding for equitable relief in any court of competent jurisdiction.</p>
                <ul style={{ marginBottom: '0cm', marginTop: '0px' }}>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Entire Agreement.</strong> These Terms (including the <em>Supplemental Terms – Jurisdiction-Specific</em>, found below) and our Privacy Policy constitute the whole legal agreement between you and Company and govern your use of the Services and completely replace any prior agreements between you and Company in relation to the Services.</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Interpretation</strong>. In these Terms, (a) the captions and headings are for convenience only and do not ‎constitute substantive ‎matter and are not to be construed as interpreting the contents of these ‎ Terms, (b) the word “including”, the ‎word “includes” the phrase “such as”, and similar words and phrases, when following a ‎general statement or ‎term (whether or not non-limiting language such as “without limitation” or “but ‎not limited to” or other words ‎of similar import are used with reference thereto), is not to be ‎construed as limiting, and the word “or” ‎between two or more listed matters does not imply an ‎exclusive relationship between the matters being ‎connected, and (c) all references to Services will also ‎include any successor or replacement applications, ‎websites, content, or services containing substantially similar information as the ‎referenced Service(s).‎</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Assignment.</strong> We may at any time assign our rights and obligations under these Terms, in whole or in part, without ‎notice to ‎you. You may not assign these Terms without our prior, written consent. These Terms will ‎inure to the benefit ‎of and bind you and us and our respective personal and legal representatives, ‎successors and permitted ‎assigns.‎</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>No Waiver.</strong> Our failure to insist upon or enforce any provision of these Terms will not be construed as a waiver of any provision or right.</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Security.</strong> The transmission of information via the internet is inherently not completely secure and we do not guarantee that our Services will be secure or free from bugs or viruses. You are responsible for configuring your information technology and computer programs to access our Services. You should use your own virus protection software.</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Severability.</strong> If any court of law, having jurisdiction to decide on this matter, rules that any provision of these Terms is invalid, then that provision will be removed from the Terms without affecting the rest of the Terms, and the remaining provisions of the Terms will continue to be valid and enforceable.</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Open Source.</strong> The Services may contain certain open source software. Each item of open source software is subject to its own applicable licence terms.</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Social Networks.</strong> If at any time the Services include features that operate in conjunction with certain third party ‎social networking websites that you visit (“<strong>Social Network Features</strong>”), your use of the Social Network Features is ‎governed by these Terms, but your access and use of third party social networking websites and the Services provided through these websites is governed by the Terms and Conditions and other ‎agreements posted on these websites. You agree that you alone are responsible for your use of ‎the social network features and that we will not be liable to you or anyone else for your ‎violation or breach of any terms of use or other agreement that may result from your use of the ‎Social Network Features.‎</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Force Majeure. </strong>We will not be liable for delays, failures in performance, or interruptions of the Services that result directly or ‎indirectly from any cause or condition beyond our reasonable control, including significant ‎market volatility, any delay or failure due to any act of God, act of civil or military authorities, act of terrorism, ‎civil disturbance, war, strike or other labor dispute, fire, interruption in telecommunications or internet ‎services or network provider services, failure of equipment and/or software, pandemic, outbreak of illness or disease, declaration of public health emergency, other catastrophe or any other ‎occurrence which is beyond our reasonable control and shall not affect the validity and enforceability of any ‎remaining provisions.‎</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>English Language. </strong>The parties have requested and agree that these Terms and all documents relating thereto be ‎drawn up in ‎English / Les parties ont demandé que cette convention ainsi que tous les documents qui ‎s’y rattachent soient ‎rédigés en anglais.‎</li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Translations.</strong> In the event of any conflict or inconsistency between the Terms in the English and any translation thereof in any other language, <strong>the English version will prevail, except for in Quebec, in which the French version will prevail</strong>.
                        <a href='#/can-f-toc'> Please click for an official French translation of these Terms</a>
                    </li>
                    <li style={{ margin: '0cm 0cm 12pt 0px', textAlign: 'justify', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><strong>Any Questions?</strong> Get in touch at
                        <strong>
                            <span className='ml-2' style={{ cursor: 'pointer', color: '#A7CA56' }} onClick={(e) => { window.open(`https://web.drugnutritioninteractions.com/#/contact`, '_blank'); }}>
                                dniteam@drugnutritioninteractions.com
                            </span>
                        </strong>
                    </li>
                </ul>
                <div>
                    {/* <hr className="msocomoff" align="left" size={1} width="33%" /> */}
                    <div>
                        <div id="_com_1" className="msocomtxt"><a name="_msocom_1" />
                            {/* <p style={{ margin: '0cm', fontSize: '10pt', fontFamily: 'Arial, sans-serif' }}><span className="MsoCommentReference"><span style={{ fontSize: '8.0pt' }}>&nbsp;<a style={{ color: '#002b5c', textDecoration: 'underline' }} href="#_msoanchor_1">[DE1]</a></span></span>Replace with link to URL for the French TOS version</p> */}
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default CanadaTOC;