import React, { useState } from 'react';
import "./CustInputRange.scss";

const CustInputRangeHeight2 = (props) => {
  const { inputValHeight, setInputValHeight } = props;

  const updateRangeDisplay = (stepsVal) => {
    props.ErSms("");
    if (!isNaN(stepsVal) == true) {
      if (props.Tab == "Imperial") {
        if (stepsVal >= 84) {
          props.ErSms("Allowed Only Less Than 83.");
          props.calorie(0);
          setFluid(0);
        } else {
          setInputValHeight(stepsVal);
        }
      } else if (props.Tab == "Metric") {
        if (stepsVal >= 211) {
          props.ErSms("Allowed Only Less Than 210.");
          props.calorie(0);
          setFluid(0);
        } else {
          setInputValHeight(stepsVal);
        }
      }
    } else {
      props.ErSms("Only digits are allowed");
      props.calorie(0);
      setFluid(0);
    }
  }
  return (
    <div className="form-group row customSlider">
      <div className='col-12'>
        <div className="ticks">
          <span className="tick">{props.minValHeight} </span>
          <span className="tick">{props.maxValHeight} </span>
        </div>
        <input type="range" style={{ width: '100%', marginLeft: '0px' }} value={(inputValHeight) ? inputValHeight : props.minValHeight} step={.1} min={props.minValHeight} name="hghtrng" max={props.maxValHeight} defaultValue={props.minValHeight} onChange={(event) => updateRangeDisplay(event.target.value)} />

      </div>
      <div className='col-7'>
        <label className='col-form-label sliderLabel' style={{ fontSize: 15, color: '#383030eb' }} >Height <span style={{ color: '#383030eb', fontSize: 13 }}>{"  " + props.label}</span></label>
      </div>
      <div className='col-5 text-right'>
        <input type="text" style={{ width: '100px', height: '38px' }} value={(inputValHeight == "0.0") ? "" : inputValHeight} min={props.minValHeight} max={props.maxValHeight} name="inputVal" maxlength="5" onChange={(e) => updateRangeDisplay(e.target.value)} id="input_val" />
      </div>
    </div>
  )
}

export default CustInputRangeHeight2;