import React from 'react'

const PageHeaderTable = (props) => {
    return (
        <section className="section search-bg1 section-height-200 parallax-container context-dark bg-gray-darkest text-xl-left" data-parallax-img={props.bgimg && props.bgimg} style={{ height: '150px' }}>
            <div className="parallax-content">
                <div className="bg-overlay-black">
                    <div className="container section-80 section-md-95 section-lg-top-120 section-lg-bottom-150" style={{ margin: '-90px', paddingLeft: '180px' }}>
                        <div className="d-none d-lg-block">
                            {props.title && <h2 className='mt-3' style={{ fontSize: 51 }}>{props.title}</h2>}
                        </div>
                        {props.title && <h5 className='d-lg-none d-flex justify-content-center' style={{ marginTop: '79px' }}>{props.title}</h5>}
                        {props.description && <p className='text-big p'>{props.description}</p>}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PageHeaderTable