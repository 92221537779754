import React from 'react'
import { Link } from "react-router-dom";
import { DummyImage } from "react-simple-placeholder-image";
import AccountNav from '../AccountNav';
import SearchBar from '../SearchBar';

function DrugDetailOption() {
  return (
    <div>
        <section className="section py-4 bg-gray-lighter mb-5 novi-background bg-cover">
            <div className="container">
                <SearchBar/>
                <div className='row account mb-5'>
                    <div className='col-lg-3 mb-3'>
                        <AccountNav />
                    </div>
                    <div className='col-lg-9 topic-check text-left'>
                        <div className='bg-white shadow p-4'>
                            <h2 className='mb-0'>Drug Details Options</h2>
                            <p className='mb-4'>Vivamus ultricies non odio eu rutrum. Praesent ac urna ut risus tristique condimentum. Tincidunt sem. Aliquam erat volutpat. Quisque nulla lacus, varius convallis nisl nec, ultricies bibendum sem. Quisque vitae tempor ante. </p>
                            <div className='row'>
                                <div className='col-lg-12 mb-5'>
                                    <div className="accordion drug-accordian" id="drugDetailsAccordian">
                                        <div className="card">
                                            <div className="card-header" id="headingOne">
                                                <h2 className="mb-0">
                                                    <button  className="btn accordion-button btn-block text-left p-0" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <div className='row align-items-center'>
                                                            <div className='col-lg-1 pl-0'>
                                                                <DummyImage width={100} height={100} placeholder='Dummy Image' className='img-fluid border-bottom' bgColor='#f5f5f5' fgColor='#999999' />
                                                            </div>
                                                            <div className='col-lg-9'>
                                                                Nulla nec euismod velit, eu iaculis nunc.Donec in volutpat erat  metus pharetra ac. 
                                                            </div>
                                                            <div className='col-lg-2 p-0'>
                                                                <div className="form-check">
                                                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" defaultChecked disabled/>
                                                                    <label className="form-check-label text-uppercase" htmlFor="exampleCheck1">show</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#drugDetailsAccordian">
                                                <div className="card-body text-left">
                                                    <div className='row'>
                                                        <div className='col-lg-11 offset-lg-1 pl-lg-4'>
                                                            <div className="form-check form-check-inline mr-5">
                                                                <h6 className='text-uppercase'>Adverse Effects</h6>
                                                            </div>
                                                            <div className="form-check form-check-inline mr-3 mr-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                                                <label className="form-check-label text-uppercase" htmlFor="inlineCheckbox1">All</label>
                                                            </div>
                                                            <div className="form-check form-check-inline mr-3 mr-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" />
                                                                <label className="form-check-label text-uppercase" htmlFor="inlineCheckbox2">Common</label>
                                                            </div>
                                                            <div className="form-check form-check-inline mr-3 mr-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3" />
                                                                <label className="form-check-label text-uppercase" htmlFor="inlineCheckbox3">Rare</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingTwo">
                                                <h2 className="mb-0">
                                                    <button className="btn accordion-button btn-block text-left p-0" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        <div className='row'>
                                                            <div className='col-lg-1 pl-0'>
                                                                <DummyImage width={100} height={100} placeholder='Dummy Image' className='img-fluid border-bottom' bgColor='#f5f5f5' fgColor='#999999' />
                                                            </div>
                                                            <div className='col-lg-9'>
                                                                Nulla nec euismod velit, eu iaculis nunc.Donec in volutpat erat  metus pharetra ac. 
                                                            </div>
                                                            <div className='col-lg-2 p-0'>
                                                                <div className="form-check">
                                                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" defaultChecked disabled/>
                                                                    <label className="form-check-label text-uppercase" htmlFor="exampleCheck1">show</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#drugDetailsAccordian">
                                                <div className="card-body text-left">
                                                    <div className='row'>
                                                        <div className='col-lg-11 offset-lg-1 pl-lg-4'>
                                                            <div className="form-check form-check-inline mr-5">
                                                                <h6 className='text-uppercase'>Adverse Effects</h6>
                                                            </div>
                                                            <div className="form-check form-check-inline mr-3 mr-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                                                <label className="form-check-label text-uppercase" htmlFor="inlineCheckbox1">All</label>
                                                            </div>
                                                            <div className="form-check form-check-inline mr-3 mr-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" />
                                                                <label className="form-check-label text-uppercase" htmlFor="inlineCheckbox2">Common</label>
                                                            </div>
                                                            <div className="form-check form-check-inline mr-3 mr-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3" />
                                                                <label className="form-check-label text-uppercase" htmlFor="inlineCheckbox3">Rare</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingThree">
                                                <h2 className="mb-0">
                                                    <button className="btn accordion-button btn-block text-left p-0" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        <div className='row'>
                                                            <div className='col-lg-1 pl-0'>
                                                                <DummyImage width={100} height={100} placeholder='Dummy Image' className='img-fluid border-bottom' bgColor='#f5f5f5' fgColor='#999999' />
                                                            </div>
                                                            <div className='col-lg-9'>
                                                                Nulla nec euismod velit, eu iaculis nunc.Donec in volutpat erat  metus pharetra ac. 
                                                            </div>
                                                            <div className='col-lg-2 p-0'>
                                                                <div className="form-check">
                                                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" defaultChecked disabled/>
                                                                    <label className="form-check-label text-uppercase" htmlFor="exampleCheck1">show</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#drugDetailsAccordian">
                                                <div className="card-body text-left">
                                                    <div className='row'>
                                                        <div className='col-lg-11 offset-lg-1 pl-lg-4'>
                                                            <div className="form-check form-check-inline mr-5">
                                                                <h6 className='text-uppercase'>Adverse Effects</h6>
                                                            </div>
                                                            <div className="form-check form-check-inline mr-3 mr-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                                                <label className="form-check-label text-uppercase" htmlFor="inlineCheckbox1">All</label>
                                                            </div>
                                                            <div className="form-check form-check-inline mr-3 mr-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" />
                                                                <label className="form-check-label text-uppercase" htmlFor="inlineCheckbox2">Common</label>
                                                            </div>
                                                            <div className="form-check form-check-inline mr-3 mr-lg-5">
                                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3" />
                                                                <label className="form-check-label text-uppercase" htmlFor="inlineCheckbox3">Rare</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <Link to="/"><button className="btn btn-primary" type="submit">Save</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

  )
}

export default DrugDetailOption