import React, { useEffect, useState } from "react"
import LabvaluesCanada from "./LabvaluesCanada";
import LabValuesUs from "./LabValuesUs";

class LabValuIndex extends React.Component{
   constructor(props){
    super(props);

    this.state={
        userDetails:''
    }
    
   } 
   componentDidMount(){
    if(localStorage.getItem('setUser')!=null){
        let data=localStorage.getItem('setUser');
        let parseData=JSON.parse(data);
        this.setState({userDetails:parseData?.userInfo});
    }
   }
   render(){
    
return<>
{this.state.userDetails?.country_view_id!=''?(this.state.userDetails?.country_view_id==2?<LabvaluesCanada/>:<LabValuesUs/>):(this.state.userDetails.country_id==2?<LabvaluesCanada/>:<LabValuesUs/>)}
</>
   }
}

export default LabValuIndex;