import React from 'react'
import Navigation from '../Navigation'
// import {useDispatch,useSelector} from 'react-redux'

function Header() {
  return (
    <Navigation />
  )
}

export default Header