import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter as Routes, Router, Switch, Route, NavLink, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import logos from "../../assets/img/logo/dni-logo.png"
import './Header/header.css';
import { Reactdom } from 'react-dom/client'
// import { offsetTop } from 'react-bootstrap';


const Navigation = () => {
	const image = useSelector((state) => state.getFlag.image);
	const navState = useSelector((state) => state?.getLocatState?.info);
	const [userDetail, setuserDetail] = useState("")
	const reloadCount1 = Number(sessionStorage.getItem('reloadCount1')) || 0;
	useEffect(() => {
		// console.log(navState)
	}, [navState])

	useEffect(() => {
		if (reloadCount1 == 0) {
			// window.location.reload();
			sessionStorage.setItem('reloadCount1', String(reloadCount1 + 1));

		} else {
			sessionStorage.removeItem('reloadCount1');
		}
		if (localStorage.getItem("setUser") != null || localStorage.getItem("setUser") != undefined) {
			let info = localStorage.getItem("setUser")
			let userInfo = JSON.parse(info);
			setuserDetail(userInfo?.userInfo)
		}
	}, []);

	const logoutHandler = () => {
		localStorage.clear();
		sessionStorage.clear()
		toast.warning('Log Out Succesfully..', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
		setTimeout(() => {
			window.location.href = `#/signin`
			window.location.reload()
		}, 1000)
	}

	function openNav() {
		document.getElementById("mySidenav").style.width = "250px";
	}

	function closeNav() {
		document.getElementById("mySidenav").style.width = "0";
	}

	const RenderMenu = () => {
		const [isNutrientSources, setNutrientSources] = useState(false)
		const [isFoodComposition, setFoodComposition] = useState(false)
		const [name1, setName1] = useState("")
		const [name2, setName2] = useState("")

		useEffect(() => {

		}, [isNutrientSources, isFoodComposition])
		useEffect(() => {

		}, [name2, name1]);
		const closeMenu = () => {
			closeNav();
			setNutrientSources(false)
			setFoodComposition(false)
		}
		return (
			<div className=''>
				<nav className="navbar navbar-expand-lg navbar-light d-lg-flex d-block">

					<div id="mySidenav" className="sidenav d-lg-none">
						<p className="closebtn" onClick={closeNav}>&times;</p>
						<ul>
							<li className="nav-item pr-xl-4 my-auto text-left">
								<a className="nav-link py-lg-4" href="#/" onClick={closeNav}>Home <span className="sr-only">(current)</span></a>
							</li>
							<li className="nav-item dropdown d-block d-lg-none text-left">
								<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									Calculators
								</a>
								<div className="dropdown-menu" aria-labelledby="navbarDropdown">

									{navState?.payment_status == "succeeded" ? <>
										<a className="dropdown-item" href="#/calculator/body-mass-index" onClick={closeNav}>Body Mass Index</a>
										<a className="dropdown-item" href="#/calculator/segmental-weights" onClick={closeNav}>Segmental Weight</a>
										<a className="dropdown-item" href="#/calculator/ideal-body-weight" onClick={closeNav}>Ideal Body Weight</a>
										<a className="dropdown-item" href="#/calculator/calorie-requirement" onClick={closeNav}>Nutrition Requirement</a>
										<a className="dropdown-item" href="#/calculator/egfr" onClick={closeNav}>eGFR</a>
										<a className="dropdown-item" href="#/calculator/body-surface-area" onClick={closeNav}>Body Surface Area</a>
									</> : <>
										<a className="dropdown-item" href="#/calculator/body-mass-index" onClick={closeNav}>Body Mass Index</a>
										<a className="dropdown-item" href="#/calculator/segmental-weights" onClick={closeNav}>Segmental Weight</a>
									</>}

								</div>
							</li>
							{navState?.payment_status == "succeeded" ?
								<li className="nav-item dropdown d-block d-lg-none text-left">
									<a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										Tables
									</a>
									<div className="dropdown-menu" >
										<a className="dropdown-item" href="#/my-table/lab-value" onClick={closeNav}>Lab Values</a>
										<a className="dropdown-item" href="#/my-table/insuline-table" onClick={closeNav}>Insulin</a>
										<a className="dropdown-item" href="#/my-table/MDSHighRisk-interactions" onClick={closeNav}>MDS high-risk drugs</a>
										<a className="dropdown-item" href="#/my-table/alcohol-interactions" onClick={closeNav}>Alcohol Interactions</a>
										<a className="dropdown-item" href="#/my-table/Grapefruit-interactions" onClick={closeNav}>Grapefruit Interactions</a>

										<a className="dropdown-item dropdown-toggle px-4 px-lg-none" href="#" onClick={(e) => {
											// console.log("Nutrient Sources clicked");
											e.preventDefault();
											if (e?.target?.name == "Nutrient") {
												setName1("Nutrient")
												setName2("");
												setFoodComposition(false)
												setNutrientSources(true);

											}
											e.stopPropagation();
										}} data-toggle="dropdown" name="Nutrient" >Nutrient Sources</a>

										<a className="dropdown-item dropdown-toggle px-4 px-lg-none" href="#" onClick={(e) => {
											// console.log("Food Composition clicked");
											console.log(isNutrientSources == true && name1 == "Nutrient")
											e.preventDefault();
											if (e?.target?.name == "Composition") {
												setName1("")
												setName2("Composition");
												setNutrientSources(false)
												setFoodComposition(true)
											}
											e.stopPropagation();
										}} data-toggle="dropdown" name="Composition"  >Food Composition</a>

										{(isFoodComposition === true && name2 == "Composition") ? <div className="av-composition-mobile mobile-drpdn1 mt-md-5"  >
											<a className="dropdown-item" href="#/my-table/Caffeine-table" onClick={closeMenu}>Caffeine</a>
											<a className="dropdown-item" href="#/my-table/glutan-table" onClick={closeMenu}>Gluten</a>
											<a className="dropdown-item" href="#/my-table/Osmolalities-table" onClick={closeMenu}>Osmolalities</a>
											<a className="dropdown-item" href="#/my-table/Oxalate-table" onClick={closeMenu}>Oxalate</a>
											<a className="dropdown-item" href="#/my-table/pH-table" onClick={closeMenu}>pH</a>
											<a className="dropdown-item" href="#/my-table/Phytate-table" onClick={closeMenu}>Phytate</a>
											<a className="dropdown-item" href="#/my-table/PressorAgents-table" onClick={closeMenu}>Pressor Agents</a>
										</div> : (isNutrientSources == true && name1 == "Nutrient") ? <div className="av-nutrient-mobile mobile-drpdn mt-md-5"  >
											<a className="dropdown-item" href="#/my-table/MagnesiumSources-table" onClick={closeMenu}>Magnesium Sources</a>
											<a className="dropdown-item" href="#/my-table/PotassiumSources-table" onClick={closeMenu}>Potassium Sources</a>
											<a className="dropdown-item" href="#/my-table/VitaminKSources-table" onClick={closeMenu}>Vitamin K Sources</a>
										</div> : ""}
									</div>
								</li>
								: ""}
							<li className="nav-item pr-xl-4 my-auto text-left">
								<a className="nav-link py-lg-4" href="#/news" onClick={closeNav}>News</a>
							</li>
							{/* <li className="nav-item pr-xl-4 my-auto text-left" onClick={closeNav}>
								<a className="nav-link py-lg-4" href="#/about" onClick={closeNav}>About</a>
							</li> */}
							<li className="nav-item dropdown d-block d-lg-none text-left">
								<a className="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About</a>
								<div className="dropdown-menu" aria-labelledby="navbarDropdown1">
									<a className="dropdown-item" href="#/about" onClick={closeNav}>About DNI Team</a>
									<a className="dropdown-item" href="#/disclaimer" onClick={closeNav}>Disclaimer</a>
									<a className="dropdown-item" href="#/privacy" onClick={closeNav}>Privacy Policy</a>

									<a className="dropdown-item nav-link dropdown-toggle px-4 px-lg-none" id="submenu" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Terms of Service</a>
									<div className="dropdown-menu mobile-dropdown mt-md-5" aria-labelledby="submenu">
										<a className="dropdown-item" href="#/can-toc" onClick={closeNav}>Canada-English</a>
										<a className="dropdown-item" href="#/can-f-toc" onClick={closeNav}>Canada-French</a>
										<a className="dropdown-item" href="#/us-toc" onClick={closeNav}>US-English</a>
									</div>
									<a className="dropdown-item" href="#/users-guide" onClick={closeNav}>DNI User Guide</a>
								</div>
							</li>
							<li className="nav-item pr-lg-4 my-auto text-left" onClick={closeNav}>
								<a className="nav-link py-lg-4" href="#/contact" onClick={closeNav}>Contact</a>
							</li>
							<li className="nav-item dropdown d-block d-lg-none text-left">
								<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									Hi, {userDetail?.first_name}&nbsp;<img src={image} width='25' style={{ marginTop: "-2px" }} />
								</a>
								<div className="dropdown-menu" aria-labelledby="navbarDropdown">
									{navState?.payment_status == "succeeded" ? <>
										{/* <li> */}
										<a className="dropdown-item" href="#/account/my-account" onClick={closeNav}>Account information</a>
										{/* <NavLink to={''} >Account information </NavLink> */}
										{/* </li> */}
										{/* <li> */}
										<a className="dropdown-item" href="#/account/payment-history" onClick={closeNav}>Payment history</a>
										{/* <NavLink to={''} >Payment history </NavLink> */}
										{/* </li> */}
										{/* <li> */}
										<a className="dropdown-item" href="#/account/view_customization" onClick={closeNav}>View Customization</a>
										{/* <NavLink to={'/'} >View Customization</NavLink> */}
										{/* </li> */}
										{/* <li> */}
										<a className="dropdown-item" onClick={logoutHandler} href="#" >Logout</a>
										{/* <NavLink to={'#'} > Logout </NavLink> */}
										{/* </li> */}
									</>
										: <>
											{/* <li> */}
											<a className="dropdown-item" href="#/plan-selection">Payment pending</a>
											{/* </li> */}
											{/* <li> */}
											<a className="dropdown-item" onClick={logoutHandler} href="#">Logout</a>
											{/* <NavLink to={'#'} > Logout </NavLink> */}
											{/* </li> */}
											{/* <li><NavLink to={'/plan-selection'} >  </NavLink></li>
											<li><NavLink to={'#'} onClick={logoutHandler} > Logout </NavLink></li> */}

										</>}
								</div>


								{/* <div className="dropdown-menu" aria-labelledby="navbarDropdown">
									<a className="dropdown-item" href="#/calculator/body-mass-index">Body Mass Index</a>
									<a className="dropdown-item" href="#/calculator/segmental-weights">Segmental Weight</a>
									<a className="dropdown-item" href="#/calculator/ideal-body-weight">Ideal Body Weight</a>
									<a className="dropdown-item" href="#/calculator/calorie-requirement">Nutrition Requirement</a>
									<a className="dropdown-item" href="#/calculator/egfr">eGFR</a>
									<a className="dropdown-item" href="#/calculator/body-surface-area">Body Surface Area</a>
								</div> */}
							</li>
						</ul>
					</div>
					<div className='d-flex justify-content-between  min-screen'>
						<span className='d-lg-none d-flex align-items-center' style={{ fontSize: 30, cursor: "pointer" }} onClick={openNav}>&#9776;</span>

						<a className="navbar-brand mr-xl-5 pr-xl-4" href="#">
							<img src={logos} />
						</a>
					</div>


					{/* Desktop nav */}
					<button className="navbar-toggler d-none" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon d-none "></span>
					</button>
					<div className="collapse navbar-collapse d-none d-lg-block" id="navbarNav">
						<ul className="navbar-nav">

							<li className="nav-item bor-right pr-lg-4 my-auto text-left">
								<NavLink className="nav-link py-lg-3" activeclassname="activeNew" to={"/"}>Home <span className="sr-only">(current)</span></NavLink>
							</li>
							<li className="dropdown bor-right pr-lg-4 d-none d-lg-block my-auto">
								<a className="nav-link py-lg-3" href="#">Calculators</a>
								<ul className="dropdown-content ml-lg-4 text-left py-lg-3">

									{navState?.payment_status == "succeeded" ? <>
										<li><a className="dropdown-text" href="#/calculator/body-mass-index">Body Mass Index</a></li>
										<li><a className="dropdown-text" href="#/calculator/segmental-weights">Segmental Weight</a></li>
										<li><a className="dropdown-text" href="#/calculator/ideal-body-weight">Ideal Body Weight</a></li>
										<li><a className="dropdown-text" href="#/calculator/calorie-requirement">Nutrition Requirement</a></li>
										<li><a className="dropdown-text" href="#/calculator/egfr">eGFR</a></li>
										<li><a className="dropdown-text" href="#/calculator/body-surface-area">Body Surface Area</a></li>
									</> : <>
										<li><a className="dropdown-text" href="#/calculator/body-mass-index">Body Mass Index</a></li>
										<li><a className="dropdown-text" href="#/calculator/segmental-weights">Segmental Weight</a></li>
									</>}


								</ul>
							</li>
							{navState?.payment_status == "succeeded" ?
								<li className="dropdown bor-right pr-lg-4 my-auto d-none d-lg-block">
									<a className="nav-link py-lg-3" href="#">Tables</a>
									<ul className="dropdown-content ml-lg-4 text-left py-lg-3">
										<li><a className="dropdown-text" href="#/my-table/lab-value">Lab Values</a></li>
										<li><a className="dropdown-text" href="#/my-table/insuline-table">Insulin</a></li>
										<li><a className="dropdown-text" href="#/my-table/MDSHighRisk-interactions">MDS high-risk drugs</a></li>
										<li><a className="dropdown-text" href="#/my-table/alcohol-interactions">Alcohol Interactions</a></li>
										<li><a className="dropdown-text" href="#/my-table/Grapefruit-interactions">Grapefruit Interactions</a></li>
										<li className="dropdown-2 pr-xl-4 my-auto">
											<a className="dropdown-text" href="#">Nutrient Sources</a>
											<ul className="dropdown-content-2  text-left py-lg-3">
												<li><a className="dropdown-text" href="#/my-table/MagnesiumSources-table">Magnesium Sources</a></li>
												<li><a className="dropdown-text" href="#/my-table/PotassiumSources-table">Potassium Sources</a></li>
												<li><a className="dropdown-text" href="#/my-table/VitaminKSources-table">Vitamin K Sources </a></li>
											</ul>
										</li>
										<li className="dropdown-1 pr-lg-4 my-auto">
											<a className="dropdown-text" href="#">Food Composition</a>
											<ul className="dropdown-content-1  text-left py-lg-3" >
												<li><a className="dropdown-text" href="#/my-table/Caffeine-table">Caffeine</a></li>
												<li><a className="dropdown-text" href="#/my-table/glutan-table">Gluten</a></li>
												<li><a className="dropdown-text" href="#/my-table/Osmolalities-table">Osmolalities</a></li>
												<li><a className="dropdown-text" href="#/my-table/Oxalate-table">Oxalate</a></li>
												<li><a className="dropdown-text" href="#/my-table/pH-table">pH</a></li>
												<li><a className="dropdown-text" href="#/my-table/Phytate-table">Phytate</a></li>
												<li><a className="dropdown-text" href="#/my-table/PressorAgents-table">Pressor Agents</a></li>
											</ul>
										</li>
									</ul>
								</li>
								: ""}

							<li className="nav-item bor-right pr-lg-4 my-auto text-left">
								<NavLink className="nav-link py-lg-3 " to={"/news"}>News</NavLink>
							</li>
							<li className="dropdown bor-right pr-lg-4 d-none d-lg-block my-auto">
								<a className="nav-link py-lg-3" href="javascript:void(0)">About</a>
								<ul className="dropdown-content ml-lg-4 text-left py-lg-3">
									<li><a className="dropdown-text" href="#/about" onClick={closeNav}>About DNI Team</a></li>
									<li><a className="dropdown-text" href="#/disclaimer" onClick={closeNav}>Disclaimer</a></li>
									<li><a className="dropdown-text" href="#/privacy" onClick={closeNav}>Privacy Policy</a></li>
									<li className="dropdown-3 pr-xl-4 my-auto">
										<a className="dropdown-text" href="javascript:void(0)">Terms of Service</a>
										<ul className="dropdown-content-3  text-left py-lg-3">
											<li><a className="dropdown-text" href="#/can-toc" onClick={closeNav}>Canada-English</a></li>
											<li><a className="dropdown-text" href="#/can-f-toc" onClick={closeNav}>Canada-French</a></li>
											<li><a className="dropdown-text" href="#/us-toc">US-English</a></li>
										</ul>
									</li>
									<li><a className="dropdown-text" href="#/users-guide">DNI User Guide</a></li>
								</ul>
							</li>
							{/* <li className="nav-item bor-right pr-lg-4 my-auto text-left">
								<NavLink className="nav-link py-lg-3" to={"/about"}>About</NavLink>
							</li> */}
							<li className="nav-item bor-right pr-lg-4 pr-xl-5 my-auto text-left">
								<NavLink className="nav-link py-lg-3" to={"/contact"}>Contact</NavLink>
							</li>
							<li className="dropdown  pr-lg-4 d-none d-lg-block my-auto">
								<a className="nav-link py-lg-3" href="#">
									Hi, {userDetail?.first_name}&nbsp;<img src={image} width='25' style={{ marginTop: "-2px" }} />
								</a>
								<ul className="dropdown-content ml-lg-4 text-left py-lg-3">
									{navState?.payment_status == "succeeded" ? <>
										<li>
											<a className="dropdown-text" href="#/account/my-account">Account information</a>
											{/* <NavLink to={''} >Account information </NavLink> */}
										</li>
										<li>
											<a className="dropdown-text" href="#/account/payment-history">Payment history</a>
											{/* <NavLink to={''} >Payment history </NavLink> */}
										</li>
										<li>
											<a className="dropdown-text" href="#/account/view_customization">View Customization</a>
											{/* <NavLink to={'/'} >View Customization</NavLink> */}
										</li>
										<li>
											<a className="dropdown-text" onClick={logoutHandler} href="#">Logout</a>
											{/* <NavLink to={'#'} > Logout </NavLink> */}
										</li>
									</>
										: <>
											<li>
												<a className="dropdown-text" href="#/plan-selection">Payment pending</a>
											</li>
											<li>
												<a className="dropdown-text" onClick={logoutHandler} href="#">Logout</a>
												{/* <NavLink to={'#'} > Logout </NavLink> */}
											</li>
											{/* <li><NavLink to={'/plan-selection'} >  </NavLink></li>
											<li><NavLink to={'#'} onClick={logoutHandler} > Logout </NavLink></li> */}

										</>}
								</ul>
							</li>
						</ul>
						{/* <button type="button" className="btn header-btn btn-success">Subscribe</button> */}
					</div>
				</nav>

			</div>
		)
	}

	// //   If user not login
	const RenderMenu1 = () => {
		return (
			<div className=''>
				<nav className="navbar navbar-expand-lg navbar-light d-lg-flex d-block">

					<div id="mySidenav" className="sidenav d-lg-none">
						<p className="closebtn" onClick={closeNav}>&times;</p>
						<ul>
							<li className="nav-item pr-xl-4 my-auto text-left">
								<a className="nav-link py-lg-4" href="#/" onClick={closeNav}>Home <span className="sr-only">(current)</span></a>
							</li>
							<li className="nav-item dropdown d-block d-lg-none text-left">
								<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									Calculators
								</a>
								<div className="dropdown-menu" aria-labelledby="navbarDropdown">
									<a className="dropdown-item" href="#/calculator/body-mass-index" onClick={closeNav}>Body Mass Index</a>
									<a className="dropdown-item" href="#/calculator/segmental-weights" onClick={closeNav}>Segmental Weight</a>
								</div>
							</li>
							<li className="nav-item pr-xl-4 my-auto text-left">
								<a className="nav-link py-lg-4" href="#/news" onClick={closeNav}>News</a>
							</li>
							{/* <li className="nav-item pr-xl-4 my-auto text-left">
								<a className="nav-link py-lg-4" href="#/about" onClick={closeNav}>About</a>
							</li> */}


							<li className="nav-item dropdown d-block d-lg-none text-left">
								<a className="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About</a>
								<div className="dropdown-menu" aria-labelledby="navbarDropdown1">
									<a className="dropdown-item" href="#/about" onClick={closeNav}>About DNI Team</a>
									<a className="dropdown-item" href="#/disclaimer" onClick={closeNav}>Disclaimer</a>
									<a className="dropdown-item" href="#/privacy" onClick={closeNav}>Privacy Policy</a>

									<a className="dropdown-item nav-link dropdown-toggle px-4 px-lg-none" id="submenu" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Terms of Service</a>
									<div className="dropdown-menu mobile-dropdown mt-md-5" aria-labelledby="submenu">
										<a className="dropdown-item" href="#/can-toc" onClick={closeNav}>Canada-English</a>
										<a className="dropdown-item" href="#/can-f-toc" onClick={closeNav}>Canada-French</a>
										<a className="dropdown-item" href="#/us-toc" onClick={closeNav}>US-English</a>
									</div>
									<a className="dropdown-item" href="#/quest/drug-details" onClick={closeNav}>View a sample DNI entry</a>
									{/* <a className="dropdown-item" href="#/users-guide" onClick={closeNav}>DNI User Guide</a> */}
								</div>
							</li>

							<li className="nav-item bor-right pr-lg-4 my-auto text-left">
								<a className="nav-link py-lg-4" href="#/contact" onClick={closeNav}>Contact</a>
							</li>

							<li className="nav-item  pr-lg-4 my-auto text-left">
								<a className="nav-link py-lg-3" href="#/signin" onClick={closeNav}>Login </a>
							</li>
							<li className="nav-item pr-lg-4  text-left ml-2 mt-2">
								<a href="#/register" onClick={closeNav}>
									<button type="button" className="btn header-btn btn-success">

										Subscribe</button>
								</a>
							</li>
						</ul>
					</div>
					<div className='d-flex justify-content-between min-screen'>
						<span className='d-lg-none' style={{ fontSize: 30, cursor: "pointer" }} onClick={openNav}>&#9776;</span>

						<a className="navbar-brand mr-xl-5 pr-xl-4" href="#">
							<img src={logos} />
						</a>
					</div>
					{/* Desktop nav */}
					<button className="navbar-toggler d-none" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon d-none "></span>
					</button>
					<div className="collapse navbar-collapse d-none d-lg-block" id="navbarNav">
						<ul className="navbar-nav">

							<li className="nav-item bor-right pr-lg-4 my-auto text-left">
								<NavLink className="nav-link py-lg-3" activeclassname="activeNew" to={"/"}>Home <span className="sr-only">(current)</span></NavLink>
							</li>
							<li className="dropdown bor-right pr-lg-4 d-none d-lg-block my-auto">
								<NavLink className="nav-link py-lg-3" to="/calculator/body-mass-index" activeclassname="activeNew">Calculators</NavLink>
								<ul className="dropdown-content ml-lg-4 text-left py-lg-3">
									<li><NavLink className="dropdown-text" activeclassname="activeNew" to={"/calculator/body-mass-index"}>Body Mass Index</NavLink></li>
									<li><NavLink className="dropdown-text" activeclassname="activeNew" to={"/calculator/segmental-weights"}>Segmental Weight</NavLink></li>
								</ul>
							</li>

							<li className="nav-item bor-right pr-lg-4 my-auto text-left">
								<NavLink className="nav-link  py-lg-3" activeclassname="activeNew" to={"/news"}>News</NavLink>
							</li>

							{/* <li className="nav-item bor-right pr-lg-4 my-auto text-left">
								<NavLink className="nav-link  py-lg-3"  activeclassname="activeNew" to={"/about"}>About</NavLink>
							</li> */}
							<li className="dropdown bor-right pr-lg-4 d-none d-lg-block my-auto">
								<a className="nav-link py-lg-3" href="javascript:void(0)">About</a>
								<ul className="dropdown-content ml-lg-4 text-left py-lg-3">
									<li><a className="dropdown-text" href="#/about">About DNI Team</a></li>
									<li><a className="dropdown-text" href="#/disclaimer">Disclaimer</a></li>
									<li><a className="dropdown-text" href="#/privacy">Privacy Policy</a></li>
									<li className="dropdown-3 pr-xl-4 my-auto">
										<a className="dropdown-text" href="javascript:void(0)">Terms of Service</a>
										<ul className="dropdown-content-3  text-left py-lg-3">
											<li><a className="dropdown-text" href="#/can-toc">Canada-English</a></li>
											<li><a className="dropdown-text" href="#/can-f-toc">Canada-French</a></li>
											<li><a className="dropdown-text" href="#/us-toc">US-English</a></li>
										</ul>
									</li>
									<li><a className="dropdown-text" href="#/quest/drug-details" >View a sample DNI entry</a></li>
									{/* <li><a className="dropdown-text" href="#/users-guide">DNI User Guide</a></li> */}
								</ul>
							</li>
							<li className="nav-item bor-right pr-lg-4 pr-xl-5 my-auto text-left">
								<NavLink className="nav-link py-lg-3" activeclassname="activeNew" to={"/contact"}>Contact</NavLink>
							</li>
							<li className="nav-item bor-right pr-lg-4 my-auto text-left">
								<NavLink className="nav-link py-lg-3" activeclassname="activeNew" to={"/signin"}>Login </NavLink>
							</li>
							<a href="#/register">
								<button type="button" className="btn header-btn btn-success">

									Subscribe</button>
							</a>
						</ul>
						{/* <button type="button" className="btn header-btn btn-success">Subscribe</button> */}
					</div>
				</nav>

			</div>
		)
	}
	return (
		<>
			<ul className="rd-navbar-nav d-lg-flex justify-content-lg-center">
				{navState != undefined ? <RenderMenu /> : <RenderMenu1 />}
			</ul>
		</>
	)
}

export default Navigation