
import React from 'react';
import '../../regular.css'
import ListItem from './listItemes';
import { SEARCH_API } from './../../services/Api/searching';
import { useState,useCallback } from 'react';
import { useEffect } from 'react';
function MySearch() {
    const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;
    const [searchResults, setSearchResults] = useState([]);
    const [threeSearch, setThreeSearch] = useState([]);
    const [userDetail, setuserDetail] = useState("");
    const [searchMsg, setSearchMsg] = useState("")
    const searchHandle = (e) => {
        if(e?.target?.value!==""){
            SEARCH_API?.genericSearch(e?.target?.value==""?0:e?.target?.value).then(res => {
                setSearchResults(res?.data)
                if(res && res?.data?.length>0){
                    setSearchMsg("");
                    // document.getElementById('recentSearched').style.display='none'
                }else{
                    if(searchResults?.length<=0){
                        setSearchMsg('No match found !');
                        setTimeout(()=>{
                        //  setSearchMsg("");
                        },3000)
                    }
                }
            })
        }else{
            setSearchMsg("");
            setSearchResults([]);
        }
    }
    const debounce=(func)=>{
        let timer;
        return function(...args){
            const context=this;
            if(timer) clearTimeout(timer)
            timer=setTimeout(()=>{
             timer=null;
             func.apply(context,args);
            },1000)
        }
    }

    useEffect(()=>{
        if(reloadCount<1) {
            sessionStorage.setItem('reloadCount', String(reloadCount + 1));
          } else {
            sessionStorage.removeItem('reloadCount');
          }
        if(localStorage.getItem('mostThree')!=null){
          let three= localStorage.getItem('mostThree');
          let finalThree=JSON.parse(three);
          setThreeSearch(finalThree)
        }
        if (localStorage.getItem("setUser") != null) {
            let info = localStorage.getItem("setUser")
            let userInfo = JSON.parse(info);
            setuserDetail(userInfo?.userInfo)
        }
    },[]);
    const searchDrugs=(e)=>{
        e.preventDefault();
        // if(searchResults?.length<=0){
        //     setSearchMsg('No record found !');
        //     setTimeout(()=>{
        //         setSearchMsg("")
        //     },3000)
        // }
    }
const optimizeFun=useCallback(debounce(searchHandle),[]);
    return <>
    { userDetail?.payment_status=="succeeded"?
        <section className="section search-bg">
            <div className="container">
                <div className="row search-section align-items-center justify-content-center">
                    <div className='col-sm-10'>
                        <div className='row mt-4'>
                            <div className='col-md-10'></div>
                            <div className='col-md-2'> <h6 className='text-center' id="set-search-fav" onClick={(e)=>{
                                window.location.href='#/account/drug-list'
                            }}><i class="fa fa-star" aria-hidden="true"></i>&nbsp;My Favorites</h6></div>
                        </div>
                       <form onSubmit={searchDrugs} className='mb-5' >
                            <div className="form-group">
                                <div className="search">
                                    <i className="fa fa-search text-dark"></i>
                                    <input type="text" className="form-control"
                                      style={{height:65,fontSize:20}}
                                        onChange={optimizeFun}
                                        placeholder="Search drugs by generic or brand name" />
                                    {/* <button className="btn btn-primary" id="search-buttun"
                                    onClick={searchDrugs}
                                    >Search</button> */}
                                </div>
                                {searchResults?.length?
                                <div style={{
                                    maxHeight:'300px',
                                    height: 'auto',
                                    overflow: 'scroll',
                                    scrollBehavior: 'smooth',
                                    overflowX: 'hidden',
                                    position: 'relative',
                                    zIndex: 1
                                }}>

                                {searchResults?.map((result, index) => (
                                    <div key={index}>
                                        <ListItem
                                            title={result.name}
                                            description={result.brand}
                                            ids={result?.generic_id}
                                        />
                                    </div>
                                ))}
                                </div>
                                :""}
                                
                                 </div> 
                        </form>
                                 
                                 <p className='mt-4' style={{color:'#F5F5F5',fontSize:18}}><b>{searchMsg}</b></p>
                               
                        <div className='text-center recent-search' id="recentSearched">
                            <h2 className='mb-3' id="set-search">
                                {threeSearch?.length>0?"Recent Searches":''}
                                
                                </h2>
                            <div className='d-flex justify-content-center'>
                                {
                                    threeSearch?.map(val=> <><button
                                        onClick={(e)=>{
                                            localStorage.setItem("gen_ids",val?.generic_id)
                                            window.location.href = `#/account/drug-details?generic_id=${val?.generic_id}`
                                        }}
                                        className='btn btn-warning'>{val?.name}</button>&nbsp;</>)
                                }
                            </div>
                        </div><br/>
                    </div>
                </div>
            </div>
        </section>:
        <>

       <section style={{height: '200px'}} className="section section-height-200 parallax-container context-dark bg-gray-darkest text-xl-left" 
            data-parallax-img="./images/banner/banner5.jpg" data-preset='{"title":"Breadcrumbs","category":"main","id":"breadcrumbs"}'>
          <div className="parallax-content">
            <div className="bg-overlay-black">
              <div className="container section-80 section-md-95 section-lg-top-120 section-lg-bottom-150">
                <div className="d-none d-lg-block" style={{marginTop: '-69px'}}>
                  <h1>Account</h1>
                </div>
                {/* <!-- List Inline--> */}
                <ul className="list-inline list-inline-dashed list-white text-big p offset-md-top-13">
                  <li><a href="#/account/search">Account</a></li>
                  <li>Search</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <br/><br/>
        <div className='row'>
            <div className='col-lg-4' style={{margin:'auto'}}>
                <strong style={{color:'#eda53a',fontFamily:'unset'}}>Your Payment Is Pending Please Complete Your Paymet...</strong>
                <button className='btn btn-primary mt-4' onClick={(e)=>{
                    window.location.href="#/plan-selection"
                }}>Proceed</button>
            </div>
        </div>
        </>
    }
    </>
}

export default MySearch