import axios from "axios";
import jwt_decode from "jwt-decode";
import { createHashHistory } from "history";
import { toast, ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css';

// import { authHeader } from "./../_helfers/auth-header";
import {  Urls1 } from "../Urls/apiUrl";
import { useEffect } from "react";
const history = createHashHistory();

export const PLAN_SELECTION = {
    PlanSelectionList,
    includePlanList,
    availablePlanList,
    billingInformation,
    rootPlanSelectionList,
    getTaxByProvinces
  };

  function getTaxByProvinces(provinces){
    let user=localStorage.getItem('setUser');
    user=JSON.parse(user);
    // console.log(user?.userInfo?.country_id)
    return axios.get(Urls1 + `api/web/tax_finder/${provinces}/${user?.userInfo?.country_id}`,{headers: { Authorization: getToken()}})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error === "Unauthorized") {
          history.push("/UnAuth");
          return [];
        }
        if (error.response !== undefined)
          if (error.response.status === 401) {
            toast.error('Un Authorized user', { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
            localStorage.clear();
            setTimeout(()=>{
              window.location.href="/#/signin"
            },2000)
          }
      });
  }

  function rootPlanSelectionList(){
    return axios.get(Urls1 + `api/web/plan_list_for_root`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error === "Unauthorized") {
          history.push("/UnAuth");
          return [];
        }
        if (error.response !== undefined)
          if (error.response.status === 401) {
            toast.error('Un Authorized user', { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
            localStorage.clear();
            setTimeout(()=>{
              window.location.href="/#/signin"
            },2000)
          }
      });
  }

   function PlanSelectionList(){
    return axios.get(Urls1 + `api/web/avilable_plan_list`,{headers: { Authorization: getToken()}})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error === "Unauthorized") {
          history.push("/UnAuth");
          return [];
        }
        if (error.response !== undefined)
          if (error.response.status === 401) {
            toast.error('Un Authorized user', { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
            localStorage.clear();
            setTimeout(()=>{
              window.location.href="/#/signin"
            },2000)
          }
      });
  }
  function includePlanList(id){
    return axios.get(Urls1 + `api/web/included_modulefor_plan/${id}`,{headers: { Authorization: getToken()}})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error === "Unauthorized") {
          history.push("/UnAuth");
          return [];
        }
        if (error.response !== undefined)
          if (error.response.status === 401) {
            toast.error('Un Authorized user', { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
            localStorage.clear();
    setTimeout(()=>{
      window.location.href="/#/signin"
    },2000)
          }
      });
  }
  
  function availablePlanList(id){
    return axios.get(Urls1 + `api/web/Avilable_modules/${id}`,{headers: { Authorization: getToken()}})
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error === "Unauthorized") {
          history.push("/UnAuth");
          return [];
        }
        if (error.response !== undefined)
          if (error.response.status === 401) {
            toast.error('Un Authorized user', { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
            localStorage.clear();
            setTimeout(()=>{
              window.location.href="/#/signin"
            },2000)
          }
      });
  }

  function billingInformation(requestOptions) {
    return axios.post(Urls1 + 'api/web/user_addplan', requestOptions,{headers: { Authorization: getToken()}})
     .then((response) => {
       return response.data;
     })
     .catch((error) => {
      if (error == "Unauthorized") {
         return [];
       }
       if (error.response != undefined)
         if (error.response.status === 401) {
           toast.error('Un Authorized user', { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
           localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
         }else if(error.response.status === 400){
           toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
         }
     });
 }
 
 

 function getToken() {
  var t = localStorage.getItem("setUser");
  t=JSON.parse(t)
  if(t!==null){
  if (t === undefined || t == null) {
    return "InvalidUser";
  }
  const decoded = jwt_decode(t?.token);
  if (
    t!==null || t!==undefined && decoded.email_id === t?.userInfo?.email_id &&
    decoded.staff_id == t?.userInfo?.staff_id
  ) {
    return "Bearer " + t?.token;
  } else {
    return "InvalidUser";
  }
  }else{
     toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
    localStorage.clear();
    setTimeout(()=>{
      window.location.href="/#/signin"
    },2000)
  }
}
