import React, { useEffect, useState } from 'react';
import '../../regular.css';
import { SEARCH_API } from '../../services/Api/searching';
const ListItem = ({ title, description, ids }) => {
  const [userInfo,setUserInfo]=useState("");
  useEffect(()=>{
    if(localStorage.getItem('setUser')!==null){
       let itm=localStorage.getItem('setUser');
       let itmparse=JSON.parse(itm);
       setUserInfo(itmparse?.userInfo);
    }
    
  },[])
    return (
       <div>
         <div className="list-item-container" style={{position:'relative',width:'100%',zIndex:99}}>   
                <div className='row'>
                    <div  className='col-md-12 col-sm-12 col-xs-12 col-lg-12' 
                    onClick={(e) => {
                        localStorage.setItem("gen_ids",ids)
                        let lastThree= []
                            lastThree.push({generic_id:ids,name:title});
                            if(localStorage.getItem('mostThree')!=null){
                              let n=localStorage.getItem('mostThree');
                              let n1=JSON.parse(n)
                              let fl=n1.filter(fls=>fls.generic_id===ids);
                              if(fl?.length>0){

                              }else{
                                if(n1?.length>=3){
                                    n1.shift()
                                    n1.push({generic_id:ids,name:title});
                                    localStorage.setItem('mostThree',JSON.stringify(n1))
                                }else{
                                    n1.push({generic_id:ids,name:title});
                                    localStorage.setItem('mostThree',JSON.stringify(n1))
                                }
                              }
                            }
                            if(localStorage.getItem('mostThree')==null){
                                localStorage.setItem('mostThree',JSON.stringify(lastThree))
                            }
                            localStorage.setItem("gen_ids",ids)
                            let obj={
                              country_id:userInfo?.country_id,
                              user_id:userInfo?.user_id,
                              generic_id:ids
                            }
                            setTimeout(()=>{
                              window.location.href = `#/account/drug-details?generic_id=${ids}`
                            },1000)
                            
                            if(obj){
                              SEARCH_API.searchGenericUpdateCount(obj).then(res);
                            }
                        }} 
                        
                        
                        style={{cursor:'pointer'}}>
                         <div id='name-info'>
                         <span style={{color:'#15678f',fontSize:20}} id="rearch-result"><strong>{title}</strong></span>  
                         <p className='' id="rearch-result-desc" style={{fontSize:16,color:"gray",fontStyle: 'oblique',marginLeft:60}}>{description }</p>
                         </div>
                    </div>
                    <div className='col-md-1'>
                        <div className="right" onClick={(e) => {
                            localStorage.setItem("gen_ids",ids)
                            let lastThree= []
                                lastThree.push({generic_id:ids,name:title});
                                if(localStorage.getItem('mostThree')!=null){
                                  let n=localStorage.getItem('mostThree');
                                  let n1=JSON.parse(n)
                                  let fl=n1.filter(fls=>fls.generic_id===ids);
                                  if(fl?.length>0){
    
                                  }else{
                                    if(n1?.length>=3){
                                        n1.shift()
                                        n1.push({generic_id:ids,name:title});
                                        localStorage.setItem('mostThree',JSON.stringify(n1))
                                    }else{
                                        n1.push({generic_id:ids,name:title});
                                        localStorage.setItem('mostThree',JSON.stringify(n1))
                                    }
                                  }
                                }
                                if(localStorage.getItem('mostThree')==null){
                                    localStorage.setItem('mostThree',JSON.stringify(lastThree))
                                }
                                localStorage.setItem("gen_ids",ids)
                                let obj={
                                  country_id:userInfo?.country_id,
                                  user_id:userInfo?.user_id,
                                  generic_id:ids
                                }
                                setTimeout(()=>{
                                  window.location.href = `#/account/drug-details?generic_id=${ids}`
                                },1000)
                                if(obj){
                                  SEARCH_API.searchGenericUpdateCount(obj).then(res);
                                }
                        }}>
                            {/* <h3 className='info'> i </h3> */}
                        </div>
                    </div>
                </div>
        </div>
       </div>
    );
};
export default ListItem;