import React from 'react'

function Search() {
  return (
    <div>
      <section className="section context-dark text-xl-left search-bg">
        <div className="container">
            <div className="row search-section align-items-center justify-content-center">
                <div className='col-sm-10'>
                    <h1 className='text-center'>Lorem ipsum dolor sit amet, consectetur</h1>
                    <p className='mb-3 text-center mb-2'>Nam at felis turpis. Integer sapien dui, gravida eu ante sit amet, faucibus efficitur diam. Etiam ullamcorper nisi vel tortor tristique egestas. Nunc pretium sodales fringilla.</p>
                    <form className='mb-5'>
                        <div className="form-group">
                            <div className="search  mb-3">
                                <i className="fa fa-search text-dark"></i> <input type="text" className="form-control" placeholder="Have a question? Ask Now" />
                                <button className="btn btn-primary border">Search</button>
                            </div>
                            <small className='text-left'>Quisque a posuere tortor, vitae auctor urna. Proin ac ligula quis turpis vestibulum luctus</small>
                        </div>
                    </form>
                    <div className='text-center recent-search'>
                        <h2 className='mb-3'>Recent Search</h2>
                        <div className='d-flex justify-content-center'>
                            <button className='btn btn-primary'>Ibuprofen</button>
                            <button className='btn btn-primary mx-lg-5'>Tramadol</button>
                            <button className='btn btn-primary'>Vardnafil</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
        <div className="container">
            <div className="col-md-6 col-md-offset-3">
                <div className="row align-items-center justify-content-center ">
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default Search