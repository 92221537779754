import { ActionType } from "../constant/actionType";
import CAN from "../../assets/CAN.png";
import USA from "../../assets/USA.png";

let info = localStorage.getItem("setUser");
let userInfo = JSON.parse(info);

const initialState = {
  image: userInfo?.userInfo?.country_view_id == 2 ? CAN : USA,
};
const initialState1 = {
  info: userInfo?.userInfo,
};

export const getFlag = (state = initialState, { type, payload }) => {
   let paloads=payload==2?CAN:USA
  switch (type) {
    case ActionType.SET_FLAG:
      return { ...state, image: paloads,localData:userInfo };
    default:
      return state;
  }
}

export const getLocatState = (state = initialState1, { type, payload }) => {
  let paloads=payload?.userInfo
 switch (type) {
   case ActionType.SET_LOCAL:
     return { ...state, data: paloads};
   default:
     return state;
 }
}