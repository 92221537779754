import React, { useState } from 'react';
import "./CustInputRange.scss";

const CustInputRangeAge2 = (props) => {

  const [inputVal, setInputVal] = useState();
  const [minVal, setMinVal] = useState(18);
  const [maxVal, setMaxVal] = useState(110);
  const { setinputValAge, inputValAge } = props;
  const updateRangeDisplay = (stepsVal) => {
    props.ErSms("");
    if (!isNaN(stepsVal) == true) {

      if (stepsVal <= 18) {
        props.ErSms("Please enter Age value above 18");
      }
      if (stepsVal >= 111) {
        props.ErSms("Allowed Only Less Than 110.");
      }
      if (stepsVal?.includes('.') == true) {
        props.ErSms("please Enter valid Input");
      }
      if (stepsVal.length > 3) {
        props.ErSms("Please Enter valid Input");
      }
      else {
        setinputValAge(stepsVal);
        //props.MainValSet(stepsVal);
      }
    } else {
      props.ErSms("Only digits are allowed.");
    }
  }

  return (
    <div className="form-group row customSlider">
      <div className='col-12'>
        <div className="ticks">
          <span className="tick">{props.minVal} </span>
          <span className="tick">{props.maxVal} </span>
        </div>
        <input type="range" style={{ width: '100%', marginLeft: '0px' }} value={(inputValAge) ? inputValAge : props.minVal} step={1} min={props.minVal} name="hghtrng" max={props.maxVal} defaultValue={props.minVal} onChange={(event) => updateRangeDisplay(event.target.value)} />

      </div>
      <div className='col-7'>
        <label className='col-form-label sliderLabel' style={{ fontSize: 15, color: '#383030eb' }}>Age<span style={{ color: '#383030eb', fontSize: 13 }}></span></label>
      </div>
      <div className='col-5 text-right'>
        <input type="text" style={{ width: '100px', height: '38px' }} value={inputValAge} min={props.minVal} max={props.maxVal} name="inputVal" onChange={(e) => updateRangeDisplay(e.target.value)} id="input_val" />
      </div>
    </div>
  )
}

export default CustInputRangeAge2;