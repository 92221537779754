

import React from "react";
import { toast } from "react-toastify";
import { AUTENTICATE_API } from "../../services/Api/authenticationApi";
class ChangesPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: '',
      errMsg: ''
    }
  }

  componentDidMount() { }

  onChange(e) {
    if (e.target.name === "password") {
      if (e.target.value?.length <= 12) {
        this.setState({ password: e.target.value })
      }
      if (e.target.value?.length == 12) {
        this.setState({ errMsg: 'Password should be 12 character' })

        setTimeout(() => {
          this.setState({ errMsg: '' })
        }, 3000)
      }
    }
    if (e.target.name === "confirmPassword") {

      if (e.target.value?.length <= 12) {
        this.setState({ confirmPassword: e.target.value })
      }
      if (e.target.value?.length == 12) {
        this.setState({ errMsg: 'Confirm password should be 12 character' })

        setTimeout(() => {
          this.setState({ errMsg: '' })
        }, 3000)
      }
    }
  }


  changePassword(e) {
    let storage = localStorage.getItem('setUser');
    storage = JSON.parse(storage);
    e.preventDefault()
    if (this.state.password == '') {
      this.setState({ errMsg1: 'Please enter password *' });
      setTimeout(() => {
        this.setState({ errMsg1: '' })
      }, 3000)
      return false;
    }
    if (this.state.password != this.state.confirmPassword) {
      this.setState({ errMsg1: 'Confirm password must be same as password' });
      setTimeout(() => {
        this.setState({ errMsg1: '' })
      }, 3000)
      return false;
    }
    let obj = {
      email_id: storage?.forgot_email,
      password: this.state.password
    }
    if (obj) {
      AUTENTICATE_API?.changeUserPassword(obj).then(res => {
        if (res && res?.status == 200) {
          toast.success(res?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(() => {
            window.location.href = '#/signin'
          }, 1500)
        }
      })
    }
  }

  render() {
    return <React.Fragment>
      <section className="section section-50 section-lg-95 bg-light novi-background bg-cover" >
        <div className='container'>
          <div className="row align-items-center justify-content-center align-items-center offset-top-40 ">
            <div className='col-lg-6 p-5 bg-white card-bs'>
              <h3 className='text-left'>Change Your Password</h3>
              <p className='text-left' style={{ marginTop: 10, color: 'orange' }}></p>
              <form className="rd-mailform text-left offset-top-20 " data-form-output="form-output-global" data-form-type="contact">
                <div className="row row-fix row-narrow-12 row-12 justify-content-sm-center">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <input className="form-input" id="contact-us-email" maxLength={12} value={this.state.password} type="password"
                        name="password" placeholder='New Password' onChange={this.onChange.bind(this)} />
                    </div>
                  </div>
                </div>
                <div className="row row-fix row-narrow-12 row-12 justify-content-sm-center">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <input className="form-input" id="contact-us-email" maxLength={12} value={this.state.confirmPassword} type="password"
                        name="confirmPassword" placeholder='Confirm Password' onChange={this.onChange.bind(this)} />
                    </div>
                  </div>
                </div>
                <div className="offset-top-10">
                  <button className="btn btn-primary" onClick={this.changePassword.bind(this)}>Submit</button>
                  <span className='text-left' style={{ marginTop: 10, color: '#ff4600', marginLeft: 15 }}>{this.state.errMsg1 != "" ? this.state.errMsg1 : ""}</span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  }
}

export default ChangesPassword