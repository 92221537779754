import { keyboard } from '@testing-library/user-event/dist/keyboard';
import React, { useState, useEffect } from 'react';
import CustInputRange from '../../../components/common/CustInputRange/CustInputRangeSerum';
import CustInputRangeAge from '../../../components/common/CustInputRange/CustInputRangeAge';
import PageHeader from '../../../components/PageHeader/PageHeader';
import CustInputRangeSerum from '../../../components/common/CustInputRange/CustInputRangeSerum';
const EGFRCalculator = () => {
  const [gender, setGender] = useState("male")
  const [newval, setNewVal] = useState("")
  const [maxSrcValue, setmaxSrcValue] = useState(0)
  const [egfr, setegfr] = useState("")
  // const[egfrf,setegfrf]=useState("")
  const [inputVal, setInputVal] = useState("");
  const [inputValAge, setInputAge] = useState("");
  const [unitValue, setUnitValue] = useState("");
  const [Tab, setTab] = useState('mg/dL');
  const [minVal, setMinValue] = useState(0.1);
  const [maxVal, setMaxValue] = useState(20);
  let Scr = inputVal;
  let age = inputValAge;
  //-----minimum_values_of_male----//
  let age_value = Math.pow(0.99, age);
  let ScrValue = Scr;
  let minimummaleScr = (ScrValue / 0.9);
  let minimum_male_value = Math.min(minimummaleScr, 1);
  let powerofminmaleValue = (1 / minimum_male_value);
  let minimum_male_Scr = Math.pow(powerofminmaleValue, 0.3);
  //-----maximum_values_of_male----//
  let maximumScrMale = (ScrValue / 0.9);
  let maximum_male_value = Math.max(maximumScrMale, 1);
  let powerofmaxmaleValue = (1 / maximum_male_value);
  let maximum_male_Scr = Math.pow(powerofmaxmaleValue, 1.2);
  let egfr_male = (142 * minimum_male_Scr * maximum_male_Scr * age_value);
  //-----minimum_values_of_female----//
  let minimumFemaleScr = (ScrValue / 0.7);
  let minimum_female_value = Math.min(minimumFemaleScr, 1);
  let powerofminfemaleValue = (1 / minimum_female_value);
  let minimum_female_Scr = Math.pow(powerofminfemaleValue, 0.24);
  //-----maximum_values_of_female----//
  let maximumFemaleScr = (ScrValue / 0.7);
  let maximum_female_value = Math.max(maximumFemaleScr, 1);
  let powerofmaxfemaleValue = (1 / maximum_female_value);
  let maximum_female_Scr = Math.pow(powerofmaxfemaleValue, 1.2);
  let egfr_female = (142 * maximum_female_Scr * minimum_female_Scr * 1.012 * age_value);
  const [ErrMsg, setErrMsg] = useState('');
  const [HiIn, setHiIn] = useState('(mg/dL)');
  var val = "";
  var nval = "";
  var valtab2 = "";
  var nval2 = "";
  useEffect(
    () => {
      if (localStorage.getItem("setUser") != null) {
        var Dflt;
        let DefltSystm = JSON.parse(localStorage.getItem("setUser"));
        Dflt = DefltSystm?.userInfo?.country_view_id;
        if (Dflt == 2) {
          document.getElementById('chkPrv').checked = true;
          setTab('μmol/L');
          setHiIn("(μmol/L)");
          setMinValue(9);
          setMaxValue(1768);
          setInputVal((inputVal * 88.4).toFixed(1))
        } else {
          document.getElementById('chkPrv').checked = false;
          setTab('mg/dL');
          setHiIn("(mg/dL)");
          setMinValue(0.1);
          setMaxValue(20);
          setInputVal((inputVal / 88.4).toFixed(1))
        }
      }
    }, []
  );
  const onChangeHandler = (e) => {
    //  setErrMsg("");
    if (e && e.target && e.target.value) {
      if (e.target.name === "gender") {
        setGender(e.target.value)
      } if (e.target.name == "chkPrvCheck") {
        if (e.target.checked == true)
          setTab('μmol/L');
        setHiIn("(μmol/L)");
        setMinValue(9);
        setMaxValue(1768);
        setInputVal((inputVal * 88.4).toFixed(1))
      }
      if (e.target.checked == false) {
        setTab('mg/dL');
        setHiIn("(mg/dL)");
        setMinValue(0.1);
        setMaxValue(20);
        setInputVal((inputVal / 88.4).toFixed(1))
      }
    }
  }
  const CalculateEgfr = (e) => {
    setErrMsg("")
    if (Tab == "μmol/L") {
      if (inputVal == "" && inputVal > 1768) {
        setErrMsg("Invalid serum creatinine value.");
      }
      else if (inputVal < 9) {
        setErrMsg("Invalid serum creatinine value")
      } else if (inputVal == 0) {
        setErrMsg("Invalid serum creatinine value.");
      } else if (inputValAge < 18) {
        setErrMsg("Please select Age above 18");
      } else if (gender == "male") {
        nval = (egfr_male * 88.4).toFixed(1);
        setegfr(nval);
      } else if (gender == "female") {
        nval = (egfr_female * 88.4).toFixed(1)
        setegfr(nval);
      }
    } else if (Tab == "mg/dL") {
      if (inputVal == "" && inputVal > 20) {
        setErrMsg("Invalid serum creatinine value.");
      } else if (inputVal == 0) {
        setErrMsg("Invalid serum creatinine value.");
      } else if (inputValAge < 18) {
        setErrMsg("Please select Age above 18");
      }
      else if (gender == "male") {
        valtab2 = (egfr_male).toFixed(1)
        //nval2= valtab2.toFixed(1)
        setegfr(valtab2);
      } else if (gender == "female") {
        valtab2 = (egfr_female).toFixed(1)
        // nval2 = valtab2.toFixed(1)
        setegfr(valtab2);
      }
    }
  }

  // useEffect(() => {

  //   const meta = document.createElement('meta');
  //   meta.name = 'viewport';
  //   meta.content = 'width=device-width, initial-scale=0.5';
  //   const existingMeta = document.querySelector('meta[name="viewport"]');
  //   if (existingMeta) {
  //     existingMeta.remove();
  //   }


  //   document.head.appendChild(meta);


  //   return () => {
  //     meta.remove();
  //   };
  // }, []);

  return (
    <div>
      <PageHeader
        bgimg="https://img.freepik.com/free-photo/adult-child-holding-kidney-shaped-paper-textured-blue-background-world-kidney-day-national-organ-donor-day-charity-donation-concept_49149-1211.jpg"
        title="eGFR Calculator"
        description={"Estimate renal function based on age, gender and serum creatinine value (CKD-EPI 2021 Race-Free Equation)"} />

      <section className="section section-50 section-lg-95 novi-background bg-cover">
        <div className='container' style={{ marginTop: '-80px' }}>
          <div className="row offset-top-40 card-bs">
            <div className='col-lg-6 text-left p-5 d-flex flex-column justify-content-between'>

              <div className='row'>
                <div className='col-12'>
                  <div className="custom-control mb-3 pl-0">
                    <h6 className='d-inline-block pr-2' value="mg/dL" name="tab" >mg/dL</h6>
                    <h6 className='d-inline-block custom-switch'>
                      <input type="checkbox" className="custom-control-input" name="chkPrvCheck" id="chkPrv" onChange={onChangeHandler} />
                      <label className="custom-control-label" htmlFor="chkPrv">&nbsp;</label>
                    </h6>
                    <h6 className='d-inline-block' value="μmol/L" name="tab1"  >μmol/L</h6>
                  </div>
                </div>
              </div>

              <CustInputRangeSerum Label={HiIn} gender={gender} Tab={Tab} setTab={setTab} inputVal={inputVal} egfr={setegfr} setInput={setInputVal} minVal={minVal} maxVal={maxVal} ErSms={setErrMsg} />

              <CustInputRangeAge label="Age" minVal={18} maxVal={110} inputValAge={inputValAge} setInputAge={setInputAge} ErSms={setErrMsg} />

              <div className="input-group mb-4">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon2">Gender</span>
                </div>

                <div className="input-group-append px-3 border">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="gender" value="male" onChange={onChangeHandler} ErSms={setErrMsg} defaultChecked="true" />
                    <label className="form-check-label" htmlFor="male">Male</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="gender" value="female" onChange={onChangeHandler} ErSms={setErrMsg} />
                    <label className="form-check-label" htmlFor="female">Female</label>
                  </div>
                </div>
              </div>

              <div className='form-group row'>
                <div className='col text-lg-left text-center'>
                  <div style={{ color: 'red' }}> {ErrMsg} </div>
                  <button className='btn btn-primary' onClick={CalculateEgfr}  >Calculate</button>
                </div>
              </div>
              {/* <div style={{color:'red'}}> {ErrMsg} </div> */}
            </div>
            <div className='col-lg-6 p-5 d-flex align-items-center' style={{ background: "#a6c550" }}>
              <div className="col">
                <h2 className='text-white mb-3 display-4'>eGFR</h2>
                <div className='badge bg-white py-2 px-3'><h5 className='text-primary'> {egfr == "" || egfr == Infinity
                  ? 0 : egfr} mL/min/1.73m<sup>2</sup></h5></div>
              </div>
            </div>
          </div>

        </div>
        <div>

        </div>
      </section>
      <div style={{ marginTop: '-70px', color: '#484343' }}><b>Reference: </b><span><a target="_blank" href=" https://www.kidney.org/professionals/kdoqi/gfr_calculator/formula">National Kidney Foundation (US). CKD-EPI Creatinine Equation (2021) </a></span> </div>
    </div>
  )
}



export default EGFRCalculator;