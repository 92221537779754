import React from 'react'

function SearchBar() {
  return (
    <div className="row justify-content-between align-items-center mb-5">
        <div className='col-3 text-left'>
            <h2>DNI Account</h2>
        </div>
        <div className='col-lg-9 pr-lg-0'>
            <form role="form">
                <div className="form-group mb-0">
                    <div className="search">
                        <i className="fa fa-search text-dark"></i> <input type="text" className="form-control" placeholder="Have a question? Ask Now" />
                        <button className="btn btn-primary border">Search</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
  )
}

export default SearchBar