import React, { useState,useEffect } from 'react';
import img from '../../assets/images/subscribe.jpg';
import { AUTENTICATE_API } from '../../services/Api/authenticationApi';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { SEARCH_API } from '../../services/Api/searching';
function Register() {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [professionList, setProfessionList] = useState([])
  const [profession, setProfession] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [country, setCountry] = useState("")
  const [countryList, setCountryList] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [areaInterestList, setareaInterestList] = useState([])
  const [areaofinterest, setareaofinterest] = useState([])
  const [marketingemail, setmarketingemail] = useState(false)
  const [terms, setTerms] = useState(false)
  const [email, setEmail] = useState("")
  const [reqMsg, setreqMsg] = useState("")
  const [isVerified, setVerfied] = useState(false)
  const [verficationcode, setVerificationCode] = useState("")
  const [prefferedCountry, setPrefferedCountry] = useState("")
  const [organization, setOrganization] = useState("")
  const [pssreqMsg, setPssreqMsg] = useState("")
  const [codeValid, setCodeValid] = useState(false)

  
  const bgLeftBox = { width: 200, height: 200, placeholder: "200x200" };
  const [selectId, setSelectId] = useState([])

  //  const sectionStyle = {
  //    backgroundImage: `url(${img(bgLeftBox)})`
  //  };
  const handleChange = (e) => {
    setreqMsg('')
 if(e?.target?.name=="zipCode"){
   if(e.target.value?.length>6){
      // AUTENTICATE_API.validate_postalCode(e.target.value).then(res=>{
      //   if(res && res?.count==0){
      //     setreqMsg('Enter valid Postal code.');
      //     setTimeout(()=>{
      //       setreqMsg('');
      //     },2000);
      //     setCodeValid(true)
      //   }else{
      //     setCodeValid(false)
      //   }
      // })
   }
 }

    if (e.target.name === "firstName") { setFirstName('') }
    if (e.target.name === "lastName") { setLastName('') }
    if (e.target.name === "profession") { setProfession('') }
    if (e.target.name === "password") { setPassword('') }
    if (e.target.name === "confirmPassword") { setConfirmPassword('') }
    if (e.target.name === "country1") { 
      setCodeValid(false);
      setCountry(''); 
    }
    if (e.target.name === "email") { setEmail('') }
    if (e.target.name === "verficationcode") { setVerificationCode('') }
    if (e.target.name === "organization") { setOrganization('') }
    if (e.target.name === "postalCode") { setPostalCode('') }
    if (e.target.name === "zipCode") { setPostalCode('') }
    if (e.target.name === "Code") { setPostalCode('') }

    setreqMsg("");
    if (e.target.name == 'areaofinterest3') {
      if ((areaofinterest.includes(e.target.value) == true && e.target.checked == false)) {
        areaofinterest.splice(areaofinterest.indexOf(e.target.value), 1);
      } else {
        areaofinterest.push(e.target.value)
      }
    }
    if (e && e.target && e.target.value) {
      if (e.target.name === "firstName") {
        setFirstName(e.target.value)
      } else if (e.target.name === "lastName") {
        setLastName(e.target.value)
      } else if (e.target.name === "organization") {
        setOrganization(e.target.value)
      }
      else if (e.target.name === "profession") {
        setProfession(e.target.value)
      } else if (e.target.name === "password") {
        
        if(e.target.value?.length<=12){
          setPassword(e.target.value)
        }
        if(e.target.value?.length==12){
          setPssreqMsg("password should be 12 character");
          setTimeout(()=>{
            setPssreqMsg('')
          },3000)
        }
      } else if (e.target.name === "confirmPassword") {
        if(e.target.value?.length<=12){
          setConfirmPassword(e.target.value)
        }
        if(e.target.value?.length==12){
          setPssreqMsg("password should be 12 character");
          setTimeout(()=>{
            setPssreqMsg('')
          },3000)
        }   
      }

      else if (e.target.name === "country1") {
        setPostalCode('')
        setCountry(e.target.value)
      } else if (e.target.name === "email") {
        setEmail(e.target.value)
      } else if (e.target.name === "zipCode") {
        if (country === '2') {
          
          if (e.target.value.length < 8) {
            if ( e.target.value.length === 3) {
              setPostalCode(e.target.value?.toUpperCase() + " ")
            } else {
              setPostalCode(e.target.value?.trim()?.toUpperCase())
            }
          }
              setreqMsg('');
              var ca = /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][ -]?[0-9][ABCEGHJ-NPDRSTV-Z][0-9]$/i
              if(!ca.test(e.target.value)){
                setreqMsg('Enter valid Postal code Ex. A1A 1A1.');
                setTimeout(() => {
                  setreqMsg('');
                }, 3000);
                setCodeValid(true)
              }else{
                setCodeValid(false)
                setTimeout(() => {
                  setreqMsg('');
                }, 3000);
              }
        }
      }
      else if (e.target.name === "postalCode") {
        if (country === '1') {
          if(isNaN(e.target.value)){
            setPostalCode(e.target.value);
            setreqMsg('Enter valid Postal code Ex. 12345 and it Should be 5 digits.');
            setTimeout(() => {
              setreqMsg('');
            }, 3000);
          }else{
            e.target.value.length < 6 && setPostalCode(e.target.value);
          }
        }
      }
      else if (e.target.name === "Code") {
        setPostalCode(e.target.value);
      }
      else if (e.target.name === "marketingemail") {
        setmarketingemail(e.target.value)
      } else if (e.target.name === "verficationcode") {
        setVerificationCode(e.target.value);
      }
    }
  }


  const onSubmit = (e) => {
    e.preventDefault();
    var module1 = [];
    areaofinterest.map((index) => {
      let v = index?.opt_id
      module1.push(v);
    })
if(country === '1'){
  if(isNaN(postalCode)){
    setreqMsg('Enter valid Postal code Ex 12345');
            setTimeout(() => {
              setreqMsg('');
            }, 3000);
            return false;
  }
}

    let obj = {
      first_name: firstName,
      last_name: lastName,
      password: password,
      profession_id: profession,
      email_id: email,
      country_id: country,
      state_id: 1,
      status: 1,
      zip_code: postalCode,
      plan_detail_id: 1,
      promo_id: 1,
      AreaIntID: areaofinterest,
      marketing_email: marketingemail === false ? 0 : 1,
      country_view_id: prefferedCountry,
      organization: organization
    }

    
   

    if (firstName !== "" && lastName !== "" && password !== "" && postalCode !== "" && profession !== "" && country !== "" && postalCode !== "" && email!=="") {
      var emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
      if ((email != "") && (!(emailfilter.test(email)))) {
        setreqMsg("Please enter the valid email");
        setTimeout(()=>{
          setreqMsg('')
        },2000)
        return false;
      }

      if(country === '1'){
        if(isNaN(postalCode) || postalCode?.length!=5){
          setreqMsg('Enter valid Postal code Ex 12345');
                  setTimeout(() => {
                    setreqMsg('');
                  }, 3000);
                  return false;
        }
      }
           
      if(password?.length>12){
        setreqMsg("Password should be 12 character");
        setTimeout(()=>{
          setreqMsg('')
        },2000)
        return false
      }
      
   if(codeValid==true){
    setreqMsg("Enter valid Postal code Ex. A1A 1A1."); 
    setTimeout(()=>{
      setreqMsg(""); 
    },3000);
    return false
   }
   if (password !== confirmPassword) {
    setreqMsg('Confirm password must be same as password', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
    setTimeout(()=>{
      setreqMsg('')
    },2000)
    return false
  }
  // if (terms == false) {
  //   setreqMsg("Please check  terms & conditons");
  //   setTimeout(()=>{
  //     setreqMsg('')
  //   },2000)
  //   return false;
  // }
      AUTENTICATE_API.createUser(obj).then(res => {
        if (res?.status === 201) {
          setTimeout(() => {
           toast.success("Successfull register otp send to your mail.", { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
            setVerfied(true);
          }, 2000)
        }
      })
    } else {   
      setreqMsg("Please complete all required fields."); 
      setTimeout(() => {
        setreqMsg(""); 
      }, 2000);
      return false;
    }
  }



  const handleVerify = (e) => {
    e.preventDefault()
    let obj = {
      email_id: email,
      verfication_code:parseInt(verficationcode)
    }
    AUTENTICATE_API.userVerification(obj).then(res => {
      if (res?.status === 200) {
        toast.success(res?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        localStorage.removeItem('setUser');
        let setUser = {
          userInfo: res.data,
          token: res?.token
        }
        localStorage.setItem('state', JSON.stringify(setUser));
        localStorage.setItem('setUser', JSON.stringify(setUser));
        let obj1 = {
          "pharm_class": 1,
          "thera_class": 1,
          "routes": 1,
          "dose_forms": 1,
          "natural_products": 1,
          "renal_warning": 1,
          "protein_binding": 1,
          "dialysis": 1,
          "hepatic_warning": 1,
          "pregnancy_warnings": 1,
          "lactation_warnings": 1,
          "oral_adverse_effects": 1,
          "lab_parameters": 1,
          "cross_references": 1,
          "citations":1,
          "user_id": res?.data?.user_id
      }

      if (obj1) {
          SEARCH_API.user_drugDetail_visibility(obj1).then(res => {
              if (res && (res?.status == 201 || res?.status == 200)) {
                  // toast.success(res?.message, { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
                  this.getView(this.state.userInfo?.user_id)
              } else {
                  // toast.error('Not updated !', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
              }
          })
      }
        setTimeout(() => {
          setVerfied(false);
          window.location.href = "#/plan-selection"
        }, 2000)
      }
    })
  }

  const onResendCode = (e) => {
    e.preventDefault()
    AUTENTICATE_API.otpResend(email).then(res => {
      if (res?.status === 201) {
        toast.success(res?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        setTimeout(() => {
          setVerfied(true);
        }, 2000)
      }
    })
  }

  useEffect(() => {
    AUTENTICATE_API.professionList().then((response) => {
      setProfessionList(response.data);
    })

  }, []);

  useEffect(() => {
    AUTENTICATE_API.countryList().then((response) => {
      setCountryList(response.data);
    })
  }, []);

  useEffect(() => {
    AUTENTICATE_API.areaInterestList().then((response) => {
      let flArea=response.data?.filter(fl=>fl?.status==1);
      setareaInterestList(flArea);
    })
  }, []);
  return (
    <div>
      <div>
        <section className="section section-50 section-lg-30 novi-background" style={{marginTop:'0px'}}>
          <div className='container '>
            <div className='row text-center'>
            </div>
            {isVerified === false ? <>
              <div className="row offset-top-0 card-bs">
                {/* <div className='col-lg-6  p-5 d-flex flex-column justify-content-between'> */}
                <img src={img} className='col-lg-6  p-2 d-flex flex-column justify-content-between'style={{height:788}} />
                {/* </div> */}
                <div className='col-lg-6'>
                  <form className="rd-mailform text-left offset-top-20  p-5"  >
                    <div className="row row-fix row-narrow-12 row-12">
                      <div className="col-sm-12">
                        <h2>Subscription</h2>
                        <p className='mt-2 mb-3'style={{color:"black",fontSize:"15px"}}>Subscribe to DNI with an annual plan for unlimited access to professional, dietitian-focused drug information.</p>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-wrap">
                          <input className="form-input" id="reg-first-name" name="firstName" placeholder='First Name' onChange={handleChange} type="text" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-wrap">
                          <input className="form-input" id="reg-last-name" type="text" name="lastName" placeholder='Last Name' onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-wrap">
                          <select className="form-control" id="" name="profession" placeholder='Profession' onChange={handleChange}>
                            <option value="" >--Select Profession--</option>
                            {professionList?.map((val,i) => <option value={val.profession_id} key={i} >{val.profession_text}</option>)}

                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-wrap">
                          <input className="form-input" id="reg-email" type="email" name="email" placeholder='Email' onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-wrap input-group">
                          <input className="form-input" id="reg-password" type="password" name="password" maxLength={12}  placeholder='Password' onChange={handleChange} />
                          {/* <i className="fa fa-eye" aria-hidden="true" style={{position:'relative',marginTop:'-30px',fontSize:30}}></i> */}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-wrap">
                          <input className="form-input" id="reg-confirm-password" type="password" maxLength={12}  name="confirmPassword" placeholder='Confirm Password' onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <select className="form-control" id="exampleFormControlSelect2" name="country1" placeholder='Country' onChange={handleChange} >
                            <option value="" >--Select Country--</option>
                            {Object.entries(countryList)?.map(([key, val]) => <>
                              <option value={val?.country_id} key={val.country_id?.toString()} >{val?.country_name.toUpperCase()}</option>
                            </>)}
                          </select>
                        </div>
                      </div>
                      {country == '1' ?
                        <div className="col-sm-6">
                          <div className="form-wrap">
                            <input className="form-input" id="reg-postalCode" value={postalCode} type="text" name="postalCode"
                            onPaste={(e)=>{
                              e.preventDefault()
                              setreqMsg('Type your postal code ');
                              setTimeout(()=>{
                                setreqMsg();
                              },2000)
                              return false;
                            }} onCopy={(e)=>{
                              e.preventDefault()
                              return false;
                              }}
                            placeholder='Zip Code' maxLength={5} onChange={handleChange} />
                          </div>
                        </div> : (country == '2') ?
                          <div className="col-sm-6">
                            <div className="form-wrap">
                              <input className="form-input" id="reg-zipcode" value={postalCode} type="text" 
                              onPaste={(e)=>{
                                e.preventDefault()
                                setreqMsg('Type your postal code ');
                                setTimeout(()=>{
                                  setreqMsg('');
                                },2000)
                                return false;
                              }} onCopy={(e)=>{
                                e.preventDefault()
                               
                                return false;
                                }}
                              name="zipCode"  placeholder='Postal Code' maxLength={7} onChange={handleChange} />
                            </div>
                          </div> :
                          <div className="col-sm-6">
                            <div className="form-wrap">
                              <input className="form-input" id="reg-zipcode" value={postalCode} type="text" 
                              onPaste={(e)=>{
                                e.preventDefault()
                                setreqMsg('Type your postal code ');
                                setTimeout(()=>{
                                  setreqMsg('');
                                },2000)
                                return false;
                            }} onCopy={(e)=>{
                                e.preventDefault()
                               
                                return false;
                                }}
                              name="Code" placeholder='Zip Code' maxLength={7} onChange={handleChange} />
                            </div>
                          </div>
                      }
                      <div className="col-sm-12">
                        <div className="form-wrap" style={{marginTop:'-10px'}}>
                          <input className="form-input" id="" value={organization} type="text" maxLength={150} name="organization" placeholder='Organization' onChange={handleChange} />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <strong>Areas of Interest</strong>
                      </div>
                      {
                        Object.entries(areaInterestList).map(([item, index]) =>
                          <div key={index?.opt_id?.toString()} >
                           &nbsp; <input type="checkbox" className="" name="areaofinterest3" value={index?.opt_id?.toString()} onChange={handleChange} />
                            &nbsp; <label className="form-check-label" style={{color:'rgb(101 101 101 / 87%)',fontSize:13,fontWeight:'bold'}} htmlFor="exampleCheck3">{index?.opt_name}</label>  &nbsp;&nbsp;
                          </div>
                        )
                      }

                      <div className="col-sm-12 mt-2 ml-1" >
                        <div className="form-group d-md-flex" style={{marginLeft:'-22px'}}>
                          <div className="form-check">
                            <input type="checkbox" className="f mt-1 mr-2" id="exampleCheck2" name="marketingemail" value={marketingemail} defaultChecked={marketingemail} onChange={() => setmarketingemail(!marketingemail)} />
                            <label className="form-check-label" htmlFor="exampleCheck2">I would like to receive emails from DNI and their partners</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 demo ml-1">
                        <div className="form-group d-md-flex" style={{marginLeft:'-22px'}}>
                          <div className="form-check">
                            {/* <input type="checkbox" className=" mt-1 mr-2 " id="exampleCheck1" name="terms" value={terms} defaultChecked={terms} onChange={() => setTerms(!terms)} />
                            <label className="form-check-label" htmlFor="exampleCheck1">I agree to the <strong>Terms</strong> and <strong>Privacy Policy</strong>&nbsp;<span style={{ color: "red" }}><strong>*</strong></span></label> */}
                            <br>
                            </br>
                            <div className='mt-2' style={{ color: "#f67107",marginLeft:'-18px' }}>{(pssreqMsg) ? `( ${pssreqMsg} )` : ''}</div>
                            <div className='mt-2' style={{ color: "red",marginLeft:'-0px' }}>{(reqMsg) ? `${reqMsg}` : ''}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="offset-top-2">
                      <button className="btn btn-primary" onClick={onSubmit}>Subscribe</button>
                    </div>
                  </form>
                </div>
              </div>

            </> :
              <>
                <div className="row align-items-center justify-content-center align-items-center offset-top-40 "  >
                  <div className='col-lg-6 p-5 bg-white card-bs'>
                    <h2 className='text-left mb-4'>Email Verification</h2>
                    <form className="rd-mailform text-left offset-top-20 mb-5">
                      <div className="row row-fix row-narrow-12 row-12 justify-content-sm-center">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <input className="form-input" id="" type="text" name="verficationcode" placeholder='Enter Verification Code' value={verficationcode} onChange={handleChange} />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group d-md-flex">
                          </div>
                        </div>
                      </div>
                      <div className="offset-top-10">
                        <button className="btn btn-primary" onClick={handleVerify}>Verify</button>
                        &nbsp;<button className="btn btn-primary" onClick={onResendCode}>Resend Code</button>
                      </div>
                    </form>
                  </div>
                </div>
              </>
            }
          </div>
        </section>
      </div>
    </div>
  )
}

export default Register


