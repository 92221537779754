
import React from "react";
import { AUTENTICATE_API } from "../../services/Api/authenticationApi";
import { toast } from 'react-toastify'

class ForgotPassword extends React.Component{
    constructor(props){
        super(props);
    this.state={
        isVerified:false,
        email:'',
        verfication_code:''
    }
    }

    componentDidMount(){
      localStorage.clear();
    }

    getVerificationCode(e){
        e.preventDefault();
        let obj={
          email_id:this.state.email
        }
        if(this.state.email==""){
          this.setState({errMsg:'Please enter email*'});
          setTimeout(()=>{
            this.setState({errMsg:''});
          },2000);
          return false
        }
        var emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
        if(!(emailfilter.test(this.state.email))){
          this.setState({errMsg:'Please enter the valid email'});
          setTimeout(()=>{
            this.setState({errMsg:''});
          },2000)
          return false
        }
        if(obj?.email_id!=''){
          AUTENTICATE_API?.getForgotOtp(obj).then(res=>{
            if(res && res?.status==201){
              toast.success(res?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
              this.setState({isVerified:true});
            }
          });
        }
    }

    verifyCode(e){
      e.preventDefault();
      let obj={
               verfication_code:parseInt(this.state.verfication_code),
               email_id:this.state.email
              }
              if(obj){
                  AUTENTICATE_API?.verifyCode(obj).then(res=>{
                    if(res && res?.status==200){
                      let objs={
                        token:res?.data?.otp_token,
                        forgot_email:res?.data?.forgot_email
                      }
                      localStorage.setItem('setUser',JSON.stringify(objs))
                      toast.success(res?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
                      setTimeout(()=>{
                        window.location.href='#/change-password'
                      },1500)
                    }
                  })
              }
    }

    render(){
        return <React.Fragment>
     <section className="section section-50 section-lg-95 bg-light novi-background bg-cover" >
        <div className='container'>
          <div className="row align-items-center justify-content-center align-items-center offset-top-40 ">
          {this.state.isVerified===false?<>
          <div className='col-lg-6 p-5 bg-white card-bs'>
                <h3 className='text-left'>Forgot Password</h3>
                {/* <p  className='text-left' style={{marginTop:10}}>Get Verification code <span style={{color:'redgfve'}}>*</span></p> */}
                <form className="rd-mailform text-left offset-top-20" data-form-output="form-output-global" data-form-type="contact">
                  <div className="row row-fix row-narrow-12 row-12 justify-content-sm-center">
                    <div className="col-sm-12">
                      <div className="form-group">
                       <input className="form-input" id="contact-us-email" type="text" name="email" placeholder='Email' onChange={(e)=>{
                        this.setState({email:e?.target?.value})
                       }} />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <button className="btn btn-primary" onClick={this.getVerificationCode.bind(this)}>Get Code</button>
                    <span className="ml-4" style={{color:'red',fontSize:14}}>
                        {this.state.errMsg?this.state.errMsg:''}
                       </span>
                  </div>
                  
                </form>
            </div>
          </>:
           <div className='col-lg-6 p-5 bg-white card-bs'>
            <h3 className='text-left'>Code Verification</h3>
            {/* <p  className='text-left' style={{marginTop:10}}>Verify</p> */}
           <form className="rd-mailform text-left offset-top-20">
             <div className="row row-fix row-narrow-12 row-12 justify-content-sm-center">
               <div className="col-sm-12">
                 <div className="form-group">
                  <input className="form-input" id="" type="text" maxLength={4} name="verficationcode" 
                  placeholder='Enter Verification Code' value={this.state.verfication_code} onChange={(e)=>{
                    this.setState({verfication_code:e?.target?.value});
                  }}/>
                 </div>
               </div>
             </div>
             <div className="offset-top-10">
               <button className="btn btn-primary" onClick={this.verifyCode.bind(this)}>Verify</button>
               &nbsp;<button className="btn btn-primary" onClick={this.getVerificationCode.bind(this)}>Resend Code</button>
             </div> 
           </form>
          </div> 
          }
            
          </div>
        </div>
      </section>
        </React.Fragment>
    }
}

export default ForgotPassword