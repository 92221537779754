import { React, useEffect, useState } from 'react';
import PageHeader from '../../../components/PageHeader/PageHeader';
import BmiDisplay from './bmiDisplay';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { hasPointerEvents } from '@testing-library/user-event/dist/utils';

function BodyMassIndexCalc() {

  const [Weight, setWeight] = useState('');
  const [Height, setHeight] = useState('');
  const [WeightKG, setWeightKG] = useState('');
  const [HeightCM, setHeightCM] = useState('');
  const [WeightLbs, setWeightLbs] = useState('');
  const [HeightInc, setHeightInc] = useState('');
  const [bmi, setBMI] = useState("");
  const [results, setResults] = useState('');
  const [msg, setmsg] = useState('');
  const [TabName, setTabName] = useState('imprial');
  const [Wt, setwt] = useState(' (lb)');
  const [Ht, setHt] = useState(' (in)');
  const [pMsg, setPmsg] = useState('');
  const [currentLabel, setCurrentLabel] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [WeightRange, setWeightRange] = useState("");
  const [HeightRange, setHeightRange] = useState("");
  const [HghtSblMin, setHghtSblMin] = useState(39);
  const [WgtSblMin, setWgtSblMin] = useState(77);
  const [HghtSblMax, setHghtSblMax] = useState(83);
  const [WgtSblMax, setWgtSblMax] = useState(700);
  const [UserDetails, setUserDetails] = useState();

  useEffect(
    () => {
      if (localStorage.getItem('setUser') != null) {
        let storageValue = "";
        storageValue = JSON.parse(localStorage.getItem('setUser'));
        if (storageValue?.userInfo?.country_view_id == 2) {
          document.getElementById('SldrTb').checked = true;
          setHt(" (cm)");
          setwt(" (kg)");
          setHghtSblMin(100);
          setWgtSblMin(35);
          setWgtSblMax(318);
          setHghtSblMax(210);
          setTabName("metrc");
        } else {
          document.getElementById('SldrTb').checked = false;
          setHt(" (in)");
          setwt(" (lb)");
          setHghtSblMin(39);
          setWgtSblMin(77);
          setWgtSblMax(700);
          setHghtSblMax(83);
          setTabName("imprial");
        }          // setUserDetails(storageValue?.userInfo);   //UserDetails.storageValue?.country_view_id: "1";
      }
    },
    []
  )

  const ChangeTab = (e) => {
    setmsg("");
    let Kul = "";
    let Jul = "";
    if (document.getElementById('SldrTb').checked == true) {
      setWeightKG("");
      setHeightCM("");
      if (Height == "" && Weight == "") {
        //-----------------Kuchh Na Kre...................................................................................!!       
      } else if ((Height != undefined && Weight == undefined) || (Height != "" && Weight == "")) {
        setHeightCM(Height);
        if (HeightInc != undefined && HeightInc != "") {
          Jul = HeightInc;
          setHeight(Jul);
          setHeightRange(Jul);
        } else {
          Jul = Height * 2.54;
          if (Jul > 210) {
            setHeight(210);
            setHeightRange(210);
          } else {
            setHeight(Jul.toFixed(1));
            setHeightRange(Jul.toFixed(1));
          }
        }
      } else if ((Height == undefined && Weight != undefined) || (Height == "" && Weight != "")) {
        setWeightKG(Weight);
        if (WeightLbs != "" && WeightLbs != undefined) {
          Kul = WeightLbs;
          setWeight(Kul);
          setWeightRange(Kul);
        } else {
          Kul = Weight / 2.20462;
          if (Kul > 318) {
            setWeight(318);
            setWeightRange(318);
          } else {
            setWeight(Kul.toFixed(1));
            setWeightRange(Kul.toFixed(1));
          }
        }
      } else {
        setWeightKG(Weight);
        setHeightCM(Height);
        if ((HeightInc == "" && WeightLbs == "") || (HeightInc == undefined && WeightLbs == undefined)) {
          Kul = Weight / 2.20462;
          Jul = Height * 2.54;
          if (Kul > 318) {
            setWeight(318);
            setWeightRange(318);
          } else {
            setWeight(Kul.toFixed(1));
            setWeightRange(Kul.toFixed(1));
          }
          if (Jul > 210) {
            setHeight(210);
            setHeightRange(210);
          } else {
            setHeight(Jul.toFixed(1));
            setHeightRange(Jul.toFixed(1));
          }
        } else {
          Kul = WeightLbs;
          Jul = HeightInc;
          setWeight(Kul);
          setWeightRange(Kul);
          setHeight(Jul);
          setHeightRange(Jul);
        }
      }
      setHt(" (cm)");
      setwt(" (kg)");
      setHghtSblMin(100);
      setWgtSblMin(35);
      setWgtSblMax(318);
      setHghtSblMax(210);
      setTabName("metrc");
    } else {
      setHeightInc("");
      setWeightLbs("");
      if ((Height == undefined && Weight == undefined) || (Height == "" && Weight == "")) {

      } else if ((Height != undefined && Weight == undefined) || (Height != "" && Weight == "")) {
        setHeightInc(Height);
        if (HeightCM != undefined && HeightCM != "") {
          Jul = HeightCM;
          setHeight(Jul);
          setHeightRange(Jul);
        } else {
          Jul = Height / 2.54;
          if (Jul > 83) {
            setHeight(83);
            setHeightRange(83);
          } else {
            setHeight(Jul.toFixed(1));
            setHeightRange(Jul.toFixed(1));
          }
        }
      } else if ((Height == undefined && Weight != undefined) || (Height == "" && Weight != "")) {
        setWeightLbs(Weight);
        if (WeightKG != undefined && WeightKG != "") {
          Kul = WeightKG;
          setWeight(Kul);
          setWeightRange(Kul);
        } else {
          Kul = Weight * 2.20462;
          if (Kul > 700) {
            setWeight(700);
            setWeightRange(700);
          } else {
            setWeight(Kul.toFixed(1));
            setWeightRange(Kul.toFixed(1));
          }
        }
      } else {
        setWeightLbs(Weight);
        setHeightInc(Height);
        if ((HeightCM == "" && WeightKG == "") || (HeightCM == undefined && WeightKG == undefined)) {
          Kul = Weight * 2.20462;;
          Jul = Height / 2.54;
          if (Kul > 700) {
            setWeight(700);
            setWeightRange(700);
          } else {
            setWeight(Kul.toFixed(1));
            setWeightRange(Kul.toFixed(1));
          }
          if (Jul > 83) {
            setHeight(83);
            setHeightRange(83);
          } else {
            setHeight(Jul.toFixed(1));
            setHeightRange(Jul.toFixed(1));
          }
        } else {
          Kul = WeightKG;
          Jul = HeightCM;
          setWeight(Kul);
          setHeight(Jul);
          setWeightRange(Kul);
          setHeightRange(Jul);
        }
      }
      setHt(" (in)");
      setwt(" (lb)");
      setHghtSblMin(39);
      setWgtSblMin(77);
      setWgtSblMax(700);
      setHghtSblMax(83);
      setTabName("imprial");
    }
  }

  const ChangeValues = (e) => {
    setmsg("");
    setBMI("");
    if (e.target.name == "weight_val" || e.target.name == "height_Val" || e.target.name == "weightrange" || e.target.name == "heightrange") {
      setWeightKG();
      setWeightLbs();
      setHeightCM();
      setHeightInc();
      if (isNaN(e.target.value) === true) {
        setmsg("Only Digits Are Allowed.");
      } else {
        if (e.target.name === "weight_val" || e.target.name == "weightrange") {
          if (e.target.value == '0') {
            setmsg('Please Enter Greater Then Or Equal ' + WgtSblMin + ".");
            setWeight("");
            setWeightRange(WgtSblMin);
          } else if (TabName == 'imprial' && e.target.value > 700) {
            setmsg('Invalid Weight. *');
          } else if (TabName == 'metrc' && e.target.value > 318) {
            setmsg('Invalid Weight. *');
          } else if (e.target.value?.includes('.') == true) {
            let check = parseFloat(e.target.value.match(/\d+\.\d{1}/));
            if (isNaN(check) == true) {
              setWeight(e.target.value);
              setWeightRange(e.target.value);
            } else {
              setWeight(check);
              setWeightRange(check);
            }
          } else {
            e.target.value?.length <= 3
            setWeight(e.target.value);
            setWeightRange(e.target.value);
          }
        } else if (e.target.name === "height_Val" || e.target.name == "heightrange") {
          if (e.target.value == '0') {
            setmsg('Please Enter Greater Then Or Equal ' + HghtSblMin + ".");
            setHeight("");
            setHeightRange(HghtSblMin);
          } else if (TabName == 'imprial' && e.target.value > 83) {
            setmsg('Invalid Height. *');
          } else if (TabName == 'metrc' && e.target.value > 210) {
            setmsg('Invalid Height. *');
          } else if (e.target.value?.includes('.') == true) {
            let check = parseFloat(e.target.value.match(/\d+\.\d{1}/));
            if (isNaN(check) == true) {
              setHeight(e.target.value);
              setHeightRange(e.target.value);
            } else {
              setHeight(check);
              setHeightRange(check);
            }
          } else {
            e.target.value?.length <= 3
            setHeight(e.target.value);
            setHeightRange(e.target.value)
          }
        }
      }
    } else if (e.target.name == "AgeOver" || e.target.name == "BreastPr") {
      let ageOver = document.getElementById("inlineCheckbox1").checked;
      let breastPr = document.getElementById("inlineCheckbox2").checked;
      if (ageOver == false) {
        setPmsg('BMI categories are not an accurate reflection of body composition for patients under age 20.');
        setResults("");
      } else if (breastPr == false) {
        setPmsg('BMI categories are not an accurate reflection of body composition for patients who are pregnant or breastfeeding.');
        setResults("");
      }
      if (ageOver === false || breastPr === false) {
        setOpenModal(true);
      } else {
        setOpenModal(false);
      }
      if (ageOver == true && breastPr == true) {
        bmiResults.label = currentLabel?.label;
        setResults(bmiResults);
      }
    }
  }

  var bmiResults = {
    label: '',
    alertClass: '',
  };

  const CalculateBMI = (e) => {
    e.preventDefault();
    let BmiC = "";
    if (Weight === "" && Height === "") {
      setmsg('* Please enter Weight & Height.');
    } else if (TabName == "metrc") {
      if (Weight == "") {
        setmsg('* Please enter Weight.');
      } else if (Height == "") {
        setmsg('* Please enter Height.');
      } else if (Weight < 35 && Height < 100) {
        setmsg('Height & Weight Both are too Low.');
      } else if (Weight < 35) {
        setmsg('Weight is too Low.');
      } else if (Weight > 318) {
        setmsg('Weight is too Much.');
      } else if (Height < 100) {
        setmsg('Height is too Low.');
      } else if (Height > 210) {
        setmsg('Height is too Much.');
      } else {
        let HeightMtr = Height / 100;
        BmiC = Weight / (HeightMtr * HeightMtr);
        setmsg('');
        setWeightLbs("");
        setHeightInc("");
      }
    } else if (TabName == "imprial") {
      if (Weight == "") {
        setmsg('* Please enter Weight.');
      } else if (Height == "") {
        setmsg('* Please enter Height.');
      } else if (Weight < 77 && Height < 39) {
        setmsg('Height & Weight Both are too Low.');
      } else if (Weight < 77) {
        setmsg('Weight is too Low.');
      } else if (Weight > 700) {
        setmsg('Weight is too Much.');
      } else if (Height < 39) {
        setmsg('Height is too Low.');
      } else if (Height > 83) {
        setmsg('Height is too Much.');
      } else {
        let P = Height * Height;
        BmiC = (703 * Weight) / P;
        setmsg('');
        setWeightKG("");
        setHeightCM("");
      }
    } else {
      alert("No Calculation.........!!");
    }
    setBMI(BmiC?.toFixed(1));
    getBMIResults(BmiC?.toFixed(1));
  }

  const getBMIResults = (bmi) => {
    let ageOver = document.getElementById("inlineCheckbox1").checked;
    let breastPr = document.getElementById("inlineCheckbox2").checked;
    if (bmi == "") {
      bmiResults.label = '';
      bmiResults.alertClass = '';
    } else if ((ageOver !== true || breastPr !== true) || (ageOver !== true && breastPr !== true)) {
      bmiResults.label = '';
      bmiResults.alertClass = '';
    } else if (ageOver === true && breastPr === true) {
      if (bmi < 18.5) {
        bmiResults.label = 'underweight';
        bmiResults.alertClass = 'info';
      }
      else if (bmi >= 18.5 && bmi <= 24.9) {
        bmiResults.label = 'normalweight';
        bmiResults.alertClass = 'success';
      }
      else if (bmi >= 25 && bmi <= 29.9) {
        bmiResults.label = 'overweight';
        bmiResults.alertClass = 'warning';
      }
      else if (bmi >= 30 && bmi <= 34.9) {
        bmiResults.label = 'obesity';
        bmiResults.alertClass = 'obesity';
      } else if (bmi >= 35 && bmi <= 39.9) {
        bmiResults.label = 'ExtraObesity';
        bmiResults.alertClass = 'Extra obesity';
      } else if (bmi >= 40) {
        bmiResults.label = 'danger';
        bmiResults.alertClass = 'danger';
      }
      setResults(bmiResults);
      setCurrentLabel(bmiResults);
    } else {
      setResults(bmiResults);
      setCurrentLabel(bmiResults);
    }
  }

  return (
    <div>
      <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
        bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
        title="Body Mass Index (BMI) Calculator"
        description={"BMI is a measure of body fat based on height and weight that applies to adults"} />
      <section className="section novi-background bg-cover">
        <div className='container' >
          <div className='col-12 justify-content-between'>
            <div className='container'>
              <div className='row' >
                <div className='col-12 d-flex px-5 mt-4'>

                  {/* <ul className="nav nav-tabs" id="myTab" role="tablist" style={{width:'1070px', fontSize:'17px', fontWeight: "bold", marginLeft:"250px"}}>
                      <li className="nav-item" role="presentation" >
                        <a className={ UserDetails.country_view_id == 2 ? "nav-link" : "nav-link active" } id="profile-tab" data-toggle="tab" href="#home" name="imprial" onClick={ChangeTab} role="tab"  aria-controls="home" aria-selected="true" style={{color:'#111112de'}}>Imperial</a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className={UserDetails.country_view_id == 2 ? "nav-link active" : "nav-link"} id="home-tab" data-toggle="tab" href="#home" name="metrc" onClick={ChangeTab} role="tab" aria-controls="profile" false="false" style={{color:'#111112de'}}>Metric</a>
                      </li>
                    </ul> */}

                  <div className="custom-control mb-3 pl-0">
                    <h6 className='d-inline-block pr-2' value="Imperial" name="Imp_tab" >Imperial</h6>
                    <h6 className='d-inline-block custom-switch'>
                      <input type="checkbox" className="custom-control-input" name="SliderTab" id="SldrTb" onChange={ChangeTab} />
                      <label className="custom-control-label" htmlFor="SldrTb">&nbsp;</label>
                    </h6>
                    <h6 className='d-inline-block' value="Metric" name="Met_tab">Metric</h6>
                  </div>

                </div>

              </div>
              <div className='row'>
                <div className="col-12">
                  <div className="tab-content pt-4" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                      <form>
                        <div className='container'>
                          <div className='row'>
                            <div className='col-lg-8 col-12'>
                              <div className='container'>
                                <div className='row'>
                                  <div className='col-lg-6 col-12 mb-4 mb-lg-0'>
                                    <div className='d-flex justify-content-between' style={{ fontSize: '11px' }}>
                                      <span>{WgtSblMin}</span>
                                      <span>{WgtSblMax}</span>
                                    </div>
                                    <div style={{ marginTop: '-1px' }}><input style={{ width: '100%' }} step={.1} id="weightrange" name='weightrange' onChange={ChangeValues} type="range" min={WgtSblMin} defaultValue={WgtSblMin} value={(WeightRange) ? WeightRange : WgtSblMin} max={WgtSblMax} /></div>
                                    <div className='d-flex justify-content-between'>
                                      <label style={{ marginLeft: '3px', marginTop: '5px', fontSize: '14px', color: 'rgb(6 5 5)' }}>Weight<span style={{ color: 'gray', fontSize: '12px' }}>{Wt}</span></label>&nbsp;&nbsp;&nbsp;
                                      <input style={{ width: '94px' }} value={Weight} min={WgtSblMin} max={WgtSblMax} name="weight_val" type="text" onChange={ChangeValues} />
                                    </div>
                                  </div>
                                  <div className='col-lg-6 col-12 mb-4 mb-lg-0'>
                                    <div className='d-flex justify-content-between' style={{ fontSize: '11px', marginTop: '2px' }}>
                                      <span>{HghtSblMin}</span>
                                      <span>{HghtSblMax}</span>
                                    </div>
                                    <div>
                                      <input step={.1} style={{ width: "100%", marginTop: '-8px' }} name="heightrange" onChange={ChangeValues} type="range" min={HghtSblMin} defaultValue={HghtSblMin} value={(HeightRange) ? HeightRange : HghtSblMin} max={HghtSblMax} />
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                      <label style={{ marginTop: '5px', color: 'rgb(6 5 5)', fontSize: '14px', }}>Height<span style={{ color: 'gray', fontSize: '12px', }}>{Ht}</span></label>&nbsp;&nbsp;&nbsp;&nbsp;
                                      <input style={{ width: '90px' }} value={Height} name="height_Val" min={HghtSblMin} max={HghtSblMax} type="text" onChange={ChangeValues} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-3 col-12'>
                              <div>
                                <input className="form-check-input" style={{ marginTop: "4px", marginLeft: '-92px' }} name="AgeOver" type="checkbox" onClick={ChangeValues} id="inlineCheckbox1" defaultChecked="true" value="AgOv" />
                                <label style={{ marginLeft: '-72px' }} className="form-check-label">Over 20 years of age</label>
                              </div>
                              <div>
                                <input className="form-check-input" style={{ marginTop: "10px" }} name="BreastPr" type="checkbox" onClick={ChangeValues} id="inlineCheckbox2" defaultChecked="true" value="BrPr" />
                                <label className="form-check-label" style={{ marginTop: "7px" }}>Is not Pregnant or Breastfeeding</label>
                              </div>
                            </div>
                            <div className='col-lg-1 col-12' style={{ marginTop: '2px' }}>
                              <button className='btn btn-primary' style={{ fontSize: '13px', marginLeft: '-25px', width: '110px', height: '50px' }} name='clculatebutton' onClick={CalculateBMI}>Calculate BMI</button>
                            </div>
                          </div>
                          <div style={{ color: 'red', marginLeft: '-10px', marginTop: '15px', fontSize: '13px' }}>{msg}</div>
                        </div><br />
                        <div className="container" style={{ marginTop: '30px' }}>
                          <div className="row" >
                            <div className='col-12 d-flex justify-content-center'>
                              <BmiDisplay label={results.label} alertClass={bmi} />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <Popup open={openModal} position="right center">
        <div className='btn float-right' style={{ textAlign: 'center', padding: 50 }}>
          <div style={{ color: 'black', fontSize: 18 }}>{pMsg}</div>
        </div>
        <button className='btn btn-danger float-right' onClick={(e) => { setOpenModal(false) }}>Close</button>
      </Popup>

    </div>
  )
}

export default BodyMassIndexCalc;