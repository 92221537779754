import jwt_decode from 'jwt-decode';
export  const checklogout=()=>{
  if(localStorage.getItem('state')!=null)
  {
     if(localStorage.getItem('exp')!=null)
     {
          const date=new Date();
          const exp_hours=localStorage.getItem('exp');
              if(exp_hours<=date.getTime())
              {
                localStorage.clear();
                window.location.href="/#/signin";
              }
     }
     else
     {
         let date=new Date();
         localStorage.setItem('exp',(date.getTime()+28800000));
     }
  }
}