import React from 'react'
import { DummyImage } from "react-simple-placeholder-image";
import { SEARCH_API } from './../../services/Api/searching';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { toast } from 'react-toastify'
import ReactTooltip from "react-tooltip";
import toxic from '../../assets/toxic.png'
import html2pdf from "html2pdf-fix-jspdf";
import logo from '../../assets/images/logo/dni-logo.png'
import moment from 'moment';
import PdfHelper from '../../services/_helpers/pdfHelper';
import MyFevPdf from '../../services/_helpers/myFevPdf';
import ReactToPrint from "react-to-print";
import Modal from 'react-modal';
import SearchIcon from '../../assets/images/searchIcon.png'

class DrugList extends React.Component {
    constructor(props) {
        super(props);
        this.highlightSearchQuery = this.highlightSearchQuery.bind(this);
        this.state = {
            searchQuery: '',
            matchedIndices: [],
            drugList: [],
            userInfo: "",
            drugDetails: "",
            pharmocologicalFlag: false,
            therapeuticFlag: false,
            routFlag: false,
            nutrineFlag: false,
            protienFlag: false,
            renalFlag: false,
            hepticlFlag: false,
            lactationFlag: false,
            pregnancyFlag: false,
            crossFlag: false,
            citationFlag1: false,
            doseFlag: false,
            citationFlag: false,
            dialysisFlag: false,
            userInfo: '',
            getGenericDetails: '',
            citationList: [],
            countryList: [],
            iconList: [],
            pharmocologicalClassList: [],
            therapeuticClassList: [],
            routeList: [],
            usesList: [],
            xrefList: [],
            brandList: [],
            doseFormList: [],
            dietList: [],
            warningList: [],
            adverseEffectList: [],
            labParameterList: [],
            noteList: [],
            pmidUrl: 'https://pubmed.ncbi.nlm.nih.gov',
            brandName: '',
            pharmaClasses: '',
            theraClasses: '',
            routeNames: '',
            country_ids: '',
            filename: '',
            more: true,
            moreHide: false,
            hideMoreCollaps: true,
            hideContainers: true,
            viewList: "",
            drug_id: '',
            OralAdvList: [],
            GIAdvList: [],
            NutritionAdvList: [],
            usesOff: [],
            moreBrand: [],
            lessBrand: [],
            lessUses: [],
            moreUses: [],
            moreBrandFlag: false,
            moreUsesFlag: false,
            // Icons State


            "PharmClassImg": "",
            "TheraClassImg": "",
            "RoutesImg": "",
            "DoseFormsImg": "",
            "HowtoTakeImg": "",
            "DietImg": "",
            "GrapefruitInteractionsImg": "",
            "AlcoholInteractionsImg": "",
            "NaturalproductinteractionsImg": "",
            "WarningsImg": "",
            "RenalwarningsImg": "",
            "ProteinbindingImg": "",
            "DialysiswarningsImg": "",
            "HepaticwarningsImg": "",
            "PregnancywarningsImg": "",
            "BreastfeedingWarningsImg": "",
            "OralAdverseEffectsImg": "",
            "GIAdverseEffectsImg": "",
            "NutritionalAdverseEffectsImg": "",
            "OtherAdverseEffectsImg": "",
            "LabParametersImg": "",
            "MonitoringImg": "",
            "CrossReferenceImg": "",
            "CitationsImg": "",
            "UsesImg": '',
            'LactationImg': '',
            'AdverseImg': '',
            GIzeroBlank: [],
            OralzeroBlank: [],
            NutzeroBlank: [],
            OtherzeroBlank: [],
            LabzeroBlank: [],
            advBool: true,
            labBool: true,
            NutriBool: true,
            GIBool: true,
            OralBool: true,
            isPdf: false,
            g_ids: '',
            isTablates: false,
            isCrose: false,
            modalIsOpenPassword: false,
            pdfName: ''
        }
        this.contentRef = React.createRef();
        this.customStyles = {
            content: {
                top: '1%',
                left: '15%',
                right: 'auto',
                bottom: 'auto',
                // marginLefts: '-18%',
                transform: 'translate(-50%, -50%)',
                width: '1010px',
                background: 'white',
                border: '1px solid #ECEBEB',
                borderRadius: '0px',
                zIndex: 999,
                // height:720
            },
        };
        this.styleOverrides = {
            margin: '10px'
        };
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
        if (localStorage.getItem('setUser') != null) {
            // this.getIconList()
            let info = localStorage.getItem("setUser")
            let userInfo = JSON.parse(info);
            this.setState({ userInfo: userInfo }, () => {
                this.getView(this.state.userInfo?.userInfo?.user_id)
                this.getDrugList(this.state.userInfo?.userInfo?.user_id, this.state.userInfo?.userInfo?.country_view_id)
                this.setState({ country_ids: userInfo?.userInfo?.country_view_id != '' ? userInfo?.userInfo?.country_view_id : userInfo?.userInfo?.country_id })
            })
        }
    }

    getView(id) {
        SEARCH_API.getViewList(id).then(res => {
            if (res && res?.data) {
                this.setState({ viewList: res?.data[0] })
            }
        })
    }

    getDrugList(id, country_id) {
        SEARCH_API?.getMyDrugList(id, country_id).then(res => {
            if (res && res?.data?.length) {
                let dlist = res?.data;
                let fl = dlist.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? 1 : 0));

                this.setState({ drugList: fl })
                // this.getIconList()
            }
        })
    }

    moreUsesDisplay(more) {
        this.setState({ moreUsesFlag: !this.state.moreUsesFlag })
        if (more == true) {
            this.setState({ lessUses: this.state.moreUses })
        } else {
            let bmore = [];
            for (let i = 0; i < 5; i++) {
                bmore.push(this.state.usesOff[i])
            }
            this.setState({ lessUses: bmore });
        }
    }

    handleSelect(e) {

        if (e.target.value === "") {

            this.setState({ moreHide: false, drug_id: '' })
        } else {
            let arr = e.target.value?.split(',');
            let g_id = arr[0];
            let d_id = arr[1]
            this.setState({ moreHide: true, drug_id: d_id })
            this.getGeneric(g_id);
            this.setState({ g_ids: g_id })
        }
    }

    getIconList() {
        SEARCH_API?.getIcons().then(res => {
            if (res && res?.data) {
                this.setState({ iconListData: res?.data }, () => {
                    this?.setState({ "PharmClassImg": this.state.iconListData?.filter(fl => fl?.body_system == "Pharm Class") })
                    this?.setState({ "TheraClassImg": this.state.iconListData?.filter(fl => fl?.body_system == "Thera Class") })
                    this?.setState({ "RoutesImg": this.state.iconListData?.filter(fl => fl?.body_system == "Routes") })
                    this?.setState({ "UsesImg": this.state.iconListData?.filter(fl => fl?.body_system == "Uses") })
                    this?.setState({ "HowtoTakeImg": this.state.iconListData?.filter(fl => fl?.body_system == "How to Take") })
                    this?.setState({ "DietImg": this.state.iconListData?.filter(fl => fl?.body_system == "Diet") })
                    this?.setState({ "GrapefruitInteractionsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Grapefruit Interactions") })
                    this?.setState({ "AlcoholInteractionsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Alcohol Interactions") })
                    this?.setState({ "NaturalproductinteractionsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Natural product interactions") })
                    this?.setState({ "WarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Warnings") })
                    this?.setState({ "DoseFormsImg": this.state.iconListData?.filter(fl => fl?.body_system == "DoseForms") })
                    this?.setState({ "RenalwarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Renal warnings") })
                    this?.setState({ "ProteinbindingImg": this.state.iconListData?.filter(fl => fl?.body_system == "Protein binding") })
                    this?.setState({ "DialysiswarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Dialysis warnings") })
                    this?.setState({ "HepaticwarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Hepatic warnings") })
                    this?.setState({ "PregnancywarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Pregnancy warnings") })
                    this?.setState({ "BreastfeedingWarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Breastfeeding Warnings") })

                    this?.setState({ "OralAdverseEffectsImg": this.state.iconListData?.filter(fl => fl?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Oral Adverse Effects" : "Oral Adverse Effects")) })
                    this?.setState({ "GIAdverseEffectsImg": this.state.iconListData?.filter(fl => fl?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust GI Adverse Effects" : 'GI Adverse Effects')) })
                    this?.setState({ "NutritionalAdverseEffectsImg": this.state.iconListData?.filter(fl => fl?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Nutritional Adverse Effects" : 'Nutritional Adverse Effects')) })
                    this?.setState({ "OtherAdverseEffectsImg": this.state.iconListData?.filter(fl => fl?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Other Adverse Effects" : "Other Adverse Effects")) })
                    this?.setState({ "LabParametersImg": this.state.iconListData?.filter(fl => fl?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Lab Parameters" : 'Lab Parameters')) })

                    // this?.setState({ "LabParametersImg": this.state.iconListData?.filter(fl => fl?.body_system == "Lab Parameters") })
                    this?.setState({ "MonitoringImg": this.state.iconListData?.filter(fl => fl?.body_system == "Monitoring") })
                    this?.setState({ "CrossReferenceImg": this.state.iconListData?.filter(fl => fl?.body_system == "Cross Reference") })
                    this?.setState({ "CitationsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Citations") })
                });
            }
        })
    }

    getGeneric(id) {
        SEARCH_API?.getSingleGeneric(id).then(res => {
            if (res && res?.data) {
                this.setState({ drugDetails: res?.data }, () => {
                    this.setState({ getGenericDetails: this.state.drugDetails[0][0] }, () => {
                        this.getIconList();
                    })
                    this.setState({
                        citationList: this.state.drugDetails[1],
                        countryList: this.state.drugDetails[2],
                        iconList: this.state.drugDetails[3],
                        pharmocologicalClassList: this.state.drugDetails[4].sort((a, b) => (a.PharmocologicalClasses > b.PharmocologicalClasses) ? 1 : ((b.PharmocologicalClasses > a.PharmocologicalClasses) ? -1 : 0)),
                        therapeuticClassList: this.state.drugDetails[5].sort((a, b) => (a.TherapeuticClasses > b.TherapeuticClasses) ? 1 : ((b.TherapeuticClasses > a.TherapeuticClasses) ? -1 : 0)),
                        routeList: this.state.drugDetails[6].sort((a, b) => (a.route_name > b.route_name) ? 1 : ((b.route_name > a.route_name) ? -1 : 0)),
                        // usesList: this.state.drugDetails[7]?.filter(fil => parseInt(this.state.country_ids) === 2 ? fil?.can_label == 1 : fil?.us_label),
                        usesList: this.state.drugDetails[7],
                        xrefList: this.state.drugDetails[8]?.filter(fil => parseInt(this.state.country_ids) === parseInt(fil?.country_id)),
                        brandList: this.state.drugDetails[9].filter(fl => this.state.country_ids == 2 ? fl.for_can : fl.for_us),
                        doseFormList: this.state.drugDetails[10].sort((a, b) => (a.doseform > b.doseform) ? 1 : ((b.doseform > a.doseform) ? -1 : 0)),
                        dietList: this.state.drugDetails[11],
                        warningList: this.state.drugDetails[12],
                        adverseEffectList: this.state.drugDetails[13],
                        labParameterList: this.state.drugDetails[14],
                        noteList: this.state.drugDetails[15],
                    }, () => {
                        const { GenericName } = this.state.getGenericDetails
                        let dt = moment(new Date()).format('DD-MMM-YYYY');
                        let dtArr = dt.split('-');
                        let finalDate = dtArr[0] + dtArr[1].toLocaleUpperCase() + dtArr[2];
                        let fname = `DNI-${GenericName}-${this.state.userInfo?.country_view_id == 2 ? "Canada" : 'USA'}-${finalDate}`
                        this.setState({ pdfName: fname })
                        let usesOff = []
                        if (this.state.country_ids == 2) {
                            this.state.usesList.sort((a, b) => { 
                                let fa = a.can_label,
                                    fb = b.us_label;

                                if (fa == 1) {
                                    return -1;
                                }
                                if (fb == 1) {
                                    return 1;
                                }
                                return 0;
                            });

                        }
                        if (this.state.country_ids != 2) {
                            this.state.usesList.sort((a, b) => {
                                let fa = a.us_label,
                                    fb = b.can_label;

                                if (fa == 1) {
                                    return -1;
                                }
                                if (fb == 1) {
                                    return 1;
                                }
                                return 0;
                            });
                        }
                        for (let i = 0; i < this.state.usesList?.length; i++) {
                            if (this.state.userInfo?.country_id == 1
                                && this.state.country_ids == 2
                                && this.state.usesList[i]?.us_label == 1
                                && this.state.usesList[i]?.can_label == 0) {
                                usesOff.push(this.state.usesList[i]);
                            } else {
                                usesOff.push(this.state.usesList[i]);
                            }
                        }

                        this.setState({ usesOff: usesOff }, () => {
                            let umore = []
                            if (this.state.usesOff?.length > 5) {
                                for (let i = 0; i < 5; i++) {
                                    umore.push(this.state.usesOff[i])
                                }
                                this.setState({ lessUses: umore });
                            } else {
                                this.setState({ lessUses: this.state.usesOff }, () => {
                                });
                            }
                            if (this.state.usesOff?.length >= 5) {
                                this.setState({ moreUses: this.state.usesOff });
                            }
                        });


                        // Lab filter
                        
                        let labnotZeroOrBlank = this.state.labParameterList?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != '' && fl?.rate != '.'))
                        // ?.sort((a, b) => (a.parameter?.toLocaleUpperCase() > b.parameter?.toLocaleUpperCase()) ? 1 : ((b.parameter?.toLocaleUpperCase() > a.parameter?.toLocaleUpperCase()) ? -1 : 0));
                        
                        let labNAOrBlank = this.state.labParameterList?.filter(fl => fl?.rate == "NA" || fl?.rate == "" || fl?.rate == ".")
                        ?.sort((a, b) => (a.parameter?.toLocaleUpperCase() > b.parameter?.toLocaleUpperCase()) ? 1 : ((b.parameter?.toLocaleUpperCase() > a.parameter?.toLocaleUpperCase()) ? -1 : 0));
                        
                        let labzeroBlank = this.state.labParameterList?.filter(fl => fl?.rate == '0')
                        ?.sort((a, b) => (a.parameter?.toLocaleUpperCase() > b.parameter?.toLocaleUpperCase()) ? 1 : ((b.parameter?.toLocaleUpperCase() > a.parameter?.toLocaleUpperCase()) ? -1 : 0));
                       
                        this.setState({ LabzeroBlank: labzeroBlank });
                        let labfinalAdv = [];
                        labfinalAdv = [...labnotZeroOrBlank, ...labNAOrBlank, ...labzeroBlank];
                        this.setState({ labParameterList: labfinalAdv, commonLab: labfinalAdv, commLabTrue: labfinalAdv }, () => {
                            if (this.state.viewList?.lab_parameters == 2) {
                                let common = this.state.labParameterList.filter(filters => (parseInt(filters?.rate) >= this.state.getGenericDetails?.cut_off_rate) || (filters?.rate == "" && this.state.getGenericDetails?.cut_off_rate == null));
                                this.setState({ labParameterList: common, commLab: labfinalAdv, commLabTrue: common });
                            }
                        });

                        // Oral filter values
                        let oraFilter = this.state.adverseEffectList?.filter(fl => fl?.a_e_category == 'oral');
                        let OralfinalAdv = [];
                        // if (this.state.viewList?.oral_adverse_effects == 2) {
                        //     let commonOralFilter = oraFilter.filter(filters => filters?.rate >= this.state.getGenericDetails?.cut_off_rate);
                        //     let OralNAOrBlank = commonOralFilter?.filter(fl => fl?.rate == "NA" || fl?.rate == "");
                        //     let OralzeroBlank = commonOralFilter?.filter(fl => fl?.rate == '0');
                        //     this.setState({ OralzeroBlank: OralzeroBlank });
                        //     let OralnotZeroOrBlank = commonOralFilter?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != ''));
                        //     OralfinalAdv = [...OralnotZeroOrBlank, ...OralNAOrBlank, ...OralzeroBlank];
                        // } else {
                        let OralNAOrBlank = oraFilter?.filter(fl => fl?.rate == "NA" || fl?.rate == "" || fl?.rate == '.')?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0));
                        let OralzeroBlank = oraFilter?.filter(fl => fl?.rate == '0')?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != '' && fl?.rate != '.'));
                        this.setState({ OralzeroBlank: OralzeroBlank })
                        let OralnotZeroOrBlank = oraFilter?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != '' && fl?.rate != '.'));
                        // let OralfinalAdv = [];
                        OralfinalAdv = [...OralnotZeroOrBlank, ...OralNAOrBlank, ...OralzeroBlank];
                        // }




                        // GI Adverse Effect Filter
                        let GIFilter = this.state.adverseEffectList?.filter(fl => fl?.a_e_category == 'GI');

                        let Liv = this.state.adverseEffectList?.filter(fl => fl?.a_e_category == "liver");
                        GIFilter = [...GIFilter, ...Liv]

                        let GIzeroBlank = GIFilter?.filter(fl => fl?.rate == '0')?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0))
                        // console.log("Zero",GIzeroBlank)
                        let GIfinalAdv = [];
                        // if (this.state.viewList?.gi_adverse_effects == 2) {
                        //     let commonGIFilter = GIFilter.filter(filters => filters?.rate >= this.state.getGenericDetails?.cut_off_rate);
                        //     let GINAOrBlank = commonGIFilter?.filter(fl => fl?.rate == "NA" || fl?.rate == "");
                        //     let GIzeroBlank = commonGIFilter?.filter(fl => fl?.rate == '0');
                        //     this.setState({ GIzeroBlank: GIzeroBlank })
                        //     let GInotZeroOrBlank = commonGIFilter?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != ''));
                        //     GIfinalAdv = [...GInotZeroOrBlank, ...GINAOrBlank, ...GIzeroBlank]
                        // } else {
                        let GINAOrBlank = GIFilter?.filter(fl => fl?.rate == "NA" || fl?.rate == "" || fl?.rate == ".")?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0))
                        this.setState({ GIzeroBlank: GIzeroBlank })
                        let GInotZeroOrBlank = GIFilter?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != '' && fl?.rate != '.'));
                        GIfinalAdv = [...GInotZeroOrBlank, ...GINAOrBlank, ...GIzeroBlank];


                        // }
                        // Nutrition Adv Filter list
                        let NutritionFilter = this.state.adverseEffectList?.filter(fl => fl?.a_e_category == 'nutrition');
                        let NutritionGIzeroBlank = NutritionFilter?.filter(fl => fl?.rate == '0')?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0));
                        let NutritionGIfinalAdv = [];
                        // if (this.state.viewList?.nutrition_adverse_effects == 2) {
                        //     // let commonNutritionFilter = NutritionFilter.filter(filters => filters?.rate >= this.state.getGenericDetails?.cut_off_rate);
                        //     let NutritionGINAOrBlank = commonNutritionFilter?.filter(fl => fl?.rate == "NA" || fl?.rate == "");
                        //     let NutritionGIzeroBlank = commonNutritionFilter?.filter(fl => fl?.rate == '0');
                        //     this.setState({ NutzeroBlank: NutritionGIzeroBlank });
                        //     let NutritionGInotZeroOrBlank = commonNutritionFilter?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != ''));
                        //     NutritionGIfinalAdv = [...NutritionGInotZeroOrBlank, ...NutritionGINAOrBlank, ...NutritionGIzeroBlank];
                        // } else {
                        let NutritionGINAOrBlank = NutritionFilter?.filter(fl => fl?.rate == "NA" || fl?.rate == "" || fl?.rate == '.')?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0));
                        this.setState({ NutzeroBlank: NutritionGIzeroBlank })
                        let NutritionGInotZeroOrBlank = NutritionFilter?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != '' && fl?.rate != '.'));
                        NutritionGIfinalAdv = [...NutritionGInotZeroOrBlank, ...NutritionGINAOrBlank, ...NutritionGIzeroBlank];


                        // }

                        this.setState({
                            OralAdvList: OralfinalAdv,
                            OralCommonTrue: OralfinalAdv,
                            GIAdvList: GIfinalAdv?.sort((a, b ) => (parseInt(b.rate) > parseInt(a.rate)) ? 1 : -1),
                            GICommonTrue: GIfinalAdv?.sort((a, b ) => (parseInt(b.rate) > parseInt(a.rate)) ? 1 : -1),
                            NutritionAdvList: NutritionGIfinalAdv,
                            NutritionCommonTrue: NutritionGIfinalAdv
                        }, () => {
                            if (this.state.viewList?.nutrition_adverse_effects == 2) {
                                let commonNutritionFilter = NutritionGIfinalAdv.filter(filters => filters?.rate >= this.state.getGenericDetails?.cut_off_rate || (filters?.rate == "." && this.state.getGenericDetails?.cut_off_rate == null));
                                this.setState({ commNutrition: NutritionGIfinalAdv, NutritionAdvList: commonNutritionFilter, NutritionCommonTrue: commonNutritionFilter });
                            }
                            if (this.state.viewList?.gi_adverse_effects == 2) {
                                let commonGIFilter = GIfinalAdv.filter(filters => filters?.rate >= this.state.getGenericDetails?.cut_off_rate);
                                this.setState({ commGI: GIfinalAdv, GIAdvList: commonGIFilter, GICommonTrue: commonGIFilter?.sort((a, b ) => (parseInt(b.rate) > parseInt(a.rate)) ? 1 : -1) });
                            }
                            if (this.state.viewList?.oral_adverse_effects == 2) {
                                let commonOralFilter = oraFilter.filter(filters => filters?.rate >= this.state.getGenericDetails?.cut_off_rate);
                                this.setState({ OralAdvList: commonOralFilter, commOral: OralfinalAdv, OralCommonTrue: commonOralFilter })
                            }
                        });


                        //Other Adverse Effects 
                        let OnlyOtherAvdFilter = this.state.adverseEffectList?.filter(fl => (fl?.a_e_category != 'oral' && fl?.a_e_category != 'GI' && fl?.a_e_category != 'nutrition' && fl?.a_e_category != 'liver'));
                        let NAOrBlank = OnlyOtherAvdFilter?.filter(fl => fl?.rate == "NA" || fl?.rate == "" || fl?.rate == '.')?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0));
                        let zeroBlank = OnlyOtherAvdFilter?.filter(fl => fl?.rate == '0')?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0));;
                        this.setState({ OtherzeroBlank: zeroBlank })
                        let notZeroOrBlank = OnlyOtherAvdFilter?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != '' && fl?.rate != '.'));

                        //   Start grouping and sorting by body system


                        let Eyes = NAOrBlank?.filter(fl => fl?.a_e_category == "eyes")?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0))
                        let Heart = NAOrBlank?.filter(fl => fl?.a_e_category == "heart")?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0))
                        let Respiratory = NAOrBlank?.filter(fl => fl?.a_e_category == "respiratory")?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0))
                        let Kidney = NAOrBlank?.filter(fl => fl?.a_e_category == "kidney")?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0))
                        let UrinaryGU = NAOrBlank?.filter(fl => fl?.a_e_category == "urinary/GU")?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0))
                        let Musculoskeletal = NAOrBlank?.filter(fl => fl?.a_e_category == "musculoskeletal")?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0))
                        let Dermatologic = NAOrBlank?.filter(fl => fl?.a_e_category == "dermatologic")?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0))
                        let Immune = NAOrBlank?.filter(fl => fl?.a_e_category == "immune")?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0))
                        let Blood = NAOrBlank?.filter(fl => fl?.a_e_category == "blood")?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0))
                        let Assorted = NAOrBlank?.filter(fl => fl?.a_e_category == "assorted")?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0))

                        let finalOtherNot = [...Eyes, ...Heart, ...Respiratory, ...Kidney, ...UrinaryGU, ...Musculoskeletal, ...Dermatologic, ...Immune, ...Blood, ...Assorted]

                        // console.log(JSON.stringify(finalOtherNot))
                        //   End grouping and sorting by body system

                        let finalAdv = [];
                        // finalAdv = [...notZeroOrBlank, ...NAOrBlank, ...zeroBlank];
                        finalAdv = [...notZeroOrBlank, ...finalOtherNot, ...zeroBlank];
                        this.setState({ adverseEffectList: finalAdv, otherCommonTrue: finalAdv }, () => {
                            if (this.state.viewList?.other_adverse_effects == 2) {
                                let OnlyOtherAvdFilterComm = OnlyOtherAvdFilter.filter(filters => filters?.rate >= this.state.getGenericDetails?.cut_off_rate);
                                this.setState({ adverseEffectList: OnlyOtherAvdFilterComm, commonOther: finalAdv, otherCommonTrue: OnlyOtherAvdFilterComm });
                            }
                        });

                        if (this.state.country_ids == '1') {
                            let filter2 = this.state.doseFormList?.filter(fl => fl?.us_doseform == 1)
                            this.setState({ doseFormList: filter2 });
                        }
                        if (this.state.country_ids == '2') {
                            let filter2 = this.state.doseFormList?.filter(fl => fl?.can_doseform == 1)
                            this.setState({ doseFormList: filter2 })
                        }
                        if (this.state.country_ids == 1) {
                            let filter2 = this.state.citationList?.filter(fl => fl?.for_us == 1)
                            this.setState({ citationList: filter2 })
                        }
                        if (this.state.country_ids == 2) {
                            let filter2 = this.state.citationList?.filter(fl => fl?.for_can == 1);
                            this.setState({ citationList: filter2 })
                        }
                        var tmp = [];
                        var prArr = [];
                        for (var i = 0; i < this.state.xrefList.length; i++) {
                            for (var j = 0; j < this.state.xrefList.length; j++) {
                                if (this.state.xrefList[i].xref == this.state.xrefList[j].xref) {
                                    tmp.push(this.state.xrefList[j].country_id);
                                }
                            }
                            prArr.push({ xref: this.state.xrefList[i].xref, country_id: tmp, generic_id: this.state.xrefList[i].generic_id });
                            tmp = [];
                        }
                        const arrayUniqueByKeyprArr = [...new Map(prArr.map(item =>
                            [item['xref'], item])).values()];
                        this.setState({ xrefList: arrayUniqueByKeyprArr })
                        // this.setState({ citationXRef: cita?.length >= 0 ? cita.toString() : '' });
                        let brands = this.state.brandList?.map((brand, i) => {
                            return brand?.brand?.trim() + `${i != this.state.brandList?.length - 1 ? ', ' : ''}`
                        });
                        let bmore = []
                        if (this.state.brandList?.length > 10) {
                            for (let i = 0; i < 10; i++) {
                                bmore.push(this.state.brandList[i])
                            }
                            this.setState({ lessBrand: bmore });
                        } else {
                            this.setState({ lessBrand: this.state.brandList });
                        }

                        if (this.state.brandList?.length >= 10) {
                            this.setState({ moreBrand: this.state.brandList });
                        }
                        this.setState({ brandName: brands?.length >= 0 ? brands : '' });
                        let phrma = this.state.pharmocologicalClassList?.map(ph => {
                            return " " + ph?.PharmocologicalClasses
                        })
                        this.setState({ pharmaClasses: phrma?.length >= 0 ? phrma.toString() : '' });
                        let thera = this.state.therapeuticClassList?.map(th => {
                            return " " + th?.TherapeuticClasses
                        })
                        this.setState({ theraClasses: thera?.length >= 0 ? thera.toString() : '' });
                        let rout = this.state.routeList?.map(th => {
                            return " " + th?.route_name
                        })
                        this.setState({ routeNames: rout?.length >= 0 ? rout.toString() : '' });
                    })
                })
            }
        })
    }
    searchAndHighlight = () => {
        const { searchQuery } = this.state;
        const content = document.documentElement.innerHTML;
        const indices = [];
        let startIndex = 0;

        while (startIndex < content.length) {
            const index = content.indexOf(searchQuery, startIndex);
            if (index === -1) break;
            indices.push({ start: index, end: index + searchQuery.length });
            startIndex = index + searchQuery.length;
        }

        this.setState({ matchedIndices: indices });
    }
    handleSearchInputChange = (event) => {
        const searchQuery = event.target.value;
        this.setState({ searchQuery }, () => {
            if (searchQuery.trim() === '') {
                this.setState({ matchedIndices: [] });
            } else {
                this.searchAndHighlight(searchQuery);
            }
        });
    }
    
    searchAndHighlight = (searchQuery) => {
        const content = this.contentRef.current.textContent;
        const escapedSearchQuery = searchQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special regex characters
        const regex = new RegExp(escapedSearchQuery, 'gi');
        const matchedIndices = [];
        let match;
        while ((match = regex.exec(content)) !== null) {
            matchedIndices.push({ start: match.index, end: regex.lastIndex });
        }
        this.setState({ matchedIndices });
    }

    // highlightSearchQuery(text) {
    //     if (!text) return ''; // Handle case when text is undefined
    //     const searchQuery = this.state.searchQuery.toLowerCase();
    //     const regex = new RegExp(`(<p[^>]*>|<\/p>)`, 'gi');
    //     return text.replace(regex, '').replace(new RegExp(`(${searchQuery})`, 'gi'), '<span style="background-color: yellow;">$1</span>');
    // }

    highlightSearchQuery(text) {
        if (!text) return ''; // Handle case when text is undefined
    
        // Decode HTML entities
        const decodedText = this.decodeHtmlEntities(text);
    
        const searchQuery = this.state.searchQuery.toLowerCase();
        const regex = new RegExp(`(${searchQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
    
        // Split the decoded text into segments of text and HTML tags
        const segments = decodedText.split(/(<[^>]+>)/);
    
        // Apply highlighting to text segments, excluding HTML tags
        const highlightedSegments = segments.map(segment => {
            if (segment.startsWith('<')) {
                // Return HTML tags unchanged
                return segment;
            } else {
                // Apply highlighting to text segments
                return segment.replace(regex, (match) => `<span style="background-color: #D3D3D3;">${match}</span>`);
            }
        });
    
        // Join the segments back together to form the final result
        const highlightedText = highlightedSegments.join('');
    
        return highlightedText;
    }
    
    decodeHtmlEntities(text) {
        const decodedTextArea = document.createElement('textarea');
        decodedTextArea.innerHTML = text;
        return decodedTextArea.value;
    }
    moreBrandDisplay(more) {
        if (more == true) {
            this.setState({ lessBrand: this.state.moreBrand })
        } else {
            let bmore = [];
            for (let i = 0; i < 10; i++) {
                bmore.push(this.state.brandList[i])
            }
            this.setState({ lessBrand: bmore });
        }
    }
    deleteConfirmation = () => {
        confirmAlert({
            title: 'Are you sure you want to remove drug from favorites?',
            //   message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    id: "deleteYes",
                    onClick: () => {
                        SEARCH_API.removeDrugList(parseInt(this.state.drug_id)).then(res => {
                            if (res) {
                                this.setState({ drugList: [], moreHide: false, drug_id: "" });
                                this.getDrugList(this.state.userInfo?.userInfo?.user_id)
                                toast.success('Drug removed from favorites', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
                            }
                        })
                    }
                },
                {
                    label: 'No',
                    id: "deletNo"
                }

            ]
        });
    }

    download() {
        this.setState({ logo: true, isPdf: true })
        setTimeout(() => {
            document.getElementById('ddl').style.color = "black";
            document.getElementById('cit-badge-id') ? document.getElementById('cit-badge-id').style.display = "none" : '';
            document.getElementById('doseform-badge-id') ? document.getElementById('doseform-badge-id').style.display = "none" : "";
            // document.getElementById('uses-badge-id').style.display = "none";
            document.getElementById('doseform-badge-id1') ? document.getElementById('doseform-badge-id1').style.display = "none" : "";
            document.getElementById('cita-id') ? document.getElementById('cita-id').style.color = "black" : "";
            document.getElementById('span_dot') ? document.getElementById('span_dot').style.display = "none" : "";
            document.getElementById('span_dotUses') ? document.getElementById('span_dotUses').style.display = "none" : "";
            document.getElementById('setDrugDetails').style.display = "block";
            document.getElementById('setDrugDetails').style.textAlign = "center";
            document.getElementById('setDrugDetails').style.marginTop = "-8px";
            document.getElementById('setDrugDetails').style.paddingBottom = "8px";
            setTimeout(() => {
                document.getElementById('ddl') ? document.getElementById('ddl').style.color = "" : '';
                document.getElementById('cita-id') ? document.getElementById('cita-id').style.color = "" : '';
                // document.getElementById('cit-badge-id').style.display = "inline";
                document.getElementById('setDrugDetails') ? document.getElementById('setDrugDetails').style.display = "none" : "";
                document.getElementById('doseform-badge-id') ? document.getElementById('doseform-badge-id').style.display = "inline" : '';
                document.getElementById('doseform-badge-id1') ? document.getElementById('doseform-badge-id1').style.display = "inline" : '';
                document.getElementById('span_dot') ? document.getElementById('span_dot').style.display = "inline" : "";
                document.getElementById('span_dotUses') ? document.getElementById('span_dotUses').style.display = "inline" : "";
                document.getElementById('uses-badge-id') ? document.getElementById('uses-badge-id').style.display = "inline" : '';
            }, 2000)
            setTimeout(() => {
                const { GenericName } = this.state.getGenericDetails
                let dt = moment(new Date()).format('DD-MMM-YYYY');
                let dtArr = dt.split('-');
                let finalDate = dtArr[0] + dtArr[1].toLocaleUpperCase() + dtArr[2];
                let fname = `DNI-${GenericName}-${this.state.userInfo?.country_view_id == 2 ? "Canada" : 'USA'}-${finalDate}`
                let element = document.getElementById('ddl');
                const opt = {
                    jsPDF: {
                      unit: "mm",
                      format: "A4",
                      orientation: "portrait",
                      compress: true,
                    },
                    html2canvas: {
                      scale: 2,
                      useCORS: true,
                    },
                    image: { type: "png" },
                    margin: [0, 0, 0, 0],
                    filename: fname,
                    pagebreak: { mode: ["avoid-all", "css"] },
                  };
          
                  html2pdf()
                    .set(opt)
                    .from(element)
                    .save()
                    .then(() => {
                      $(".card").css(
                        "box-shadow",
                        "0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12)"
                      );
                    });
                this.setState({ logo: false, isPdf: false });
            }, 1)
        }, 5000)
    }

    render() {
        const { matchedIndices } = this.state;
        return (
            <div>
                <Modal
                    isOpen={this.state.modalIsOpenPassword}
                    style={this.customStyles}
                    contentLabel="Example Modal"
                >
                    <button className="btn-danger shadow-none float-right close-btn"
                        onClick={() => {
                            this.setState({ modalIsOpenPassword: false })
                        }}
                    >X</button>
                    <ReactToPrint
                        //  pageStyle={this.styleOverrides}
                        trigger={() => <span>
                            <button className='btn btn-primary d-block d-lg-inline-block '>
                                <i className="fa fa-print" aria-hidden="true">
                                </i>
                                &nbsp; Print
                            </button>
                        </span>
                        }
                        content={() => this.componentRef}
                        documentTitle={this.state.pdfName}
                        onAfterPrint={() => {
                            setTimeout(() => {
                                this.setState({ modalIsOpenPassword: false });
                                toast.success('Summary downloaded', { autoClose: 2000, position: toast.POSITION.TOP_RIGHT });
                            }, 1000);
                        }}
                    />
                    <div >
                        <MyFevPdf generic_id={this.state.g_ids} ref={el => (this.componentRef = el)} />
                    </div>
                </Modal>
                <section className="section search-bgfeb section-height-800 parallax-container context-dark bg-gray-darkest text-xl-left" data-parallax-img="./images/home/resized/fev.jpg">
                    <div className="parallax-content">
                        <div className="bg-overlay-black">
                            <div className="container section-80 section-md-30 section-lg-top-10 section-lg-bottom-30" style={{ paddingTop: 6 }}>
                                <div className="d-none d-lg-block">
                                    <h1 className='mt-4'>My Favorites</h1>
                                </div>
                                <ul className="list-inline list-inline-dashed list-white text-big p offset-md-top-8">
                                    <li></li>
                                    {/* <li>About</li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container">
                        <div className='row'>
                            <div className='col-12 mb-3 text-left'>
                                {/* <button className='btn btn-primary mb-2' onClick={(e) => {
                                    window.location.href = "#/account"
                                }}><i class="fa fa-angle-double-left" aria-hidden="true"></i> Go Back</button> */}
                                <h2>
                                    {/* Drug 7 */}
                                </h2>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <select className='form-control' onChange={this.handleSelect.bind(this)}>
                                    <option value="">-- Select Generic Drug--</option>
                                    {
                                        this.state.drugList?.map(val => <option value={val?.generic_id + ',' + val?.user_drug_id}>
                                            {val?.name}
                                        </option>)
                                    }
                                </select><br />
                            </div>
                            <div className='col-md-3'>
                                {this.state.drug_id != "" ?
                                    <button className='add-fevf' onClick={this.deleteConfirmation.bind(this)} style={{ background: '#8a0505f0', color: 'white', border: '1px solid #8a0505f0', padding: 10, borderRadius: 20 }}>Remove From Favorites</button>
                                    : ''}
                            </div>
                            <div className='col-md-3'>
                                {this.state.drug_id != "" ?
                                    <button className='downlod-pdf-cf'
                                        onClick={this.download.bind(this)}
                                        style={{ background: 'rgb(166, 197, 80)', color: 'white', border: '1px solid rgb(166, 197, 80)', padding: 10, borderRadius: 20, float: 'left' }}>
                                        <i className='fa fa-file-pdf-o' aria-hidden="true"></i>
                                        &nbsp;Download Summary</button>
                                    : ''}
                            </div>
                        </div>
                        {this.state.isPdf == true ? <MyFevPdf generic_id={this.state.g_ids} /> :
                            <>
                                {this.state.moreHide === false ? <h6 style={{ textAlign: 'initial' }}>Select Generic drug above</h6> :
                                    <section className="section py-4" id="ddl" ref={this.myRef} style={{}}>
                                        <h5 style={{ margin: 'auto', display: "none" }} id="setDrugDetails">
                                            {/* Drug Details */}
                                        </h5>
                                        <div className="container" >
                                            <div className='row'>
                                                <div className='col-lg-12 text-left drug-details-list'>
                                                    <div className='row'>
                                                        <div className='col-md-6' >
                                                            {this.state.logo == true ? <img id="image-logo" style={{ marginLeft: '275px', marginTop: '-54px' }} src={logo} width='100' /> : ''}
                                                        </div>
                                                        <div className='col-md-6' >
                                                            {this.state.logo == true ? <>
                                                                <p style={{ color: 'black', marginLeft: '150px', marginTop: '-27px' }}>Downloaded on: {moment(new Date()).format('MM/DD/YYYY')}</p>
                                                                <p style={{ color: 'black', marginLeft: '150px', marginTop: '-10px' }}>
                                                                    {this.state.userInfo?.country_view_id == 2 ? "Canadian" : 'USA'} warnings/products
                                                                </p>
                                                            </>
                                                                : ''}
                                                        </div>
                                                    </div>
                                                                               <div>
                                        {this.state.getGenericDetails?.GenericName !== "" ? <>
        <div className='row' style={{ alignItems: 'center' }}>
            <div className='order-2 order-lg-1 mt-2 mt-lg-0 col-lg-4 col-12'>
                <h5 className='text-sm-center text-lg-start d-flex justify-content-center justify-content-lg-start' style={{ color: "#15678F",  marginRight: '10px' }}>
                    {this.state.getGenericDetails?.GenericName}
                </h5>
            </div>
            <div className='col-lg-6 col-12 order-1 order-lg-2  d-flex justify-content-center justify-content-lg-start'>
            <div id="search-container">
                <form role="search" method="get" id="searchform" action="">
                    <label for="s">
                        <img src={SearchIcon} alt="Search Icon"style={{width:'20px'}} />
                    </label>
                    <input type="text" value={this.state.searchQuery} onChange={this.handleSearchInputChange} placeholder="Search this page" class="" id="s" autoComplete="text" />
                </form>
            </div>
            </div>
        </div>
      
                                            </> : ''}
</div>
                                                    <div>
                                                        {this.state.lessBrand != [] ?
                                                            <div className='row justify-content-center justify-content-lg-start' style={{ fontWeight: 'bold', color: 'rgb(88 94 98 / 81%)', fontSize: 16 }}>
                                                                ({this.state.lessBrand?.length > 0 ? this.state.lessBrand?.map((val, id) => <span key={id}>
                                                                <span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(val?.brand?.trim()) }}></span>{id !== this.state.lessBrand?.length - 1 ? <span style={{}}>, <span style={{ marginLeft: 2 }}></span> </span> : ''}
                                                                </span>) : ""}

                                                                {this.state.lessBrand?.length == 10 ? <span> <span className='badge badge-secondary'
                                                                    onClick={this.moreBrandDisplay.bind(this, true)}
                                                                    id="doseform-badge-id1" style={{ color: 'whitesmoke', cursor: 'pointer' }}>+</span> </span> : this.state.lessBrand?.length > 10 ? <span> <span className='badge badge-secondary'
                                                                        onClick={this.moreBrandDisplay.bind(this, false)}
                                                                        id="doseform-badge-id1" style={{ color: 'whitesmoke', cursor: 'pointer' }}>&#8211;</span> </span> : ""}
                                                                )
                                                            </div>
                                                            : ""}
                                                    </div>

                                                    {/* alternatename */}
                                                    {this.state.getGenericDetails?.alternate_names ? <div style={{ fontWeight: 'bold', color: 'rgb(88 94 98 / 81%)', fontSize: 16, marginLeft: '-12px' }}>
                                                        also known as: <span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.getGenericDetails?.alternate_names) }}></span>
                                                    </div> : ''}

                                                    {/* <br /> */}
                                                    {
                                                        this.state.usesList?.length > 0 ? <div className='d-flex'>
                                                            <div className=" mt-1 " style={{ width: 50 }} >
                                                                {
                                                                    this.state.UsesImg[0]?.body_system == "Uses" ?
                                                                        <div class="click-to-top uss-ico">
                                                                            <img src={this.state.UsesImg[0].iconImg} width={40} height={40} data-tip="Uses"
                                                                            />
                                                                            <ReactTooltip place="left" type="dark" effect="float" />
                                                                            {/* <span>Uses</span> */}
                                                                        </div>

                                                                        : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-2 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                }
                                                            </div>
                                                            <div className='uss-of-uss' style={{ marginTop: 16, marginLeft: 25, width: "100%" }}>
                                                                {this.state.lessUses?.map((uses, i) =>
                                                                    <div className='row' key={i} style={{
                                                                        textTransform: (this.state.country_ids == 2 && uses?.can_label == 1) ? 'uppercase'
                                                                            : (this.state.country_ids != 2 && uses?.us_label == 1) ? 'uppercase' : '',
                                                                        fontSize: 14, fontWeight: 'bold', color: '#3d3b3b', width: "100%"
                                                                    }}>
                                                                        <span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(uses?.Uses) }}></span>
                                                                        {i == this.state.lessUses?.length - 1 ?
                                                                            <span>
                                                                                {(this.state.usesOff?.length > 5 && this.state.moreUsesFlag == false) ? <span id="span_dotUses"> <span className='badge badge-secondary'
                                                                                    onClick={this.moreUsesDisplay.bind(this, true)}
                                                                                    id="doseform-badge-id1" style={{ color: 'whitesmoke', cursor: 'pointer', marginLeft: 1 }}>+</span> </span> : this.state.moreUsesFlag == true ? <span id="span_dotUses"> <span className='badge badge-secondary'
                                                                                        onClick={this.moreUsesDisplay.bind(this, false)}
                                                                                        id="doseform-badge-id1" style={{ color: 'whitesmoke', cursor: 'pointer', marginLeft: 1 }}>&#8211;</span> </span> : ""}
                                                                            </span>
                                                                            : ""}
                                                                    </div>

                                                                )}
                                                            </div>

                                                        </div> : ''
                                                    }<br />
                                                    {
                                                        <div className="drug-info mb-3 d-flex" style={{ marginTop: '-16px', color: 'black', fontSize: 16 }} ref={this.contentRef}>
                                                            {
                                                                this.state.PharmClassImg[0]?.body_system == "Pharm Class" ?
                                                                    <>
                                                                        <img src={this.state.PharmClassImg[0].iconImg} width={40} height={40} className=' mr-3 '
                                                                            data-tip="Pharmacological Class" data-for="Pharmacological Class" />
                                                                        <ReactTooltip id="Pharmacological Class" place="left" type="dark" effect="float" />
                                                                    </>

                                                                    : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                            }
                                                            <p className="mt-2" style={{ fontSize: 16 }}>

                                                                {
                                                                    this.state.viewList?.pharm_class === 1 ? <span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.pharmaClasses) }}></span> : <span>

                                                                        {this.state.pharmocologicalFlag == true ? <>{this.state.pharmaClasses}
                                                                            <span className='badge badge-secondary' style={{ cursor: 'pointer', marginLeft: 3 }} onClick={() => {
                                                                                this.setState({ pharmocologicalFlag: !this.state.pharmocologicalFlag })
                                                                            }}> &#8722;</span></> : <>
                                                                            <span className='badge badge-secondary' style={{ cursor: 'pointer' }} onClick={() => {
                                                                                this.setState({ pharmocologicalFlag: !this.state.pharmocologicalFlag })
                                                                            }}>+</span>
                                                                        </>}

                                                                    </span>
                                                                }

                                                            </p>

                                                        </div>
                                                    }
                                                    {/* {
                                     this.state.viewList?.thera_class === 1 ? <> */}
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-9px', color: 'black', fontSize: 16 }}>
                                                        {
                                                            this.state.TheraClassImg[0]?.body_system == "Thera Class" ?
                                                                <>
                                                                    <img src={this.state.TheraClassImg[0].iconImg} width={40} height={40} className=' mr-3'
                                                                        data-tip="Therapeutic Class" data-for="Therapeutic Class"
                                                                    />
                                                                    <ReactTooltip id="Therapeutic Class" place="left" type="dark" effect="float" />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }
                                                        <p className="mt-2" style={{ fontSize: 16 }}>
                                                            {
                                                                this.state.viewList?.thera_class === 1 ? <span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.theraClasses) }}></span> : <span>
                                                                    {this.state.therapeuticFlag == true ? <><span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.theraClasses) }}></span>
                                                                        <span className='badge badge-secondary' style={{ cursor: 'pointer', marginLeft: 3 }} onClick={() => {
                                                                            this.setState({ therapeuticFlag: !this.state.therapeuticFlag })
                                                                        }}> &#8722;</span></> : <>
                                                                        <span className='badge badge-secondary' style={{ cursor: 'pointer' }} onClick={() => {
                                                                            this.setState({ therapeuticFlag: !this.state.therapeuticFlag })
                                                                        }}>+</span>
                                                                    </>}
                                                                </span>
                                                            }
                                                        </p>
                                                    </div>
                                                    {/* </> : ""
                                 } */}
                                                    {/* {
                                     this.state.viewList?.routes == 1 > 0 ? <> */}
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-12px', color: 'black', fontSize: 16 }}>
                                                        {
                                                            this.state.RoutesImg[0]?.body_system == "Routes" ?
                                                                <>
                                                                    <img src={this.state.RoutesImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                        data-tip="Routes" data-for="Routes" />
                                                                    <ReactTooltip id="Routes" place="left" type="dark" effect="float" />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }
                                                        <p className="mt-2" style={{ fontSize: 16 }}>
                                                            {
                                                                this.state.viewList?.routes === 1 ? <span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.routeNames) }}></span>  : <span>
                                                                    {this.state.routFlag == true ? <>{this.state.routeNames}
                                                                        <span className='badge badge-secondary' style={{ cursor: 'pointer', marginLeft: 3 }} onClick={() => {
                                                                            this.setState({ routFlag: !this.state.routFlag })
                                                                        }}> &#8722;</span></> : <>
                                                                        <span className='badge badge-secondary' style={{ cursor: 'pointer' }} onClick={() => {
                                                                            this.setState({ routFlag: !this.state.routFlag })
                                                                        }}>+</span>
                                                                    </>}
                                                                </span>
                                                            }
                                                        </p>
                                                    </div>
                                                    {
                                                        (this.state.doseFormList?.length > 0) ? <>
                                                            <div className="drug-info mb-3 d-flex" id="headingDoseForm"
                                                                // data-toggle="collapse"
                                                                data-target="#collapseDoseForm" aria-expanded="false" aria-controls="collapseDoseForm" style={{ marginTop: '-12px', cursor: 'pointer' }}>

                                                                {
                                                                    this.state.DoseFormsImg[0]?.body_system == "DoseForms" ?
                                                                        <>
                                                                            <img src={this.state.DoseFormsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                                data-tip="Dose Forms" data-for="Dose Forms"
                                                                            />
                                                                            <ReactTooltip id="Dose Forms" place="left" type="dark" effect="float" />
                                                                        </>
                                                                        : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                }
                                                                &nbsp; &nbsp;
                                                                <p className="mt-2" style={{ marginLeft: '-12px', fontSize: 16, color: 'black', fontSize: 16 }}>
                                                                    {this.state.viewList.dose_forms == 1 ? <>
                                                                        {
                                                                            <span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.doseFormList?.length > 1 ? 'dose- multiple dose forms available' : "")}}></span>
                                                                        }
                  <span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.doseFormList[0]?.doseform?.doesform_text ? '' + this.state.doseFormList[0]?.doseform?.doesform_text : '')}}></span>&nbsp;&nbsp;
                                                                        <span onClick={() => {
                                                                            this.setState({ isTablates: !this.state.isTablates, pm_id: null })
                                                                        }}>

                                                                            {this.state.doseFormList?.length > 1 ? <>
                                                                                {this.state.isTablates == false ? <><span className='badge badge-secondary ' id="doseform-badge-id" style={{ color: 'whitesmoke' }}>+</span>
                                                                                </> : <><span className='badge badge-secondary' id="doseform-badge-id" style={{ color: 'whitesmoke' }}>&#8211;</span></>}
                                                                            </> : ''}

                                                                        </span>
                                                                    </> : <>{this.state.doseFlag == true ? <>
                                                                        {
                                                                         <span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.doseFormList?.length > 1 ? 'dose- multiple dose forms available' : "")}}></span>
                                                                        }
                                                                    </> : ""}
                                                                        {this.state.doseFlag == false ? <>
                                                                            <span onClick={() => {
                                                                                this.setState({ isTablates: true, doseFlag: true })
                                                                            }} className='badge badge-secondary' id="doseform-badge-id" style={{ color: 'whitesmoke', display: this.state.doseFormList?.length > 1 ? "inline" : 'none' }}>+</span>
                                                                        </> : <span onClick={() => {
                                                                            this.setState({ isTablates: false, doseFlag: false, pm_id: null })
                                                                        }} className='badge badge-secondary ml-2' id="doseform-badge-id" style={{ color: 'whitesmoke' }}>&#8211;</span>}
                                                                    </>}
                                                                </p>
                                                            </div>
                                                        </> : ''
                                                    }
                                                    {
                                                        ((this.state.doseFormList?.length > 1 && this.state.isTablates == true || this.state.doseFormList?.length == 1 && this.state.isTablates == false)) > 0 ? <>
                                                            <div id="collapseDoseForm"
                                                                aria-labelledby="headingDoseForm" >
                                                                {this.state.doseFormList?.map((dose, index) =>
                                                                    <>
                                                                        <div className='row col-lg-12'>
                                                                            <p style={{ fontSize: 15, paddingBottom: 0, marginLeft: 56, marginTop: this.state.doseFormList?.length == 1 ? '-33px' : '-6px', color: 'black', fontSize: 16 }}>
                                                                                <div className='' onClick={(e) => {
                                                                                    if (this.state.pm_id == index) {
                                                                                        this.setState({ pm_id: null })
                                                                                    } else {
                                                                                        this.setState({ pm_id: index });
                                                                                    }
                                                                                }} data-target={`#${dose?.doseform_id}`}
                                                                                    style={{ cursor: 'pointer' }}>

                                                                                    {(this.state.viewList?.dose_forms == 0 && this.state.doseFormList?.length == 1) ? <span>&nbsp;
                                                                                        {(this.state.pm_id == index) ? <>{this.highlightSearchQuery(dose?.doseform)} {this.highlightSearchQuery(dose?.doesform_text ? '' + dose?.doesform_text : "")}&nbsp;<span className='badge badge-secondary'
                                                                                            id="doseform-badge-id1" style={{ color: 'whitesmoke' }}>

                                                                                        </span></> : <>
                                                                                            {/* <span className='badge badge-secondary'
                                                                                    id="doseform-badge-id1" style={{ color: 'whitesmoke' }}>+</span> */}
                                                                                        </>}
                                                                                    </span> : <span>
                                                                                    <span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(dose?.doseform)}}></span> <span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(dose?.doesform_text ? '' + dose?.doesform_text : "")}}></span>&nbsp;&nbsp;
                                                                                    </span>}

                                                                                    <span onClick={() => {
                                                                                        if (this.state.pm_id == index) {
                                                                                            this.setState({ pm_id: null })
                                                                                        } else {
                                                                                            this.setState({ pm_id: index });
                                                                                        }
                                                                                    }}>
                                                                                        {(this.state.pm_id == index) ? <><span className='badge badge-secondary'
                                                                                            id="doseform-badge-id1" style={{ color: 'whitesmoke', fontSize: (this.state.viewList?.dose_forms == 0 && this.state.doseFormList?.length == 1) ? '70%' : '' }}>&nbsp;-&nbsp;</span></> : <><span className='badge badge-secondary dose-1'
                                                                                                id="doseform-badge-id1" style={{ color: 'whitesmoke', fontSize: (this.state.viewList?.dose_forms == 0 && this.state.doseFormList?.length == 1) ? '70%' : '' }}>+</span></>}
                                                                                    </span>
                                                                                </div>
                                                                                <div id={dose?.doseform_id} className='row'>
                                                                                    <div className='row ml-2'>
                                                                                        {
                                                                                            dose.chlidExp?.map(val => <>
                                                                                                <div className='col-lg-12' style={{
                                                                                                    color: 'rgb(21 103 143 / 68%)'
                                                                                                }}>
                                                                                                    {this.state.pm_id == index ? <>
                                                                                                        {(this.state.country_ids == "2" && val?.can_doseform == 1 && val?.doesform_text == dose?.doesform_text) ? <>
                                                                                                            <span></span>&nbsp;&nbsp;<span><span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(val?.excipient)}}></span>&nbsp;{val?.excipient_qty ? <span>({val?.excipient_qty})</span> : ''}</span>
                                                                                                        </> : (this.state.country_ids != "2" && val?.us_doseform == 1 && val?.doesform_text == dose?.doesform_text) ? <>
                                                                                                            <span></span>&nbsp;&nbsp;<span><span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(val?.excipient)}}></span>&nbsp;{val?.excipient_qty ? <span>({val?.excipient_qty})</span> : ''}</span>
                                                                                                        </> : ""}
                                                                                                    </>
                                                                                                        : ""}
                                                                                                </div>
                                                                                            </>)
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </p>
                                                                        </div>

                                                                    </>
                                                                )}

                                                            </div>
                                                        </> : ""
                                                    }

                                                    {this.state.dietList[0]?.how_to_take == "" ? <br /> : ''}
                                                    {
                                                        (this.state.dietList?.length > 0 && this.state.dietList[0]?.how_to_take) ? <>
                                                            <div className="drug-info mb-3 d-flex" style={{ marginTop: this.state.doseFormList?.length == 1 ? "3px" : '-12px', color: 'black', fontSize: 16 }}>
                                                                {
                                                                    this.state.HowtoTakeImg[0]?.body_system == "How to Take" ?
                                                                        <>
                                                                            <img src={this.state.HowtoTakeImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                                data-for="How to take" data-tip="How to take"
                                                                            />
                                                                            <ReactTooltip id="How to take" place="left" type="dark" effect="float" />
                                                                        </>
                                                                        : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                }
                                                                <p className="mt-2" style={{ marginLeft: '-2px', fontSize: 16 }}>
                                                                {this.state.country_ids == 2 ?
                                                            <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.dietList[0]?.how_to_take?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.how_to_take : this.state.dietList[0]?.how_to_take) }}></div> :
                                                            <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.dietList[0]?.how_to_take?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.how_to_take?.split('Canadian labelling:')[0] : this.state.dietList[0]?.how_to_take) }}></div>
                                                        }
                                                                </p>
                                                            </div>
                                                        </> : ''
                                                    }
                                                    {
                                                        (this.state.dietList?.length > 0 && this.state.dietList[0]?.diet_interactions) ? <>
                                                            <div className="drug-info mb-0 d-flex" style={{ marginTop: '-16px', color: 'black', fontSize: 16 }}>
                                                                {
                                                                    this.state.DietImg[0]?.body_system == "Diet" ?
                                                                        <>
                                                                            <img src={this.state.DietImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                                data-for="Diet" data-tip="Diet"
                                                                            />
                                                                            <ReactTooltip id="Diet" place="left" type="dark" effect="float" />
                                                                        </>
                                                                        : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                }
                                                                <p className="mt-2" style={{ fontSize: 16 }}>
                                                                {
                    this.state.country_ids == 2 ?
                        (
                            <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.dietList[0]?.diet_interactions) }}></div>
                        )
                        :
                        (
                            <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.dietList[0]?.diet_interactions?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.diet_interactions?.split('Canadian labelling:')[0] : this.state.dietList[0]?.diet_interactions) }}></div>
                        )
                }
                                                                    {/* <div dangerouslySetInnerHTML={{ __html: this.state.dietList[0]?.diet_interactions }}></div> */}
                                                                </p>
                                                            </div>
                                                        </> : ''
                                                    }
                                                    {
                                                        this.state.hideContainers === true ? <>
                                                            <div>
                                                                {
                                                                    (this.state.dietList?.length > 0 && this.state.dietList[0]?.grapefruit_interactions) ? <>
                                                                        <div className="drug-info mb-3 d-flex" style={{ marginTop: '0px', color: 'black', fontSize: 16 }}>
                                                                            {
                                                                                this.state.GrapefruitInteractionsImg[0]?.body_system == "Grapefruit Interactions" ?
                                                                                    <>
                                                                                        <img src={this.state.GrapefruitInteractionsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                                            data-tip="Grapefruit Interactions" data-for="Grapefruit Interactions"
                                                                                        />
                                                                                        <ReactTooltip id="Grapefruit Interactions" place="left" type="dark" effect="float" />
                                                                                    </>
                                                                                    : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                            } <p className="mt-2" style={{ fontSize: 16 }}>
                                                                                {this.state.country_ids == 2 ?
                                                                                  <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.dietList[0]?.grapefruit_interactions?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.grapefruit_interactions : this.state.dietList[0]?.grapefruit_interactions) }}></div> :
                                                                                  <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.dietList[0]?.grapefruit_interactions?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.grapefruit_interactions?.split('Canadian labelling:')[0] : this.state.dietList[0]?.grapefruit_interactions) }}></div>
                                                                                }
                                                                                {/* <div id="grapft" dangerouslySetInnerHTML={{ __html: this.state.dietList[0]?.grapefruit_interactions }}></div> */}
                                                                            </p>
                                                                        </div>
                                                                    </> : ''
                                                                }
                                                                {this.state.dietList[0]?.grapefruit_interactions == "" ? <br /> : ''}
                                                                {
                                                                    (this.state.dietList?.length > 0 && this.state.dietList[0]?.alcohol_interactions) ? <>
                                                                        <div className="drug-info mb-3 d-flex" style={{ marginTop: '-16px', color: 'black', fontSize: 16 }}>
                                                                            {
                                                                                this.state.AlcoholInteractionsImg[0]?.body_system == "Alcohol Interactions" ?
                                                                                    <>
                                                                                        <img src={this.state.AlcoholInteractionsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                                            data-for="Alcohol Interactions" data-tip="Alcohol Interactions"
                                                                                        />
                                                                                        <ReactTooltip id="Alcohol Interactions" place="left" type="dark" effect="float" />
                                                                                    </>
                                                                                    : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                            }
                                                                            <p className="mt-2" style={{ fontSize: 16 }}>
                                                                                {this.state.country_ids == 2 ?
                                                                                    <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.dietList[0]?.alcohol_interactions?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.alcohol_interactions : this.state.dietList[0]?.alcohol_interactions) }}></div> :
                                                                                    <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.dietList[0]?.alcohol_interactions?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.alcohol_interactions?.split('Canadian labelling:')[0] : this.state.dietList[0]?.alcohol_interactions) }}></div>
                                                                                }
                                                                                {/* <div id="grapft" dangerouslySetInnerHTML={{ __html: this.state.dietList[0]?.alcohol_interactions }}></div> */}
                                                                            </p>
                                                                        </div>
                                                                    </> : ''
                                                                }
                                                                <div className="drug-info mb-3 d-flex" style={{ marginTop: '-16px', color: 'black', fontSize: 16 }}>
                                                                    {
                                                                        this.state.NaturalproductinteractionsImg[0]?.body_system == "Natural product interactions" ?
                                                                            <>
                                                                                <img src={this.state.NaturalproductinteractionsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                                    data-for="Natural Products" data-tip="Natural Products"
                                                                                />
                                                                                <ReactTooltip id="Natural Products" place="left" type="dark" effect="float" />
                                                                            </>
                                                                            : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                    }

                                                                    {
                                                                        (this.state.dietList[0]?.natural_product_interactions) ? <>
                                                                            <p className="mt-2" style={{ fontSize: 16 }}>
                                                                                {
                                                                                    this.state.viewList?.natural_products === 1 ? <>
                                                                                        {this.state.country_ids == 2 ?
                                                                                             <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.dietList[0]?.natural_product_interactions?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.natural_product_interactions : this.state.dietList[0]?.natural_product_interactions) }}></div> :
                                                                                             <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.dietList[0]?.natural_product_interactions?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.natural_product_interactions?.split('Canadian labelling:')[0] : this.state.dietList[0]?.natural_product_interactions) }}></div>
                                                                                        }
                                                                                    </> : <span>
                                                                                        {this.state.nutrineFlag == true ? <div style={{ display: 'flex' }}>
                                                                                            {this.state.country_ids == 2 ?
                                                                                                 <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.dietList[0]?.natural_product_interactions?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.natural_product_interactions : this.state.dietList[0]?.natural_product_interactions) }}></div> :
                                                                                                 <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.dietList[0]?.natural_product_interactions?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.natural_product_interactions?.split('Canadian labelling:')[0] : this.state.dietList[0]?.natural_product_interactions) }}></div>
                                                                                            }
                                                                                            <div className='badge badge-secondary' style={{ cursor: 'pointer', height: '17px', marginTop: '3px' }} onClick={() => {
                                                                                                this.setState({ nutrineFlag: !this.state.nutrineFlag })
                                                                                            }}>&#8722;</div></div> : <>
                                                                                            <span className='badge badge-secondary' style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                this.setState({ nutrineFlag: !this.state.nutrineFlag })
                                                                                            }}>+</span>
                                                                                        </>}

                                                                                    </span>
                                                                                }
                                                                            </p>
                                                                        </> : ""
                                                                    }
                                                                </div>
                                                                {
                                                                    this.state.warningList?.length > 0 ? <>
                                                                        <div className="drug-info mb-3 d-flex" style={{ marginTop: '-16px', color: 'black', fontSize: 16 }}>
                                                                            {
                                                                                this.state.WarningsImg[0]?.body_system == "Warnings" ?
                                                                                    <>
                                                                                        <img src={this.state.WarningsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                                            data-tip="Warnings" data-for="Warnings"
                                                                                        />
                                                                                        <ReactTooltip id="Warnings" place="left" type="dark" effect="float" />
                                                                                    </>
                                                                                    : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                            }
                                                                            <p className='mt-2' style={{ fontSize: 16 }}>
                                                                                {this.state.userInfo?.country_view_id == 1 ?
                                                                                    <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.us_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.us_warning?.split('Canadian labelling:')[1] : this.state.warningList[0]?.us_warning) }}></div> :
                                                                                    <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.can_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.can_warning?.split('Canadian labelling:')[0] : this.state.warningList[0]?.can_warning) }}></div>
                                                                                }
                                                                                {/* <div dangerouslySetInnerHTML={{ __html: this.state.country_ids == '1' ? this.state.warningList[0]?.us_warning : this.state.warningList[0]?.can_warning }}></div> */}
                                                                            </p>
                                                                        </div>
                                                                    </> : ''
                                                                }
                                                                {this.state.warningList[0]?.protein_binding ? <>
                                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-16px', color: 'black', fontSize: 16 }}>
                                                                        {
                                                                            this.state.ProteinbindingImg[0]?.body_system == "Protein binding" ?
                                                                                <>
                                                                                    <img src={this.state.ProteinbindingImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                                        data-tip="Protein Binding" data-for="Protein Binding"
                                                                                    />
                                                                                    <ReactTooltip id="Protein Binding" place="left" type="dark" effect="float" />
                                                                                </>
                                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                        }
                                                                        <p className='mt-2' style={{ fontSize: 16 }}>

                                                                            {
                                                                                this.state.viewList?.protein_binding === 1 ?
                                                                                    <>
                                                                                        {this.state.country_ids == 2 ?
                                                                                          <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.protein_binding?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.protein_binding : this.state.warningList[0]?.protein_binding) }}></div> :
                                                                                          <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.protein_binding?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.protein_binding?.split('Canadian labelling:')[0] : this.state.warningList[0]?.protein_binding) }}></div>
                                                                                        }
                                                                                    </>
                                                                                    : <span>
                                                                                        {this.state.protienFlag == true ? <div style={{ display: 'flex' }}>
                                                                                            {this.state.country_ids == 2 ?
                                                                                                 <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.protein_binding?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.protein_binding : this.state.warningList[0]?.protein_binding) }}></div> :
                                                                                                 <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.protein_binding?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.protein_binding?.split('Canadian labelling:')[0] : this.state.warningList[0]?.protein_binding) }}></div>
                                                                                            }
                                                                                            <div className='badge badge-secondary' style={{ cursor: 'pointer', height: '17px', marginTop: '3px' }} onClick={() => {
                                                                                                this.setState({ protienFlag: !this.state.protienFlag })
                                                                                            }}>&#8722;</div></div> : <>
                                                                                            <span className='badge badge-secondary' style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                this.setState({ protienFlag: !this.state.protienFlag })
                                                                                            }}>+</span>
                                                                                        </>}
                                                                                    </span>
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </> : ""
                                                                }
                                                                {this.state.warningList[0]?.renal_warning ? <>
                                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-16px', color: 'black', fontSize: 16 }}>
                                                                        {
                                                                            this.state.RenalwarningsImg[0]?.body_system == "Renal warnings" ?
                                                                                <>
                                                                                    <img src={this.state.RenalwarningsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                                        data-for="Renal Warnings" data-tip="Renal Warnings"
                                                                                    />
                                                                                    <ReactTooltip id="Renal Warnings" place="left" type="dark" effect="float" />
                                                                                </>
                                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                        }
                                                                        <p className='mt-2' style={{ fontSize: 16 }}>
                                                                            {
                                                                                this.state.viewList?.renal_warning === 1 ?
                                                                                    <>
                                                                                        {this.state.country_ids == 2 ?
                                                                                           <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.renal_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.renal_warning : this.state.warningList[0]?.renal_warning) }}></div> :
                                                                                           <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.renal_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.renal_warning?.split('Canadian labelling:')[0] : this.state.warningList[0]?.renal_warning) }}></div>
                                                                                        }
                                                                                    </>
                                                                                    : <span>
                                                                                        {this.state.renalFlag == true ? <div style={{ display: 'flex' }}>
                                                                                            {this.state.country_ids == 2 ?
                                                                                               <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.renal_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.renal_warning : this.state.warningList[0]?.renal_warning )}}></div> :
                                                                                               <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.renal_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.renal_warning?.split('Canadian labelling:')[0] : this.state.warningList[0]?.renal_warning) }}></div>
                                                                                            }
                                                                                            <div className='badge badge-secondary' style={{ cursor: 'pointer', height: '17px', marginTop: '3px' }} onClick={() => {
                                                                                                this.setState({ renalFlag: !this.state.renalFlag })
                                                                                            }}>&#8722;</div></div> : <>
                                                                                            <span className='badge badge-secondary' style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                this.setState({ renalFlag: !this.state.renalFlag })
                                                                                            }}>+</span>
                                                                                        </>}
                                                                                    </span>
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </> : ''
                                                                }
                                                                {
                                                                    this.state.warningList[0]?.dialysis_warning ? <>
                                                                        <div className="drug-info mb-3 d-flex" style={{ marginTop: '-16px', color: 'black', fontSize: 16 }}>
                                                                            {
                                                                                this.state.DialysiswarningsImg[0]?.body_system == "Dialysis warnings" ?
                                                                                    <>
                                                                                        <img src={this.state.DialysiswarningsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                                            data-for='Dialysis Warnings' data-tip='Dialysis Warnings'
                                                                                        />
                                                                                        <ReactTooltip id="Dialysis Warnings" place="left" type="dark" effect="float" />
                                                                                    </>
                                                                                    : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                            }
                                                                            {
                                                                                this.state.viewList?.dialysis === 1 ? <div style={{ marginTop: 7 }}>
                                                                                    {this.state.country_ids == 2 ?
                                                                                        <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.dialysis_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.dialysis_warning : this.state.warningList[0]?.dialysis_warning) }}></div> :
                                                                                        <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.dialysis_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.dialysis_warning?.split('Canadian labelling:')[0] : this.state.warningList[0]?.dialysis_warning) }}></div>
                                                                                    }
                                                                                </div>
                                                                                    : <span>
                                                                                        {this.state.dialysisFlag == true ?
                                                                                            <div style={{ display: 'flex', marginTop: 10 }}>
                                                                                                {this.state.country_ids == 2 ?
                                                                                                    <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.dialysis_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.dialysis_warning : this.state.warningList[0]?.dialysis_warning }}></div> :
                                                                                                    <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.dialysis_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.dialysis_warning?.split('Canadian labelling:')[0] : this.state.warningList[0]?.dialysis_warning }}></div>
                                                                                                }
                                                                                                <div className='badge badge-secondary' style={{ cursor: 'pointer', height: '17px', marginTop: '3px' }} onClick={() => {
                                                                                                    this.setState({ dialysisFlag: !this.state.dialysisFlag })
                                                                                                }}>&#8722;</div></div> : <div style={{ marginTop: 7 }}>
                                                                                                <span className='badge badge-secondary' style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                    this.setState({ dialysisFlag: !this.state.dialysisFlag })
                                                                                                }}>+</span>
                                                                                            </div>}
                                                                                    </span>
                                                                            }
                                                                        </div>
                                                                    </> : ''
                                                                }
                                                                {/* {
                                                 this.state.viewList?.hepatic_warning == 1 ? <> */}
                                                                <div className="drug-info mb-3 d-flex" style={{ marginTop: '-16px', color: 'black', fontSize: 16 }}>
                                                                    {
                                                                        this.state.HepaticwarningsImg[0]?.body_system == "Hepatic warnings" ?
                                                                            <>
                                                                                <img src={this.state.HepaticwarningsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                                    data-for="Hepatic Warnings" data-tip="Hepatic Warnings"
                                                                                />
                                                                                <ReactTooltip id="Hepatic Warnings" place="left" type="dark" effect="float" />
                                                                            </>
                                                                            : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                    }
                                                                    <p className='mt-2' style={{ fontSize: 16 }}>
                                                                        {
                                                                            this.state.viewList?.hepatic_warning === 1 ? <>
                                                                                {this.state.country_ids == 2 ?
                                                                        <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.hepatic_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.hepatic_warning : this.state.warningList[0]?.hepatic_warning) }}></div> :
                                                                        <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.hepatic_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.hepatic_warning?.split('Canadian labelling:')[0] : this.state.warningList[0]?.hepatic_warning) }}></div>
                                                                                }
                                                                            </>
                                                                                : <span>
                                                                                    {this.state.hepticlFlag == true ? <div style={{ display: 'flex' }}>
                                                                                        {this.state.country_ids == 2 ? <>
                                                                                            <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.hepatic_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.hepatic_warning : this.state.warningList[0]?.hepatic_warning) }}></div>
                                                                                            <span className='badge badge-secondary' style={{ cursor: 'pointer', height: '17px', marginTop: '3px' }} onClick={() => {
                                                                                                this.setState({ hepticlFlag: !this.state.hepticlFlag })
                                                                                            }}>&#8722;</span>
                                                                                        </>
                                                                                            : <>
                                                                                    <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.hepatic_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.hepatic_warning?.split('Canadian labelling:')[0] : this.state.warningList[0]?.hepatic_warning) }}></div>
                                                                                                <span className='badge badge-secondary' style={{ cursor: 'pointer', height: '17px', marginTop: '3px' }} onClick={() => {
                                                                                                    this.setState({ hepticlFlag: !this.state.hepticlFlag })
                                                                                                }}>&#8722;</span>
                                                                                            </>
                                                                                        }
                                                                                    </div> : <>
                                                                                        <span className='badge badge-secondary' style={{ cursor: 'pointer' }} onClick={() => {
                                                                                            this.setState({ hepticlFlag: !this.state.hepticlFlag })
                                                                                        }}>+</span>
                                                                                    </>}
                                                                                </span>
                                                                        }
                                                                    </p>
                                                                </div>
                                                                {/* </> : ''
                                             } */}
                                                                {/* {
                                                 this.state.viewList?.pregnancy_warnings == 1 ? <> */}
                                                                <div className="drug-info mb-3 d-flex" style={{ marginTop: '-16px', color: 'black', fontSize: 16 }}>
                                                                    {
                                                                        this.state.PregnancywarningsImg[0]?.body_system == "Pregnancy warnings" ?
                                                                            <>
                                                                                <img src={this.state.PregnancywarningsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                                    data-for="Pregnancy Warnings"
                                                                                    data-tip='Pregnancy Warnings'
                                                                                />
                                                                                <ReactTooltip id="Pregnancy Warnings" place="left" type="dark" effect="float" />
                                                                            </>
                                                                            : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                    }
                                                                    <p className='mt-2' style={{ fontSize: 16 }}>
                                                                        {
                                                                            this.state.viewList?.pregnancy_warnings === 1 ?
                                                                                <>
                                                                                    {this.state.country_ids == 2 ?
                                                                            <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.pregnancy_warnings?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.pregnancy_warnings : this.state.warningList[0]?.pregnancy_warnings) }}></div> :
                                                                            <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.pregnancy_warnings?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.pregnancy_warnings?.split('Canadian labelling:')[0] : this.state.warningList[0]?.pregnancy_warnings) }}></div>
                                                                        }
                                                                                </>
                                                                                : <span>
                                                                                    {this.state.pregnancyFlag == true ? <div style={{ display: 'flex' }}>
                                                                                        {this.state.country_ids == 2 ?
                                                                                <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.pregnancy_warnings?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.pregnancy_warnings : this.state.warningList[0]?.pregnancy_warnings) }}></div> :
                                                                                <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.pregnancy_warnings?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.pregnancy_warnings?.split('Canadian labelling:')[0] : this.state.warningList[0]?.pregnancy_warnings) }}></div>
                                                                                        }
                                                                                        <div className='badge badge-secondary' style={{ cursor: 'pointer', height: '17px', marginTop: '3px' }} onClick={() => {
                                                                                            this.setState({ pregnancyFlag: !this.state.pregnancyFlag })
                                                                                        }}>&#8722;</div></div> : <>
                                                                                        <span className='badge badge-secondary' style={{ cursor: 'pointer' }} onClick={() => {
                                                                                            this.setState({ pregnancyFlag: !this.state.pregnancyFlag })
                                                                                        }}>+</span>
                                                                                    </>}
                                                                                </span>
                                                                        }
                                                                    </p>
                                                                </div>
                                                                {/* </> : ''
                                             } */}
                                                                {/* {
                                                 this.state.viewList?.lactation_warnings == 1 ? <> */}
                                                                <div className="drug-info mb-3 d-flex" style={{ marginTop: '-16px', color: 'black', fontSize: 16 }}>
                                                                    {
                                                                        this.state.BreastfeedingWarningsImg[0]?.body_system == "Breastfeeding Warnings" ?
                                                                            <>
                                                                                <img src={this.state.BreastfeedingWarningsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                                    data-tip="Lactation Warnings" data-for='Lactation Warnings'
                                                                                />
                                                                                <ReactTooltip id="Lactation Warnings" place="left" type="dark" effect="float" />
                                                                            </>
                                                                            : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                    }
                                                                    <p className="m-0">
                                                                        <p className='mt-2' style={{ fontSize: 16 }}>
                                                                            {
                                                                                this.state.viewList?.lactation_warnings === 1 ? <>
                                                                                     {this.state.country_ids == 2 ?
                                                                            <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.lactation_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.lactation_warning : this.state.warningList[0]?.lactation_warning) }}></div> :
                                                                            <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.lactation_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.lactation_warning?.split('Canadian labelling:')[0] : this.state.warningList[0]?.lactation_warning) }}></div>
                                                                        }
                                                                                </>
                                                                                    : <span>
                                                                                        {this.state.lactationFlag == true ? <div style={{ display: 'flex' }}>
                                                                                            {this.state.country_ids == 2 ?
                                                                                    <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.lactation_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.lactation_warning : this.state.warningList[0]?.lactation_warning) }}></div> :
                                                                                    <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.lactation_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.lactation_warning?.split('Canadian labelling:')[0] : this.state.warningList[0]?.lactation_warning) }}></div>
                                                                                            }
                                                                                            <div className='badge badge-secondary' style={{ cursor: 'pointer', height: '17px', marginTop: '3px' }} onClick={() => {
                                                                                                this.setState({ lactationFlag: !this.state.lactationFlag })
                                                                                            }}>&#8722;</div></div> : <>
                                                                                            <span className='badge badge-secondary' style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                this.setState({ lactationFlag: !this.state.lactationFlag })
                                                                                            }}>+</span>
                                                                                        </>}
                                                                                    </span>
                                                                            }
                                                                        </p>
                                                                    </p>
                                                                </div>
                                                                <div style={{ height: 11 }}></div>
                                                                {/* </> : ''
                                             } */}

                                                                {/* Oral */}
                                                                {
                                                                    (this.state.OralAdvList?.length > 0 || this.state.commOral?.length > 0) ? <>
                                                                        <div className="drug-info mb-3 d-flex" style={{ marginTop: '-22px', color: 'black', fontSize: 16 }}>
                                                                            {
                                                                                this.state.OralAdverseEffectsImg[0]?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Oral Adverse Effects" : "Oral Adverse Effects") ?
                                                                                    <>
                                                                                        <ReactTooltip id="Oral Adverse Effects" place="left" type="dark" effect="float" />
                                                                                        <img src={this.state.OralAdverseEffectsImg[0].iconImg} width={40} height={40} className=' icon-img'
                                                                                            data-tip='Oral Adverse Effects' data-for='Oral Adverse Effects'
                                                                                        />
                                                                                    </>
                                                                                    : <DummyImage width={40} height={40} placeholder='Dummy Image' className='  icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                            }
                                                                            <p className="" style={{ marginLeft: 17 }}>
                                                                                <p className='' style={{ fontSize: 16, marginTop: '-18px' }}>
                                                                                    {this.state.viewList?.oral_adverse_effects == 0 ?
                                                                                        <>
                                                                                            {this.state.OralBool == true ? <span onClick={(e) => {
                                                                                                this.setState({ OralBool: !this.state.OralBool })
                                                                                            }} className='badge badge-secondary' style={{ cursor: 'pointer' }}>+</span> : ""}

                                                                                            {this.state.OralBool == false ? <span>
                                                                                                {this.state.OralAdvList?.map((ae, i) =>
                                                                                                    <span>
                                                                                                        {((ae.rate == 0) && this.state.OralAdvList?.length - this.state.OralzeroBlank?.length == i) ? <b>Rare: </b> : ''}
                                                                                                        {ae?.change == 'Increase' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }}>&#9650;</span>
                                                                                                            : ae?.change == 'Decrease' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} >&#9660;</span>
                                                                                                                : ae?.change == 'up or down' ?
                                                                                                                    <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} >&#9660;/&#9650;</span>
                                                                                                                    : ae?.change == "" ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} ></span> : ''}
                                                                                                        {parseFloat(ae?.rate) > 19.9 ? <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                            : (parseFloat(ae?.rate) >= 15 && parseFloat(ae?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                            // : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: ae?.adverse }}></span>    
                                                                                                            : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse)  }}></span>
                                                                                                                    : (parseFloat(ae.rate) >= 0.1 && parseFloat(ae.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '', color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse)  }}></span>
                                                                                                                        : <span style={{ fontSize: 16, color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : "", textTransform: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "uppercase" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse)  }}>
                                                                                                                            {/* {ae?.adverse} */}
                                                                                                                        </span>}
                                                                                                        {ae?.severe == 1 ?
                                                                                                            <span style={{
                                                                                                                color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ?
                                                                                                                    'red' : '', fontWeight: ((parseFloat(ae?.rate) > 19.9)) ? 'bold' : '', fontStyle: ae?.effect_desired == 1 ? "italic" : ''
                                                                                                            }} >^</span>
                                                                                                            : ""}
                                                                                                        {ae?.toxicity_due == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                                                        {i !== this.state.OralAdvList?.length - 1 ? <b style={{ fontSize: 18 }}>{(i == this.state.OralAdvList?.length - (this.state.OralzeroBlank?.length + 1)) ? '. ' : ', '} </b> : ''}
                                                                                                    </span>
                                                                                                )}
                                                                                                {this.state.OralBool == false ? <span onClick={(e) => {
                                                                                                    this.setState({ OralBool: !this.state.OralBool })
                                                                                                }} className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}>&#8211;</span> : ""}
                                                                                            </span> : ""}
                                                                                        </> : <span>
                                                                                            {this.state.OralCommonTrue?.map((ae, i) =>
                                                                                                <span>
                                                                                                    {(ae.rate == 0 && this.state.OralCommonTrue?.length - this.state.OralzeroBlank?.length == i) ? <b>Rare: </b> : ''}
                                                                                                    {ae?.change == 'Increase' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }}>&#9650;</span>
                                                                                                        : ae?.change == 'Decrease' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} >&#9660;</span>
                                                                                                            : ae?.change == 'up or down' ?
                                                                                                                <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} >&#9660;/&#9650;</span>
                                                                                                                : ae?.change == "" ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} ></span> : ''}
                                                                                                    {parseFloat(ae?.rate) > 19.9 ? <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                        : (parseFloat(ae?.rate) >= 15 && parseFloat(ae?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse)  }}></span>
                                                                                                        // : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: ae?.adverse }}></span>    
                                                                                                        : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse)  }}></span>
                                                                                                                : (parseFloat(ae.rate) >= 0.1 && parseFloat(ae.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '', color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                                    : <span style={{ fontSize: 16, color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : "", textTransform: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "uppercase" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }} >
                                                                                                                        {/* {ae?.adverse} */}
                                                                                                                    </span>}
                                                                                                    {ae?.severe == 1 ?
                                                                                                        <span style={{
                                                                                                            color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ?
                                                                                                                'red' : '', fontWeight: ((parseFloat(ae?.rate) > 19.9)) ? 'bold' : '', fontStyle: ae?.effect_desired == 1 ? "italic" : ''
                                                                                                        }} >^</span>
                                                                                                        : ""}
                                                                                                    {ae?.toxicity_due == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                                                    {i !== this.state.OralCommonTrue?.length - 1 ? <b style={{ fontSize: 18 }}>{(i == this.state.OralCommonTrue?.length - (this.state.OralzeroBlank?.length + 1)) ? '. ' : ', '} </b> : ''}
                                                                                                </span>
                                                                                            )}
                                                                                            {(this.state.viewList?.oral_adverse_effects == 2 || this.state.OralCommonTrue?.length == 0) ? <span onClick={(e) => {
                                                                                                this.setState({ OralBool: !this.state.OralBool }, () => {
                                                                                                    if (this.state.OralBool == true) {
                                                                                                        this.setState({ OralCommonTrue: this.state.OralAdvList });
                                                                                                    } else {
                                                                                                        this.setState({ OralCommonTrue: this.state.commOral });
                                                                                                    }
                                                                                                });
                                                                                            }}>
                                                                                                {this.state.OralBool == true ? <span className='badge badge-secondary ml-1 oral-1' style={{ cursor: 'pointer' }}>+</span> : <span className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}>&#8211;</span>}
                                                                                            </span> : ""}
                                                                                        </span>}
                                                                                </p>
                                                                            </p>
                                                                        </div>
                                                                    </> : ''
                                                                }
                                                                {/* GI */}
                                                                {
                                                                    (this.state.GIAdvList?.length > 0 || this.state.commGI?.length > 0) ? <>
                                                                        <div className="drug-info mb-3 d-flex" style={{ marginTop: '-20px', color: 'black', fontSize: 16 }}>
                                                                            {
                                                                                this.state.GIAdverseEffectsImg[0]?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust GI Adverse Effects" : 'GI Adverse Effects') ?
                                                                                    <>
                                                                                        <ReactTooltip id="GI Adverse Effects" place="left" type="dark" effect="float" />
                                                                                        <img src={this.state.GIAdverseEffectsImg[0].iconImg} width={40} height={40} className=' icon-img'
                                                                                            data-tip='GI Adverse Effects' data-for='GI Adverse Effects'
                                                                                        />
                                                                                    </>
                                                                                    : <DummyImage width={40} height={40} placeholder='Dummy Image' className='  icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                            }

                                                                            <p className="" style={{ marginLeft: 16 }}>
                                                                                <p className='' style={{ fontSize: 16, marginTop: '-18px', color: 'black', fontSize: 16 }}>
                                                                                    {this.state.viewList?.gi_adverse_effects == 0 ? <>
                                                                                        {this.state.GIBool == true ? <span onClick={(e) => {
                                                                                            this.setState({ GIBool: !this.state.GIBool })
                                                                                        }} className='badge badge-secondary' style={{ cursor: 'pointer' }}>+</span> : ""}

                                                                                        {this.state.GIBool == false ? <span>
                                                                                            {this.state.GIAdvList?.map((ae, i) =>
                                                                                                <span>
                                                                                                    {(ae.rate == 0 && this.state.GIAdvList?.length - this.state.GIzeroBlank?.length == i) ? <b>Rare: </b> : ''}
                                                                                                    {ae?.change == 'Increase' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }}>&#9650;</span>
                                                                                                        : ae?.change == 'Decrease' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} >&#9660;</span>
                                                                                                            : ae?.change == 'up or down' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} >&#9660;/&#9650;</span>
                                                                                                                : ae?.change == "" ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} ></span> : ''}
                                                                                                    {parseFloat(ae?.rate) > 19.9 ? <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse)}}></span>
                                                                                                        : (parseFloat(ae?.rate) >= 15 && parseFloat(ae?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '',fontWeight:'bold' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                        : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                        // : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: ae?.adverse }}></span>
                                                                                                                : (parseFloat(ae.rate) >= 0.1 && parseFloat(ae.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '', color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                                    : <span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }} style={{ color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : "", textTransform: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "uppercase" : '' }} ></span>}
                                                                                                    {ae?.severe == 1 ?
                                                                                                        <span style={{
                                                                                                            color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ?
                                                                                                                'red' : '', fontWeight: ((parseFloat(ae?.rate) > 19.9)) ? 'bold' : '', fontStyle: ae?.effect_desired == 1 ? "italic" : ''
                                                                                                        }} >^</span>
                                                                                                        : ""}
                                                                                                    {ae?.toxicity_due == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                                                    {i !== this.state.GIAdvList?.length - 1 ? <b style={{ fontSize: 18 }}>{(i == this.state.GIAdvList?.length - (this.state.GIzeroBlank?.length + 1)) ? '. ' : ', '} </b> : ''}
                                                                                                </span>
                                                                                            )}
                                                                                            {this.state.GIBool == false ? <span onClick={(e) => {
                                                                                                this.setState({ GIBool: !this.state.GIBool })
                                                                                            }} className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}>&#8211;</span> : ""}
                                                                                        </span> : ""}
                                                                                    </> : <span>
                                                                                        {(this.state.GICommonTrue)?.map((ae, i) =>
                                                                                            <span>
                                                                                                {(ae.rate == 0 && this.state.GICommonTrue?.length - this.state.GIzeroBlank?.length == i) ? <b>Rare: </b> : ''}
                                                                                                {ae?.change == 'Increase' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }}>&#9650;</span>
                                                                                                    : ae?.change == 'Decrease' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} >&#9660;</span>
                                                                                                        : ae?.change == 'up or down' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} >&#9660;/&#9650;</span>
                                                                                                            : ae?.change == "" ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} ></span> : ''}
                                                                                                {parseFloat(ae?.rate) > 19.9 ? <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                    : (parseFloat(ae?.rate) >= 15 && parseFloat(ae?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '',fontWeight:'bold' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                        : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                        // : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: ae?.adverse }}></span>
                                                                                                        
                                                                                                        : (parseFloat(ae.rate) >= 0.1 && parseFloat(ae.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '', color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                                : <span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }} style={{ color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : "", textTransform: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "uppercase" : '' }}></span>}
                                                                                                {ae?.severe == 1 ?
                                                                                                    <span style={{
                                                                                                        color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ?
                                                                                                            'red' : '', fontWeight: ((parseFloat(ae?.rate) > 19.9)) ? 'bold' : '', fontStyle: ae?.effect_desired == 1 ? "italic" : '', color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : ''
                                                                                                    }} >^</span>
                                                                                                    : ""}
                                                                                                {ae?.toxicity_due == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                                                {i !== this.state.GICommonTrue?.length - 1 ? <b style={{ fontSize: 18 }}>{(i == this.state.GICommonTrue?.length - (this.state.GIzeroBlank?.length + 1)) ? '. ' : ', '} </b> : ''}
                                                                                            </span>
                                                                                        )}
                                                                                        {this.state.viewList?.gi_adverse_effects == 2 ? <span onClick={(e) => {
                                                                                            this.setState({ GIBool: !this.state.GIBool }, () => {
                                                                                                if (this.state.GIBool == true) {
                                                                                                    this.setState({ GICommonTrue: this.state.GIAdvList });
                                                                                                } else {
                                                                                                    this.setState({ GICommonTrue: this.state.commGI });
                                                                                                }
                                                                                            });
                                                                                        }}>
                                                                                            {this.state.GIBool == true ? <span className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}>+</span> : <span className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}>&#8211;</span>}
                                                                                        </span> : ""}
                                                                                    </span>}
                                                                                </p>
                                                                            </p>
                                                                        </div>
                                                                    </> : ''
                                                                }
                                                                {/* Nutrition */}
                                                                {
                                                                    (this.state.NutritionAdvList?.length > 0 || this.state.commNutrition?.length > 0) ? <>
                                                                        <div className="drug-info mb-3 d-flex" style={{ marginTop: '-21px', color: 'black', fontSize: 16 }}>
                                                                            {
                                                                                this.state.NutritionalAdverseEffectsImg[0]?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Nutritional Adverse Effects" : 'Nutritional Adverse Effects') ?
                                                                                    <>
                                                                                        <ReactTooltip id="Nutrition Adverse Effects" place="left" type="dark" effect="float" />
                                                                                        <img src={this.state.NutritionalAdverseEffectsImg[0].iconImg} width={40} height={40} className=' icon-img'
                                                                                            data-tip='Nutrition Adverse Effects' data-for='Nutrition Adverse Effects'
                                                                                        />
                                                                                    </>
                                                                                    : <DummyImage width={40} height={40} placeholder='Dummy Image' className='  icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                            }
                                                                            <p className="" style={{ marginLeft: 16 }}>
                                                                                <p className='' style={{ fontSize: 16, marginTop: '-18px' }}>
                                                                                    {this.state.viewList?.nutrition_adverse_effects == 0 ? <>
                                                                                        {this.state.NutriBool == true ? <span onClick={(e) => {
                                                                                            this.setState({ NutriBool: !this.state.NutriBool })
                                                                                        }} className='badge badge-secondary' style={{ cursor: 'pointer' }}>+</span> : ""}

                                                                                        {this.state.NutriBool == false ? <span>
                                                                                            {this.state.NutritionAdvList?.map((ae, i) => <span>
                                                                                                {(ae.rate == 0 && this.state.NutritionAdvList?.length - this.state.NutzeroBlank?.length == i) ? <b>Rare: </b> : ''}
                                                                                                {ae?.change == 'Increase' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }}>&#9650;</span>
                                                                                                    : ae?.change == 'Decrease' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} >&#9660;</span>
                                                                                                        : ae?.change == 'up or down' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} >&#9660;/&#9650;</span>
                                                                                                            : ae?.change == "" ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} ></span> : ''}
                                                                                                {parseFloat(ae?.rate) > 19.9 ? <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                    : (parseFloat(ae?.rate) >= 15 && parseFloat(ae?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                    // : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: ae?.adverse }}></span>
                                                                                                    : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                            : (parseFloat(ae.rate) >= 0.1 && parseFloat(ae.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                                : <span style={{ fontSize: 16, fontStyle: ae?.effect_desired == 1 ? "italic" : '', color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : '', textTransform: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "uppercase" : '' }} dangerouslySetInnerHTML={{ __html: ae?.adverse }}>
                                                                                                                    {/* {ae?.adverse} */}
                                                                                                                </span>}
                                                                                                {ae?.severe == 1 ?
                                                                                                    <span style={{
                                                                                                        color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ?
                                                                                                            'red' : '', fontWeight: ((parseFloat(ae?.rate) > 19.9)) ? 'bold' : '', fontStyle: ae?.effect_desired == 1 ? "italic" : '', color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : ''
                                                                                                    }} >^</span>
                                                                                                    : ""}
                                                                                                {ae?.toxicity_due == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                                                {i !== this.state.NutritionAdvList?.length - 1 ? <b style={{ fontSize: 18 }}>{(i == this.state.NutritionAdvList?.length - (this.state.NutzeroBlank?.length + 1)) ? '. ' : ', '}</b> : ''}
                                                                                            </span>
                                                                                            )}
                                                                                            {this.state.NutriBool == false ? <span onClick={(e) => {
                                                                                                this.setState({ NutriBool: !this.state.NutriBool })
                                                                                            }} className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}>&#8211;</span> : ""}
                                                                                        </span> : ""}
                                                                                    </> : <span>
                                                                                        {this.state.NutritionCommonTrue?.map((ae, i) => <span>
                                                                                            {(ae.rate == 0 && this.state.NutritionCommonTrue?.length - this.state.NutzeroBlank?.length == i) ? <b>Rare: </b> : ''}
                                                                                            {ae?.change == 'Increase' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }}>&#9650;</span>
                                                                                                : ae?.change == 'Decrease' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} >&#9660;</span>
                                                                                                    : ae?.change == 'up or down' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} >&#9660;/&#9650;</span>
                                                                                                        : ae?.change == "" ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} ></span> : ''}
                                                                                            {parseFloat(ae?.rate) > 19.9 ? <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                : (parseFloat(ae?.rate) >= 15 && parseFloat(ae?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                // : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: ae?.adverse }}></span>    
                                                                                                : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                        : (parseFloat(ae.rate) >= 0.1 && parseFloat(ae.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                            : <span style={{ fontSize: 16, fontStyle: ae?.effect_desired == 1 ? "italic" : '', color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : '', textTransform: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "uppercase" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}>
                                                                                                                {/* {ae?.adverse} */}
                                                                                                            </span>}
                                                                                            {ae?.severe == 1 ?
                                                                                                <span style={{
                                                                                                    color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ?
                                                                                                        'red' : '', fontWeight: ((parseFloat(ae?.rate) > 19.9)) ? 'bold' : '', fontStyle: ae?.effect_desired == 1 ? "italic" : '', color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : ''
                                                                                                }} >^</span>
                                                                                                : ""}
                                                                                            {ae?.toxicity_due == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                                            {i !== this.state.NutritionCommonTrue?.length - 1 ? <b style={{ fontSize: 18 }}>{(i == this.state.NutritionCommonTrue?.length - (this.state.NutzeroBlank?.length + 1)) ? '. ' : ', '}</b> : ''}
                                                                                        </span>
                                                                                        )}
                                                                                        {this.state.viewList?.nutrition_adverse_effects == 2 ? <span onClick={(e) => {
                                                                                            this.setState({ NutriBool: !this.state.NutriBool }, () => {
                                                                                                if (this.state.NutriBool == true) {
                                                                                                    this.setState({ NutritionCommonTrue: this.state.NutritionAdvList });
                                                                                                } else {
                                                                                                    this.setState({ NutritionCommonTrue: this.state.commNutrition });
                                                                                                }
                                                                                            });
                                                                                        }}>
                                                                                            {this.state.NutriBool == true ? <span className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}>+</span> : <span className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}>&#8211;</span>}
                                                                                        </span> : ""}
                                                                                    </span>}
                                                                                </p>
                                                                            </p>
                                                                        </div>
                                                                    </> : ''
                                                                }
                                                                {
                                                                    (this.state.adverseEffectList?.length > 0 || this.state.commonOther > length > 0) ? <>
                                                                        <div className="drug-info mb-3 d-flex" style={{ marginTop: '-22px', color: 'black', fontSize: 16 }}>
                                                                            {
                                                                                this.state.OtherAdverseEffectsImg[0]?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Other Adverse Effects" : "Other Adverse Effects") ?
                                                                                    <>
                                                                                        <ReactTooltip id="Adverse Effects" place="left" type="dark" effect="float" />
                                                                                        <img src={this.state.OtherAdverseEffectsImg[0].iconImg} width={40} height={40} className=' icon-img'
                                                                                            data-tip='Other Adverse Effects' data-for='Adverse Effects'
                                                                                        />
                                                                                    </>
                                                                                    : <DummyImage width={40} height={40} placeholder='Dummy Image' className='  icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                            } 
                                                                            <p className="" style={{ marginLeft: 12 }}>
                                                                                <p className='' style={{ fontSize: 16, marginTop: '-14px', marginLeft: 6 }}>
                                                                                    {this.state.viewList?.other_adverse_effects == 0 ?
                                                                                        <>
                                                                                            {this.state.advBool == true ? <span onClick={(e) => {
                                                                                                this.setState({ advBool: !this.state.advBool })
                                                                                            }} className='badge badge-secondary other-1' style={{ cursor: 'pointer' }}>+</span> : ""}

                                                                                            {this.state.advBool == false ? <span>
                                                                                                {this.state.adverseEffectList?.map((ae, i) =>
                                                                                                    <span>
                                                                                                        {(ae.rate == 0 && this.state.adverseEffectList?.length - this.state.OtherzeroBlank?.length == i) ? <b>Rare: </b> : ''}
                                                                                                        {ae?.change == 'Increase' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }}>&#9650;</span>
                                                                                                            : ae?.change == 'decreased' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} >&#9660;</span>
                                                                                                                : ae?.change == 'up or down' ?
                                                                                                                    <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} >&#9660;/&#9650;</span>
                                                                                                                    : ae?.change == "" ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} ></span> : ''}

                                                                                                        {parseFloat(ae?.rate) > 19.9 ? <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                            : (parseFloat(ae?.rate) >= 15 && parseFloat(ae?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                            // : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: ae?.adverse }}></span>   
                                                                                                            : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                                    : (parseFloat(ae.rate) >= 0.1 && parseFloat(ae.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                                        : <span style={{ fontSize: 16, fontStyle: ae?.effect_desired == 1 ? "italic" : '', textTransform: (parseFloat(ae?.rate) >= 10 && parseFloat(ae?.rate) <= 19.9) ? "uppercase" : '', color: (parseFloat(ae?.rate) >= 10 && parseFloat(ae?.rate) <= 19.9) ? "red" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>}
                                                                                                        {ae?.severe == 1 ?
                                                                                                            <span style={{
                                                                                                                color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ?
                                                                                                                    'red' : '', fontWeight: ((parseFloat(ae?.rate) > 19.9)) ? 'bold' : '', fontStyle: ae?.effect_desired == 1 ? "italic" : '', color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : ''
                                                                                                            }} >^</span>
                                                                                                            : ""}
                                                                                                        {ae?.toxicity_due == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                                                        {i !== this.state.adverseEffectList?.length - 1 ? <b style={{ fontSize: 18 }}>
                                                                                                            {(ae?.direction != 'Increase' || ae?.direction != 'Decrease' || ae?.direction != 'up or down') ? <span>{(i == this.state.adverseEffectList?.length - (this.state.OtherzeroBlank?.length + 1)) ? '. ' : ', '}</span> : <>{(i == this.state.NutritionAdvList?.length - (this.state.NutzeroBlank?.length + 1)) ? '.' : ','}</>}
                                                                                                        </b> : ''}
                                                                                                    </span>
                                                                                                )}
                                                                                                {this.state.advBool == false ? <span onClick={(e) => {
                                                                                                    this.setState({ advBool: !this.state.advBool })
                                                                                                }} className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}>&#8211;</span> : ""}
                                                                                            </span> : ""}
                                                                                        </> : <span>
                                                                                            {this.state.otherCommonTrue?.map((ae, i) =>
                                                                                                <span>
                                                                                                    {(ae.rate == 0 && this.state.otherCommonTrue?.length - this.state.OtherzeroBlank?.length == i) ? <b>Rare: </b> : ''}
                                                                                                    {ae?.change == 'Increase' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }}>&#9650;</span>
                                                                                                        : ae?.change == 'Decrease' ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} >&#9660;</span>
                                                                                                            : ae?.change == 'up or down' ?
                                                                                                                <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} >&#9660;/&#9650;</span>
                                                                                                                : ae?.change == "" ? <span style={{ color: (parseFloat(ae?.rate) >= 4.9) ? "red" : 'black' }} ></span> : ''}

                                                                                                    {parseFloat(ae?.rate) > 19.9 ? <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                        : (parseFloat(ae?.rate) >= 15 && parseFloat(ae?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                            
                                                                                                        // : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: ae?.adverse }}></span>
                                                                                                        : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                                : (parseFloat(ae.rate) >= 0.1 && parseFloat(ae.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>
                                                                                                                    : <span style={{ fontSize: 16, fontStyle: ae?.effect_desired == 1 ? "italic" : '', textTransform: (parseFloat(ae?.rate) >= 10 && parseFloat(ae?.rate) <= 19.9) ? "uppercase" : '', color: (parseFloat(ae?.rate) >= 10 && parseFloat(ae?.rate) <= 19.9) ? "red" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ae?.adverse) }}></span>}
                                                                                                    {ae?.severe == 1 ?
                                                                                                        <span style={{
                                                                                                            color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ?
                                                                                                                'red' : '', fontWeight: ((parseFloat(ae?.rate) > 19.9)) ? 'bold' : '', fontStyle: ae?.effect_desired == 1 ? "italic" : '', color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : ''
                                                                                                        }} >^</span>
                                                                                                        : ""}
                                                                                                    {ae?.toxicity_due == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                                                    {i !== this.state.otherCommonTrue?.length - 1 ? <b style={{ fontSize: 18 }}>
                                                                                                        {(ae?.direction != 'Increase' || ae?.direction != 'Decrease' || ae?.direction != 'up or down') ? <span>{(i == this.state.otherCommonTrue?.length - (this.state.OtherzeroBlank?.length + 1)) ? '. ' : ', '}</span> : <span>{(i == this.state.NutritionAdvList?.length - (this.state.NutzeroBlank?.length + 1)) ? '.' : ','}</span>}
                                                                                                    </b> : ''}
                                                                                                </span>
                                                                                            )}
                                                                                            {this.state.viewList?.other_adverse_effects == 2 ? <span onClick={(e) => {
                                                                                                this.setState({ advBool: !this.state.advBool })
                                                                                                if (this.state.advBool == false) {
                                                                                                    this.setState({ otherCommonTrue: this.state.adverseEffectList });
                                                                                                } else {
                                                                                                    this.setState({ otherCommonTrue: this.state.commonOther });
                                                                                                }
                                                                                            }}>
                                                                                                {this.state.advBool == true ? <span className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}>+</span> : <span className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}>&#8211;</span>}
                                                                                            </span> : ""}
                                                                                        </span>}
                                                                                </p>
                                                                            </p>
                                                                        </div>
                                                                    </> : ''
                                                                }

                                                                {
                                                                    (this.state.labParameterList?.length > 0 || this.state.commLab?.length > 0) ? <>
                                                                        <div className="drug-info mb-3 d-flex" style={{ marginTop: '-28px', color: 'black', fontSize: 16 }}>
                                                                            {
                                                                                this.state.LabParametersImg[0]?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Lab Parameters" : 'Lab Parameters') ?
                                                                                    <>
                                                                                        <ReactTooltip id="Lab Parameters" place="left" type="dark" effect="float" />
                                                                                        <img src={this.state.LabParametersImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img mt-1'
                                                                                            data-tip='Lab Parameters' data-for='Lab Parameters'
                                                                                        />
                                                                                    </>
                                                                                    : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                            }
                                                                            <p className="" style={{ marginLeft: 8 }}>
                                                                                <p className='' style={{ fontSize: 16, marginTop: '-14px', marginLeft: '-10px' }}>
                                                                                    {this.state.viewList?.lab_parameters == 0 ? <>
                                                                                        {this.state.labBool == true ? <span onClick={(e) => {
                                                                                            this.setState({ labBool: !this.state.labBool })
                                                                                        }} className='badge badge-secondary lab-1' style={{ cursor: 'pointer' }}>+</span> : ""}

                                                                                        {this.state.labBool == false ? <span>
                                                                                            {
                                                                                                this.state.labParameterList?.map((lb, i) =>
                                                                                                    <span>
                                                                                                        {parseFloat(lb.rate) == 0 ? <span><span style={{ fontSize: 17, color: 'black' }}>
                                                                                                            {(lb.rate == 0 && this.state.labParameterList?.length - this.state.LabzeroBlank?.length == i) ? <b>Rare: </b> : ''}
                                                                                                        </span></span> : ""}


                                                                                                        {/* start Changes false  */}

                                                                                                        {parseFloat(lb?.rate) > 19.9 ?
                                                                                                            <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }} >{lb?.change_false == 1 ? 'false ' : ""}</span>
                                                                                                            : (parseFloat(lb?.rate) >= 15 && parseFloat(lb?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }} >{lb?.change_false == 1 ? 'false ' : ""}</span>
                                                                                                                : (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: '', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }} >{lb?.change_false == 1 ? 'false ' : ""}</span>
                                                                                                                    : (parseFloat(lb.rate) >= 0.1 && parseFloat(lb.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }} >{lb?.change_false == 1 ? 'false ' : ""}</span>
                                                                                                                        : <span style={{ fontSize: 16, fontStyle: lb?.effect_desired == 1 ? "italic" : '', textTransform: (parseFloat(lb.rate) >= 10 && parseFloat(lb.rate) <= 19.9) ? "uppercase" : '', color: (parseFloat(lb.rate) >= 10 && parseFloat(lb.rate) <= 19.9) ? "red" : '' }} >{lb?.change_false == 1 ? 'false ' : ""}</span>}

                                                                                                        {/* End Changes false  */}


                                                                                                        {lb?.direction == 'increased' ? <i style={{ color: (parseFloat(lb.rate) >= 4.9) ? "red" : 'black' }}>&#9650;</i>
                                                                                                            : lb?.direction == 'decreased' ? <i style={{ color: (parseFloat(lb.rate) >= 4.9) ? "red" : 'black' }} >&#9660;</i>
                                                                                                                : lb?.direction == 'Positive' ? <i style={{ color: (parseFloat(lb.rate) >= 4.9) ? "red" : 'black' }} ><b>&#43;</b>&nbsp;</i>
                                                                                                                    : lb?.direction == 'Negative' ? <i style={{ color: (parseFloat(lb.rate) >= 4.9) ? "red" : 'black' }} ><b>&#8211;</b>&nbsp;</i>
                                                                                                                        : lb?.direction == 'up or down' ?
                                                                                                                            <span style={{ color: (parseFloat(lb.rate) >= 4.9) ? "red" : 'black' }} >&#9660;/&#9650;</span>
                                                                                                                            : ''}
                                                                                                        {parseFloat(lb?.rate) > 19.9 ?
                                                                                                            <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(lb?.parameter) }}></span>
                                                                                                            : (parseFloat(lb?.rate) >= 15 && parseFloat(lb?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }}dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(lb?.parameter) }}></span>
                                                                                                                : (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: '', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }}dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(lb?.parameter) }}></span>
                                                                                                                    : (parseFloat(lb.rate) >= 0.1 && parseFloat(lb.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }}>{lb?.parameter}</span>
                                                                                                                        : <span style={{ fontSize: 16, fontStyle: lb?.effect_desired == 1 ? "italic" : '', textTransform: (parseFloat(lb.rate) >= 10 && parseFloat(lb.rate) <= 19.9) ? "uppercase" : '', color: (parseFloat(lb.rate) >= 10 && parseFloat(lb.rate) <= 19.9) ? "red" : '' }}dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(lb?.parameter) }}></span>}
                                                                                                        {lb?.change_false == 1 ?
                                                                                                            <span style={{
                                                                                                                color: ((parseFloat(lb?.rate) > 19.9) || (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9)) ?
                                                                                                                    'red' : '', fontWeight: ((parseFloat(lb?.rate) > 19.9)) ? 'bold' : '', fontStyle: lb?.effect_desired == 1 ? "italic" : '', textTransform: (parseFloat(lb.rate) >= 10 && parseFloat(lb.rate) <= 19.9) ? "uppercase" : ''
                                                                                                            }} ></span>
                                                                                                            : ""}
                                                                                                        {lb?.toxic_range == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                                                        {i !== this.state.labParameterList?.length - 1 ? <b style={{ fontSize: 18, marginLeft: '0px' }}>
                                                                                                            {(lb?.direction != 'increased' || lb?.direction != 'decreased' || lb?.direction != 'Positive' || lb?.direction != 'Negative' || lb?.direction != 'up or down') ?
                                                                                                                <span>{(i == this.state.labParameterList?.length - (this.state.LabzeroBlank?.length + 1)) ? '. ' : ', '}</span> : <span>{(i == this.state.labParameterList?.length - (this.state.LabzeroBlank?.length + 1)) ? '. ' : ','}</span>}</b> : ''}
                                                                                                    </span>
                                                                                                )
                                                                                            }
                                                                                            {this.state.labBool == false ? <span onClick={(e) => {
                                                                                                this.setState({ labBool: !this.state.labBool })
                                                                                            }} className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}>&#8211;</span> : ""}
                                                                                        </span> : ""}
                                                                                    </> : <span>
                                                                                        {
                                                                                            (this.state.commLabTrue)?.map((lb, i) =>
                                                                                                <span>
                                                                                                    {parseFloat(lb.rate) == 0 ? <span><span style={{ fontSize: 17, color: 'black' }}>
                                                                                                        {(lb.rate == 0 && this.state.commLabTrue?.length - this.state.LabzeroBlank?.length == i) ? <b>Rare: </b> : ''}
                                                                                                    </span></span> : ""}

                                                                                                    {/* start Changes false  */}

                                                                                                    {parseFloat(lb?.rate) > 19.9 ?
                                                                                                        <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }} >{lb?.change_false == 1 ? 'false ' : ""}</span>
                                                                                                        : (parseFloat(lb?.rate) >= 15 && parseFloat(lb?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }} >{lb?.change_false == 1 ? 'false ' : ""}</span>
                                                                                                            : (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: '', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }} >{lb?.change_false == 1 ? 'false ' : ""}</span>
                                                                                                                : (parseFloat(lb.rate) >= 0.1 && parseFloat(lb.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }} >{lb?.change_false == 1 ? 'false ' : ""}</span>
                                                                                                                    : <span style={{ fontSize: 16, fontStyle: lb?.effect_desired == 1 ? "italic" : '', textTransform: (parseFloat(lb.rate) >= 10 && parseFloat(lb.rate) <= 19.9) ? "uppercase" : '', color: (parseFloat(lb.rate) >= 10 && parseFloat(lb.rate) <= 19.9) ? "red" : '' }} >{lb?.change_false == 1 ? 'false ' : ""}</span>}

                                                                                                    {/* End Changes false  */}

                                                                                                    {lb?.direction == 'increased' ? <i style={{ color: (parseFloat(lb.rate) >= 4.9) ? "red" : 'black' }}>&#9650;</i>
                                                                                                        : lb?.direction == 'decreased' ? <i style={{ color: (parseFloat(lb.rate) >= 4.9) ? "red" : 'black' }} >&#9660;</i>
                                                                                                            : lb?.direction == 'Positive' ? <i style={{ color: (parseFloat(lb.rate) >= 4.9) ? "red" : 'black' }} ><b>&#43;</b>&nbsp;</i>
                                                                                                                : lb?.direction == 'Negative' ? <i style={{ color: (parseFloat(lb.rate) >= 4.9) ? "red" : 'black' }} ><b>&#8211;</b>&nbsp;</i>
                                                                                                                    : lb?.direction == 'up or down' ?
                                                                                                                        <i style={{ color: (parseFloat(lb.rate) >= 4.9) ? "red" : 'black' }} >&#9660;/&#9650;</i>
                                                                                                                        : ''}
                                                                                                    {parseFloat(lb?.rate) > 19.9 ?
                                                                                                        <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(lb?.parameter) }}></span>
                                                                                                        : (parseFloat(lb?.rate) >= 15 && parseFloat(lb?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(lb?.parameter) }}></span>
                                                                                                            : (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: '', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(lb?.parameter) }}></span>
                                                                                                                : (parseFloat(lb.rate) >= 0.1 && parseFloat(lb.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(lb?.parameter) }}></span>
                                                                                                                    : <span style={{ fontSize: 16, fontStyle: lb?.effect_desired == 1 ? "italic" : '', textTransform: (parseFloat(lb.rate) >= 10 && parseFloat(lb.rate) <= 19.9) ? "uppercase" : '', color: (parseFloat(lb.rate) >= 10 && parseFloat(lb.rate) <= 19.9) ? "red" : '' }} dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(lb?.parameter) }}></span>}
                                                                                                    {lb?.change_false == 1 ?
                                                                                                        <span style={{
                                                                                                            color: ((parseFloat(lb?.rate) > 19.9) || (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9)) ?
                                                                                                                'red' : '', fontWeight: ((parseFloat(lb?.rate) > 19.9)) ? 'bold' : '', fontStyle: lb?.effect_desired == 1 ? "italic" : '', textTransform: (parseFloat(lb.rate) >= 10 && parseFloat(lb.rate) <= 19.9) ? "uppercase" : ''
                                                                                                        }} ></span>
                                                                                                        : ""}
                                                                                                    {lb?.toxic_range == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                                                    {i !== this.state.commLabTrue?.length - 1 ? <b style={{ fontSize: 18, marginLeft: '0px' }}>
                                                                                                        {(lb?.direction != 'increased' || lb?.direction != 'decreased' || lb?.direction != 'Positive' || lb?.direction != 'Negative' || lb?.direction != 'up or down') ? <span>{(i == this.state.commLabTrue?.length - (this.state.LabzeroBlank?.length + 1)) ? '. ' : ', '}</span> :
                                                                                                            <span>{(i == this.state.commLabTrue?.length - (this.state.LabzeroBlank?.length + 1)) ? '. ' : ','}</span>}</b> : ''}
                                                                                                </span>
                                                                                            )
                                                                                        }
                                                                                        {this.state.viewList?.lab_parameters == 2 ? <span onClick={(e) => {
                                                                                            this.setState({ labBool: !this.state.labBool });
                                                                                            if (this.state.labBool == false) {
                                                                                                this.setState({ commLabTrue: this.state.labParameterList });
                                                                                            } else {
                                                                                                this.setState({ commLabTrue: this.state.commLab });
                                                                                            }
                                                                                        }}>
                                                                                            {this.state.labBool == true ? <span className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}>+</span> : <span className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}>&#8211;</span>}
                                                                                        </span> : ""}
                                                                                    </span>}
                                                                                </p>
                                                                            </p>
                                                                        </div>
                                                                    </> : ''
                                                                }
                                                                {
                                                                    this.state.warningList[0]?.monitor_param != "" ? <>
                                                                        <div className="drug-info mb-3 d-flex" style={{ marginTop: '-21px', color: 'black', fontSize: 16 }}>
                                                                            {
                                                                                this.state.MonitoringImg[0]?.body_system == "Monitoring" ?
                                                                                    <>
                                                                                        <img src={this.state.MonitoringImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                                            data-tip="Monitoring Parameters" data-for='Monitoring Parameters'
                                                                                        />
                                                                                        <ReactTooltip id="Monitoring Parameters" place="left" type="dark" effect="float" />
                                                                                    </>
                                                                                    : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                            }
                                                                            <p style={{ marginLeft: 2 }}>
                                                                                <p className='mt-2' style={{ fontSize: 16 }}>
                                                                                {this.state.country_ids == 2 ?
                                                                            <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.monitor_param?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.monitor_param : this.state.warningList[0]?.monitor_param )}}></div> :
                                                                            <div dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(this.state.warningList[0]?.monitor_param?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.monitor_param?.split('Canadian labelling:')[0] : this.state.warningList[0]?.monitor_param) }}></div>
                                                                        }
                                                                                </p>
                                                                            </p>
                                                                        </div>
                                                                        <div style={{ height: 11 }}></div>
                                                                    </> : ''
                                                                }
                                                                {
                                                                    (this.state.xrefList?.length) ? <>
                                                                        <div className="drug-info mb-3 d-flex" style={{ marginTop: '-28px', color: 'black', fontSize: 16 }}>
                                                                            {
                                                                                this.state.CrossReferenceImg[0]?.body_system == "Cross Reference" ?
                                                                                    <>
                                                                                        <ReactTooltip id="Cross Reference" place="left" type="dark" effect="float" />
                                                                                        <img src={this.state.CrossReferenceImg[0].iconImg} width={40} height={40} className=' mr-3 mt-1 icon-img'
                                                                                            data-for='Cross Reference' data-tip='Cross Reference'
                                                                                        />
                                                                                    </>
                                                                                    : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                            }
                                                                            <p style={{ fontSize: 16, marginLeft: 2, marginTop: 10 }}>
                                                                                {
                                                                                    this.state.viewList?.cross_references === 1 ? <span>{
                                                                                        this.state.xrefList?.sort((a, b) => (a.xref > b.xref) ? 1 : ((b.xref > a.xref) ? -1 : 0))?.map((xref, i) =>
                                                                                            <span key={i}>
                                                                                                <a id="crossRefId"
                                                                                                    onClick={(e) => {
                                                                                                        let info = localStorage.getItem("setUser")
                                                                                                        let userInfo = JSON.parse(info);
                                                                                                        if (xref?.country_id?.includes(parseInt(userInfo?.userInfo?.country_view_id)) == true) {
                                                                                                            localStorage.setItem("gen_ids", xref?.generic_id);
                                                                                                            window.open(`#/account/drug-details?generic_id=${xref?.generic_id}`, '_blank');
                                                                                                        } else {
                                                                                                            toast.error('Generic not available in your selected country or you don’t have the module installed that contains that generic drug.', { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
                                                                                                        }
                                                                                                    }}
                                                                                                >

                                                                                                <span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(xref?.xref)}}></span>
                                                                                                </a>
                                                                                                {i !== this.state.xrefList?.length - 1 ? <span style={{ fontSize: 18, marginLeft: '0px' }}>, </span> : ''}

                                                                                            </span>)
                                                                                    }</span> : <span>

                                                                                        {this.state.crossFlag == true ? <>{
                                                                                            this.state.xrefList?.sort((a, b) => (a.xref > b.xref) ? 1 : ((b.xref > a.xref) ? -1 : 0))?.map((xref, i) =>
                                                                                                <span key={i}>
                                                                                                    <a id="crossRefId"
                                                                                                        onClick={(e) => {
                                                                                                            let info = localStorage.getItem("setUser")
                                                                                                            let userInfo = JSON.parse(info);
                                                                                                            if (xref?.country_id?.includes(parseInt(userInfo?.userInfo?.country_view_id)) == true) {
                                                                                                                localStorage.setItem("gen_ids", xref?.generic_id);
                                                                                                                window.open(`#/account/drug-details?generic_id=${xref?.generic_id}`, '_blank');
                                                                                                            } else {
                                                                                                                toast.error('Generic not available in your selected country or you don’t have the module installed that contains that generic drug.', { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
                                                                                                            }
                                                                                                        }}
                                                                                                    >

<span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(xref?.xref)}}></span>
                                                                                                    </a>
                                                                                                    {i !== this.state.xrefList?.length - 1 ? <span style={{ fontSize: 18, marginLeft: '0px' }}>, </span> : ''}

                                                                                                </span>)
                                                                                        }
                                                                                            <span className='badge badge-secondary' style={{ cursor: 'pointer', marginLeft: 3 }} onClick={() => {
                                                                                                this.setState({ crossFlag: !this.state.crossFlag })
                                                                                            }}> &#8722;</span></> : <>
                                                                                            <span className='badge badge-secondary' style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                this.setState({ crossFlag: !this.state.crossFlag })
                                                                                            }}>+</span>
                                                                                        </>}

                                                                                    </span>
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </> : ''
                                                                }
                                                                {/* {this.state.viewList?.citations == 1 ? <> */}
                                                                <div className="drug-info mb-3 d-flex" id="headingCitation" onClick={() => {
                                                                    setTimeout(() => {
                                                                        this.setState({ isCrose: !this.state.isCrose })
                                                                    }, 100);
                                                                }}
                                                                    data-target="#collapseCitaction" aria-expanded="false" aria-controls="collapseAE"
                                                                    style={{ cursor: 'pointer', marginTop: '-16px', color: 'black' }}>

                                                                    {
                                                                        this.state.CitationsImg[0]?.body_system == "Citations" ?
                                                                            <>
                                                                                <img src={this.state.CitationsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                                    data-tip="Citations" data-for='Citations'
                                                                                />
                                                                                <ReactTooltip id="Citations" place="left" type="dark" effect="float" />
                                                                            </>
                                                                            : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                                    }
                                                                    &nbsp; &nbsp;
                                                                    <p style={{ marginLeft: "-10px", marginTop: 10, fontSize: 16 }} onClick={() => {
                                                                        if (this.state.citationFlag1 == false) {
                                                                            this.setState({ citationFlag: !this.state.citationFlag });
                                                                        }
                                                                    }}>
                                                                        {this.state.viewList?.citations == 1 ? <>
                                                                            {this.state.citationList?.length > 1 ? <>{this.state.country_ids == 2 ? <span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery("Product Monograph, Citations and Treatment Guidelines") }}></span> : <span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery("Prescribing Information, Citations and Treatment Guidelines") }}></span>}</> :
                                                                                <span className='mt-2'>
                                                                                    <a className='link' id='cita-id' href={this.state.citationList[0]?.type == "URL" ? `${this.state.citationList[0]?.url}` : `${this.state.pmidUrl}/${this.state.citationList[0]?.pm_id}`} target='_blank'>  
                                                                                  {this.highlightSearchQuery(this.state.citationList[0]?.citation)}</a>
                                                                                </span>
                                                                            }   &nbsp;&nbsp;
                                                                            <span>
                                                                                {this.state.citationList?.length > 1 ? <>
                                                                                    {(this.state.isCrose == false) ? <>
                                                                                        <span id="cit-badge-id" className='badge badge-secondary' style={{ color: 'whitesmoke' }}>+</span>
                                                                                    </> : <>
                                                                                        <span id="cit-badge-id" className='badge badge-secondary' style={{ color: 'whitesmoke' }}>&#8211;</span></>}
                                                                                </> : ""}

                                                                            </span>
                                                                        </> : <>{this.state.citationFlag1 == false ? <><span onClick={() => {
                                                                            this.setState({ citationFlag1: true })
                                                                        }} id="cit-badge-id" className='badge badge-secondary' style={{ color: 'whitesmoke' }}>+</span></> : ""}{this.state.citationFlag1 == true ? <>
                                                                            {this.state.citationList?.length > 1 ? <>{this.state.country_ids == 2 ? "Product Monograph, Citations and Treatment Guidelines" : 'Prescribing Information, Citations and Treatment Guidelines'}</> : <span className='mt-2'>
                                                                                <a className='link' id='cita-id' href={this.state.citationList[0]?.type == "URL" ? `${this.state.citationList[0]?.url}` : `${this.state.pmidUrl}/${this.state.citationList[0]?.pm_id}`} target='_blank'>{this.state.citationList[0]?.citation}</a>
                                                                            </span>}
                                                                            {
                                                                                (this.state.citationList.length == 1 && this.state.viewList?.citations == 0 && this.state.citationFlag1 == true) ?
                                                                                    <span onClick={() => {
                                                                                        this.setState({ citationFlag1: false, citationFlag: false })
                                                                                    }} id="cit-badge-id" className='badge badge-secondary' style={{ color: 'whitesmoke', cursor: 'pointer', marginLeft: 5 }}>&#8211;</span> : ''
                                                                            }
                                                                        </>
                                                                            : ""}</>}
                                                                    </p>
                                                                </div>
                                                                {this.state.citationList?.length > 1 && this.state.citationFlag == true ? <>
                                                                    <div id="collapseCitaction" aria-labelledby="headingCitation" data-parent="#drugDetailsAccordian" >
                                                                        {this.state.citationList?.map((ct, i) =>
                                                                            <>
                                                                                <div className='row' style={{ zIndex: 1000 }}>
                                                                                    <p style={{ fontSize: 15, paddingBottom: 12, marginLeft: 20, marginTop: '-25px' }}>
                                                                                        <span className='mt-2'>
                                                                                        <a className='link' id='cita-id' href={ct?.type == "URL" ? `${ct?.url}` : `${this.state.pmidUrl}/${ct?.pm_id}`} target='_blank'><span dangerouslySetInnerHTML={{ __html: this.highlightSearchQuery(ct?.citation)}}></span></a>
                                                                                        </span>
                                                                                        {(this.state.citationList.length - 1 == i && this.state.citationFlag1 == true) ? <>
                                                                                            &nbsp;&nbsp;<span onClick={() => {
                                                                                                this.setState({ citationFlag1: false, citationFlag: false })
                                                                                            }} id="cit-badge-id" className='badge badge-secondary' style={{ color: 'whitesmoke', cursor: 'pointer' }}>&#8211;</span>
                                                                                        </> : ""}
                                                                                    </p>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </> : ""}
                                                            </div>
                                                            <div className='row'>
                                                                <p className='ml-4'>Last updated on {this.state.getGenericDetails?.updated_dt}</p>
                                                            </div>
                                                            <div className='row ml-2'>
                                                            © 2023 &nbsp; <a href='https://web.drugnutritioninteractions.com/' target='_blank'>  Drug Nutrition Interactions, Ltd. </a>
                                                            </div>
                                                        </> : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                }
                            </>
                        }

                    </div>
                </section>
            </div>
        )
    }
}


export default DrugList;