
import React from "react"
import not from '../../../src/assets/no-drugs.png'

const PageNotFound=()=>{

    return <>
    <br/><br/>
    <br/><br/>
    <br/><br/><br/><br/><br/><br/>
    <img src={not} width='100'/><br/><br/>
    <h3 style={{color:'#FD3018'}}>Page Not Found....</h3>
    </>
}

export default PageNotFound