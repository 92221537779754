import React from 'react'

function Sidebar() {
  return (
    <div>
        <aside className="inset-lg-left-20 row-50 aside-wrapper">
            {/* <div className="aside-item">
                <form className="form-search rd-search rd-search-sidebar" action="search-results.html" method="GET">
                <div className="form-wrap">
                    <label className="form-label form-search-label rd-input-label" htmlFor="rd-search-form-input">Search...</label>
                    <input className="form-search-input form-input" id="rd-search-form-input" type="text" name="s" autocomplete="off" />
                </div>
                <button className="form-search-submit" type="submit"><span className="icon novi-icon icon-xs fa fa-search text-gray"></span></button>
                </form>
            </div> */}
            <div className="aside-item">
                <h3>Popular posts</h3>
                <div className="post-wrapper row-30 offset-top-13">
                <div className="post-item">
                    <div className="unit align-items-center flex-row unit-spacing-sm">
                    <div className="unit-left"><a className="thumbnail-classic" href="blog-post.html"><span className="thumbnail-classic-img-wrap">
                        <img className="img-responsive center-block" src="images/blog/post-10-80x80.jpg" width="80" height="80" alt="" /></span></a></div>
                    <div className="unit-body">
                        <p className="font-weight-bold"><a className="link-decoration-none text-hover-primary text-gray" href="blog-post.html">5 Simple smoothie recipes + free challenge</a></p>
                        {/* <!-- List Inline--> */}
                        <ul className="list-inline list-inline-dashed">
                        <li>January 16, 2019</li>
                        <li className="p"><a className="link-decoration-none text-hover-primary text-gray-light" href="/">1 Comment</a></li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="post-item">
                    <div className="unit align-items-center flex-row unit-spacing-sm">
                    <div className="unit-left"><a className="thumbnail-classic" href="blog-post.html"><span className="thumbnail-classic-img-wrap">
                        <img className="img-responsive center-block" src="images/blog/post-11-80x80.jpg" width="80" height="80" alt="" /></span></a></div>
                    <div className="unit-body">
                        <p className="font-weight-bold"><a className="link-decoration-none text-hover-primary text-gray" href="blog-post.html">How does natural food affect your mood?</a></p>
                        {/* <!-- List Inline--> */}
                        <ul className="list-inline list-inline-dashed">
                        <li>January 20, 2019</li>
                        <li className="p"><a className="link-decoration-none text-hover-primary text-gray-light" href="/">2 Comments</a></li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="post-item">
                    <div className="unit align-items-center flex-row unit-spacing-sm">
                    <div className="unit-left"><a className="thumbnail-classic" href="blog-post.html"><span className="thumbnail-classic-img-wrap"><img className="img-responsive center-block" src="images/blog/post-12-80x80.jpg" width="80" height="80" alt="" /></span></a></div>
                    <div className="unit-body">
                        <p className="font-weight-bold"><a className="link-decoration-none text-hover-primary text-gray" href="blog-post.html">Chia seed pudding: easy healthy breakfast idea</a></p>
                        {/* <!-- List Inline--> */}
                        <ul className="list-inline list-inline-dashed">
                        <li>January 22, 2019</li>
                        <li className="p"><a className="link-decoration-none text-hover-primary text-gray-light" href="/">2 Comments</a></li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {/* <div className="aside-item">
                <h3>Archive</h3>
                <ul className="list list-marked font-weight-bold offset-top-13">
                <li><a className="link-decoration-none" href="/">January 2019</a></li>
                <li><a className="link-decoration-none" href="/">February 2019</a></li>
                <li><a className="link-decoration-none" href="/">March 2019</a></li>
                <li><a className="link-decoration-none" href="/">April 2019</a></li>
                <li><a className="link-decoration-none" href="/">Jul 2019</a></li>
                </ul>
            </div> */}
            {/* <div className="aside-item">
                <h3>Tags</h3>
                <div className="group-sm offset-top-13"><a className="btn btn-tag btn-primary-gray-outline" href="/">Weight Loss</a><a className="btn btn-tag btn-primary-gray-outline" href="/">Diet</a><a className="btn btn-tag btn-primary-gray-outline" href="/">Fitness</a><a className="btn btn-tag btn-primary-gray-outline" href="/">Pilates</a><a className="btn btn-tag btn-primary-gray-outline" href="/">Massage</a><a className="btn btn-tag btn-primary-gray-outline" href="/">Personal Training</a><a className="btn btn-tag btn-primary-gray-outline" href="/">Fruit</a><a className="btn btn-tag btn-primary-gray-outline" href="/">Health Coach</a><a className="btn btn-tag btn-primary-gray-outline" href="/">Vegetables</a></div>
            </div>
            <div className="aside-item"><a className="thumbnail-classic" href="/"><span className="thumbnail-classic-img-wrap"><img className="img-responsive center-block" src="images/banner-01-350x500.jpg" width="350" height="500" alt="" /></span></a></div> */}
        </aside>
    </div>
  )
}

export default Sidebar