
import React from "react";
import { toast } from "react-toastify";
import PageHeader from "../../components/PageHeader/PageHeader";
import { SEARCH_API } from "../../services/Api/searching";
import { connect, useDispatch } from 'react-redux';
import { setFlag } from '../../redux/actions/product';

class ViewCustomization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        if (localStorage.getItem("setUser") != null) {
            // document.getElementById('cit-badge-id').style.display = "none";
            // document.getElementsByTagName('label').style.cursor='pointer'
            this.getIconList()
            if (this.state.userInfo?.country_view_id == 1) {
                setTimeout(() => {
                    document.getElementById('usId').checked = true
                }, 500)
            }
            if (this.state.userInfo?.country_view_id == 2) {
                setTimeout(() => {
                    document.getElementById('canadaId').checked = true
                }, 500)
            }
            let By = localStorage.getItem("setUser");
            By = JSON.parse(By);
            this.setState({ userInfo: By?.userInfo }, () => {
                this.getUserDetails(this.state.userInfo?.user_id);
            })
        }
    }

    getUserDetails(id) {
        SEARCH_API?.getUserDetails(id).then(res => {
            if (res && res?.data) {
                this.getView(this.state.userInfo?.user_id)
                this.setState({ userDetails: res?.data[0] }, () => {
                    const str2 = this.state.userDetails?.first_name.charAt(0).toUpperCase() + this.state.userDetails?.first_name.slice(1);
                    this.setState({ userName: str2 }, () => {
                        if (this.state.userInfo?.country_view_id == 1) {
                            setTimeout(() => {
                                document.getElementById('usId').checked = true
                            }, 500)
                        }
                        if (this.state.userInfo?.country_view_id == 2) {
                            setTimeout(() => {
                                document.getElementById('canadaId').checked = true
                            }, 500)
                        }
                    })
                })
            }
        })
    }

    getView(id) {
        SEARCH_API.getViewList(id).then(res => {
            if (res && res?.data) {
                this.setState({ viewList: res?.data[0] }, () => {
                    this.setState({
                        pharm_class: this.state.viewList?.pharm_class,
                        thera_class: this.state.viewList?.thera_class,
                        routes: this.state.viewList?.routes,
                        dose_forms: this.state.viewList?.dose_forms,
                        natural_products: this.state.viewList?.natural_products,
                        renal_warning: this.state.viewList?.renal_warning,
                        protein_binding: this.state.viewList?.protein_binding,
                        dialysis: this.state.viewList?.dialysis,
                        hepatic_warning: this.state.viewList?.hepatic_warning,
                        pregnancy_warnings: this.state.viewList?.pregnancy_warnings,
                        lactation_warnings: this.state.viewList?.lactation_warnings,
                        oral_adverse_effects: this.state.viewList?.oral_adverse_effects,
                        lab_parameters: this.state.viewList?.lab_parameters,
                        cross_references: this.state.viewList?.cross_references,
                        citations: this.state.viewList?.citations,
                        other_adverse_effects: this.state.viewList?.other_adverse_effects,
                        nutrition_adverse_effects: this.state.viewList?.nutrition_adverse_effects,
                        gi_adverse_effects: this.state.viewList?.gi_adverse_effects
                    }, () => {
                        var inputs = document.getElementsByTagName("input");
                        for (var i = 0; i < inputs.length; i++) {
                            if (inputs[i].type == 'checkbox' && inputs[i].value == '1') {
                                inputs[i].checked = true;
                            }
                        }
                    });
                });
            }
        })
    }

    getIconList() {
        SEARCH_API?.getIcons().then(res => {
            if (res && res?.data) {
                this.setState({ iconListData: res?.data }, () => {
                    this?.setState({ "PharmClassImg": this.state.iconListData?.filter(fl => fl?.body_system == "Pharm Class") })
                    this?.setState({ "TheraClassImg": this.state.iconListData?.filter(fl => fl?.body_system == "Thera Class") })
                    this?.setState({ "RoutesImg": this.state.iconListData?.filter(fl => fl?.body_system == "Routes") })
                    this?.setState({ "UsesImg": this.state.iconListData?.filter(fl => fl?.body_system == "Uses") })
                    this?.setState({ "HowtoTakeImg": this.state.iconListData?.filter(fl => fl?.body_system == "How to Take") })
                    this?.setState({ "DietImg": this.state.iconListData?.filter(fl => fl?.body_system == "Diet") })
                    this?.setState({ "GrapefruitInteractionsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Grapefruit Interactions") })
                    this?.setState({ "AlcoholInteractionsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Alcohol Interactions") })
                    this?.setState({ "NaturalproductinteractionsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Natural product interactions") })
                    this?.setState({ "WarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Warnings") })
                    this?.setState({ "DoseFormsImg": this.state.iconListData?.filter(fl => fl?.body_system == "DoseForms") })
                    this?.setState({ "RenalwarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Renal warnings") })
                    this?.setState({ "ProteinbindingImg": this.state.iconListData?.filter(fl => fl?.body_system == "Protein binding") })
                    this?.setState({ "DialysiswarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Dialysis warnings") })
                    this?.setState({ "HepaticwarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Hepatic warnings") })
                    this?.setState({ "PregnancywarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Pregnancy warnings") })
                    this?.setState({ "BreastfeedingWarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Breastfeeding Warnings") })
                    this?.setState({ "OralAdverseEffectsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Oral Adverse Effects") })
                    this?.setState({ "GIAdverseEffectsImg": this.state.iconListData?.filter(fl => fl?.body_system == "GI Adverse Effects") })
                    this?.setState({ "NutritionalAdverseEffectsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Nutritional Adverse Effects") })
                    this?.setState({ "OtherAdverseEffectsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Other Adverse Effects") })
                    this?.setState({ "LabParametersImg": this.state.iconListData?.filter(fl => fl?.body_system == "Lab Parameters") })
                    this?.setState({ "MonitoringImg": this.state.iconListData?.filter(fl => fl?.body_system == "Monitoring") })
                    this?.setState({ "CrossReferenceImg": this.state.iconListData?.filter(fl => fl?.body_system == "Cross Reference") })
                    this?.setState({ "CitationsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Citations") })
                });
            }
        })
    }

    handleChanges(val, title, e) {
        if (title == 'pharm_class') { this.setState({ pharm_class: val == 1 ? 0 : 1 }) }
        if (title == 'thera_class') { this.setState({ thera_class: val == 1 ? 0 : 1 }) }
        if (title == 'routes') { this.setState({ routes: val == 1 ? 0 : 1 }) }
        if (title == 'dose_forms') { this.setState({ dose_forms: val == 1 ? 0 : 1 }) }
        if (title == 'natural_products') { this.setState({ natural_products: val == 1 ? 0 : 1 }) }
        if (title == 'renal_warning') { this.setState({ renal_warning: val == 1 ? 0 : 1 }) }
        if (title == 'protein_binding') { this.setState({ protein_binding: val == 1 ? 0 : 1 }) }
        if (title == 'dialysis') { this.setState({ dialysis: val == 1 ? 0 : 1 }) }
        if (title == 'hepatic_warning') { this.setState({ hepatic_warning: val == 1 ? 0 : 1 }) }
        if (title == 'pregnancy_warnings') { this.setState({ pregnancy_warnings: val == 1 ? 0 : 1 }) }
        if (title == 'lactation_warnings') { this.setState({ lactation_warnings: val == 1 ? 0 : 1 }) }
        if (title == 'oral_adverse_effects') { this.setState({ oral_adverse_effects: val == 1 ? 2 : val == 2 ? 0 : 1 }) }
        if (title == 'lab_parameters') { this.setState({ lab_parameters: val == 1 ? 2 : val == 2 ? 0 : 1 }) }
        if (title == 'cross_references') { this.setState({ cross_references: val == 1 ? 0 : 1 }) }
        if (title == 'citations') { this.setState({ citations: val == 1 ? 0 : 1 }) }
        if (title == 'other_adverse_effects') { this.setState({ other_adverse_effects: val == 1 ? 2 : val == 2 ? 0 : 1 }) }
        if (title == 'nutrition_adverse_effects') { this.setState({ nutrition_adverse_effects: val == 1 ? 2 : val == 2 ? 0 : 1 }) }
        if (title == 'gi_adverse_effects') { this.setState({ gi_adverse_effects: val == 1 ? 2 : val == 2 ? 0 : 1 }) }
    }

    handleSubmit() {
        let obj = {
            "pharm_class": this.state.pharm_class,
            "thera_class": this.state.thera_class,
            "routes": this.state.routes,
            "dose_forms": this.state.dose_forms,
            "natural_products": this.state.natural_products,
            "renal_warning": this.state.renal_warning,
            "protein_binding": this.state.protein_binding,
            "dialysis": this.state.dialysis,
            "hepatic_warning": this.state.hepatic_warning,
            "pregnancy_warnings": this.state.pregnancy_warnings,
            "lactation_warnings": this.state.lactation_warnings,
            "oral_adverse_effects": this.state.oral_adverse_effects,
            "other_adverse_effects": this.state.other_adverse_effects,
            "nutrition_adverse_effects": this.state.nutrition_adverse_effects,
            "gi_adverse_effects": this.state.gi_adverse_effects,
            "lab_parameters": this.state.lab_parameters,
            "cross_references": this.state.cross_references,
            "citations": this.state.citations,
            "user_id": this.state.userInfo?.user_id
        }
        if (obj) {
            SEARCH_API.user_drugDetail_visibility(obj).then(res => {
                if (res && (res?.status == 201 || res?.status == 200)) {
                    toast.success(res?.message, { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
                    this.getView(this.state.userInfo?.user_id)
                } else {
                    toast.error('Not updated !', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
                }
            })
        }
    }

    onChangeRadio(data, e) {
        this.setState({ c_id: parseInt(e?.target.value) })
    }
    updateCounty(e) {
        const { dispatch } = this.props;
        e?.preventDefault()
        if (this.state.c_id != "") {
            SEARCH_API?.updatePreferredCountry(this.state.userInfo?.user_id, this.state.c_id).then(res => {
                if (res) {
                    toast.success(`Preferred country updated successfully.`, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
                    let stateData = localStorage.getItem('setUser');
                    let stateDataParse = JSON.parse(stateData);
                    stateDataParse.userInfo.country_view_id = this.state.c_id
                    let jsonStr = JSON.stringify(stateDataParse)
                    setTimeout(() => {
                        localStorage.setItem("setUser", jsonStr);
                        localStorage.removeItem('mostThree');
                        dispatch(setFlag());
                        setTimeout(() => {
                            if (res?.status == 200) {
                                window.location.reload();
                            }
                        }, 500);
                    }, 1000)
                }
            })
        }
    }

    render() {
        return <React.Fragment>
            <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                title="View Customization"
                description={""} />

            <section className="section py-1  novi-background bg-cover">
                <div className="container">
                    <div className='row account mb-4 mt-4'>
                        <div className='col-lg-12' style={{ margin: 'auto' }}>
                            <div className="col-12" style={{ textAlign: 'initial', margin: 'auto' }}>
                                <div className='card-header'>
                                    <h5 style={{ textAlign: 'center', background: '#A7CA56', color: 'whitesmoke' }}>Customization</h5>
                                    <div className='p-0'>
                                        <p className='mt-2' style={{ color: "black", fontSize: "15px" }}>
                                            Make the DNI website more efficient by making it your own!
                                            Select your country to view drugs available there and relevant warnings.
                                            Never see patients on dialysis? Hide that content in your standard view.
                                            Only want to see common drug adverse effects? Select 'Show common'.
                                            Hidden Information is always available with one click if needed.
                                        </p>
                                        <div className='row' id="custom-label" style={{ textAlign: 'center', color: '#6c6b6b' }}>
                                            <div className='col-lg-2'>
                                                {/* <BootstrapSwitchButton checked={true} onstyle="light" offstyle="dark" style="border"  /> */}
                                                {this.state.PharmClassImg ?
                                                    <img className='ml-0' src={this.state.PharmClassImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                {/* <input type={'checkbox'} name="pharm_class" id="pharm_class" value={this.state.pharm_class} onChange={this.handleChanges.bind(this)} />&nbsp;&nbsp; */}
                                                {/* <input type="checkbox" checked data-toggle="toggle" data-on="Ready" data-off="Not Ready" data-onstyle="success" data-offstyle="danger"></input> */}
                                                <br /> <label style={{ fontSize: 13 }} onClick={this.handleChanges.bind(this, this.state.pharm_class, 'pharm_class')} name="pharm_class" value={this.state.pharm_class}
                                                    className="form-check-label" htmlFor="pharm_class">Pharmacological Class</label>
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.pharm_class == 1 ? 'green' : '', marginLefts: 25 }}>Show</span>
                                                /
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.pharm_class == 0 ? 'red' : '' }}>Hide</span>

                                            </div>
                                            <div className='col-lg-2'>
                                                {this.state.TheraClassImg ?
                                                    <img style={{ marginLefts: 34 }} src={this.state.TheraClassImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                {/* <input type={'checkbox'} name="thera_class" id="thera_class" value={this.state.thera_class} onChange={this.handleChanges.bind(this)} /> */}
                                                <br /><label style={{ fontSize: 13 }} onClick={this.handleChanges.bind(this, this.state.thera_class, 'thera_class')} className="form-check-label" htmlFor="thera_class">Therapeutic Class</label>
                                                <br />
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.thera_class == 1 ? 'green' : '', marginLefts: 15 }}>Show</span>
                                                /
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.thera_class == 0 ? 'red' : '' }}>Hide</span>
                                            </div>
                                            <div className='col-lg-2'>
                                                {this.state.RoutesImg ?
                                                    <img src={this.state.RoutesImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                {/* <input type={'checkbox'} name="routes" id="routes" value={this.state.routes} onChange={this.handleChanges.bind(this)} /> */}
                                                <br /><label style={{ fontSize: 13 }} onClick={this.handleChanges.bind(this, this.state.routes, 'routes')} className="form-check-label" htmlFor="routes">Routes</label><br />
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.routes == 1 ? 'green' : '', marginLefts: '-14px' }}>Show</span>
                                                /
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.routes == 0 ? 'red' : '' }}>Hide</span>
                                            </div>
                                            <div className='col-lg-2'>
                                                {this.state.DoseFormsImg ?
                                                    <img style={{ marginLefts: 15 }} src={this.state.DoseFormsImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                {/* <input type={'checkbox'} name="dose_forms" id="dose_forms" value={this.state.dose_forms} onChange={this.handleChanges.bind(this)} /> */}
                                                <br /><label style={{ fontSize: 13 }} onClick={this.handleChanges.bind(this, this.state.dose_forms, 'dose_forms')} className="form-check-label" htmlFor="dose_forms">Dose Forms</label><br />
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.dose_forms == 1 ? 'green' : '', marginLefts: '0x' }}>Show</span>
                                                /
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.dose_forms == 0 ? 'red' : '' }}>Hide</span>
                                            </div>
                                            <div className='col-lg-2 '>
                                                {this.state.NaturalproductinteractionsImg ?
                                                    <img style={{ marginLefts: 30 }} src={this.state.NaturalproductinteractionsImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                {/* <input type={'checkbox'} name="natural_products" id="natural_products" value={this.state.natural_products} onChange={this.handleChanges.bind(this)} /> */}
                                                <br /><label style={{ fontSize: 13 }} onClick={this.handleChanges.bind(this, this.state.natural_products, 'natural_products')} className="form-check-label" htmlFor="natural_products">Natural Products</label><br />
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.natural_products == 1 ? 'green' : '', marginLefts: '15px' }}>Show</span>
                                                /
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.natural_products == 0 ? 'red' : '' }}>Hide</span>
                                            </div>
                                            <div className='col-lg-2'>
                                                {this.state.ProteinbindingImg ?
                                                    <img style={{ marginLefts: 25 }} src={this.state.ProteinbindingImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                {/* <input type={'checkbox'} name="protein_binding" id="protein_binding" value={this.state.protein_binding} onChange={this.handleChanges.bind(this)} /> */}
                                                <br /><label style={{ fontSize: 13 }} onClick={this.handleChanges.bind(this, this.state.protein_binding, 'protein_binding')} className="form-check-label" htmlFor="protein_binding">Protein Binding</label><br />
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.protein_binding == 1 ? 'green' : '', marginLefts: '5px' }}>Show</span>
                                                /
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.protein_binding == 0 ? 'red' : '' }}>Hide</span>
                                            </div>
                                            <div className='col-lg-2 mt-3'>
                                                {this.state.RenalwarningsImg ?
                                                    <img style={{ marginLefts: 28 }} src={this.state.RenalwarningsImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                {/* <input type={'checkbox'} name="renal_warning" id="renal_warning" value={this.state.renal_warning} onChange={this.handleChanges.bind(this)} /> */}
                                                <br /><label style={{ fontSize: 13 }} className="form-check-label" onClick={this.handleChanges.bind(this, this.state.renal_warning, 'renal_warning')} htmlFor="renal_warning">Renal Warnings</label><br />
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.renal_warning == 1 ? 'green' : '', marginLefts: '9px' }}>Show</span>
                                                /
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.renal_warning == 0 ? 'red' : '' }}>Hide</span>
                                            </div>
                                            <div className='col-lg-2 mt-3'>
                                                {this.state.DialysiswarningsImg ?
                                                    <img style={{ marginLefts: 5 }} src={this.state.DialysiswarningsImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                {/* <input type={'checkbox'} name="dialysis" id="dialysis" value={this.state.dialysis} onChange={this.handleChanges.bind(this)} /> */}
                                                <br /><label style={{ fontSize: 13 }} onClick={this.handleChanges.bind(this, this.state.dialysis, 'dialysis')} className="form-check-label" htmlFor="dialysis">Dialysis</label><br />
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.dialysis == 1 ? 'green' : '', marginLefts: '-13px' }}>Show</span>
                                                /
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.dialysis == 0 ? 'red' : '' }}>Hide</span>
                                            </div>

                                            <div className='col-lg-2 mt-3'>
                                                {this.state.HepaticwarningsImg ?
                                                    <img style={{ marginLefts: 34 }} src={this.state.HepaticwarningsImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                {/* <input type={'checkbox'} name="hepatic_warning" id="hepatic_warning" value={this.state.hepatic_warning} onChange={this.handleChanges.bind(this)} /> */}
                                                <br /><label style={{ fontSize: 13 }} onClick={this.handleChanges.bind(this, this.state.hepatic_warning, 'hepatic_warning')} className="form-check-label" htmlFor="hepatic_warning">Hepatic Warnings</label>
                                                <br />
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.hepatic_warning == 1 ? 'green' : '', marginLefts: '15px' }}>Show</span>
                                                /
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.hepatic_warning == 0 ? 'red' : '' }}>Hide</span>
                                            </div>
                                            <div className='col-lg-2 mt-3'>
                                                {this.state.PregnancywarningsImg ?
                                                    <img style={{ marginLefts: 11 }} src={this.state.PregnancywarningsImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                {/* <input type={'checkbox'} name="pregnancy_warnings" id="pregnancy_warnings" value={this.state.pregnancy_warnings} onChange={this.handleChanges.bind(this)} /> */}
                                                <br /><label style={{ fontSize: 13 }} onClick={this.handleChanges.bind(this, this.state.pregnancy_warnings, 'pregnancy_warnings')} className="form-check-label" htmlFor="pregnancy_warnings">Pregnancy</label><br />
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.pregnancy_warnings == 1 ? 'green' : '', marginLefts: '-5px' }}>Show</span>
                                                /
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.pregnancy_warnings == 0 ? 'red' : '' }}>Hide</span>
                                            </div>
                                            <div className='col-lg-2 mt-3'>
                                                {this.state.BreastfeedingWarningsImg ?
                                                    <img style={{ marginLefts: 8 }} src={this.state.BreastfeedingWarningsImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                <br /><label style={{ fontSize: 13 }} onClick={this.handleChanges.bind(this, this.state.lactation_warnings, 'lactation_warnings')} className="form-check-label" htmlFor="lactation_warnings">Lactation</label><br />
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.lactation_warnings == 1 ? 'green' : '', marginLefts: '-9px' }}>Show</span>
                                                /
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.lactation_warnings == 0 ? 'red' : '' }}>Hide</span>
                                            </div>


                                            <div className='col-lg-2 mt-3'>
                                                {this.state.OralAdverseEffectsImg ?
                                                    <img style={{ marginLefts: 8 }} src={this.state.OralAdverseEffectsImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                <br /><label style={{ fontSize: 13 }}
                                                    // onClick={this.handleChanges.bind(this, this.state.oral_adverse_effects, 'oral_adverse_effects')}
                                                    className="form-check-label" id='oral_adverse_effects_hr' onClick={this.handleChanges.bind(this, this.state.oral_adverse_effects, 'oral_adverse_effects')} htmlFor="oral_adverse_effects">Oral Adverse Effects</label><br />
                                                <span
                                                    // onClick={(e)=>{
                                                    //     this.setState({oral_adverse_effects:1})
                                                    // }}
                                                    style={{ fontWeight: 'bold', fontSize: 12, color: this.state.oral_adverse_effects == 1 ? 'green' : '', marginLefts: '-9px' }}>Show All</span>
                                                /
                                                <span
                                                    // onClick={(e)=>{
                                                    //     this.setState({oral_adverse_effects:2})
                                                    // }}
                                                    style={{ fontWeight: 'bold', fontSize: 12, color: this.state.oral_adverse_effects == 2 ? 'rgb(121 131 242 / 96%)' : '' }}>Common</span>
                                                /
                                                <span
                                                    // onClick={(e)=>{
                                                    //     this.setState({oral_adverse_effects:0})
                                                    // }}
                                                    style={{ fontWeight: 'bold', fontSize: 12, color: this.state.oral_adverse_effects == 0 ? 'red' : '' }}>Hide</span>
                                            </div>
                                            <div className='col-lg-2 mt-3'>
                                                {this.state.GIAdverseEffectsImg ?
                                                    <img style={{ marginLefts: 8 }} src={this.state.GIAdverseEffectsImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                <br /><label style={{ fontSize: 13 }}
                                                    // onClick={this.handleChanges.bind(this, this.state.gi_adverse_effects, 'gi_adverse_effects')}
                                                    className="form-check-label" id="oral_adverse_effects_hr" onClick={this.handleChanges.bind(this, this.state.gi_adverse_effects, 'gi_adverse_effects')} htmlFor="gi_adverse_effects">GI Adverse Effects</label><br />
                                                <span
                                                    // onClick={(e)=>{
                                                    //     this.setState({gi_adverse_effects:1})
                                                    // }}
                                                    style={{ fontWeight: 'bold', fontSize: 12, color: this.state.gi_adverse_effects == 1 ? 'green' : '', marginLefts: '-9px' }}>Show All</span>
                                                /
                                                <span
                                                    // onClick={(e)=>{
                                                    //     this.setState({gi_adverse_effects:2})
                                                    // }}
                                                    style={{ fontWeight: 'bold', fontSize: 12, color: this.state.gi_adverse_effects == 2 ? 'rgb(121 131 242 / 96%)' : '' }}>Common</span>
                                                /
                                                <span
                                                    // onClick={(e)=>{
                                                    //     this.setState({gi_adverse_effects:0})
                                                    // }}
                                                    style={{ fontWeight: 'bold', fontSize: 12, color: this.state.gi_adverse_effects == 0 ? 'red' : '' }}>Hide</span>
                                            </div>
                                            <div className='col-lg-2 mt-3'>
                                                {this.state.NutritionalAdverseEffectsImg ?
                                                    <img style={{ marginLefts: 8 }} src={this.state.NutritionalAdverseEffectsImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                <br /><label style={{ fontSize: 13 }}
                                                    // onClick={this.handleChanges.bind(this, this.state.nutrition_adverse_effects, 'nutrition_adverse_effects')} 
                                                    className="form-check-label" id='oral_adverse_effects_hr' onClick={this.handleChanges.bind(this, this.state.nutrition_adverse_effects, 'nutrition_adverse_effects')} htmlFor="nutrition_adverse_effects">Nutrition Adverse Effects</label><br />
                                                <span
                                                    // onClick={(e)=>{
                                                    //     this.setState({nutrition_adverse_effects:1})
                                                    // }}
                                                    style={{ fontWeight: 'bold', fontSize: 12, color: this.state.nutrition_adverse_effects == 1 ? 'green' : '', marginLefts: '-9px' }}>Show All</span>
                                                /
                                                <span
                                                    // onClick={(e)=>{
                                                    //     this.setState({nutrition_adverse_effects:2})
                                                    // }}
                                                    style={{ fontWeight: 'bold', fontSize: 12, color: this.state.nutrition_adverse_effects == 2 ? 'rgb(121 131 242 / 96%)' : '' }}>Common</span>
                                                /
                                                <span
                                                    // onClick={(e)=>{
                                                    //     this.setState({nutrition_adverse_effects:0})
                                                    // }}
                                                    style={{ fontWeight: 'bold', fontSize: 12, color: this.state.nutrition_adverse_effects == 0 ? 'red' : '' }}>Hide</span>
                                            </div>

                                            <div className='col-lg-2 mt-3'>
                                                {this.state.OtherAdverseEffectsImg ?
                                                    <img style={{ marginLefts: 25 }} src={this.state.OtherAdverseEffectsImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                <br />
                                                <label style={{ fontSize: 13 }}
                                                    // onClick={this.handleChanges.bind(this, this.state.other_adverse_effects, 'other_adverse_effects')} 
                                                    className="form-check-label" htmlFor="oral_adverse_effects" onClick={this.handleChanges.bind(this, this.state.other_adverse_effects, 'other_adverse_effects')} id="oral_adverse_effects_hr">Other Adverse Effects</label><br />
                                                <span
                                                    // onClick={(e)=>{
                                                    //     this.setState({other_adverse_effects:1})
                                                    // }}
                                                    style={{ fontWeight: 'bold', fontSize: 12, color: this.state.other_adverse_effects == 1 ? 'green' : '', marginLefts: '7px' }}>Show All</span>
                                                /
                                                <span
                                                    // onClick={(e)=>{
                                                    //     this.setState({other_adverse_effects:2})
                                                    // }}
                                                    style={{ fontWeight: 'bold', fontSize: 12, color: this.state.other_adverse_effects == 2 ? 'rgb(121 131 242 / 96%)' : '', marginLefts: '7px' }}>Common</span>
                                                /
                                                <span
                                                    // onClick={(e)=>{
                                                    //     this.setState({other_adverse_effects:0})
                                                    // }}
                                                    style={{ fontWeight: 'bold', fontSize: 12, color: this.state.other_adverse_effects == 0 ? 'red' : '' }}>Hide</span>
                                            </div>

                                            <div className='col-lg-2 mt-3'>
                                                {this.state.LabParametersImg ?
                                                    <img style={{ marginLefts: 30 }} src={this.state.LabParametersImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                {/* <input type={'checkbox'} name="lab_parameters" id="lab_parameters" value={this.state.lab_parameters} onChange={this.handleChanges.bind(this)} /> */}
                                                <br />
                                                <label style={{ fontSize: 13 }}
                                                    // onClick={this.handleChanges.bind(this, this.state.lab_parameters, 'lab_parameters')} 
                                                    className="form-check-label" htmlFor="lab_parameters" onClick={this.handleChanges.bind(this, this.state.lab_parameters, 'lab_parameters')} id="oral_adverse_effects_hr">Lab Parameters</label><br />
                                                <span
                                                    //  onClick={(e)=>{
                                                    //     this.setState({lab_parameters:1})
                                                    // }} 
                                                    style={{ fontWeight: 'bold', fontSize: 12, color: this.state.lab_parameters == 1 ? 'green' : '', marginLefts: '8px' }}>Show All</span>
                                                /
                                                <span
                                                    // onClick={(e)=>{
                                                    //     this.setState({lab_parameters:2})
                                                    // }} 
                                                    style={{ fontWeight: 'bold', fontSize: 12, color: this.state.lab_parameters == 2 ? 'rgb(121 131 242 / 96%)' : '' }}>Common</span>
                                                /
                                                <span
                                                    // onClick={(e)=>{
                                                    //     this.setState({lab_parameters:0})
                                                    // }} 
                                                    style={{ fontWeight: 'bold', fontSize: 12, color: this.state.lab_parameters == 0 ? 'red' : '' }}>Hide</span>
                                            </div>
                                            <div className='col-lg-2 mt-3'>
                                                {this.state.CrossReferenceImg ?
                                                    <img style={{ marginLefts: 30 }} src={this.state.CrossReferenceImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                {/* <input type={'checkbox'} name="cross_references" id="cross_references" value={this.state.cross_references} onChange={this.handleChanges.bind(this)} /> */}
                                                <br /><label style={{ fontSize: 13 }} onClick={this.handleChanges.bind(this, this.state.cross_references, 'cross_references')} className="form-check-label" htmlFor="cross_references">Cross references</label>
                                                <br />
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.cross_references == 1 ? 'green' : '', marginLefts: '15px' }}>Show</span>
                                                /
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.cross_references == 0 ? 'red' : '' }}>Hide</span>
                                            </div>
                                            <div className='col-lg-2 mt-3'>
                                                {this.state.CitationsImg ?
                                                    <img style={{ marginLefts: 10 }} src={this.state.CitationsImg[0].iconImg} width='50' id="PharmClassImg" />
                                                    : ""}
                                                {/* <input type={'checkbox'} name="citations" id="citations" value={this.state.citations} onChange={this.handleChanges.bind(this)} /> */}
                                                <br /><label style={{ fontSize: 13 }} onClick={this.handleChanges.bind(this, this.state.citations, 'citations')} className="form-check-label" htmlFor="citations">Citations</label><br />
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.citations == 1 ? 'green' : '', marginLefts: '-10px' }}>Show</span>
                                                /
                                                <span style={{ fontWeight: 'bold', fontSize: 12, color: this.state.citations == 0 ? 'red' : '' }}>Hide</span>
                                            </div>
                                            <div className='col-lg-2 mt-3'>
                                                {/* <input type={'checkbox'} onChange={this.handleChanges.bind(this)} />&nbsp;&nbsp;<label className="form-check-label" htmlFor="">Dose Forms</label>&nbsp;&nbsp; */}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <button className='btn btn-primary mt-3' onClick={this.handleSubmit.bind(this)} id='update-setting'>Update</button>
                                            </div>
                                        </div>
                                        <hr
                                            style={{
                                                marginTop: 10,
                                                marginBottom: 0,
                                                borderTop: '1px solid #A7CA56',
                                            }}
                                        />
                                        <div className="row">
                                            <div className='col-md-9'>
                                                <div className='row mt-1 ml-0'>
                                                    <span id="vals">Show Product Availability and Warnings from:</span>
                                                    <div className='col-md-4' id="vals">
                                                        <div className="form-group d-md-flex">
                                                            <div className="form-check">
                                                                <input type="radio" onChange={this.onChangeRadio.bind(this, 'exampleCheck1')} value={1} className="form-check-input mt-1" id="usId" name="markUs" />
                                                                <label className="form-check-label" htmlFor="exampleCheck1"><b style={{ color: '#15678f' }}>USA</b></label>
                                                            </div>
                                                            <div className="form-check ml-4">
                                                                <input type="radio" onChange={this.onChangeRadio.bind(this, 'exampleCheck2US')} value={2} id="canadaId" className="form-check-input mt-1 exampleCheck2US" name="markUs" />
                                                                <label className="form-check-label" htmlFor="exampleCheck2US"><b style={{ color: '#c90303' }}>CANADA</b></label>
                                                            </div>
                                                            <div className="form-check ml-4">
                                                                <button onClick={this.updateCounty.bind(this)} style={{ color: '#fff', backgroundColor: "#A7CA56", border: '4px solid #A7CA56', marginTop: '-8px', borderRadius: 50, marginLefts: '-38px' }}>Update</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    }
}

export default connect()(ViewCustomization) 