import React from 'react'

function NewsComp(props) {
const newsTagList = [...props?.newsTag]
  return (
    <div>
        <article className="article-item bounding-fix post-classic">
        <p className="post-title text-big font-weight-bold text-uppercase text-spacing-100 offset-top-20">
            <a href={`#/news/news-details/${props.id}`} style={{color:'#15678F'}}>{props?.title}</a>
            </p>
            {props?.image && 
                <span className="thumbnail-zoom-img-wrap">
                    <img className="img-responsive center-block" src={props?.image} width="770" height="480" alt="News Details Image" 
                    style={{height: 330,width: 600}}/>
                </span>
            }
            <div className="post-body">
                
                {/* <!-- List Inline--> */}
                <ul className="list-inline list-inline-22 list-inline-dashed-vertical font-weight-bold p">
                    <li>
                        <span className="icon novi-icon icon-xs material-icons-ico material-icons-event text-middle text-gray"></span>
                        <span className="text-middle inset-left-7 post-meta">{props?.date}</span>
                    </li>
                    <li>
                        <span className="icon novi-icon icon-xs material-icons-ico material-icons-person text-middle text-gray"></span>
                        <span className="text-regular inset-left-4 text-gray text-middle"><b>By:</b></span>
                        <a className="text-middle link-decoration-none text-hover-primary text-gray-light inset-left-4" href="/">{props?.author}</a>
                    </li>
                </ul>
                <p className="offset-top-10" >
                <span id="grapft" dangerouslySetInnerHTML={{ __html:props?.desc}}></span>
                </p>
                <p className="offset-top-10">
                 <b>More at : </b><a href={`${props?.source}`} target="_blank">Source</a>
                </p>
                <div className="group-sm offset-top-10">
                    {newsTagList.map((item, i) => 
                    // <a className="btn btn-tag btn-primary-gray-outline" key={i} href={'#'}>{item}</a>
                    <span className="btn btn-tag btn-primary-gray-outline" key={i} href={'#'}>{item}</span>
                    )}
                </div>
            </div>
        </article>
    </div>
  )
}

export default NewsComp