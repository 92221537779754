import React, { useState } from "react";
import PageHeader from "../../../components/PageHeader/PageHeader";
const PressorAgents = () => {


    return (
        <div style={{ textAlign: 'initial' }}>
            <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                title="Pressor&nbsp;Agents&nbsp;In&nbsp;Foods&nbsp;And&nbsp;Beverages" />
            <div className="container mt-4">
                {/* <h6 ><p style={{marginTop:"-14px",marginLeft:-13,fontWeight:'700px',fontSize:16,color:'#484343'}}><b>PRESSOR AGENTS IN FOODS AND BEVERAGES</b></p></h6> */}
                <p style={{ marginTop: "-10px", marginLeft: -13, fontWeight: '700px', fontSize: 14, color: '#484343' }}>(Tyramine, Dopamine, Histamine, Phenylethylamine)</p>
                <br />
                <p style={{ marginTop: "-20px", marginLeft: -13, fontWeight: '700px', fontSize: 15, color: '#484343' }}><b>FOODS THAT MUST BE AVOIDED</b></p>
                <p style={{ marginTop: "-12px", marginLeft: -13, fontWeight: '400px', fontSize: 14, color: '#484343' }}>aged cheeses (e.g. Cheddar, Bleu, Gorgonzola, Stilton)
                    aged meats: (e.g. dry sausage such as salami, mortadella)
                    bean pods (contain dopamine)
                    Chinese dried duck
                    fava beans, snowpea or broad banana peel
                    fermented soya bean, soya bean paste
                    miso soup tofu/fermented bean curd
                    sauerkraut, kim chee
                    tap beer, Korean beer
                    concentrated yeast extracts (Marmite, Vegemite)
                    soy sauce
                    All casseroles made with aged cheese</p><br />
                <p style={{ marginTop: "-20px", marginLeft: -13, fontWeight: '500px', fontSize: 15, color: '#484343' }}><b>FOODS THAT MAY BE USED WITH CAUTION</b></p>
                <p style={{ marginTop: "-12px", marginLeft: -13, fontWeight: '400px', fontSize: 14, color: '#484343' }}>alcohol-free beer, 2x 12 ounce bottles max
                    bottled beer, 2x 12 ounce bottles max coffee, cola*
                    red or white wine, 2-4 ounces per day<br />
                    pizza (homemade or gourmet pizzas may have higher content)</p><br />
                <p style={{ marginTop: "-20px", marginLeft: -13, fontWeight: '500px', fontSize: 15, color: '#484343' }}><b>FOODS NOT LIMITED</b>&nbsp;(Based on current analyses)</p>
                <br />
                <p style={{ marginTop: "-30px", marginLeft: -13, fontWeight: '400px', fontSize: 14, color: '#484343' }}>Avocado, baked raised products, banana, beetroot, boiled egg, Brewer&#39;s yeast (vitamin
                    supplements), canned figs, carp, chocolate, cucumber, curry powder, English cookies,
                    Figs, fresh meat, poultry or fish, fresh pineapple, ice cream, mushrooms, peanuts,
                    pickled herring, raisins, raspberries, salad dressings, tomato sauce, smoked fish (anchovies
                    white fish, salmon), sweet corn, unfermented cheeses (cream, cottage, ricotta, processed),
                    Worcestershire sauce, yogurt</p><br />
                <p style={{ marginTop: "-30px", marginLeft: -13, fontWeight: '400px', fontSize: 14, color: '#484343' }}>All packaged or processed meats e.g. hot dogs, bologna, liverwurst (store in refrigerator
                    immediately &amp; eat as soon as possible).</p><br />
                <br />
                <p style={{ marginTop: "-40px", marginLeft: -13, fontWeight: '400px', fontSize: 15, color: '#484343' }}><b>HISTAMINE:</b>&nbsp;Content is highest in improperly stored or spoiled fish, tuna.</p><br />
                <br />
                <p style={{ marginTop: "-30px", marginLeft: -13, fontWeight: '400px', fontSize: 14, color: '#484343' }}>*Caffeine, a weak pressor agent, in quantities &gt; 500 mg per day may exacerbate reactions</p><br />
                {/* <br/>
                <p style={{marginTop:"-50px",marginLeft:-13,fontWeight:'400px',fontSize:14,color:'#484343'}}>Rapaport M, Dietary restrictions and drug interactions with monoamine oxidase inhibitors: the
state of the art&quot; 2007 J Clin Psychiatry 68 Suppl 8:42-6.</p><br/>
       <br/>          */}

                {/* <p style={{marginTop:"-20px",marginLeft:-13,fontWeight:'400px',fontSize:14,color:'#484343'}}><b>References&nbsp;:</b>McCabe BJ. Dietary tyramine and other pressor amines in MAOI regimens: a review. J Am Diet Assoc 1986; 86(8): 1059-1064.<br/><a target={"_blank"} href="https://pubmed.ncbi.nlm.nih.gov/3525654/">https://pubmed.ncbi.nlm.nih.gov/3525654/</a>Rapaport MH. Dietary restrictions and drug interactions with monoamine oxidase inhibitors: the state of the art. J Clin Psychiatry 2007; 68<br/> Suppl 8: 42-46. 
 <a target={"_blank"} href="https://pubmed.ncbi.nlm.nih.gov/17640147/">https://pubmed.ncbi.nlm.nih.gov/17640147/</a><br/>Van den Eynde V, Gillman PK, Blackwell BB. The Prescriber's Guide to the MAOI Diet-Thinking Through Tyramine Troubles. Psychopharmacol Bull 2022; 52(2): 73-116.<a target={"_blank"} href="https://pubmed.ncbi.nlm.nih.gov/35721816/">https://pubmed.ncbi.nlm.nih.gov/35721816/</a>
</p> */}
                <p style={{ marginTop: "-20px", marginLeft: -22, fontWeight: '400px', fontSize: 14, color: '#484343' }}><b>References: </b><br />
                    <a target={"_blank"} href="https://pubmed.ncbi.nlm.nih.gov/3525654/">
                        McCabe BJ. Dietary tyramine and other pressor amines in MAOI regimens: a review. J Am Diet Assoc 1986; 86(8): 1059-1064.
                    </a><br />
                    <a target={"_blank"} href="https://pubmed.ncbi.nlm.nih.gov/17640147/">
                        Rapaport MH. Dietary restrictions and drug interactions with monoamine oxidase inhibitors: the state of the art. J Clin Psychiatry 2007; 68
                    </a>
                    <br />
                    <a target={"_blank"} href="https://pubmed.ncbi.nlm.nih.gov/35721816/">
                        Van den Eynde V, Gillman PK, Blackwell BB. The Prescriber's Guide to the MAOI Diet-Thinking Through Tyramine Troubles. Psychopharmacol Bull 2022; 52(2): 73-116.
                    </a>
                </p>
            </div>
        </div>

    );

}

export default PressorAgents;