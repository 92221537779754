import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DummyImage } from "react-simple-placeholder-image";
import { AUTENTICATE_API } from "../../services/Api/authenticationApi";
import logo from './../../assets/img/logo/dni-logo.png';
import { saveAs } from "file-saver";
import jsPDF from 'jspdf'
import { toast } from "react-toastify";
//import domtoimage from 'dom-to-image';
//import pdfMake from "pdfmake";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf-fix-jspdf";
import moment from "moment";



function ModulePurchased() {
    const moment = require('moment');

    const [billingDetail, setBillingDetail] = useState("")
    let value = moment().format(billingDetail?.created_dt, "dddd, MMMM Do YYYY, h:mm:ss a");
    let userDetail = JSON.parse(localStorage.getItem('setUser'));
    useEffect(() => {
        BillingDetail(userDetail?.userInfo?.user_id)
    },
        []);
    const BillingDetail = () => {
        AUTENTICATE_API.billingDetail(userDetail?.userInfo?.user_id).then((response) => {
            setBillingDetail(response.data);
        })
    }

    const generatePDF = () => {

        let element = document.querySelector(".modal-content");
        document.getElementById('close-id').style.display = 'none'
        document.getElementById('download-btns').style.display = 'none'
        let opt = {
            margin: 0.3,
            filename: `invoice_${moment(new Date()).format('MM-DD-YYYY')}`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 1 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };

        setTimeout(() => {
            html2pdf(element, opt);
        }, 100)

        setTimeout(() => {
            document.getElementById('close-id').style.display = 'block'
            document.getElementById('download-btns').style.display = 'block'
        }, 1000)
    }
    const ContinueProcess = () => {
        window.location.href = "#/account/search"
    }

    return (
        <>
            <section className="section section-md-bottom-50 bg-gray-lighter novi-background bg-cover"><br /><br />
                <div className="container">
                    <div className="row justify-content-sm-center align-items-center offset-top-40">
                    </div>
                    <div className="row justify-content-sm-center align-items-center">
                        <div className='col'>
                            <div className="jumbotron bg-white shadow-lg">
                                <div className='mb-4'>
                                    <i className="fa-solid fa-check rounded-check display-3"></i>
                                </div>
                                <h2 className="text-uppercase">Payment Confirmed!</h2>
                                <p className='mb-5 px-lg-5'>ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets</p>
                                <div>
                                    <button type='submit' className='btn btn btn btn-outline-success mr-4' onClick={ContinueProcess} >Continue</button>
                                    <button type='submit' className='btn btn btn btn-outline-success mr-4' data-toggle="modal" data-target="#reciept">View Receipt</button>
                                    <button className='btn btn btn btn-outline-success mr-4' onClick={generatePDF} >Download Reciept</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="modal fade" id="reciept" tabindex="-1" aria-labelledby="reciept" >
                <div id="test1" >

                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content p-3">
                            <div className="modal-body text-center">
                                <button type="button" className="close" id='close-id' data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                {/* <div className='text-center mb-5'> */}
                                <img className="m-auto" src={logo} alt="" width={200} />
                                {/* </div> */}<br /><br />
                                <h2 className="">Thank You For Your Purchase!</h2>
                                <p className="text-big font-weight-bold text-primary text-uppercase">Order Summary</p>
                                <p className='lead'>{moment(billingDetail?.created_dt).format('MM/DD/YYYY h:mm A')}</p>
                                <table className="table text-left table-borderless">
                                    <tbody className="border-bottom">
                                        <tr>
                                            <td scope="row">Subtotal</td>
                                            <th className="text-right">${billingDetail?.billable_amt}</th>
                                        </tr>

                                        <tr>
                                            <td scope="row">Tax</td>
                                            <th className="text-right">${billingDetail?.tax}</th>
                                        </tr>
                                    </tbody>
                                    <tbody className="border-bottom">
                                        <tr>
                                            <td scope="row">Total Amount</td>
                                            <th className="text-right">${billingDetail?.total_billable_amt}</th>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <th scope="row">Grand Total</th>
                                            <td className="text-right"><p className="text-big font-weight-bold text-primary">${billingDetail?.total_billable_amt}</p></td>
                                        </tr>
                                    </tbody>
                                </table>

                                <h2 className="mb-4 text-center">Your Details</h2>
                                <table className="table text-left table-borderless">
                                    <tbody>
                                        <tr>
                                            <td className='pb-0'>Billed to</td>
                                            <th className="text-right pb-0">{billingDetail?.user_name}</th>
                                        </tr>
                                        <tr>
                                            <th colSpan={2} className="text-right pb-0">{billingDetail?.email_id}</th>
                                        </tr>

                                    </tbody>
                                </table>
                                <p>Notice anything wrong? <Link to="#">Contact us</Link> and we'll happy to help</p>
                            </div>
                            <div className="modal-footer text-center">
                                <button onClick={generatePDF} id='download-btns' className="btn btn-outline-primary btn-block col-md-1">Download</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default ModulePurchased