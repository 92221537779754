import React, { useState, useEffect } from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import { TableAPI } from "../../services/Api/TableApi";
import ReactModal from 'react-modal';
import '../../../src/regular.css';
import Table from 'react-bootstrap/Table';
import grayfruit_img from "../../../src/assets/grapefruit_img.jpg";
import BackIcon from '../../assets/images/previousBtn.png'

const Grapefruit = () => {
    const [Grapefruit, setGrapefruit] = useState([]);
    const dropVal = [
        "avoid",
        "caution",
        "theoretical",
        "no interaction",
        "no suspected interaction",
    ];

    const [modalIsOpen, setIsOpen] = useState(false);
    const [Generic, setGeneric] = useState("");
    const [Text, setText] = useState("");
    const [GenericId, setGenericID] = useState("");
    const [getHeight, setHeight] = useState("");
    const [modalStyle, setModalStyle] = useState({
        left: '0%',
        width: '100%',
    });

    const handleChange = (e) => {
        if (e.target.name === "Interaction1") {
            if (e.target.value === "0") {
                setGrapefruit([]);
                return false;
            }

            const obj = {
                interaction: e.target.value,
                flag: "Grapefruitinteractions"
            };

            TableAPI?.InteractionApi(obj).then((res) => {
                setGrapefruit(res?.data);
            });
        }
    };

    useEffect(() => {
        const updateModalStyle = () => {
            if (window.innerWidth > 768) {
                setModalStyle({
                    left: '35%',
                    width: '32%',
                });
            } else {
                setModalStyle({
                    left: '0%',
                    width: '100%',
                });
            }
        };

        window.addEventListener('resize', updateModalStyle);
        updateModalStyle();

        return () => {
            window.removeEventListener('resize', updateModalStyle);
        };
    }, []);

    const result = Grapefruit?.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 3);
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);

    const ParentDiv = result?.length >= 20 ? { height: "600px", overflowY: "auto" } : { overflowY: "auto" };

    const setModal = (generic, text, textlen, generic_id) => {
        setHeight(textlen > 700 ? "40%" : "auto");
        setIsOpen(true);
        setGeneric(generic);
        setText(text);
        setGenericID(generic_id);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const afterOpenModal = () => {
        subtitle.style.color = '#f00';
    };

    return (
        <>
            <ReactModal
                size="xs"
                max-width="200px"
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                contentLabel={Generic}
                className="Modal"
                overlayClassName="Overlay"
                style={{
                    overlay: {
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.75)",
                        zIndex: 9999,
                    },
                    content: {
                        position: "absolute",
                        top: '35%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: "translate(-50%, -50%)",
                        border: "1px solid #ccc",
                        background: "#fff",
                        color: "#333",
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        borderRadius: "4px",
                        outline: "none",
                        padding: "20px",
                        height: `${getHeight}`,
                        ...modalStyle,
                    },
                }}
            >
                <div className="row align-items-center">
                    <div className="col-11 text-center">
                        <h2
                            id="popup-heading-hover"
                            style={{ cursor: "pointer", wordBreak: "break-word" }}
                            onClick={() => {
                                window.open(`#/account/drug-details?generic_id=${GenericId}`, '_blank');
                            }}
                        >
                            {Generic}
                        </h2>
                    </div>
                    <div className="col-1">
                        <h6 onClick={closeModal} style={{ color: "#15678f", cursor: "pointer" }}>
                            X
                        </h6>
                    </div>
                </div>

                <hr style={{ color: "red" }} />
                <div>
                    <p dangerouslySetInnerHTML={{ __html: Text }} ></p>
                </div>
            </ReactModal>

            <div style={{ textAlign: 'initial' }}>
                <section className="section grayfruit-bg section-height-800 parallax-container context-dark bg-gray-darkest text-xl-left" data-parallax-img={grayfruit_img}>
                    <div className="parallax-content">
                        <div className="bg-overlay-black">
                            <div className="container section-80 section-md-30 section-lg-top-10 section-lg-bottom-30" style={{ paddingTop: 6 }}>
                                <div className='d-flex d-lg-block'>
                                    <img
                                        src={BackIcon}
                                        alt="Back"
                                        className="d-lg-none d-md-none"
                                        onClick={() => { window.location.href = '/#/' }}
                                        style={{
                                            position: 'absolute',
                                            top: '101px',
                                            left: '10px',
                                            width: '8%',
                                            height: '16%',
                                            cursor: 'pointer'
                                        }}
                                    />
                                </div>
                                <div className="d-none d-lg-block">
                                    <h1 className='mt-4'>Grapefruit/Related&nbsp;Fruit&nbsp;Interactions</h1>
                                </div>
                                <div className="d-lg-none">
                                    <h5 className='d-flex justify-content-center' style={{ marginTop: '127px' }}>Grapefruit/Related&nbsp;Fruit&nbsp;Interactions</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="container mt-1">
                    <br />
                    <div className="row">
                        <p style={{ fontWeight: '400px', fontSize: 14, color: '#484343' }}>
                            Many drugs interact with grapefruit (juice, segments, extract) and related fruits
                            (e.g. Seville oranges, tangelos, minneolas, pummelos and certain exotic oranges).
                            Components in grapefruit/related fruits called furanocoumarins (two of which are bergamottin and 6'7'-dihydroxybergamottin)
                            irreversibly inhibit cytochrome p450 (CYP) 3A4 isoenzymes (3A4) in the intestinal wall, decreasing the pre-systemic metabolism of affected drugs taken up to 72 hours after grapefruit consumption.<br /><br />
                            Intestinal 3A4 activity remains inhibited during this time as the body produces enzymes. For drugs that are normally poorly absorbed due to significant pre-systemic 3A4 metabolism, grapefruit intake can lead to significantly higher blood levels, potentially resulting in increased therapeutic effects, adverse effects and/or toxicity.
                            In experimental studies, use of furanocoumarin-free grapefruit juice (produced by heat treatment or UV irradiation) with drugs proven to interact with normal grapefruit juice did not lead to increased blood levels, providing further confirmatory evidence for their role in enzyme inhibition.<br /><br /> Limited evidence
                            indicates that following intake of very large amounts of grapefruit, hepatic 3A4 may also be inhibited.
                            Orally administered drugs known to be metabolized by 3A4 with low oral bioavailability it may predict an interaction with grapefruit. Grapefruit weakly inhibits intestinal cell wall p-glycoprotein (p-GP), an efflux pump in enterocytes that actively secretes some absorbed drugs back into the gut lumen.
                            Not all 3A4-metabolized drugs are p-GP substrates. Organic anion transporting polypeptide (OATP) is a drug transporter system inhibited by grapefruit.
                            <br /> <br /> Drugs handled by OATP1A2 and OATP2B1 may have decreased absorption when taken with grapefruit, potentially leading to loss of efficacy.
                        </p> <br />
                    </div>

                    <div className="row text-center">
                        <div className="col-lg-8">
                            <select className="form-control col-lg-6" name="Interaction1" onChange={handleChange}>
                                <option value="0" selected>--Select Warning Level--</option>
                                {dropVal.map((val) => (
                                    <option value={val} key={val}>{val}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-4"></div>
                        <br />
                    </div>
                    <br />

                    <div>
                        {Grapefruit?.length ?
                            <div className="row" style={ParentDiv}>
                                <Table striped bordered hover>
                                    {result?.map((arr, index) => (
                                        <tr key={index}>
                                            {arr.map((itemVal, key) => (
                                                <td key={key}>
                                                    <a id="Gen-heading-hover" href="javascript:void(0)" onClick={() => { setModal(itemVal?.GenericName, itemVal?.intractions, itemVal?.intractions.length, itemVal?.generic_id) }}>
                                                        <p style={{ fontSize: "12px !important", fontFamily: "Open Sans, Helvetica, Arial, sans-serif !important" }}>
                                                            {itemVal?.GenericName.trim()}
                                                        </p>
                                                    </a>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </Table>
                            </div>
                            :
                            <center style={{ color: "red", fontSize: "18px" }}>No Record Found.</center>
                        }
                    </div>
                </div>
                <br />
            </div>
        </>
    );
}

export default Grapefruit;
