import React, { useState } from 'react';
import "./CustInputRange.scss";

const CustInputRangeWeight2 = (props) => {

  const { inputValWeight, setinputValWeight } = props;
  //   const [MinVal, setMinVal] = useState(29.6);
  //   const [MaxVal, setMaxVal] = useState(98.5);

  const updateRangeDisplay = (stepsVal) => {
    props.ErSms("");
    if (!isNaN(stepsVal) == true) {
      if (props.Tab == "Imperial") {
        if (stepsVal <= 76) {
          props.ErSms("Allowed Only above 77");
        }
        if (stepsVal >= 701) {
          setinputValWeight(700)
          props.ErSms("Allowed Only Less Than 700");
        } else {
          setinputValWeight(stepsVal);
        }
      } else if (props.Tab == "Metric") {
        if (stepsVal <= 34) {
          props.ErSms('Allowed Only Above 35')
        }
        if (stepsVal >= 319) {
          setinputValWeight(318)
          props.ErSms("Allowed Only Less Than 319.");
        } else {
          setinputValWeight(stepsVal);
        }
      }
    } else {
      props.ErSms("Only digits are allowed");
    }
  }
  return (
    <div className="form-group row customSlider">
      <div className='col-12'>
        <div className="ticks">
          <span className="tick">{props.minVal} </span>
          <span className="tick">{props.maxVal} </span>
        </div>
        <input type="range" style={{ width: '100%', marginLeft: '0px' }} value={(inputValWeight) ? inputValWeight : props.minVal} step={.1} min={props.minVal} name="hghtrng" max={props.maxVal} defaultValue={props.minVal} onChange={(event) => updateRangeDisplay(event.target.value)} />

      </div>
      <div className='col-7'>
        <label className='col-form-label sliderLabel' style={{ fontSize: 15, color: '#383030eb' }}>  Weight<span style={{ color: '#383030eb', fontSize: 13 }}>{"  " + props.label}</span></label>
      </div>
      <div className='col-5 text-right'>
        <input type="text" style={{ width: '100px', height: '38px' }} value={(inputValWeight == "0.0") ? "" : inputValWeight} min={props.minVal} max={props.maxVal} name="inputVal" maxlength="5" onChange={(e) => updateRangeDisplay(e.target.value)} id="input_val" />
      </div>
    </div>
  )
}

export default CustInputRangeWeight2;