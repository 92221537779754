import React, { Component } from 'react'
import { DummyImage } from "react-simple-placeholder-image";
import { SEARCH_API } from './../../services/Api/searching';
import { toast } from 'react-toastify'
import logo from '../../assets/images/logo/dni-logo.png'
import moment from 'moment';
import html2pdf from "html2pdf-fix-jspdf";
import ReactTooltip from "react-tooltip";
import toxic from '../../assets/toxic.png'
import queryString from 'query-string';
import { checkAuthToken } from '../../services/_helpers/checkAuthToken';
import "../../pages/DrugDetails/pdfCss.css"
import { AUTENTICATE_API } from '../../services/Api/authenticationApi';

class GuestDrugDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            drugDetails: "",
            userInfo: '',
            getGenericDetails: '',
            citationList: [],
            countryList: [],
            iconList: [],
            pharmocologicalClassList: [],
            therapeuticClassList: [],
            routeList: [],
            usesList: [],
            xrefList: [],
            brandList: [],
            doseFormList: [],
            dietList: [],
            warningList: [],
            adverseEffectList: [],
            labParameterList: [],
            noteList: [],
            brandName: '',
            pharmaClasses: '',
            theraClasses: '',
            routeNames: '',
            citationXRef: '',
            country_ids: '',
            filename: '',
            more: true,
            hideContainers: true,
            hideMoreCollaps: true,
            viewList: "",
            pmidUrl: 'https://pubmed.ncbi.nlm.nih.gov',
            iconListData: [],
            OralAdvList: [],
            GIAdvList: [],
            NutritionAdvList: [],
            usesOff: [],
            moreBrand: [],
            lessBrand: [],
            lessUses: [],
            moreUses: [],
            moreBrandFlag: false,
            moreUsesFlag: false,
            // Image Urls state

            "PharmClassImg": "",
            "TheraClassImg": "",
            "RoutesImg": "",
            "DoseFormsImg": "",
            "HowtoTakeImg": "",
            "DietImg": "",
            "GrapefruitInteractionsImg": "",
            "AlcoholInteractionsImg": "",
            "NaturalproductinteractionsImg": "",
            "WarningsImg": "",
            "RenalwarningsImg": "",
            "ProteinbindingImg": "",
            "DialysiswarningsImg": "",
            "HepaticwarningsImg": "",
            "PregnancywarningsImg": "",
            "BreastfeedingWarningsImg": "",
            "OralAdverseEffectsImg": "",
            "GIAdverseEffectsImg": "",
            "NutritionalAdverseEffectsImg": "",
            "OtherAdverseEffectsImg": "",
            "LabParametersImg": "",
            "MonitoringImg": "",
            "CrossReferenceImg": "",
            "CitationsImg": "",
            "UsesImg": '',
            'LactationImg': '',
            'AdverseImg': '',
            logo: false,
            GIzeroBlank: [],
            OralzeroBlank: [],
            NutzeroBlank: [],
            OtherzeroBlank: [],
            LabzeroBlank: [],
            advBool: false,
            labBool: false,
            NutriBool: false,
            GIBool: false,
            OralBool: false
        }
        this.myRef = React.createRef();
        this.pageStyle = "@page {size: A4 landscape;}";
    }

    componentDidMount() {
        // let city = (new URLSearchParams(window.location)).get("generic_id")
        // if (localStorage.getItem("setUser") != null) {
        //     let g_ids = window.location?.hash.slice(34)
        //     let info = localStorage.getItem("setUser")
        //     let userInfo = JSON.parse(info);

        //     // this.setState({ filename: fname })

        //     this.setState({ country_ids: userInfo?.userInfo?.country_view_id != '' ? userInfo?.userInfo?.country_view_id : userInfo?.userInfo?.country_id })
        //     // if (checkAuthToken.getTokenCheck() == true) {
        //     this.setState({ userInfo: userInfo?.userInfo }, () => {
        //         this.getView(this.state.userInfo.user_id)
        //     })
        //     // }

        // }
        this.getGeneric();
    }

    getIconList() {
        AUTENTICATE_API?.guestDrugIcons().then(res => {
            if (res && res?.data) {
                this.setState({ iconListData: res?.data }, () => {
                    this?.setState({ "PharmClassImg": this.state.iconListData?.filter(fl => fl?.body_system == "Pharm Class") })
                    this?.setState({ "TheraClassImg": this.state.iconListData?.filter(fl => fl?.body_system == "Thera Class") })
                    this?.setState({ "RoutesImg": this.state.iconListData?.filter(fl => fl?.body_system == "Routes") })
                    this?.setState({ "UsesImg": this.state.iconListData?.filter(fl => fl?.body_system == "Uses") })
                    this?.setState({ "HowtoTakeImg": this.state.iconListData?.filter(fl => fl?.body_system == "How to Take") })
                    this?.setState({ "DietImg": this.state.iconListData?.filter(fl => fl?.body_system == "Diet") })
                    this?.setState({ "GrapefruitInteractionsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Grapefruit Interactions") })
                    this?.setState({ "AlcoholInteractionsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Alcohol Interactions") })
                    this?.setState({ "NaturalproductinteractionsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Natural product interactions") })
                    this?.setState({ "WarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Warnings") })
                    this?.setState({ "DoseFormsImg": this.state.iconListData?.filter(fl => fl?.body_system == "DoseForms") })
                    this?.setState({ "RenalwarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Renal warnings") })
                    this?.setState({ "ProteinbindingImg": this.state.iconListData?.filter(fl => fl?.body_system == "Protein binding") })
                    this?.setState({ "DialysiswarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Dialysis warnings") })
                    this?.setState({ "HepaticwarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Hepatic warnings") })
                    this?.setState({ "PregnancywarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Pregnancy warnings") })
                    this?.setState({ "BreastfeedingWarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Breastfeeding Warnings") })
                    this?.setState({ "OralAdverseEffectsImg": this.state.iconListData?.filter(fl => fl?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Oral Adverse Effects" : "Oral Adverse Effects")) })
                    this?.setState({ "GIAdverseEffectsImg": this.state.iconListData?.filter(fl => fl?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust GI Adverse Effects" : 'GI Adverse Effects')) })
                    this?.setState({ "NutritionalAdverseEffectsImg": this.state.iconListData?.filter(fl => fl?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Nutritional Adverse Effects" : 'Nutritional Adverse Effects')) })
                    this?.setState({ "OtherAdverseEffectsImg": this.state.iconListData?.filter(fl => fl?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Other Adverse Effects" : "Other Adverse Effects")) })
                    this?.setState({ "LabParametersImg": this.state.iconListData?.filter(fl => fl?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Lab Parameters" : 'Lab Parameters')) })
                    this?.setState({ "MonitoringImg": this.state.iconListData?.filter(fl => fl?.body_system == "Monitoring") })
                    this?.setState({ "CrossReferenceImg": this.state.iconListData?.filter(fl => fl?.body_system == "Cross Reference") })
                    this?.setState({ "CitationsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Citations") })
                });
            }
        })
    }

    getView(id) {
        SEARCH_API.getViewList(id).then(res => {
            if (res && res?.data) {
                this.setState({ viewList: res?.data[0] })
            }
        })
    }

    getGeneric() {
        AUTENTICATE_API?.guestDrugDetails().then(res => {
            if (res && res?.data) {
                this.setState({ drugDetails: res?.data }, () => {
                    this.setState({ getGenericDetails: this.state.drugDetails[0][0] }, () => {
                        this.getIconList()
                    })
                    this.setState({
                        citationList: this.state.drugDetails[1],
                        countryList: this.state.drugDetails[2],
                        iconList: this.state.drugDetails[3],
                        pharmocologicalClassList: this.state.drugDetails[4].sort((a, b) => (a.PharmocologicalClasses > b.PharmocologicalClasses) ? 1 : ((b.PharmocologicalClasses > a.PharmocologicalClasses) ? -1 : 0)),
                        therapeuticClassList: this.state.drugDetails[5].sort((a, b) => (a.TherapeuticClasses > b.TherapeuticClasses) ? 1 : ((b.TherapeuticClasses > a.TherapeuticClasses) ? -1 : 0)),
                        routeList: this.state.drugDetails[6].sort((a, b) => (a.route_name > b.route_name) ? 1 : ((b.route_name > a.route_name) ? -1 : 0)),
                        // usesList: this.state.drugDetails[7]?.filter(fil => parseInt(this.state.country_ids) ===2? fil?.can_label==1:fil?.us_label),
                        usesList: this.state.drugDetails[7],
                        xrefList: this.state.drugDetails[8]?.filter(fil => parseInt(this.state.country_ids) === parseInt(fil?.country_id)),
                        brandList: this.state.drugDetails[9].filter(fl => this.state.country_ids == 2 ? fl.for_can : fl.for_us),
                        doseFormList: this.state.drugDetails[10].sort((a, b) => (a.doseform > b.doseform) ? 1 : ((b.doseform > a.doseform) ? -1 : 0)),
                        dietList: this.state.drugDetails[11],
                        warningList: this.state.drugDetails[12],
                        adverseEffectList: this.state.drugDetails[13],
                        labParameterList: this.state.drugDetails[14],
                        noteList: this.state.drugDetails[15],
                    }, () => {
                        // let usesOff = []
                        // for (let i = 0; i < this.state.usesList?.length; i++) {
                        //     if (this.state.userInfo?.country_id == 1 && this.state.userInfo?.country_view_id == 2 && this.state.usesList[i]?.us_label == 1 && this.state.usesList[i]?.can_label == 0) {
                        //         usesOff.push(this.state.usesList[i]?.Uses?.toLowerCase());
                        //     } else {
                        //         usesOff.push(this.state.usesList[i]?.Uses?.toUpperCase());
                        //     }
                        // }
                        // this.setState({ usesOff: usesOff }, () => {
                        //     let umore = []
                        //     if (this.state.usesOff?.length ) {
                        //         for (let i = 0; i < this.state.usesOff?.length; i++) {
                        //             umore.push(this.state.usesOff[i])
                        //         }
                        //         this.setState({ lessUses: umore });
                        //     } else {
                        //         this.setState({ lessUses: this.state.usesOff }, () => {
                        //         });
                        //     }
                        //     if (this.state.usesOff?.length >= 5) {
                        //         this.setState({ moreUses: this.state.usesOff });
                        //     }
                        // });
                        // Lab filter values
                        let labNAOrBlank = this.state.labParameterList?.filter(fl => fl?.rate == "NA" || fl?.rate == "" || fl?.rate == ".")?.sort((a, b) => (a.parameter?.toLocaleUpperCase() > b.parameter?.toLocaleUpperCase()) ? 1 : ((b.parameter?.toLocaleUpperCase() > a.parameter?.toLocaleUpperCase()) ? -1 : 0));

                        let labzeroBlank = this.state.labParameterList?.filter(fl => fl?.rate == '0')?.sort((a, b) => (a.parameter?.toLocaleUpperCase() > b.parameter?.toLocaleUpperCase()) ? 1 : ((b.parameter?.toLocaleUpperCase() > a.parameter?.toLocaleUpperCase()) ? -1 : 0));

                        let labnotZeroOrBlank = this.state.labParameterList?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != '' && fl?.rate != '.'));
                        this.setState({ LabzeroBlank: labzeroBlank });
                        let labfinalAdv = [];
                        labfinalAdv = [...labnotZeroOrBlank, ...labNAOrBlank, ...labzeroBlank];
                        this.setState({ labParameterList: labfinalAdv, commonLab: labfinalAdv, commLabTrue: labfinalAdv }, () => {
                            if (this.state.viewList?.lab_parameters == 3) {
                                let common = this.state.labParameterList.filter(filters => (parseInt(filters?.rate) >= this.state.getGenericDetails?.cut_off_rate) || (filters?.rate == "" && this.state.getGenericDetails?.cut_off_rate == null));
                                this.setState({ labParameterList: common, commLab: labfinalAdv, commLabTrue: common });
                            }
                        });


                        // Oral filter values
                        let oraFilter = this.state.adverseEffectList?.filter(fl => fl?.a_e_category == 'oral');
                        let OralfinalAdv = [];
                        // if (this.state.viewList?.oral_adverse_effects == 2) {
                        //     let commonOralFilter = oraFilter.filter(filters => filters?.rate >= this.state.getGenericDetails?.cut_off_rate);
                        //     let OralNAOrBlank = commonOralFilter?.filter(fl => fl?.rate == "NA" || fl?.rate == "");
                        //     let OralzeroBlank = commonOralFilter?.filter(fl => fl?.rate == '0');
                        //     this.setState({ OralzeroBlank: OralzeroBlank });
                        //     let OralnotZeroOrBlank = commonOralFilter?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != ''));
                        //     OralfinalAdv = [...OralnotZeroOrBlank, ...OralNAOrBlank, ...OralzeroBlank];
                        // } else {
                        let OralNAOrBlank = oraFilter?.filter(fl => fl?.rate == "NA" || fl?.rate == "" || fl?.rate == '.')?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0));
                        let OralzeroBlank = oraFilter?.filter(fl => fl?.rate == '0')?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != '' && fl?.rate != '.'));
                        this.setState({ OralzeroBlank: OralzeroBlank })
                        let OralnotZeroOrBlank = oraFilter?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != '' && fl?.rate != '.'));
                        // let OralfinalAdv = [];
                        OralfinalAdv = [...OralnotZeroOrBlank, ...OralNAOrBlank, ...OralzeroBlank];
                        // }




                        // GI Adverse Effect Filter
                        let GIFilter = this.state.adverseEffectList?.filter(fl => fl?.a_e_category == 'GI');

                        let Liv = this.state.adverseEffectList?.filter(fl => fl?.a_e_category == "liver");
                        GIFilter = [...GIFilter, ...Liv]

                        let GIzeroBlank = GIFilter?.filter(fl => fl?.rate == '0')?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0))
                        // console.log("Zero",GIzeroBlank)
                        let GIfinalAdv = [];
                        // if (this.state.viewList?.gi_adverse_effects == 2) {
                        //     let commonGIFilter = GIFilter.filter(filters => filters?.rate >= this.state.getGenericDetails?.cut_off_rate);
                        //     let GINAOrBlank = commonGIFilter?.filter(fl => fl?.rate == "NA" || fl?.rate == "");
                        //     let GIzeroBlank = commonGIFilter?.filter(fl => fl?.rate == '0');
                        //     this.setState({ GIzeroBlank: GIzeroBlank })
                        //     let GInotZeroOrBlank = commonGIFilter?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != ''));
                        //     GIfinalAdv = [...GInotZeroOrBlank, ...GINAOrBlank, ...GIzeroBlank]
                        // } else {
                        let GINAOrBlank = GIFilter?.filter(fl => fl?.rate == "NA" || fl?.rate == "" || fl?.rate == ".")?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0))
                        this.setState({ GIzeroBlank: GIzeroBlank })
                        let GInotZeroOrBlank = GIFilter?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != '' && fl?.rate != '.'));
                        GIfinalAdv = [...GInotZeroOrBlank, ...GINAOrBlank, ...GIzeroBlank];


                        // }
                        // Nutrition Adv Filter list
                        let NutritionFilter = this.state.adverseEffectList?.filter(fl => fl?.a_e_category == 'nutrition');
                        let NutritionGIzeroBlank = NutritionFilter?.filter(fl => fl?.rate == '0');
                        let NutritionGIfinalAdv = [];
                        // if (this.state.viewList?.nutrition_adverse_effects == 2) {
                        //     // let commonNutritionFilter = NutritionFilter.filter(filters => filters?.rate >= this.state.getGenericDetails?.cut_off_rate);
                        //     let NutritionGINAOrBlank = commonNutritionFilter?.filter(fl => fl?.rate == "NA" || fl?.rate == "");
                        //     let NutritionGIzeroBlank = commonNutritionFilter?.filter(fl => fl?.rate == '0');
                        //     this.setState({ NutzeroBlank: NutritionGIzeroBlank });
                        //     let NutritionGInotZeroOrBlank = commonNutritionFilter?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != ''));
                        //     NutritionGIfinalAdv = [...NutritionGInotZeroOrBlank, ...NutritionGINAOrBlank, ...NutritionGIzeroBlank];
                        // } else {
                        let NutritionGINAOrBlank = NutritionFilter?.filter(fl => fl?.rate == "NA" || fl?.rate == "" || fl?.rate == '.')?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0));
                        this.setState({ NutzeroBlank: NutritionGIzeroBlank })
                        let NutritionGInotZeroOrBlank = NutritionFilter?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != '' && fl?.rate != '.'));
                        NutritionGIfinalAdv = [...NutritionGInotZeroOrBlank, ...NutritionGINAOrBlank, ...NutritionGIzeroBlank];


                        // }

                        this.setState({
                            OralAdvList: OralfinalAdv,
                            OralCommonTrue: OralfinalAdv,
                            GIAdvList: GIfinalAdv,
                            GICommonTrue: GIfinalAdv,
                            NutritionAdvList: NutritionGIfinalAdv,
                            NutritionCommonTrue: NutritionGIfinalAdv
                        }, () => {
                            if (this.state.viewList?.nutrition_adverse_effects == 3) {
                                let commonNutritionFilter = NutritionGIfinalAdv.filter(filters => filters?.rate >= this.state.getGenericDetails?.cut_off_rate || (filters?.rate == "." && this.state.getGenericDetails?.cut_off_rate == null));
                                this.setState({ commNutrition: NutritionGIfinalAdv, NutritionAdvList: commonNutritionFilter, NutritionCommonTrue: commonNutritionFilter });
                            }
                            if (this.state.viewList?.gi_adverse_effects == 3) {
                                let commonGIFilter = GIfinalAdv.filter(filters => filters?.rate >= this.state.getGenericDetails?.cut_off_rate);
                                this.setState({ commGI: GIfinalAdv, GIAdvList: commonGIFilter, GICommonTrue: commonGIFilter });
                            }
                            if (this.state.viewList?.oral_adverse_effects == 3) {
                                let commonOralFilter = oraFilter.filter(filters => filters?.rate >= this.state.getGenericDetails?.cut_off_rate);
                                this.setState({ OralAdvList: commonOralFilter, commOral: OralfinalAdv, OralCommonTrue: commonOralFilter })
                            }
                        });


                        //Other Adverse Effects 
                        let OnlyOtherAvdFilter = this.state.adverseEffectList?.filter(fl => (fl?.a_e_category != 'oral' && fl?.a_e_category != 'GI' && fl?.a_e_category != 'nutrition' && fl?.a_e_category != 'liver'));
                        let NAOrBlank = OnlyOtherAvdFilter?.filter(fl => fl?.rate == "NA" || fl?.rate == "" || fl?.rate == '.')?.sort((a, b) => (a.adverse?.toLocaleUpperCase() > b.adverse?.toLocaleUpperCase()) ? 1 : ((b.adverse?.toLocaleUpperCase() > a.adverse?.toLocaleUpperCase()) ? -1 : 0));




                        let zeroBlank = OnlyOtherAvdFilter?.filter(fl => fl?.rate == '0');
                        this.setState({ OtherzeroBlank: zeroBlank })
                        let notZeroOrBlank = OnlyOtherAvdFilter?.filter(fl => (fl?.rate != '0' && fl?.rate != 'NA' && fl?.rate != '' && fl?.rate != '.'));
                        let finalAdv = [];
                        finalAdv = [...notZeroOrBlank, ...NAOrBlank, ...zeroBlank];
                        this.setState({ adverseEffectList: finalAdv }, () => {
                            if (this.state.viewList?.other_adverse_effects == 3) {
                                let OnlyOtherAvdFilterComm = OnlyOtherAvdFilter.filter(filters => filters?.rate >= this.state.getGenericDetails?.cut_off_rate);
                                this.setState({ adverseEffectList: OnlyOtherAvdFilterComm });
                            }
                        });
                        this.setState({ OralAdvList: OralfinalAdv, GIAdvList: GIfinalAdv, NutritionAdvList: NutritionGIfinalAdv });
                        if (this.state.country_ids == '1') {
                            let filter2 = this.state.doseFormList?.filter(fl => fl?.us_doseform == 1)
                            this.setState({ doseFormList: filter2 });
                        }
                        if (this.state.country_ids == '2') {
                            let filter2 = this.state.doseFormList?.filter(fl => fl?.can_doseform == 1)
                            this.setState({ doseFormList: filter2 })
                        }

                        if (this.state.country_ids == 1) {
                            let filter2 = this.state.citationList?.filter(fl => fl?.for_us == 1)
                            this.setState({ citationList: filter2 })
                        }
                        if (this.state.country_ids == 3) {
                            let filter2 = this.state.citationList?.filter(fl => fl?.for_can == 1);
                            this.setState({ citationList: filter2 })
                        };

                        var tmp = [];
                        var prArr = [];
                        for (var i = 0; i < this.state.xrefList.length; i++) {
                            for (var j = 0; j < this.state.xrefList.length; j++) {
                                if (this.state.xrefList[i].xref == this.state.xrefList[j].xref) {
                                    tmp.push(this.state.xrefList[j].country_id);
                                }
                            }
                            prArr.push({ xref: this.state.xrefList[i].xref, country_id: tmp, generic_id: this.state.xrefList[i].generic_id });
                            tmp = [];
                        }
                        const arrayUniqueByKeyprArr = [...new Map(prArr.map(item =>
                            [item['xref'], item])).values()];
                        this.setState({ xrefList: arrayUniqueByKeyprArr });

                        // this.setState({ citationXRef: cita?.length >= 0 ? cita.toString() : '' });
                        let brands = this.state.brandList?.map((brand, i) => {
                            return brand?.brand?.trim() + `${i != this.state.brandList?.length - 1 ? ', ' : ''}`
                        });
                        let bmore = []
                        if (this.state.brandList?.length) {
                            for (let i = 0; i < this.state.brandList?.length; i++) {
                                bmore.push(this.state.brandList[i])
                            }
                            this.setState({ lessBrand: bmore });
                        } else {
                            this.setState({ lessBrand: this.state.brandList });
                        }

                        if (this.state.brandList?.length >= 10) {
                            this.setState({ moreBrand: this.state.brandList });
                        }



                        if (this.state.brandList?.length >= 5) {
                            this.setState({ moreUses: this.state.usesOff });
                        }


                        this.setState({ brandName: brands?.length >= 0 ? brands : '' }, () => {
                        });
                        let phrma = this.state.pharmocologicalClassList?.map(ph => {
                            return " " + ph?.PharmocologicalClasses
                        })
                        this.setState({ pharmaClasses: phrma?.length >= 0 ? phrma.toString() : '' });
                        let thera = this.state.therapeuticClassList?.map(th => {
                            return " " + th?.TherapeuticClasses
                        })
                        this.setState({ theraClasses: thera?.length >= 0 ? thera.toString() : '' });
                        let rout = this.state.routeList?.map(th => {
                            return " " + th?.route_name
                        })
                        this.setState({ routeNames: rout?.length >= 0 ? rout.toString() : '' });
                    })
                })
            }
        })
    }




    render() {
        return (
            <div>
                <section className="section" id="ddl" ref={this.myRef} >
                    <h5 style={{ margin: 'auto', display: "none" }} id="setDrugDetails">
                        {/* Drug Details */}
                    </h5>
                    {/* <div className="container pt-5 pl-5 pr-5" style={{ marginTop: '10px' }}></div> */}
                    <div className="container container-top">
                        <div className='row mt-6' >
                            <div className='col-lg-12 text-left drug-details-list' >
                                {/* <div className='row'>
                                    <div className='col-md-6' >
                                        <div style={{ width: 10, color: 'white' }}>
                                            NA
                                        </div>
                                        {this.state.logo == false ? <img id="image-logo" style={{ marginLeft: '275px', marginTop: '-54px' }} src={logo} width='100' /> : ''}
                                    </div>
                                    <div className='col-md-6' >
                                        <div style={{ width: 10, color: 'white' }}>
                                            NA
                                        </div>
                                        {this.state.logo == false ? <>
                                            <p style={{ color: 'black', marginLeft: '150px', marginTop: '-27px' }}>Downloaded on: {moment(new Date()).format('MM/DD/YYYY')}</p>
                                            <p style={{ color: 'black', marginLeft: '150px', marginTop: '-10px' }}>
                                                {this.state.userInfo?.country_view_id == 2 ? "Canadian" : 'USA'} warnings/products
                                            </p>
                                        </>
                                            : ''}
                                    </div>
                                </div> */}
                                <div className='mt-4'>
                                    {this.state.getGenericDetails?.GenericName !== "" ? <>
                                        <div className='row' style={{ alignItems: 'center' }}>
                                            <div className='order-2 order-lg-1 mt-2 mt-lg-0 col-lg-4 col-12'>
                                                <h5 className=' text-sm-center text-lg-start d-flex justify-content-center justify-content-lg-start' style={{ color: "#15678F", marginTop: '-30px' }}>
                                                    semaglutide
                                                </h5>
                                            </div>
                                        </div>
                                    </> : ''}
                                </div>
                                <div className='ml-2'>
                                    {this.state.lessBrand != [] ?
                                        <div className='row justify-content-center justify-content-lg-start' style={{ fontWeight: 'bold', fontSize: 16 }}>
                                            ({this.state.lessBrand?.length > 0 ? this.state.lessBrand?.map((val, id) => <span key={id}>
                                                <span>{val?.brand?.trim()}</span>{id !== this.state.lessBrand?.length - 1 ? <span style={{}}>, <span style={{ marginLeft: 2 }}></span> </span> : ''}
                                            </span>) : ""}

                                            {this.state.lessBrand?.length == 10 ? <span id="span_dot"> ...<span className='badge badge-secondary'

                                                id="doseform-badge-id1" style={{ color: 'whitesmoke', cursor: 'pointer' }}></span> </span> : this.state.lessBrand?.length > 10 ? <span id="span_dot"><span className='badge badge-secondary'

                                                    id="doseform-badge-id1" style={{ color: 'whitesmoke', cursor: 'pointer' }}></span> </span> : ""}
                                            )
                                        </div>
                                        : ""}
                                </div>
                                {/* alternatename */}

                                {this.state.getGenericDetails?.alternate_names ? <div style={{ fontWeight: 'bold', color: 'rgb(88 94 98 / 81%)', fontSize: 16, marginLeft: "-5px" }}>
                                    also known as: {this.state.getGenericDetails?.alternate_names}
                                </div> : ''}


                                {
                                    this.state.usesList?.length > 0 ? <div className='d-flex'>
                                        <div className="mt-1 " style={{ width: 50 }} >
                                            {
                                                this.state.UsesImg[0]?.body_system == "Uses" ?
                                                    <div class="click-to-top uss-ico">
                                                        <img src={this.state.UsesImg[0].iconImg} width={40} height={40} data-tip="Uses"
                                                        />
                                                        <ReactTooltip place="left" type="dark" effect="float" />
                                                        {/* <span>Uses</span> */}
                                                    </div>

                                                    : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-2 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                            }
                                        </div>
                                        <div className='uss-of-uss' style={{ marginTop: 16, marginLeft: 25, width: "100%" }}>
                                            {this.state.usesList?.map((uses, i) =>
                                                <div className='row' key={i} style={{
                                                    textTransform: (this.state.country_ids == 2 && uses?.can_label == 1) ? 'uppercase'
                                                        : (this.state.country_ids != 2 && uses?.us_label == 1) ? 'uppercase' : '',
                                                    fontSize: 14, fontWeight: 'bold', color: '#3d3b3b', width: "100%"
                                                }}>
                                                    {uses?.Uses}

                                                </div>

                                            )}
                                        </div>

                                    </div> : ''
                                }<br />
                                <div className="drug-info mb-3 d-flex" style={{ marginTop: '-16px', color: 'black' }}>
                                    {
                                        this.state.PharmClassImg[0]?.body_system == "Pharm Class" ?
                                            <>
                                                <img src={this.state.PharmClassImg[0].iconImg} width={40} height={40} className=' mr-3 '
                                                    data-tip="Pharmacological Class" data-for="Pharmacological Class" />
                                                <ReactTooltip id="Pharmacological Class" place="left" type="dark" effect="float" />
                                            </>

                                            : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                    }
                                    <p className="mt-2" style={{ fontSize: 16 }}>{this.state.pharmaClasses}</p>
                                </div>
                                <div className="drug-info mb-3 d-flex" style={{ marginTop: '-9px', color: 'black' }}>
                                    {
                                        this.state.TheraClassImg[0]?.body_system == "Thera Class" ?
                                            <>
                                                <img src={this.state.TheraClassImg[0].iconImg} width={40} height={40} className=' mr-3'
                                                    data-tip="Therapeutic Class" data-for="Therapeutic Class"
                                                />
                                                <ReactTooltip id="Therapeutic Class" place="left" type="dark" effect="float" />
                                            </>
                                            : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                    }
                                    <p className="mt-2" style={{ fontSize: 16 }}>{this.state.theraClasses}</p>
                                </div>
                                {
                                    (this.state.viewList?.routes == 1 || this.state.viewList?.routes == 0) ? <>
                                        <div className="drug-info mb-3 d-flex" style={{ marginTop: '-12px', color: 'black' }}>
                                            {
                                                this.state.RoutesImg[0]?.body_system == "Routes" ?
                                                    <>
                                                        <img src={this.state.RoutesImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                            data-tip="Routes" data-for="Routes" />
                                                        <ReactTooltip id="Routes" place="left" type="dark" effect="float" />
                                                    </>
                                                    : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                            }


                                            <p className="mt-2" style={{ fontSize: 16 }}>{this.state.routeNames}</p>
                                        </div>
                                    </> : ""
                                }
                                {
                                    (this.state.doseFormList?.length > 0) ? <>
                                        <div className="drug-info mb-3 d-flex" id="headingDoseForm" data-toggle="collapse"
                                            data-target="#collapseDoseForm" aria-expanded="false" aria-controls="collapseDoseForm" style={{ marginTop: '-12px', cursor: 'pointer' }}>

                                            {
                                                this.state.DoseFormsImg[0]?.body_system == "DoseForms" ?
                                                    <>
                                                        <img src={this.state.DoseFormsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                            data-tip="Dose Forms" data-for="Dose Forms"
                                                        />
                                                        <ReactTooltip id="Dose Forms" place="left" type="dark" effect="float" />
                                                    </>
                                                    : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                            }
                                            &nbsp; &nbsp;<p className="mt-2" style={{ marginLeft: '-12px', fontSize: 16, color: 'black' }}>
                                                {this.state.doseFormList[0]?.doseform}
                                                {this.state.doseFormList[0]?.doseform?.doesform_text ? ' ' + this.state.doseFormList[0]?.doseform?.doesform_text : ''}&nbsp;&nbsp; <span className='badge badge-secondary' id="doseform-badge-id" style={{ color: 'whitesmoke' }}></span>
                                            </p>
                                        </div>
                                    </> : ''
                                }
                                {
                                    this.state.doseFormList?.length > 0 ? <>
                                        <div id="collapseDoseForm" className="" aria-labelledby="headingDoseForm" data-parent="#drugDetailsAccordian1" >
                                            {this.state.doseFormList?.map((dose, i) =>
                                                <>
                                                    <div className='row col-lg-12'>
                                                        <p style={{ fontSize: 15, paddingBottom: 0, marginLeft: 40, marginTop: '-6px', color: 'black' }}>
                                                            <div className='ml-3 mt-0' id="headingDoseFormChild" data-toggle="collapse" data-target={`#${dose?.doseform_id}`} aria-expanded="false" aria-controls="collapseDoseFormChild"
                                                                style={{ cursor: 'pointer' }}>
                                                                {dose?.doseform} {dose?.doesform_text ? ' ' + dose?.doesform_text : ""}&nbsp;&nbsp; <span className='badge badge-secondary' id="doseform-badge-id1" style={{ color: 'whitesmoke' }}></span>
                                                            </div>
                                                            <div id={dose?.doseform_id} className='row' aria-labelledby="headingDoseFormChild" data-parent="#drugDetailsAccordian1">
                                                                <div className='row ml-2'>
                                                                    {/* {
                                                                        dose.chlidExp?.map(val => <>
                                                                            <div className='col-lg-12' style={{ color: 'rgb(21 103 143 / 68%)' }}>
                                                                                <span></span>&nbsp;&nbsp;<span>{val?.excipient}&nbsp;{val?.excipient_qty ? <span>({val?.excipient_qty})</span> : ''}</span>
                                                                            </div>
                                                                        </>)
                                                                    } */}
                                                                    {/* {this.state.doseFormList.length == i + 1 ?
                                                                        <a id="exp_urls" href='https://drugnutritioninteractions.com/' className='ml-4' target={'_blank'}>
                                                                            Visit the DNI website to view non-medicinal ingredients (excipients). Generic form may be available with different excipients.
                                                                        </a>
                                                                        : ""} */}
                                                                </div>
                                                            </div>
                                                        </p>

                                                    </div>
                                                </>
                                            )}

                                        </div>
                                    </> : ""
                                }
                                {this.state.dietList[0]?.how_to_take == "" ? <br /> : ''}
                                {
                                    (this.state.dietList?.length > 0 && this.state.dietList[0]?.how_to_take != "") ? <>
                                        <div className="drug-info mb-3 d-flex" style={{ marginTop: '-12px', color: 'black' }}>
                                            {
                                                this.state.HowtoTakeImg[0]?.body_system == "How to Take" ?
                                                    <>
                                                        <img src={this.state.HowtoTakeImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                            data-for="How to take" data-tip="How to take"
                                                        />
                                                        <ReactTooltip id="How to take" place="left" type="dark" effect="float" />
                                                    </>
                                                    : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                            }
                                            <p className="mt-2" style={{ marginLeft: '-2px', fontSize: 16 }}>
                                                {this.state.country_ids == 2 ?
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.dietList[0]?.how_to_take?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.how_to_take : this.state.dietList[0]?.how_to_take }}></div> :
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.dietList[0]?.how_to_take?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.how_to_take?.split('Canadian labelling:')[0] : this.state.dietList[0]?.how_to_take }}></div>
                                                }
                                            </p>
                                        </div>
                                    </> : ''
                                }
                                {
                                    (this.state.dietList?.length > 0 && this.state.dietList[0]?.diet_interactions != "") ? <>
                                        <div className="drug-info mb-0 d-flex" style={{ marginTop: '-21px', color: 'black' }}>
                                            {
                                                this.state.DietImg[0]?.body_system == "Diet" ?
                                                    <>
                                                        <img src={this.state.DietImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                            data-for="Diet" data-tip="Diet"
                                                        />
                                                        <ReactTooltip id="Diet" place="left" type="dark" effect="float" />
                                                    </>
                                                    : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                            }
                                            <p className="mt-2" style={{ fontSize: 16 }}>
                                                {this.state.country_ids == 2 ?
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.dietList[0]?.diet_interactions?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.diet_interactions : this.state.dietList[0]?.diet_interactions }}></div> :
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.dietList[0]?.diet_interactions?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.diet_interactions?.split('Canadian labelling:')[0] : this.state.dietList[0]?.diet_interactions }}></div>
                                                }
                                            </p>
                                        </div>
                                    </> : ''
                                }
                                {
                                    this.state.hideContainers === true ? <>
                                        <div>
                                            {
                                                (this.state.dietList?.length > 0 && this.state.dietList[0]?.grapefruit_interactions) ? <>
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '2px', color: 'black' }}>
                                                        {
                                                            this.state.GrapefruitInteractionsImg[0]?.body_system == "Grapefruit Interactions" ?
                                                                <>
                                                                    <img src={this.state.GrapefruitInteractionsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                        data-tip="Grapefruit Interactions" data-for="Grapefruit Interactions"
                                                                    />
                                                                    <ReactTooltip id="Grapefruit Interactions" place="left" type="dark" effect="float" />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        } <p className="mt-2" style={{ fontSize: 16 }}>
                                                            {this.state.country_ids == 2 ?
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.dietList[0]?.grapefruit_interactions?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.grapefruit_interactions : this.state.dietList[0]?.grapefruit_interactions }}></div> :
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.dietList[0]?.grapefruit_interactions?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.grapefruit_interactions?.split('Canadian labelling:')[0] : this.state.dietList[0]?.grapefruit_interactions }}></div>
                                                            }
                                                        </p>
                                                    </div>
                                                </> : ''
                                            }
                                            {this.state.dietList[0]?.grapefruit_interactions == "" ? <br /> : ''}
                                            {
                                                (this.state.dietList?.length > 0 && this.state.dietList[0]?.alcohol_interactions) ? <>
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-14px', color: 'black' }}>
                                                        {
                                                            this.state.AlcoholInteractionsImg[0]?.body_system == "Alcohol Interactions" ?
                                                                <>
                                                                    <img src={this.state.AlcoholInteractionsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                        data-for="Alcohol Interactions" data-tip="Alcohol Interactions"
                                                                    />
                                                                    <ReactTooltip id="Alcohol Interactions" place="left" type="dark" effect="float" />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }
                                                        <p className="mt-2" style={{ fontSize: 16 }}>
                                                            {this.state.country_ids == 2 ?
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.dietList[0]?.alcohol_interactions?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.alcohol_interactions : this.state.dietList[0]?.alcohol_interactions }}></div> :
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.dietList[0]?.alcohol_interactions?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.alcohol_interactions?.split('Canadian labelling:')[0] : this.state.dietList[0]?.alcohol_interactions }}></div>
                                                            }
                                                            {/* <div id="grapft" dangerouslySetInnerHTML={{ __html: this.state.dietList[0]?.alcohol_interactions }}></div> */}
                                                            {/* <span className='arrow-up d-inline-block'></span> bleeding risk. */}
                                                        </p>
                                                    </div>
                                                </> : ''
                                            }
                                            {
                                                (this.state.dietList[0]?.natural_product_interactions) ? <>
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-14px', color: 'black' }}>
                                                        {
                                                            this.state.NaturalproductinteractionsImg[0]?.body_system == "Natural product interactions" ?
                                                                <>
                                                                    <img src={this.state.NaturalproductinteractionsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                        data-for="Natural Products" data-tip="Natural Products"
                                                                    />
                                                                    <ReactTooltip id="Natural Products" place="left" type="dark" effect="float" />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }

                                                        <p className="mt-2" style={{ fontSize: 16 }}>
                                                            {this.state.country_ids == 2 ?
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.dietList[0]?.natural_product_interactions?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.natural_product_interactions : this.state.dietList[0]?.natural_product_interactions }}></div> :
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.dietList[0]?.natural_product_interactions?.includes('Canadian labelling:') == true ? this.state.dietList[0]?.natural_product_interactions?.split('Canadian labelling:')[0] : this.state.dietList[0]?.natural_product_interactions }}></div>
                                                            }
                                                            {/* <div dangerouslySetInnerHTML={{ __html: this.state.dietList[0]?.natural_product_interactions }}></div> */}
                                                            {/* <span className='arrow-up d-inline-block'></span> bleeding risk. */}
                                                        </p>
                                                    </div>
                                                </> : ""
                                            }
                                            {
                                                this.state.warningList?.length > 0 ? <>
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-14px', color: 'black' }}>
                                                        {
                                                            this.state.WarningsImg[0]?.body_system == "Warnings" ?
                                                                <>
                                                                    <img src={this.state.WarningsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                        data-tip="Warnings" data-for="Warnings"
                                                                    />
                                                                    <ReactTooltip id="Warnings" place="left" type="dark" effect="float" />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }
                                                        <p className='mt-2' style={{ fontSize: 16 }}>
                                                            {this.state.userInfo?.country_view_id == 1 ?
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.us_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.us_warning?.split('Canadian labelling:')[1] : this.state.warningList[0]?.us_warning }}></div> :
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.can_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.can_warning?.split('Canadian labelling:')[0] : this.state.warningList[0]?.can_warning }}></div>
                                                            }
                                                            {/* <div dangerouslySetInnerHTML={{ __html: this.state.country_ids == '1' ? this.state.warningList[0]?.us_warning : this.state.warningList[0]?.can_warning }}></div> */}
                                                        </p>
                                                    </div>
                                                </> : ''
                                            }
                                            {
                                                (this.state.warningList[0]?.protein_binding) ? <>
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-14px', color: 'black' }}>
                                                        {
                                                            this.state.ProteinbindingImg[0]?.body_system == "Protein binding" ?
                                                                <>
                                                                    <img src={this.state.ProteinbindingImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                        data-tip="Protein Binding" data-for="Protein Binding"
                                                                    />
                                                                    <ReactTooltip id="Protein Binding" place="left" type="dark" effect="float" />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }
                                                        <p className='mt-2' style={{ fontSize: 16 }}>
                                                            {this.state.country_ids == 2 ?
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.protein_binding?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.protein_binding : this.state.warningList[0]?.protein_binding }}></div> :
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.protein_binding?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.protein_binding?.split('Canadian labelling:')[0] : this.state.warningList[0]?.protein_binding }}></div>
                                                            }
                                                        </p>
                                                    </div>
                                                </> : ""
                                            }
                                            {
                                                (this.state.viewList?.renal_warning == 1 || this.state.viewList?.renal_warning == 0) ? <>
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-14px', color: 'black' }}>
                                                        {
                                                            this.state.RenalwarningsImg[0]?.body_system == "Renal warnings" ?
                                                                <>
                                                                    <img src={this.state.RenalwarningsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                        data-for="Renal Warnings" data-tip="Renal Warnings"
                                                                    />
                                                                    <ReactTooltip id="Renal Warnings" place="left" type="dark" effect="float" />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }
                                                        <p className='mt-2' style={{ fontSize: 16 }}>
                                                            {this.state.country_ids == 2 ?
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.protein_binding?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.protein_binding : this.state.warningList[0]?.protein_binding }}></div> :
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.protein_binding?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.protein_binding?.split('Canadian labelling:')[0] : this.state.warningList[0]?.protein_binding }}></div>
                                                            }
                                                            {/* <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.renal_warning }}></div> */}
                                                        </p>
                                                    </div>
                                                </> : ''
                                            }
                                            {
                                                (this.state.viewList?.dialysis == 1 || this.state.viewList?.dialysis == 0) ? <>
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-14px', color: 'black' }}>
                                                        {
                                                            this.state.DialysiswarningsImg[0]?.body_system == "Dialysis warnings" ?
                                                                <>
                                                                    <img src={this.state.DialysiswarningsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                        data-for='Dialysis Warnings' data-tip='Dialysis Warnings'
                                                                    />
                                                                    <ReactTooltip id="Dialysis Warnings" place="left" type="dark" effect="float" />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }
                                                        <p className='mt-2' style={{ fontSize: 16 }}>
                                                            {this.state.country_ids == 2 ?
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.dialysis_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.dialysis_warning : this.state.warningList[0]?.dialysis_warning }}></div> :
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.dialysis_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.dialysis_warning?.split('Canadian labelling:')[0] : this.state.warningList[0]?.dialysis_warning }}></div>
                                                            }
                                                        </p>
                                                    </div>
                                                </> : ''
                                            }
                                            {
                                                (this.state.viewList?.hepatic_warning == 1 || this.state.viewList?.hepatic_warning == 0) ? <>
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-14px', color: 'black' }}>
                                                        {
                                                            this.state.HepaticwarningsImg[0]?.body_system == "Hepatic warnings" ?
                                                                <>
                                                                    <img src={this.state.HepaticwarningsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                        data-for="Hepatic Warnings" data-tip="Hepatic Warnings"
                                                                    />
                                                                    <ReactTooltip id="Hepatic Warnings" place="left" type="dark" effect="float" />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }
                                                        <p className='mt-2' style={{ fontSize: 16 }}>
                                                            {this.state.country_ids == 2 ?
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.hepatic_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.hepatic_warning : this.state.warningList[0]?.hepatic_warning }}></div> :
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.hepatic_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.hepatic_warning?.split('Canadian labelling:')[0] : this.state.warningList[0]?.hepatic_warning }}></div>
                                                            }
                                                        </p>
                                                    </div>
                                                </> : ''
                                            }
                                            {
                                                (this.state.viewList?.pregnancy_warnings == 1 || this.state.viewList?.pregnancy_warnings == 0) ? <>
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-14px', color: 'black' }}>
                                                        {
                                                            this.state.PregnancywarningsImg[0]?.body_system == "Pregnancy warnings" ?
                                                                <>
                                                                    <img src={this.state.PregnancywarningsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                        data-for="Pregnancy Warnings"
                                                                        data-tip='Pregnancy Warnings'
                                                                    />
                                                                    <ReactTooltip id="Pregnancy Warnings" place="left" type="dark" effect="float" />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }
                                                        <p className='mt-2' style={{ fontSize: 16 }}>
                                                            {this.state.country_ids == 2 ?
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.pregnancy_warnings?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.pregnancy_warnings : this.state.warningList[0]?.pregnancy_warnings }}></div> :
                                                                <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.pregnancy_warnings?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.pregnancy_warnings?.split('Canadian labelling:')[0] : this.state.warningList[0]?.pregnancy_warnings }}></div>
                                                            }
                                                        </p>
                                                    </div>
                                                </> : ''
                                            }
                                            {
                                                (this.state.viewList?.lactation_warnings == 1 || this.state.viewList?.lactation_warnings == 0) ? <>
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-14px', color: 'black' }}>
                                                        {
                                                            this.state.BreastfeedingWarningsImg[0]?.body_system == "Breastfeeding Warnings" ?
                                                                <>
                                                                    <img src={this.state.BreastfeedingWarningsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                        data-tip="Lactation Warnings" data-for='Lactation Warnings'
                                                                    />
                                                                    <ReactTooltip id="Lactation Warnings" place="left" type="dark" effect="float" />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }
                                                        <p className="m-0">
                                                            <p className='mt-2' style={{ fontSize: 16 }}>
                                                                {this.state.country_ids == 2 ?
                                                                    <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.lactation_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.lactation_warning : this.state.warningList[0]?.lactation_warning }}></div> :
                                                                    <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.lactation_warning?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.lactation_warning?.split('Canadian labelling:')[0] : this.state.warningList[0]?.lactation_warning }}></div>
                                                                }
                                                            </p>
                                                        </p>
                                                    </div>
                                                    <div style={{ height: 11 }}></div>
                                                </> : ''
                                            }

                                            {/* Oral */}
                                            {
                                                (this.state.OralAdvList?.length > 0) ? <>
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-22px', color: 'black' }}>
                                                        {
                                                            this.state.OralAdverseEffectsImg[0]?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Oral Adverse Effects" : "Oral Adverse Effects") ?
                                                                <>
                                                                    <ReactTooltip id="Oral Adverse Effects" place="left" type="dark" effect="float" />
                                                                    <img src={this.state.OralAdverseEffectsImg[0].iconImg} width={40} height={40} className=' icon-img'
                                                                        data-tip='Oral Adverse Effects' data-for='Oral Adverse Effects'
                                                                    />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className='  icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }
                                                        <p className="" style={{ marginLeft: 10 }}>
                                                            <p className='' style={{ fontSize: 16, marginTop: '-18px', marginLeft: 8 }}>
                                                                {this.state.viewList?.oral_adverse_effects == 0 ? <>
                                                                    {this.state.OralBool == true ? <span onClick={(e) => {
                                                                        this.setState({ OralBool: !this.state.OralBool })
                                                                    }} className='badge badge-secondary' style={{ cursor: 'pointer' }}></span> : ""}

                                                                    {this.state.OralBool == false ? <span>
                                                                        {this.state.OralAdvList?.map((ae, i) =>
                                                                            <span>
                                                                                {((ae.rate == 0) && this.state.OralAdvList?.length - this.state.OralzeroBlank?.length == i) ? 'Rare :' : ''}
                                                                                {ae?.change == 'Increase' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }}>&#9650;</span>
                                                                                    : ae?.change == 'Decrease' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} >&#9660;</span>
                                                                                        : ae?.change == 'up or down' ?
                                                                                            <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} >&#9660;/&#9650;</span>
                                                                                            : ae?.change == "" ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} ></span> : ''}
                                                                                {parseFloat(ae?.rate) > 19.9 ? <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                    : (parseFloat(ae?.rate) >= 15 && parseFloat(ae?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                        : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                            : (parseFloat(ae.rate) >= 0.1 && parseFloat(ae.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                                : <span style={{ fontSize: 16, color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : "", textTransform: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "uppercase" : '' }}>
                                                                                                    {ae?.adverse}
                                                                                                </span>}
                                                                                {ae?.severe == 1 ?
                                                                                    <span style={{
                                                                                        color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ?
                                                                                            'red' : '', fontWeight: ((parseFloat(ae?.rate) > 19.9)) ? 'bold' : '', fontStyle: ae?.effect_desired == 1 ? "italic" : ''
                                                                                    }} >^</span>
                                                                                    : ""}
                                                                                {ae?.toxicity_due == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                                {i !== this.state.OralAdvList?.length - 1 ? <b style={{ fontSize: 18 }}>{(i == this.state.OralAdvList?.length - (this.state.OralzeroBlank?.length + 1)) ? '. ' : ', '} </b> : ''}
                                                                            </span>
                                                                        )}
                                                                        {this.state.OralBool == false ? <span onClick={(e) => {
                                                                            this.setState({ OralBool: !this.state.OralBool })
                                                                        }} className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}></span> : ""}
                                                                    </span> : ""}
                                                                </> : <span>
                                                                    {this.state.OralAdvList?.map((ae, i) =>
                                                                        <span>
                                                                            {(ae.rate == 0 && this.state.OralAdvList?.length - this.state.OralzeroBlank?.length == i) ? 'Rare:' : ''}
                                                                            {ae?.change == 'Increase' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }}>&#9650;</span>
                                                                                : ae?.change == 'Decrease' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} >&#9660;</span>
                                                                                    : ae?.change == 'up or down' ?
                                                                                        <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} >&#9660;/&#9650;</span>
                                                                                        : ae?.change == "" ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} ></span> : ''}
                                                                            {parseFloat(ae?.rate) > 19.9 ? <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                : (parseFloat(ae?.rate) >= 15 && parseFloat(ae?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                    : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                        : (parseFloat(ae.rate) >= 0.1 && parseFloat(ae.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                            : <span style={{ fontSize: 16, color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : "", textTransform: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "uppercase" : '' }}>
                                                                                                {ae?.adverse}
                                                                                            </span>}
                                                                            {ae?.severe == 1 ?
                                                                                <span style={{
                                                                                    color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ?
                                                                                        'red' : '', fontWeight: ((parseFloat(ae?.rate) > 19.9)) ? 'bold' : '', fontStyle: ae?.effect_desired == 1 ? "italic" : ''
                                                                                }} >^</span>
                                                                                : ""}
                                                                            {ae?.toxicity_due == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                            {i !== this.state.OralAdvList?.length - 1 ? <b style={{ fontSize: 18 }}>{(i == this.state.OralAdvList?.length - (this.state.OralzeroBlank?.length + 1)) ? '. ' : ', '} </b> : ''}
                                                                        </span>
                                                                    )}
                                                                </span>}
                                                            </p>
                                                        </p>
                                                    </div>
                                                </> : ''
                                            }
                                            {/* GI */}
                                            {
                                                (this.state.GIAdvList?.length > 0) ? <>
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-20px', color: 'black' }}>
                                                        {
                                                            this.state.GIAdverseEffectsImg[0]?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust GI Adverse Effects" : 'GI Adverse Effects') ?
                                                                <>
                                                                    <ReactTooltip id="GI Adverse Effects" place="left" type="dark" effect="float" />
                                                                    <img src={this.state.GIAdverseEffectsImg[0].iconImg} width={40} height={40} className=' icon-img'
                                                                        data-tip='GI Adverse Effects' data-for='GI Adverse Effects'
                                                                    />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className='  icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }

                                                        <p className="" style={{ marginLeft: 10 }}>
                                                            <p className='' style={{ fontSize: 16, marginTop: '-18px', color: 'black', marginLeft: 8 }}>
                                                                {this.state.viewList?.gi_adverse_effects == 0 ? <>
                                                                    {this.state.GIBool == true ? <span onClick={(e) => {
                                                                        this.setState({ GIBool: !this.state.GIBool })
                                                                    }} className='badge badge-secondary' style={{ cursor: 'pointer' }}></span> : ""}

                                                                    {this.state.GIBool == false ? <span>
                                                                        {this.state.GIAdvList?.map((ae, i) =>
                                                                            <span>
                                                                                {(ae.rate == 0 && this.state.GIAdvList?.length - this.state.GIzeroBlank?.length == i) ? 'Rare:' : ''}
                                                                                {ae?.change == 'Increase' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }}>&#9650;</span>
                                                                                    : ae?.change == 'Decrease' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} >&#9660;</span>
                                                                                        : ae?.change == 'up or down' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} >&#9660;/&#9650;</span>
                                                                                            : ae?.change == "" ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} ></span> : ''}
                                                                                {parseFloat(ae?.rate) > 19.9 ? <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                    : (parseFloat(ae?.rate) >= 15 && parseFloat(ae?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                        : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                            : (parseFloat(ae.rate) >= 0.1 && parseFloat(ae.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                                : <span style={{ color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : "", textTransform: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "uppercase" : '' }}>{ae?.adverse}</span>}
                                                                                {ae?.severe == 1 ?
                                                                                    <span style={{
                                                                                        color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ?
                                                                                            'red' : '', fontWeight: ((parseFloat(ae?.rate) > 19.9)) ? 'bold' : '', fontStyle: ae?.effect_desired == 1 ? "italic" : ''
                                                                                    }} >^</span>
                                                                                    : ""}
                                                                                {ae?.toxicity_due == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                                {i !== this.state.GIAdvList?.length - 1 ? <b style={{ fontSize: 18 }}>{(i == this.state.GIAdvList?.length - (this.state.GIzeroBlank?.length + 1)) ? '. ' : ', '} </b> : ''}
                                                                            </span>
                                                                        )}
                                                                        {this.state.GIBool == false ? <span onClick={(e) => {
                                                                            this.setState({ GIBool: !this.state.GIBool })
                                                                        }} className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}></span> : ""}
                                                                    </span> : ""}
                                                                </> : <span>
                                                                    {this.state.GIAdvList?.map((ae, i) =>
                                                                        <span>
                                                                            {(ae.rate == 0 && this.state.GIAdvList?.length - this.state.GIzeroBlank?.length == i) ? 'Rare:' : ''}
                                                                            {ae?.change == 'Increase' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }}>&#9650;</span>
                                                                                : ae?.change == 'Decrease' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} >&#9660;</span>
                                                                                    : ae?.change == 'up or down' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} >&#9660;/&#9650;</span>
                                                                                        : ae?.change == "" ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} ></span> : ''}
                                                                            {parseFloat(ae?.rate) > 19.9 ? <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                : (parseFloat(ae?.rate) >= 15 && parseFloat(ae?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                    : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                        : (parseFloat(ae.rate) >= 0.1 && parseFloat(ae.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                            : <span style={{ color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : "", textTransform: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "uppercase" : '' }}>{ae?.adverse}</span>}
                                                                            {ae?.severe == 1 ?
                                                                                <span style={{
                                                                                    color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ?
                                                                                        'red' : '', fontWeight: ((parseFloat(ae?.rate) > 19.9)) ? 'bold' : '', fontStyle: ae?.effect_desired == 1 ? "italic" : ''
                                                                                }} >^</span>
                                                                                : ""}
                                                                            {ae?.toxicity_due == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                            {i !== this.state.GIAdvList?.length - 1 ? <b style={{ fontSize: 18 }}>{(i == this.state.GIAdvList?.length - (this.state.GIzeroBlank?.length + 1)) ? '. ' : ', '} </b> : ''}
                                                                        </span>
                                                                    )}
                                                                </span>}
                                                            </p>
                                                        </p>
                                                    </div>
                                                </> : ''
                                            }
                                            {/* Nutrition */}
                                            {
                                                (this.state.NutritionAdvList?.length > 0) ? <>
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-21px', color: 'black' }}>
                                                        {
                                                            this.state.NutritionalAdverseEffectsImg[0]?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Nutritional Adverse Effects" : 'Nutritional Adverse Effects') ?
                                                                <>
                                                                    <ReactTooltip id="Nutrition Adverse Effects" place="left" type="dark" effect="float" />
                                                                    <img src={this.state.NutritionalAdverseEffectsImg[0].iconImg} width={40} height={40} className=' icon-img'
                                                                        data-tip='Nutrition Adverse Effects' data-for='Nutrition Adverse Effects'
                                                                    />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className='  icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }
                                                        <p className="" style={{ marginLeft: 19 }}>
                                                            <p className='' style={{ fontSize: 16, marginTop: '-18px' }}>
                                                                {this.state.viewList?.other_adverse_effects == 0 ? <>
                                                                    {this.state.NutriBool == true ? <span onClick={(e) => {
                                                                        this.setState({ NutriBool: !this.state.NutriBool })
                                                                    }} className='badge badge-secondary' style={{ cursor: 'pointer' }}></span> : ""}

                                                                    {this.state.NutriBool == false ? <span>
                                                                        {this.state.NutritionAdvList?.map((ae, i) => <span>
                                                                            {(ae.rate == 0 && this.state.NutritionAdvList?.length - this.state.NutzeroBlank?.length == i) ? 'Rare:' : ''}
                                                                            {ae?.change == 'Increase' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }}>&#9650;</span>
                                                                                : ae?.change == 'Decrease' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} >&#9660;</span>
                                                                                    : ae?.change == 'up or down' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} >&#9660;/&#9650;</span>
                                                                                        : ae?.change == "" ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} ></span> : ''}
                                                                            {parseFloat(ae?.rate) > 19.9 ? <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                : (parseFloat(ae?.rate) >= 15 && parseFloat(ae?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                    : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                        : (parseFloat(ae.rate) >= 0.1 && parseFloat(ae.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                            : <span style={{ fontSize: 16, fontStyle: ae?.effect_desired == 1 ? "italic" : '', color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : "", textTransform: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "uppercase" : '' }}>
                                                                                                {ae?.adverse}
                                                                                            </span>}
                                                                            {ae?.severe == 1 ?
                                                                                <span style={{
                                                                                    color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ?
                                                                                        'red' : '', fontWeight: ((parseFloat(ae?.rate) > 19.9)) ? 'bold' : '', fontStyle: ae?.effect_desired == 1 ? "italic" : ''
                                                                                }} >^</span>
                                                                                : ""}
                                                                            {ae?.toxicity_due == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                            {i !== this.state.NutritionAdvList?.length - 1 ? <b style={{ fontSize: 18 }}>{(i == this.state.NutritionAdvList?.length - (this.state.NutzeroBlank?.length + 1)) ? '. ' : ', '}</b> : ''}
                                                                        </span>
                                                                        )}
                                                                        {this.state.NutriBool == false ? <span onClick={(e) => {
                                                                            this.setState({ NutriBool: !this.state.NutriBool })
                                                                        }} className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}></span> : ""}
                                                                    </span> : ""}
                                                                </> : <span>
                                                                    {this.state.NutritionAdvList?.map((ae, i) => <span>
                                                                        {(ae.rate == 0 && this.state.NutritionAdvList?.length - this.state.NutzeroBlank?.length == i) ? 'Rare:' : ''}
                                                                        {ae?.change == 'Increase' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }}>&#9650;</span>
                                                                            : ae?.change == 'Decrease' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} >&#9660;</span>
                                                                                : ae?.change == 'up or down' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} >&#9660;/&#9650;</span>
                                                                                    : ae?.change == "" ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} ></span> : ''}
                                                                        {parseFloat(ae?.rate) > 19.9 ? <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                            : (parseFloat(ae?.rate) >= 15 && parseFloat(ae?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                    : (parseFloat(ae.rate) >= 0.1 && parseFloat(ae.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                        : <span style={{ fontSize: 16, fontStyle: ae?.effect_desired == 1 ? "italic" : '', color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : "", textTransform: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "uppercase" : '' }}>
                                                                                            {ae?.adverse}
                                                                                        </span>}
                                                                        {ae?.severe == 1 ?
                                                                            <span style={{
                                                                                color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ?
                                                                                    'red' : '', fontWeight: ((parseFloat(ae?.rate) > 19.9)) ? 'bold' : '', fontStyle: ae?.effect_desired == 1 ? "italic" : ''
                                                                            }} >^</span>
                                                                            : ""}
                                                                        {ae?.toxicity_due == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                        {i !== this.state.NutritionAdvList?.length - 1 ? <b style={{ fontSize: 18 }}>{(i == this.state.NutritionAdvList?.length - (this.state.NutzeroBlank?.length + 1)) ? '. ' : ', '}</b> : ''}
                                                                    </span>
                                                                    )}
                                                                </span>}
                                                            </p>
                                                        </p>
                                                    </div>
                                                </> : ''
                                            }
                                            {
                                                this.state.adverseEffectList?.length > 0 ? <>
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-22px', color: 'black' }}>
                                                        {
                                                            this.state.OtherAdverseEffectsImg[0]?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Other Adverse Effects" : "Other Adverse Effects") ?
                                                                <>
                                                                    <ReactTooltip id="Adverse Effects" place="left" type="dark" effect="float" />
                                                                    <img src={this.state.OtherAdverseEffectsImg[0].iconImg} width={40} height={40} className=' icon-img'
                                                                        data-tip='Other Adverse Effects' data-for='Adverse Effects'
                                                                    />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className='  icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        } &nbsp; &nbsp;
                                                        <p className="" style={{ marginLeft: 0 }}>
                                                            <p className='' style={{ fontSize: 16, marginTop: '-14px', marginLeft: 6 }}>
                                                                {this.state.viewList?.other_adverse_effects == 0 ? <>
                                                                    {this.state.advBool == true ? <span onClick={(e) => {
                                                                        this.setState({ advBool: !this.state.advBool })
                                                                    }} className='badge badge-secondary' style={{ cursor: 'pointer' }}></span> : ""}

                                                                    {this.state.advBool == false ? <span>
                                                                        {this.state.adverseEffectList?.map((ae, i) =>
                                                                            <span>
                                                                                {(ae.rate == 0 && this.state.adverseEffectList?.length - this.state.OtherzeroBlank?.length == i) ? 'Rare:' : ''}
                                                                                {ae?.change == 'Increase' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }}>&#9650;</span>
                                                                                    : ae?.change == 'Decrease' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} >&#9660;</span>
                                                                                        : ae?.change == 'up or down' ?
                                                                                            <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} >&#9660;/&#9650;</span>
                                                                                            : ae?.change == "" ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} ></span> : ''}

                                                                                {parseFloat(ae?.rate) > 19.9 ? <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                    : (parseFloat(ae?.rate) >= 15 && parseFloat(ae?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                        : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                            : (parseFloat(ae.rate) >= 0.1 && parseFloat(ae.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                                : <span style={{ fontSize: 16, fontStyle: ae?.effect_desired == 1 ? "italic" : '', color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : "", textTransform: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "uppercase" : '' }}>{ae?.adverse}</span>}
                                                                                {ae?.severe == 1 ?
                                                                                    <span style={{
                                                                                        color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ?
                                                                                            'red' : '', fontWeight: ((parseFloat(ae?.rate) > 19.9)) ? 'bold' : '', fontStyle: ae?.effect_desired == 1 ? "italic" : ''
                                                                                    }} >^</span>
                                                                                    : ""}
                                                                                {ae?.toxicity_due == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                                {i !== this.state.adverseEffectList?.length - 1 ? <b style={{ fontSize: 18 }}>
                                                                                    {(ae?.direction != 'Increase' || ae?.direction != 'Decrease' || ae?.direction != 'up or down') ? <span>{(i == this.state.adverseEffectList?.length - (this.state.OtherzeroBlank?.length + 1)) ? '. ' : ', '}</span> : <>{(i == this.state.NutritionAdvList?.length - (this.state.NutzeroBlank?.length + 1)) ? '. ' : ','}</>}
                                                                                </b> : ''}
                                                                            </span>
                                                                        )}
                                                                        {this.state.advBool == false ? <span onClick={(e) => {
                                                                            this.setState({ advBool: !this.state.advBool })
                                                                        }} className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}></span> : ""}
                                                                    </span> : ""}
                                                                </> : <span>
                                                                    {this.state.adverseEffectList?.map((ae, i) =>
                                                                        <span>
                                                                            {(ae.rate == 0 && this.state.adverseEffectList?.length - this.state.OtherzeroBlank?.length == i) ? 'Rare:' : ''}
                                                                            {ae?.change == 'Increase' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }}>&#9650;</span>
                                                                                : ae?.change == 'Decrease' ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} >&#9660;</span>
                                                                                    : ae?.change == 'up or down' ?
                                                                                        <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} >&#9660;/&#9650;</span>
                                                                                        : ae?.change == "" ? <span style={{ color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ? 'red' : '' }} ></span> : ''}

                                                                            {parseFloat(ae?.rate) > 19.9 ? <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                : (parseFloat(ae?.rate) >= 15 && parseFloat(ae?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                    : (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                        : (parseFloat(ae.rate) >= 0.1 && parseFloat(ae.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: ae?.effect_desired == 1 ? "italic" : '' }}>{ae?.adverse}</span>
                                                                                            : <span style={{ fontSize: 16, fontStyle: ae?.effect_desired == 1 ? "italic" : '', color: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "red" : "", textTransform: (parseFloat(ae.rate) >= 10 && parseFloat(ae.rate) <= 19.9) ? "uppercase" : '' }}>{ae?.adverse}</span>}
                                                                            {ae?.severe == 1 ?
                                                                                <span style={{
                                                                                    color: ((parseFloat(ae?.rate) > 19.9) || (parseFloat(ae.rate) >= 5 && parseFloat(ae.rate) <= 9.9)) ?
                                                                                        'red' : '', fontWeight: ((parseFloat(ae?.rate) > 19.9)) ? 'bold' : '', fontStyle: ae?.effect_desired == 1 ? "italic" : ''
                                                                                }} >^</span>
                                                                                : ""}
                                                                            {ae?.toxicity_due == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                            {i !== this.state.adverseEffectList?.length - 1 ? <b style={{ fontSize: 18 }}>
                                                                                {(ae?.direction != 'Increase' || ae?.direction != 'Decrease' || ae?.direction != 'up or down') ? <span>{(i == this.state.adverseEffectList?.length - (this.state.OtherzeroBlank?.length + 1)) ? '. ' : ', '}</span> : <span>{(i == this.state.NutritionAdvList?.length - (this.state.NutzeroBlank?.length + 1)) ? '. ' : ','}</span>}
                                                                            </b> : ''}
                                                                        </span>
                                                                    )}
                                                                </span>}
                                                            </p>
                                                        </p>
                                                    </div>
                                                </> : ''
                                            }

                                            {
                                                this.state.labParameterList?.length > 0 ? <>
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-28px', color: 'black' }}>
                                                        {
                                                            this.state.LabParametersImg[0]?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Lab Parameters" : 'Lab Parameters') ?
                                                                <>
                                                                    <ReactTooltip id="Lab Parameters" place="left" type="dark" effect="float" />
                                                                    <img src={this.state.LabParametersImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img mt-1'
                                                                        data-tip='Lab Parameters' data-for='Lab Parameters'
                                                                    />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }
                                                        <p className="" style={{ marginLeft: 0 }}>
                                                            <p className='' style={{ fontSize: 16, marginTop: '-14px', marginLeft: '5px' }}>
                                                                {this.state.viewList?.lab_parameters == 0 ? <>
                                                                    {this.state.labBool == true ? <span onClick={(e) => {
                                                                        this.setState({ labBool: !this.state.labBool })
                                                                    }} className='badge badge-secondary' style={{ cursor: 'pointer' }}></span> : ""}

                                                                    {this.state.labBool == false ? <span>
                                                                        {
                                                                            this.state.labParameterList?.map((lb, i) =>
                                                                                <span>
                                                                                    {parseFloat(lb.rate) == 0 ? <span><span style={{ fontSize: 17, color: 'black' }}>
                                                                                        {(lb.rate == 0 && this.state.labParameterList?.length - this.state.LabzeroBlank?.length == i) ? 'Rare:' : ''}
                                                                                    </span></span> : ""}
                                                                                    {lb?.direction == 'increased' ? <i style={{ color: ((parseFloat(lb?.rate) > 19.9) || (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9)) ? 'red' : '' }}>&#9650;</i>
                                                                                        : lb?.direction == 'decreased' ? <i style={{ color: ((parseFloat(lb?.rate) > 19.9) || (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9)) ? 'red' : '' }} >&#9660;</i>
                                                                                            : lb?.direction == 'Positive' ? <i style={{ color: ((parseFloat(lb?.rate) > 19.9) || (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9)) ? 'red' : '' }} ><b>&#43;</b>&nbsp;</i>
                                                                                                : lb?.direction == 'Negative' ? <i style={{ color: ((parseFloat(lb?.rate) > 19.9) || (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9)) ? 'red' : '' }} ><b></b>&nbsp;</i>
                                                                                                    : lb?.direction == 'up or down' ?
                                                                                                        <span style={{ color: ((parseFloat(lb?.rate) > 19.9) || (parseFloat(lb.rate) >= 5 || parseFloat(lb.rate) <= 9.9)) ? 'red' : '' }} >&#9660;/&#9650;</span>
                                                                                                        : ''}
                                                                                    {parseFloat(lb?.rate) > 19.9 ?
                                                                                        <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }}>{lb?.parameter}</span>
                                                                                        : (parseFloat(lb?.rate) >= 15 && parseFloat(lb?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }}>{lb?.parameter}</span>
                                                                                            : (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }}>{lb?.parameter}</span>
                                                                                                : (parseFloat(lb.rate) >= 0.1 && parseFloat(lb.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: 'lowercase', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }}>{lb?.parameter}</span>
                                                                                                    : <span style={{ fontSize: 16, fontStyle: lb?.effect_desired == 1 ? "italic" : '', color: (parseFloat(lb.rate) >= 10 && parseFloat(lb.rate) <= 19.9) ? "red" : "", textTransform: (parseFloat(lb.rate) >= 10 && parseFloat(lb.rate) <= 19.9) ? "uppercase" : "" }}>{lb?.parameter}</span>}
                                                                                    {lb?.change_false == 1 ?
                                                                                        <span style={{
                                                                                            color: ((parseFloat(lb?.rate) > 19.9) || (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9)) ?
                                                                                                'red' : '', fontWeight: ((parseFloat(lb?.rate) > 19.9)) ? 'bold' : '', fontStyle: lb?.effect_desired == 1 ? "italic" : ''
                                                                                        }} ></span>
                                                                                        : ""}
                                                                                    {lb?.toxic_range == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                                    {i !== this.state.labParameterList?.length - 1 ? <b style={{ fontSize: 18, marginLeft: '0px' }}>
                                                                                        {(lb?.direction != 'increased' || lb?.direction != 'decreased' || lb?.direction != 'Positive' || lb?.direction != 'Negative' || lb?.direction != 'up or down') ?
                                                                                            <span>{(i == this.state.labParameterList?.length - (this.state.LabzeroBlank?.length + 1)) ? '. ' : ', '}</span> : <span>{(i == this.state.labParameterList?.length - (this.state.LabzeroBlank?.length + 1)) ? '. ' : ','}</span>}</b> : ''}
                                                                                </span>
                                                                            )
                                                                        }
                                                                        {this.state.labBool == false ? <span onClick={(e) => {
                                                                            this.setState({ labBool: !this.state.labBool })
                                                                        }} className='badge badge-secondary ml-1' style={{ cursor: 'pointer' }}></span> : ""}
                                                                    </span> : ""}
                                                                </> : <span>
                                                                    {
                                                                        this.state.labParameterList?.map((lb, i) =>
                                                                            <span>
                                                                                {parseFloat(lb.rate) == 0 ? <span><span style={{ fontSize: 17, color: 'black' }}>
                                                                                    {(lb.rate == 0 && this.state.labParameterList?.length - this.state.LabzeroBlank?.length == i) ? 'Rare:' : ''}
                                                                                </span></span> : ""}

                                                                                {parseFloat(lb?.rate) > 19.9 ?
                                                                                    <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }} >{lb?.change_false == 1 ? 'false ' : ""}</span>
                                                                                    : (parseFloat(lb?.rate) >= 15 && parseFloat(lb?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }} >{lb?.change_false == 1 ? 'false ' : ""}</span>
                                                                                        : (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: '', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }} >{lb?.change_false == 1 ? 'false ' : ""}</span>
                                                                                            : (parseFloat(lb.rate) >= 0.1 && parseFloat(lb.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: '', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }} >{lb?.change_false == 1 ? 'false ' : ""}</span>
                                                                                                : <span style={{ fontSize: 16, fontStyle: lb?.effect_desired == 1 ? "italic" : '', textTransform: (parseFloat(lb.rate) >= 10 && parseFloat(lb.rate) <= 19.9) ? "uppercase" : '', color: (parseFloat(lb.rate) >= 10 && parseFloat(lb.rate) <= 19.9) ? "red" : '' }} >{lb?.change_false == 1 ? 'false ' : ""}</span>}

                                                                                {lb?.direction == 'increased' ? <i style={{ color: ((parseFloat(lb?.rate) > 19.9) || (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9)) ? 'red' : '' }}>&#9650;</i>
                                                                                    : lb?.direction == 'decreased' ? <i style={{ color: ((parseFloat(lb?.rate) > 19.9) || (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9)) ? 'red' : '' }} >&#9660;</i>
                                                                                        : lb?.direction == 'Positive' ? <i style={{ color: ((parseFloat(lb?.rate) > 19.9) || (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9)) ? 'red' : '' }} ><b>&#43;</b>&nbsp;</i>
                                                                                            : lb?.direction == 'Negative' ? <i style={{ color: ((parseFloat(lb?.rate) > 19.9) || (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9)) ? 'red' : '' }} ><b></b>&nbsp;</i>
                                                                                                : lb?.direction == 'up or down' ?
                                                                                                    <span style={{ color: ((parseFloat(lb?.rate) > 19.9) || (parseFloat(lb.rate) >= 5 || parseFloat(lb.rate) <= 9.9)) ? 'red' : '' }} >&#9660;/&#9650;</span>
                                                                                                    : ''}
                                                                                {parseFloat(lb?.rate) > 19.9 ?
                                                                                    <span style={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'red', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }}>{lb?.parameter}</span>
                                                                                    : (parseFloat(lb?.rate) >= 15 && parseFloat(lb?.rate) <= 19.9) ? <span style={{ color: 'red', textTransform: 'uppercase', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }}>{lb?.parameter}</span>
                                                                                        : (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9) ? <span style={{ color: 'red', textTransform: 'lowercase', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }}>{lb?.parameter}</span>
                                                                                            : (parseFloat(lb.rate) >= 0.1 && parseFloat(lb.rate) <= 4.9) ? <span style={{ color: 'black', textTransform: 'lowercase', fontStyle: lb?.effect_desired == 1 ? "italic" : '' }}>{lb?.parameter}</span>
                                                                                                : <span style={{ fontSize: 16, fontStyle: lb?.effect_desired == 1 ? "italic" : '', color: (parseFloat(lb.rate) >= 10 && parseFloat(lb.rate) <= 19.9) ? "red" : "", textTransform: (parseFloat(lb.rate) >= 10 && parseFloat(lb.rate) <= 19.9) ? "uppercase" : "" }}>{lb?.parameter}</span>}
                                                                                {lb?.change_false == 1 ?
                                                                                    <span style={{
                                                                                        color: ((parseFloat(lb?.rate) > 19.9) || (parseFloat(lb.rate) >= 5 && parseFloat(lb.rate) <= 9.9)) ?
                                                                                            'red' : '', fontWeight: ((parseFloat(lb?.rate) > 19.9)) ? 'bold' : '', fontStyle: lb?.effect_desired == 1 ? "italic" : ''
                                                                                    }} ></span>
                                                                                    : ""}
                                                                                {lb?.toxic_range == 1 ? <img src={toxic} width='14' height='12' style={{ marginTop: '-5px' }} /> : ''}
                                                                                {i !== this.state.labParameterList?.length - 1 ? <b style={{ fontSize: 18, marginLeft: '0px' }}>
                                                                                    {(lb?.direction != 'increased' || lb?.direction != 'decreased' || lb?.direction != 'Positive' || lb?.direction != 'Negative' || lb?.direction != 'up or down') ?
                                                                                        <span>{(i == this.state.labParameterList?.length - (this.state.LabzeroBlank?.length + 1)) ? '. ' : ', '}</span> :
                                                                                        <span>{(i == this.state.labParameterList?.length - (this.state.LabzeroBlank?.length + 1)) ? '. ' : ','}</span>}</b> : ''}
                                                                            </span>
                                                                        )
                                                                    }
                                                                </span>}
                                                            </p>
                                                        </p>
                                                    </div>
                                                </> : ''
                                            }
                                            {
                                                this.state.warningList[0]?.monitor_param ? <>
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-21px', color: 'black' }}>
                                                        {
                                                            this.state.MonitoringImg[0]?.body_system == "Monitoring" ?
                                                                <>
                                                                    <img src={this.state.MonitoringImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                        data-tip="Monitoring Parameters" data-for='Monitoring Parameters'
                                                                    />
                                                                    <ReactTooltip id="Monitoring Parameters" place="left" type="dark" effect="float" />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }
                                                        <p className="m-0">
                                                            <p className='mt-2' style={{ fontSize: 16, marginLeft: 5 }}>
                                                                {this.state.userInfo?.country_view_id == 2 ?
                                                                    <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.monitor_param?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.monitor_param?.split('Canadian labelling:')[1] : this.state.warningList[0]?.monitor_param }}></div> :
                                                                    <div dangerouslySetInnerHTML={{ __html: this.state.warningList[0]?.monitor_param?.includes('Canadian labelling:') == true ? this.state.warningList[0]?.monitor_param?.split('Canadian labelling:')[0] : this.state.warningList[0]?.monitor_param }}></div>
                                                                }
                                                            </p>
                                                        </p>
                                                    </div>
                                                    <div style={{ height: 11 }}></div>
                                                </> : ''
                                            }
                                            {
                                                (this.state.xrefList?.length) ? <>
                                                    <div className="drug-info mb-3 d-flex" style={{ marginTop: '-28px', color: 'black' }}>
                                                        {
                                                            this.state.CrossReferenceImg[0]?.body_system == "Cross Reference" ?
                                                                <>
                                                                    <ReactTooltip id="Cross Reference" place="left" type="dark" effect="float" />
                                                                    <img src={this.state.CrossReferenceImg[0].iconImg} width={40} height={40} className=' mr-3 mt-1 icon-img'
                                                                        data-for='Cross Reference' data-tip='Cross Reference'
                                                                    />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }
                                                        <p className="mt-3" style={{ marginLeft: '5px', fontSize: 16 }}>
                                                            {
                                                                this.state.xrefList?.map((xref, i) => <span key={i}>
                                                                    <a id="crossRefId"
                                                                        onClick={(e) => {
                                                                            let info = localStorage.getItem("setUser")
                                                                            let userInfo = JSON.parse(info);
                                                                            if (xref?.country_id?.includes(userInfo?.userInfo?.country_view_id) == true) {
                                                                                localStorage.setItem("gen_ids", xref?.generic_id);
                                                                                window.open(`#/account/drug-details?generic_id=${xref?.generic_id}`, '_blank');
                                                                            } else {
                                                                                toast.error('Generic not available in your selected country or you don’t have the module installed that contains that generic drug.', { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
                                                                            }
                                                                        }}
                                                                    >{xref?.xref}</a>
                                                                    {i !== this.state.xrefList?.length - 1 ? <span style={{ fontSize: 18, marginLeft: '0px' }}>, </span> : ''}
                                                                </span>)
                                                            }
                                                        </p>
                                                    </div>
                                                </> : ''
                                            }
                                            {
                                                (this.state.viewList?.citations == 1 || this.state.viewList?.citations == 0 || this.state.viewList?.citations == undefined) ? <>
                                                    <div className="drug-info mb-3 d-flex" id="headingCitation"
                                                        data-toggle="collapse"
                                                        data-target="#collapseCitaction" aria-expanded="true" aria-controls="collapseAE"
                                                        style={{ cursor: 'pointer', marginTop: '-18px', color: 'black' }}>
                                                        {
                                                            this.state.CitationsImg[0]?.body_system == "Citations" ?
                                                                <>
                                                                    <img src={this.state.CitationsImg[0].iconImg} width={40} height={40} className=' mr-3 icon-img'
                                                                        data-tip="Citations" data-for='Citations'
                                                                    />
                                                                    <ReactTooltip id="Citations" place="left" type="dark" effect="float" />
                                                                </>
                                                                : <DummyImage width={40} height={40} placeholder='Dummy Image' className=' mr-3 icon-img' bgColor='#f5f5f5' fgColor='#999999' />
                                                        }
                                                        &nbsp; &nbsp;<p className="" style={{ marginLeft: "-6px", marginTop: 10, fontSize: 16 }}>
                                                            {this.state.citationList.length == 1 ?
                                                                <span className='ml-3 mt-2'>
                                                                    <a style={{ color: '#15678f' }} className='link' id='cita-id' href={this.state.citationList[0]?.type == "URL" ? `${this.state.citationList[0]?.url}` : `${this.state.pmidUrl}/${this.state.citationList[0]?.pm_id}`} target='_blank'>{this.state.citationList[0]?.citation}</a>
                                                                </span>
                                                                : 'Prescribing Information, Citations and Treatment Guidelines'}  &nbsp;&nbsp; <span id="cit-badge-id" className='badge badge-secondary' style={{ color: 'whitesmoke' }}></span>
                                                        </p>
                                                    </div>
                                                </> : ''
                                            }
                                            {
                                                this.state.citationList?.length > 1 ? <>
                                                    <div style={{ color: '#15678f', marginLeft: 0 }} id="collapseCitaction" className="" aria-labelledby="headingCitation" data-parent="#drugDetailsAccordian" >
                                                        {this.state.citationList?.map(ct =>
                                                            <>
                                                                <div className='row' style={{ zIndex: 1000 }}>
                                                                    <p style={{ fontSize: 13, paddingBottom: 12, marginLeft: 10, marginTop: '-25px' }}>
                                                                        <span className='ml-3 mt-2'>
                                                                            <a style={{ color: '#15678f' }} className='link' id='cita-id' href={ct?.type == "URL" ? `${ct?.url}` : `${this.state.pmidUrl}/${ct?.pm_id}`} target='_blank'>{ct?.citation}</a>
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </> : ""
                                            }
                                        </div>
                                        {/* <div className='row'>
                                            <p className='ml-4'>Last updated on {this.state.getGenericDetails?.updated_dt}</p>
                                        </div> */}
                                        <div className='row ml-2'>
                                            © 2023 &nbsp; <a href='https://web.drugnutritioninteractions.com/' target='_blank'>  Drug Nutrition Interactions, Ltd. </a>
                                        </div>
                                    </> : ''
                                }
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        )
    }
}

export default GuestDrugDetails