import React, { useEffect, useState } from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'

function IdealBodyWeightCalc() {

  const [MainVal, setMainVal] = useState();
  const [NewVar, setNewVar] = useState();
  const [NeVarble, setNeVarble] = useState();
  const [IBW, setIBW] = useState();
  const [MinVal, setMinVal] = useState(39);
  const [MaxVal, setMaxVal] = useState(83);
  const [Gen, setGen] = useState("male");
  const [PraPgc, setPraPgc] = useState("no");
  const [FrmSze, setFrmSze] = useState('medium');
  const [Tab, setTab] = useState('Imperial');
  const [UnitLable, setUnitLabel] = useState(" lb");
  const [HiIn, setHiIn] = useState('(in)');
  const [ErrMsg, setErrMsg] = useState('');

  useEffect(
    () => {
      if (localStorage.getItem("setUser") != null) {
        var Dflt;
        let DefltSystm = JSON.parse(localStorage.getItem("setUser"));
        Dflt = DefltSystm?.userInfo?.country_view_id;
        if (Dflt == 2) {
          document.getElementById('chkPrv').checked = true;
          setTab('Metric');
          setUnitLabel(" kg");
          setHiIn(" (cm)");
          setMinVal(100);
          setMaxVal(210);
        } else {
          document.getElementById('chkPrv').checked = false;
          setTab('Imperial');
          setHiIn("(in)");
          setUnitLabel(" lb");
          setMinVal(39);
          setMaxVal(83);
        }
      }
    }, []
  );

  const UpdateRangeInput = (event) => {
    setMainVal("");
    setErrMsg("");
    setNewVar("");
    setNeVarble("");
    setIBW("");
    if (event == '0') {
      setErrMsg("Please Enter Greater Than Or Equal " + MinVal + ".");
      setMainVal("");
    } else if (isNaN(event) == true) {
      setErrMsg("Only Digits Are Allowed.*");
    } else if (event?.includes('.') == true) {
      let check = parseFloat(event.match(/\d+\.\d{1}/));
      if (isNaN(check) == true) {
        setMainVal(event);
      } else {
        setMainVal(check);
      }
    } else if (Tab == 'Metric') {
      if (event.length == 1) {
        if (parseFloat(event) < 1 || parseFloat(event) > 2) {
          setErrMsg("Invalid height. *");
        } else {
          setMainVal(parseFloat(event));
        }
      } else if (event.length == 2) {
        if (parseFloat(event) < 10 || parseFloat(event) > 21) {
          setErrMsg("Invalid height. *");
        } else {
          setMainVal(parseFloat(event));
        }
      } else if (event.length == 3) {
        if (parseFloat(event) < 100 || parseFloat(event) > 210) {
          setErrMsg("Invalid height. *");
        } else {
          setMainVal(parseFloat(event));
        }
      } else if (event.length > 3) {
        setErrMsg("Invalid height. *");
      }
    } else if (Tab == 'Imperial') {
      if (event.length == 1) {
        if (parseFloat(event) < 3 || parseFloat(event) > 8) {
          setErrMsg("Invalid height. *");
        } else {
          setMainVal(parseFloat(event));
        }
      } else if (event.length == 2) {
        if (parseFloat(event) < 39 || parseFloat(event) > 83) {
          setErrMsg("Invalid height. *");
        } else {
          setMainVal(parseFloat(event));
        }
      } else if (event.length > 2) {
        setErrMsg("Invalid height. *");
      }
    }
  }

  const HandleChange = (e) => {
    setErrMsg("");
    setIBW("");
    if (e.target.name == "gender") {
      setGen(e.target.value);
    } else if (e.target.name == "framesize") {
      setFrmSze(e.target.value);
    } else if (e.target.name == "paraplegic") {
      setPraPgc(e.target.value);
    } else if (e.target.name == "chkPrvCheck") {
      let Kul = "";
      if (e.target.checked == true) {
        if (MainVal != "" && isNaN(MainVal) == false && MainVal != undefined) {
          if (NeVarble != "" && NeVarble != undefined) {
            Kul = NeVarble;
            setMainVal(Kul);
          } else {
            Kul = MainVal * 2.54;
            if (Kul > 210) {
              setMainVal(210);
            } else {
              setMainVal(Kul.toFixed(1));
              setNewVar(MainVal);
            }
          }
          setNeVarble("");
        }
        setTab('Metric');
        setUnitLabel(" kg");
        setHiIn(" (cm)");
        setMinVal(100);
        setMaxVal(210);
      } else {
        if (MainVal != "" && isNaN(MainVal) == false && MainVal != undefined) {
          if (NewVar != "" && NewVar != undefined) {
            Kul = NewVar;
            setMainVal(Kul);
          } else {
            Kul = MainVal / 2.54;
            if (Kul > 83) {
              setMainVal(83);
            } else {
              setMainVal(Kul.toFixed(1));
              setNeVarble(MainVal);
            }
          }
          setNewVar("");
        }
        setTab('Imperial');
        setHiIn("(in)");
        setUnitLabel(" lb");
        setMinVal(39);
        setMaxVal(83);
      }
    }
  }

  const MetImp = () => {
    setErrMsg();
    let IBWeight = "";
    let HlfClclton = "";
    let PraplagcYN = "";
    let FrmSzeLMS = "";
    let PrplgcProcess = "";
    let Frmszeprocess = "";
    if (Tab == "Metric") {
      if (MainVal == "" || MainVal == undefined) {
        setErrMsg("Please enter your height. *");
      } else if (MainVal < 100) {
        setErrMsg("Please enter a valid height. *");
      } else if (MainVal > 210) {
        setErrMsg("Please enter a valid height. *");
      } else {
        // MetCalForIBW();
        setUnitLabel(" kg");
      }
      if (Gen == "male") {
        if (MainVal > 152.4) {
          HlfClclton = (0.3161 * 152.4) + (1.0738 * (MainVal - 152.4));
          // Lal = HlfClclton;
        } else {
          HlfClclton = (0.3161 * MainVal);
          // Lal = HlfClclton;
        }
      } else {
        if (MainVal < 152.4) {
          HlfClclton = (0.2982 * MainVal);
          // Lal = HlfClclton;
        } else {
          HlfClclton = (0.2982 * 152.4) + (1.0738 * (MainVal - 152.4));
          // Lal = HlfClclton;
        }
      }
    } else {
      if (MainVal == "" || MainVal == undefined) {
        setErrMsg("Please enter your height. *");
      } else if (MainVal < 39) {
        setErrMsg("Please enter a valid height. *");
      } else if (MainVal > 83) {
        setErrMsg("Please enter a valid height. *");
      } else {
        // ImpCalForIBW();
        setUnitLabel(" lb");
      }
      if (Gen == "male") {
        if (MainVal >= 61) {
          HlfClclton = (1.7667 * 60) + (6 * (MainVal - 60));
          // Lal = HlfClclton;
        } else {
          HlfClclton = 1.766 * MainVal;
          // Lal = HlfClclton;
        }
      } else {
        if (MainVal <= 60) {
          HlfClclton = (1.66 * MainVal);
          // Lal = HlfClclton;
        } else {
          HlfClclton = (1.66 * 60) + (5 * (MainVal - 60));
          // Lal = HlfClclton;
        }
      }
    }

    if (PraPgc == "yes") {
      PrplgcProcess = (HlfClclton * 10) / 100;
      PraplagcYN = HlfClclton - PrplgcProcess;
    } else {
      PraplagcYN = HlfClclton;
    }

    if (FrmSze == "small") {
      Frmszeprocess = (PraplagcYN * 10) / 100;
      FrmSzeLMS = PraplagcYN - Frmszeprocess;
      IBWeight = FrmSzeLMS.toFixed(1);
    } else if (FrmSze == "large") {
      Frmszeprocess = (PraplagcYN * 10) / 100;
      FrmSzeLMS = PraplagcYN + Frmszeprocess;
      IBWeight = FrmSzeLMS.toFixed(1);
    } else {
      FrmSzeLMS = PraplagcYN;
      IBWeight = FrmSzeLMS.toFixed(1);
    }

    if (IBWeight == undefined || isNaN(IBWeight) == true) {
      setIBW("");
    } else {
      setIBW(IBWeight);
    }

  }
  // useEffect(() => {

  //   const meta = document.createElement('meta');
  //   meta.name = 'viewport';
  //   meta.content = 'width=device-width, initial-scale=0.5';
  //   const existingMeta = document.querySelector('meta[name="viewport"]');
  //   if (existingMeta) {
  //     existingMeta.remove();
  //   }


  //   document.head.appendChild(meta);


  //   return () => {
  //     meta.remove();
  //   };
  // }, []);

  return (
    <div>
      <PageHeader
        bgimg="https://img.freepik.com/free-photo/adult-child-holding-kidney-shaped-paper-textured-blue-background-world-kidney-day-national-organ-donor-day-charity-donation-concept_49149-1211.jpg"
        title="Ideal&nbsp;Body&nbsp;Weight&nbsp;(IBW)&nbsp;Calculator"
        description="Estimate ideal (lean) body weight in adults based on measured height, gender and frame size" />

      <section className="section section-50 section-lg-95 novi-background bg-cover">
        <div className='container' style={{ marginTop: '-110px' }}>
          <div className="row offset-top-40 card-bs">
            <div className='col-lg-6 text-left p-5 d-flex flex-column justify-content-between' style={{ marginTop: '11px' }}>
              <div className='row'>
                <div className='col-12'>
                  <div className="custom-control mb-3 pl-0">
                    <h6 className='d-inline-block pr-2' value="Imperial" name="tab" >Imperial</h6>
                    <h6 className='d-inline-block custom-switch'>
                      <input type="checkbox" className="custom-control-input" name="chkPrvCheck" id="chkPrv" onChange={HandleChange} />
                      <label className="custom-control-label" htmlFor="chkPrv">&nbsp;</label>
                    </h6>
                    <h6 className='d-inline-block' value="Metric" name="tab1">Metric</h6>
                  </div>
                </div>
              </div>
              <div className='row' style={{ marginLeft: '0px' }}>
                <div className="col-12 form-group row customSlider">
                  <div className='col-12'>
                    <div className="ticks" style={{ marginTop: '10px' }}>
                      <span className="tick">{MinVal}</span>
                      <span className="tick">{MaxVal}</span>
                    </div>
                    <input type="range" style={{ width: '100%', marginTop: '-35px' }} step={.1} min={MinVal} value={(MainVal) ? MainVal : MinVal} name="heightrange" max={MaxVal} defaultValue={MinVal} onChange={(event) => UpdateRangeInput(event.target.value)} />
                  </div>
                  <div className='col-7'>
                    <label className='col-form-label' style={{ fontSize: '15px', color: '#383030eb' }}>Height<span style={{ color: 'gray', fontSize: '13px' }}>{"  " + HiIn}</span></label>
                  </div>
                  <div className='col-5 text-right'>
                    <input type="text" value={MainVal} min={MinVal} name="inputVal" style={{ width: '100px', marginRight: '25px', height: '38px' }} max={MaxVal} id="inputval" onChange={(event) => UpdateRangeInput(event.target.value)} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <label className='col-form-label font-weight-bold'>Paraplegic</label>
                </div>
                <div className="col-9 pt-2">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" onChange={HandleChange} defaultChecked="true" name="paraplegic" id="paraplegic1" value="no" />
                    <label className="form-check-label" htmlFor="paraplegic1">No</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" onChange={HandleChange} name="paraplegic" id="paraplegic2" value="yes" />
                    <label className="form-check-label" htmlFor="paraplegic2">Yes</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className='col-3'>
                  <label className='col-form-label font-weight-bold'>Gender</label>
                </div>
                <div className="col-9 pt-2">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" onChange={HandleChange} name="gender" id="male" defaultChecked="true" value="male" />
                    <label className="form-check-label" htmlFor="male">Male</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" onChange={HandleChange} name="gender" id="female" value="female" />
                    <label className="form-check-label" htmlFor="female">Female</label>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className='col-3'>
                  <label className='col-form-label font-weight-bold'>Frame Size</label>
                </div>
                <div className='col-9 pt-2'>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" onChange={HandleChange} name="framesize" id="small" value="small" />
                    <label className="form-check-label" htmlFor="small">Small</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" onChange={HandleChange} defaultChecked="true" name="framesize" id="medium" value="medium" />
                    <label className="form-check-label" htmlFor="medium">Medium</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" onChange={HandleChange} name="framesize" id="large" value="large" />
                    <label className="form-check-label" htmlFor="large">Large</label>
                  </div>
                </div>
              </div>
              {/* <div className='row'>
                <div className='col-12 col-lg-6 text-lg-left text-center mb-2 mb-lg-0'>
                  <div style={{ color: 'red' }}>{ErrMsg}</div>
                </div>
                <div className='col-12 col-lg-6 text-lg-right text-center'>
                  <button className='btn btn-primary' onClick={MetImp}>Calculate</button>
                </div>
              </div> */}
              <div className='row'>
                <div className='col-12 col-lg-6 text-lg-left text-center mb-2 mb-lg-0'>
                  <div style={{ color: 'red' }}>{ErrMsg}</div>
                  <button className='btn btn-primary' onClick={MetImp}>Calculate</button>
                </div>
                <div className='col-12 col-lg-6 text-lg-right text-center'>

                </div>
              </div>

            </div>
            <div className='col-lg-6 p-5 d-flex align-items-center' style={{ background: "#a6c550" }}>
              <div className="col">
                <h2 className='text-white mb-3 display-5' style={{ fontSize: "50px" }}>IBW</h2>
                <div className='badge bg-white py-2 px-3'><h5 className='text-primary'>{IBW == "" ? 0 + UnitLable : IBW == undefined ? "" + UnitLable : IBW + UnitLable}</h5></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div style={{ marginTop: '-46px' }}>
        <div style={{ color: '#484343' }}><b>References: </b>Hamwi GJ. Therapy: changing dietary concepts. In: Danowski TS, editor. Diabetes mellitus: diagnosis and Treatment, vol 1. New York: American Diabetes Association; 1964:73–78.</div>
        <div><a target="_blank" href="https://pubmed.ncbi.nlm.nih.gov/34857204/">Chichester S, Holmes TM, Hubbard J. Ideal body weight: A commentary. Clin Nutr ESPEN. 2021;46:246-250.</a></div>
      </div>
    </div>
  )
}

export default IdealBodyWeightCalc