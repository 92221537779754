import React from 'react'
import "./Team.scss"


function Team(props) {
  return (
    <div className='mx-4'>
        <div className="team">
            <img src={props.img} alt="wrapkit" className="img-fluid rounded-circle mb-3" />
            <div className="pt-2">
                <h2 className='mb-3 title'>{props.name}</h2>
                <h6 className="subtitle mb-3">{props.designation}</h6>
                <p style={{color:'#585858'}}>{props.desc}</p>
                {/* <ul className="list-inline">
                    <li className="list-inline-item"><a href={props.fbLink} className="text-decoration-none d-block px-1"><span className="icon novi-icon icon-lg fl-great-icon-set-ico text-primary fl-great-icon-set-facebook2"></span></a></li>
                    <li className="list-inline-item"><a href={props.twitterLink} className="text-decoration-none d-block px-1"><span className="icon novi-icon icon-lg fl-great-icon-set-ico text-primary fl-great-icon-set-twitter"></span></a></li>
                    <li className="list-inline-item"><a href={props.instaLink} className="text-decoration-none d-block px-1"><span className="icon novi-icon icon-lg fl-great-icon-set-ico text-primary fl-great-icon-set-instagram19"></span></a></li>
                </ul> */}
            </div>
        </div>
    </div>
  )
}

export default Team