import React, { useState } from "react";
import PageHeaderTable from "../../../components/PageHeader/headertable";
import PageHeader from "../../../components/PageHeader/PageHeader";
const Oxalate = () => {


    return (

        <div style={{ textAlign: 'initial' }}>
            <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                title="High&nbsp;Oxalate&nbsp;(Oxalic&nbsp;Acid)&nbsp;Food&nbsp;Sources" />
            <div className="container mt-4">
                <p style={{ marginTop: "-5px", marginLeft: -13, fontWeight: '600px', fontSize: 15, color: '#484343' }}><b>Fruits</b></p>
                <p style={{ marginTop: "-12px", marginLeft: -13, fontWeight: '400px', fontSize: 14, color: '#484343' }}>Blackberries, blueberries, cranberries, *green gooseberries, dewberries, raw or canned
                    strawberries, red and black raspberries, red currants, canned fruit cocktail, Concord grapes,
                    *lemon peel, *lime/orange peel, damson plums, unspecified plums, canned or stewed *rhubarb,
                    tangerines</p>
                <br />

                <p style={{ marginTop: "-20px", marginLeft: -13, fontWeight: '600px', fontSize: 15, color: '#484343' }}><b>Vegetables</b></p>
                <p style={{ marginTop: "-12px", marginLeft: -13, ontWeight: '400px', fontSize: 14, color: '#484343' }}>*Amaranth; beans in tomato sauce, beans (boiled/raw green, wax, dried). *Beets, *beetroot,
                    *cassava root, celery, *Swiss chard, chicory, *collards, dandelion greens, eggplant, escarole,
                    kale, *leek, *okra, *parsley, parsnips, sweet green pepper and chilies, pokeweed, sweet potato,
                    *purslane, rutabagas, *spinach (boiled/frozen), summer squash, watercress.</p>

                <br />
                <p style={{ marginTop: "-20px", marginLeft: -13, fontWeight: '600px', fontSize: 15, color: '#484343' }}><b>Beverages (5-10 ounces)</b></p>
                <p style={{ marginTop: "-12px", marginLeft: -13, fontWeight: '400px', fontSize: 14, color: '#484343' }}><b>Beer:</b> Guinness Stout, draft- Lager, Pilsner, Tuborg. Juices (that contain high oxalate berries
                    e.g. cranberry), Ovaltine and other mixes, tea (oxalate content ▲ with ▲ brewing time), coffee
                    powder (Nescafe), colas.</p>
                <br />
                <p style={{ marginTop: "-20px", marginLeft: -13, fontWeight: '600px', fontSize: 15, color: '#484343' }}><b>Miscellaneous</b></p>
                <p style={{ marginTop: "-12px", marginLeft: -13, fontWeight: '400px', fontSize: 14, color: '#484343' }}><b>Nuts:&nbsp;</b>*almonds,*peanuts,*pecans.*wheat germ,*poppy seeds,*peanut butter,*chocolate,*dry
                    cocoa,*fig newton,fruit cake,graham crackers,pop corn,*soybean crackers,*sunflower seeds,
                    *pepper (&gt; 1 tsp/day), grits (white corn),whole wheat flour. <br /><br />* ≥ 70 mg/100 grams</p>


                <br />
                <p style={{ marginTop: "-30px", marginLeft: -13, fontWeight: '400px', fontSize: 14, color: '#484343' }}>40-50 mg/day = low oxalate intake</p>
                <br />
                {/* <p style={{marginTop:"-20px",marginLeft:-13,fontWeight:'600px',fontSize:14,color:'#484343'}}> <b>Reference:&nbsp;</b>Brzezinski E, et al. Oxalate Content of Selected Foods. University of California, 17-29, 2002.
Noonan SC, Savage GP. Oxalate content of foods and its effect on humans. Asia Pac J Clin Nutr 1999; 8(1): 64-74. 
<a target={"_blank"} href="https://apjcn.nhri.org.tw/server/APJCN/8/1/64.pdf]">https://apjcn.nhri.org.tw/server/APJCN/8/1/64.pdf</a>
</p> */}

                <p style={{ marginTop: "-20px", marginLeft: -22, fontWeight: '400px', fontSize: 14, color: '#484343' }}><b>References : </b>
                    <br />
                    Brzezinski E, et al. Oxalate Content of Selected Foods. University of California, 17-29, 2002.<br />
                    <a target={"_blank"} href="https://apjcn.nhri.org.tw/server/APJCN/8/1/64.pdf">
                        Noonan SC, Savage GP. Oxalate content of foods and its effect on humans. Asia Pac J Clin Nutr 1999; 8(1): 64-74.
                    </a>
                </p>

            </div>
        </div>

    );

}

export default Oxalate;