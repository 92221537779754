import React from 'react'

function Counter(props) {
  return (
    <div>
        <div className="inset-xl-left-25 inset-xl-right-25 inset-lg-left-25 inset-lg-right-25">
            <span className={`icon novi-icon icon-lg fl-great-icon-set-ico ${props.icon}`} style={{color: 'white'}}></span>
            <h1 className="counter offset-top-13">{props.count}</h1>
            <p className="text-big offset-top-0" style={{color: 'white'}}>{props.countertxt}<br className="d-none d-xl-inline-block"  />{props.counterbrtxt}</p>
        </div>
    </div>
  )
}

export default Counter