import React from 'react';
import BackIcon from '../../assets/images/previousBtn.png';

const PageHeader = (props) => {
  return (
    <section className={`section section-height-150 parallax-container bg-cover-contact ${props?.title === 'Contact DNI' ? "" : 'pageHeader-imgs'} context-dark bg-gray-darkest text-xl-left`} data-parallax-img={props.bgimg && props.bgimg}>
      <div className="parallax-content">
        <div className="bg-overlay-black1">
          <div className="container mt-5 pt-5" style={{ fontSize: '60px', position: 'relative' }}>
            {/* Back Icon */}
            <div className='d-flex d-lg-block'>
              <img
                src={BackIcon}
                alt="Back"
                className="d-lg-none d-md-block"
                onClick={() => { window.location.href = '/#/' }}
                style={{
                  top: '10px',
                  left: '10px',
                  width: '6%',
                  height: '19%',
                  cursor: 'pointer'
                }}
              />
            </div>
            <div className="d-none d-lg-block">
              {props.title && <h1 className="page-header title custom-title">{props.title}</h1>}
            </div>
            {props.title && <h5 className='d-lg-none page-header title custom-title'>{props.title}</h5>}
            {props.description && <p className='text-big p px-3 px-lg-0'>{props.description}</p>}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageHeader;
