import React from 'react';
import dniLogo from '../../assets/images/logo/dni-logo.png'
import BackIcon from '../../assets/images/previousBtn.png'
const Desclimer = () => {
    return (
        <>
            <section className="section search-bg2 section-height-800 parallax-container context-dark bg-gray-darkest text-xl-left" data-parallax-img="./images/home/resized/about-news.jpg">
                <div className="parallax-content">
                    <div className="bg-overlay-black">
                        <div className="container section-80 section-md-30 section-lg-top-10 section-lg-bottom-30" style={{ paddingTop: 6 }}>
                            <div className="d-none d-lg-block">
                                <h1 className='mt-4'>Disclaimer</h1>
                            </div>
                            <div className='d-flex d-lg-block'>
                                <img src={BackIcon} alt="Back" className="d-lg-none d-md-none" onClick={() => { window.location.href = '/#/' }} style={{ width: '8%', height: "8%", marginTop: '97px' }} />
                                {/* <ul className="list-inline list-inline-dashed list-white text-big p offset-md-top-8">
                                    <li>Learn More about the DNI team</li>
                                
                                </ul> */}
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <div className='col-md-7 mt-3' style={{ textAlign: 'justify', color: 'black', margin: 'auto' }}>

                <p style={{ lineHeight: 'normal', margin: '0cm 0cm 10pt', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}><strong><u><span style={{ color: '#1f497d' }}>
                    <img style={{ width: '127.5pt', height: '35pt', visibility: 'visible' }} src={dniLogo} /></span></u></strong></p>
                <p style={{ lineHeight: 'normal', margin: '0cm 0cm 10pt', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}><strong><u><span style={{ color: '#1f497d' }}>DISCLAIMER </span></u></strong></p>
                <p style={{ lineHeight: 'normal', margin: '0cm 0cm 10pt', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}><span style={{ color: '#1f497d' }}>The information (the “<strong>Information</strong>”) found on this site (this “<strong>Site</strong>”) and provided through any services offered on this Site (the “<strong>Services</strong>”) is provided by Elbe’s Drug Nutrition Interactions Ltd. or one of its affiliates (the “<strong>Company</strong>”, “<strong>we</strong>”, “<strong>our</strong>” or “<strong>us</strong>”) and is intended for use by professional registered dietitians, registered pharmacists or other healthcare professionals, is not patient-specific, and is not intended to be a substitute for professional medical advice, diagnosis, or treatment. To the extent any conflict exists between the Information and the Canadian Product Monograph (the “<strong>PM</strong>”) or the United States Prescribing Information (the “<strong>PI</strong>”), the PM or PI shall prevail. The Information found on this Site are an extraction from the PM, the PI, and published literature and thus do not contain all warnings or information contained in the PM or PI. While the Information is updated on a regular basis, the Site may sometimes lag behind PM and PI changes and thus may have outdated information. </span></p>
                <p style={{ lineHeight: 'normal', margin: '0cm 0cm 10pt', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}><span style={{ color: '#1f497d' }}>THE INFORMATION IS PROVIDED “AS-IS” AND “AS AVAILABLE” AND WE EXPRESSLY DISCLAIM, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ANY WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, OR NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT THE INFORMATION: (A) WILL MEET YOUR REQUIREMENTS; (B) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (C) WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES, SO PORTIONS OF THE FOREGOING EXCLUSION MAY NOT APPLY TO YOU.&nbsp; </span></p>
                <p style={{ lineHeight: 'normal', margin: '0cm 0cm 10pt', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}><span style={{ color: '#1f497d' }}>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOSS, CONSEQUENCE, EFFECT, ADVERSE REACTION, INJURY, CLAIM, LIABILITY, OR DAMAGE OF ANY KIND RESULTING FROM YOU USING OR ACCESSING THE INFORMATION (COLLECTIVELY, “<strong>DAMAGES</strong>”). WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THE INFORMATION, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THE INFORMATION (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO THE AGGREGATE AMOUNT THAT YOU PAID US IN THE PRIOR 12 MONTHS (IF ANY). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT INCREASE THIS LIMIT. </span></p>
                <p style={{ lineHeight: 'normal', margin: '0cm 0cm 10pt', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}><span style={{ color: '#1f497d' }}>THESE TERMS ARE GOVERNED BY AND WILL BE CONSTRUED IN ACCORDANCE WITH THE LAWS OF THE PROVINCE OF BRITISH COLUMBIA AND THE LAWS OF CANADA APPLICABLE THEREIN, WITHOUT REGARD TO CONFLICT OF LAWS PRINCIPLES. BY USING OR ACCESSING THE INFORMATION, YOU ATTORN TO THE JURISDICTION OF THE COURTS OF COMPETENT JURISDICTION LOCATED IN BRITISH COLUMBIA, CANADA. </span></p>
                {/* <p style={{ lineHeight: 'normal', margin: '0cm 0cm 10pt', fontSize: '11pt', fontFamily: 'Calibri, sans-serif' }}><span style={{ color: '#1f497d' }}>By clicking “I Agree” or by using or accessing the Information, you represent that you understand, accept, and agree to be bound and comply with these terms. </span></p> */}
            </div>
        </>
    );
}

export default Desclimer;