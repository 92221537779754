import React from 'react';

const Undwegt = "BMI < 18.5";
const Nrmlwegt = "BMI 18.5 - 24.9";
const Ovrwegt = "BMI 25 - 29.9";
const ObstLvl1 = "BMI 30 - 34.9";
const ObstLvl2 = "BMI 35 - 39.9";
const ExtrmObst = "BMI > 40";

function BmiDisplay(props) {

  return (
    <>
      <div className='pt-4' >

        <h2 className='mb-4' style={{ marginLeft: '-10px', marginTop: '-40px' }}><span style={{ fontSize: '34px' }}>BMI: </span>
          <span className={"mx-auto mb-5"} style={{ fontFamily: 'serif' }}>{props.alertClass == "" ? '--.- ' : props.alertClass} kg&#47;m<sup>2</sup> </span>
        </h2>

        <ul className="list-group-horizontal bmi-bar d-block d-md-flex" style={{ marginTop: '15px' }}>

          <li className={(props?.label == 'underweight' && props.alertClass != "") ? "list-group-item under bg-info" : 'list-group-item under bg-info-active'}
            style={{ padding: '0px' }}>
            <div style={{ background: '#dd0909', padding: '5px' }} ><text>{Undwegt}</text></div>
            <div style={{ fontSize: '30px' }} className={(props.label === 'underweight' && props.alertClass != "") ? "fa fa-arrow-down" : "invisible"}></div><br></br>
            <div style={{ padding: '15px', marginTop: '-6px', fontWeight: 'bold' }}>Underweight</div>
          </li>


          <li className={(props?.label == 'normalweight' && props.alertClass != "") ? "list-group-item normal bg-success-active" : "list-group-item normal bg-success"}
            style={{ padding: '0px' }}>
            <div style={{ background: 'rgb(131 175 11 / 89%)', padding: '5px' }}><text>{Nrmlwegt}</text></div>
            <div style={{ fontSize: '30px' }} className={(props.label === 'normalweight' && props.alertClass != "") ? "fa fa-arrow-down" : "invisible"}></div><br></br>
            <div style={{ padding: '15px', marginTop: '-6px', fontWeight: 'bold' }}>Normal Weight</div>
          </li>


          <li className={(props?.label == 'overweight' && props.alertClass != "") ? "list-group-item over bg-warning-active" : "list-group-item over bg-warning"}
            style={{ padding: '0px' }}>
            <div style={{ background: 'rgb(217 105 16 / 76%)', padding: '5px', marginTop: "0px" }}><text>{Ovrwegt}</text></div>
            <div style={{ fontSize: '30px' }} className={(props.label === 'overweight' && props.alertClass != "") ? "fa fa-arrow-down" : "invisible"}></div><br></br>
            <div style={{ padding: '15px', marginTop: '-6px', fontWeight: 'bold' }}>Overweight</div>
          </li>


          <li className={(props?.label == 'obesity' && props.alertClass != "") ? "list-group-item obesity bg-obesity-active" : "list-group-item obesity bg-obesity"}
            style={{ padding: '0px' }}>
            <div style={{ background: 'rgb(176 31 213 / 63%)', padding: '5px', marginTop: "0px" }}><text>{ObstLvl1}</text></div>
            <div style={{ fontSize: '30px' }} className={(props.label === 'obesity' && props.alertClass != "") ? "fa fa-arrow-down" : "invisible"}></div><br></br>
            <div style={{ marginTop: '10px', fontWeight: 'bold' }}>Obesity Level I</div>
          </li>


          <li className={(props?.label == 'ExtraObesity' && props.alertClass != "") ? 'list-group-item bg-ext-danger-active' : 'list-group-item bg-ext-danger'}
            style={{ padding: '0px' }}>
            <div style={{ background: '#b7041d', padding: '5px', marginTop: "0px" }}><text>{ObstLvl2}</text></div>
            <div style={{ fontSize: '25px' }} className={(props.label === 'ExtraObesity' && props.alertClass != "") ? "fa fa-arrow-down" : "invisible"}></div><br></br>
            <div style={{ marginTop: '10px', fontWeight: 'bold' }}>Obesity Level II</div>
          </li>


          <li className={(props?.label == 'danger' && props.alertClass != "") ? 'list-group-item extreame-danger-active' : 'list-group-item extreame-danger'}
            style={{ padding: '0px' }}>
            <div style={{ background: '#dd0909', padding: '5px', marginTop: "0px" }}><text>{ExtrmObst}</text></div>
            <div style={{ fontSize: '30px' }} className={(props.label === 'danger' && props.alertClass != "") ? "fa fa-arrow-down" : "invisible"}></div><br></br>
            <div style={{ padding: '7px', background: 'rgb(239 61 42 / 0%)', marginTop: '5px', fontWeight: 'bold' }}>Extreme Obesity</div>
          </li>

        </ul>
        <div>
        </div>

        <div className='mt-4'>
          <div style={{ color: '#484343' }}><b>Reference: </b> <a target="_blank" href="https://www.who.int/europe/news-room/fact-sheets/item/a-healthy-lifestyle---who-recommendations">World Health Organization. A healthy lifestyle - WHO recommendations for Body Mass Index (BMI).</a></div>
        </div>
      </div>
    </>
  )
}

export default BmiDisplay