import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DummyImage } from "react-simple-placeholder-image";
import { AUTENTICATE_API } from "../../services/Api/authenticationApi";
import logo from './../../assets/img/logo/dni-logo.png';
import { saveAs } from "file-saver";
import jsPDF from 'jspdf'
import { toast } from "react-toastify";
//import domtoimage from 'dom-to-image';
//import pdfMake from "pdfmake";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf-fix-jspdf";
import moment from "moment";
import { SEARCH_API } from "../../services/Api/searching";
import { PLAN_SELECTION } from "../../services/Api/PlanselectionApi";



function ModulePurchased() {
    const moment = require('moment');
    const [selectePrice, setSelectedModulPrice] = useState("")
    const [billingDetail, setBillingDetail] = useState("");
    const [taxVal, setTaxVal] = useState(0)
    const [taxs, setTaxs] = useState(0)
    const [totalDic, setTotalAMT] = useState(0)
    const [calculatedTotal, setCalculatedTotal] = useState(0);
    const [selectedTax, setSelectedTax] = useState(0);
    const [selectedCount, setSelectedTaxCount] = useState(0);
    const [hstAmount, setHstAmount] = useState(0);
    const [gstAmount, setGstAmount] = useState(0);
    const [pstAmount, setPstAmount] = useState(0);
    const [qstAmount, setQstAmount] = useState(0);





    const bStyle = {
        borderTop: '1px solid #ded9d9eb',
        borderBottom: '1px solid #ded9d9eb'
    }


    let value = moment().format(billingDetail?.created_dt, "dddd, MMMM Do YYYY, h:mm:ss a");
    let userDetail = JSON.parse(localStorage.getItem('setUser'));
    useEffect(() => {
        if (!sessionStorage.getItem('reloaded')) {
            sessionStorage.setItem('reloaded', 'true');
            window.location.reload();
        } else {
            BillingDetail(userDetail?.userInfo?.user_id);
            if (userDetail?.userInfo?.country_id == 1) {
                setTaxs(0)
            } else {
                setTaxs(1.8)
            }
        }
    }, []);


    const getModules = (ids) => {
        SEARCH_API?.getModulesByIds(ids).then(res => {
            if (res && res?.data) {
                //    setSelectedModuls(res?.data?.map(val=>{
                //      return val?.module_name
                //    }));
                setTimeout(() => {
                    setSelectedModulPrice(res?.data)
                }, 1000)
            }
        });
    }

    const BillingDetail = () => {

        AUTENTICATE_API.billingDetail(userDetail?.userInfo?.user_id).then((response) => {

            if (response && response?.data) {
                setBillingDetail(response.data);
                getModules(response?.data?.topup_plan_id);
                PLAN_SELECTION?.getTaxByProvinces(userDetail?.userInfo?.zip_code).then(res => {
                    setSelectedTaxCount(res?.count);
                    if (res && res?.data) {
                        setSelectedTax(res?.data[0]);
                    }
                });



                if (response?.data?.tax !== 0) {
                    let promoPer = (response?.data.billable_amt * response?.data?.tax) / 100
                    let promoFix = (promoPer?.toFixed(2));
                    let newVal = parseFloat(promoFix)
                    let final = response?.data.billable_amt + newVal
                    setTaxVal(final);
                }

                if (response?.data?.discount_type == 'Percentage') {
                    let promoPer = response?.data.total_billable_amt.toFixed(2);
                    let payVal = taxVal !== 0 ? taxVal : response?.data?.billable_amt
                    let minus = (payVal - parseFloat(promoPer));
                    setTotalAMT(parseFloat(minus?.toFixed(2)));

                }
            }
        })
    }

    const generatePDF = async () => {

        let element = document.querySelector(".modal-content");
        document.getElementById('close-id').style.display = 'none'
        document.getElementById('download-btns').style.display = 'none'

        let opt = {
            margin: 0.3,
            filename: `invoice_${moment(new Date()).format('MM-DD-YYYY')}`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 1 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };

        setTimeout(() => {
            html2pdf(element, opt);

        }, 100)

        setTimeout(() => {
            document.getElementById('download-btns').style.display = 'block'
            document.getElementById('close-id').style.display = 'block'
        }, 1000)
    }


    const calculateHST = () => {

        if (selectedTax) {

            if (selectedTax?.HST !== 0 && selectedTax?.HST !== undefined) {
                let discount = 0;

                // Determine discount amount based on discount type
                if (billingDetail?.discount > 0) {
                    if (billingDetail?.discount_type == 'Amount') {
                        discount = parseFloat(billingDetail?.discount);
                    } else if (billingDetail?.discount_type == 'Percentage') {
                        discount = (parseFloat(billingDetail?.billable_amt) * parseFloat(billingDetail?.discount) / 100).toFixed(2);
                    }
                }

                const billableAmount = parseFloat(billingDetail?.billable_amt) - parseFloat(discount);
                const calculatedHst = ((billableAmount.toFixed(2)) * selectedTax?.HST) / 100;
                setHstAmount(calculatedHst.toFixed(2));
            } else {
                setHstAmount(0);
            }
        }
    };


    const calculateGST = () => {
        if (selectedTax) {

            if (selectedTax?.GST !== 0 && selectedTax?.GST !== undefined) {
                let discount = 0;

                // Determine discount amount based on discount type
                if (billingDetail?.discount > 0) {
                    if (billingDetail?.discount_type === 'Amount') {
                        discount = parseFloat(billingDetail?.discount);
                    } else if (billingDetail?.discount_type === 'Percentage') {
                        discount = (parseFloat(billingDetail?.billable_amt) * parseFloat(billingDetail?.discount) / 100).toFixed(2);
                    }
                }

                const billableAmount = parseFloat(billingDetail?.billable_amt) - parseFloat(discount);
                const calculatedGst = ((billableAmount.toFixed(2)) * selectedTax?.GST) / 100;
                setGstAmount(calculatedGst.toFixed(2));
            } else {
                setGstAmount(0);
            }
        }
    };



    const calculateQST = () => {
        if (selectedTax) {
            if (selectedTax?.QST !== 0 && selectedTax?.QST !== undefined) {
                let discount = 0;

                // Determine discount amount based on discount type
                if (billingDetail?.discount > 0) {
                    if (billingDetail?.discount_type === 'Amount') {
                        discount = parseFloat(billingDetail?.discount);
                    } else if (billingDetail?.discount_type === 'Percentage') {
                        discount = (parseFloat(billingDetail?.billable_amt) * parseFloat(billingDetail?.discount) / 100).toFixed(2);
                    }
                }

                const billableAmount = parseFloat(billingDetail?.billable_amt) - parseFloat(discount);
                const calculatedQst = ((billableAmount.toFixed(2)) * selectedTax?.QST) / 100;
                setQstAmount(calculatedQst.toFixed(2));
            } else {
                setQstAmount(0);
            }
        }
    };


    const calculatePST = () => {
        if (selectedTax) {
            if (selectedTax?.PST !== 0 && selectedTax?.PST !== undefined) {
                let discount = 0;

                // Determine discount amount based on discount type
                if (billingDetail?.discount > 0) {
                    if (billingDetail?.discount_type === 'Amount') {
                        discount = parseFloat(billingDetail?.discount);
                    } else if (billingDetail?.discount_type === 'Percentage') {
                        discount = (parseFloat(billingDetail?.billable_amt) * parseFloat(billingDetail?.discount) / 100).toFixed(2);
                    }
                }

                const billableAmount = parseFloat(billingDetail?.billable_amt) - parseFloat(discount);
                const calculatedPst = ((billableAmount.toFixed(2)) * selectedTax?.PST) / 100;
                setPstAmount(calculatedPst.toFixed(2));
            } else {
                setPstAmount(0);
            }
        }
    };

    useEffect(() => {
        if (selectedTax) {

            calculateHST();
            calculateGST();
            calculatePST();
            calculateQST();
        }
    }, [selectedTax]);

    useEffect(() => {
        if (billingDetail) {



            let newCalculatedTotal = 0;

            if (billingDetail?.discount > 0 && billingDetail?.discount_type === 'Amount') {
                newCalculatedTotal = (parseFloat(billingDetail?.billable_amt) - parseFloat(billingDetail?.discount)).toFixed(2);
            } else if (billingDetail?.discount > 0 && billingDetail?.discount_type === 'Percentage') {
                const discountAmount = (parseFloat(billingDetail?.billable_amt) * parseFloat(billingDetail?.discount) / 100).toFixed(2);
                newCalculatedTotal = (parseFloat(billingDetail?.billable_amt) - parseFloat(discountAmount)).toFixed(2);
            } else {
                newCalculatedTotal = billingDetail?.billable_amt;
            }

            if (newCalculatedTotal < 0) {
                newCalculatedTotal = 0;
            }

            setCalculatedTotal(newCalculatedTotal);

            console.log((calculatedTotal) +
                parseFloat(gstAmount) +
                parseFloat(hstAmount) +
                parseFloat(pstAmount) +
                parseFloat(qstAmount), "new hai")
        }
    }, [billingDetail, selectedTax]);

    const fetchPromoCode = (promoCode) => {
        AUTENTICATE_API.getPromoCode(promoCode).then(res => {
            if (res?.data?.discountpromo) {
                const discountPromo = res.data.discountpromo;
                let discount = 0;
                if (discountPromo.discount_type === 'Percentage') {
                    discount = (parseFloat(billingDetail?.billable_amt) * parseFloat(discountPromo.discount_amt) / 100).toFixed(2);
                } else if (discountPromo.discount_type === 'Amount') {
                    discount = parseFloat(userDetail?.userInfo?.country_id === 2 ? discountPromo.amount_for_can : discountPromo.amount_for_us);
                }
                setTotalAMT(discount);
            }
        });
    };


    const ContinueProcess = () => {
        window.location.href = "#/"
    }

    return (
        <>
            <section className="section section-md-bottom-50 bg-gray-lighter novi-background bg-cover"><br /><br />
                <div className="container">
                    <div className="row justify-content-sm-center align-items-center offset-top-40">
                    </div>
                    <div className="row justify-content-sm-center align-items-center">
                        <div className='col'>
                            <div className="jumbotron bg-white shadow-lg">
                                <div className='mb-4'>
                                    <i className="fa-solid fa-check rounded-check display-3"></i>
                                </div>
                                <h2 className="text-uppercase">Payment Confirmed!</h2>
                                <p className='px-lg-5' style={{ fontSize: 18, color: 'black' }}>Thank you for your subscription to Drug Nutrition Interactions.</p>
                                <p className='mb-5 px-lg-5' style={{ fontSize: 18, color: 'black' }}>Press the continue button to access this outstanding professional resource.</p>
                                <div>
                                    <button type='submit' className='btn btn btn btn-outline-success mr-4' onClick={ContinueProcess} >Continue</button>
                                    <button type='submit' className='btn btn btn btn-outline-success mr-4' data-toggle="modal" data-target="#reciept">View Receipt</button>
                                    <button className='btn btn btn btn-outline-success mr-4' onClick={generatePDF} >Download Receipt</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="modal fade" id="reciept" tabindex="-1" aria-labelledby="reciept" >
                <div id="test1" >
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content p-3">
                            <div className="modal-body text-center">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" id='close-id'>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                {/* <div className='text-center mb-5'> */}
                                <img className="m-auto" src={logo} alt="" width={200} />
                                {/* </div> */}<br /><br />
                                <h3 className="text-uppercase">thank you for subscribing to dni</h3>
                                <p className="text-big font-weight-bold text-primary text-uppercase"><Link to="#">Order Summary</Link></p>
                                <p className='lead'>{moment(billingDetail?.created_dt).format('MM/DD/YYYY')} {moment(billingDetail?.created_dt).local().format('h:mm A')}</p>
                                <table className="table text-left table-borderless">
                                    <tbody className="border-bottom">
                                        <tr style={bStyle}>
                                            <td scope="row">{billingDetail?.plan_name} Plan</td>
                                            <th className="text-right">

                                                {/* {moment.utc(billingDetail?.created_dt).local().format('h:mm A')} */}
                                                ${userDetail?.userInfo?.country_id == 2 ? billingDetail?.plan_price_can : billingDetail?.plan_price_us} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}</th>
                                        </tr>
                                        {selectePrice?.length > 0 ? selectePrice?.map((val, i) => <tr style={bStyle}>
                                            <td scope="row">{val?.module_name}</td>
                                            <th className="text-right">${userDetail?.userInfo?.country_id == 2 ? val?.can_price : val?.us_price} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}</th>
                                        </tr>) : ""}
                                        <tr style={bStyle}>
                                            <td scope="row">Subtotal</td>
                                            <th className="text-right">
                                                ${Math.max(calculatedTotal, 0)} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}
                                            </th>
                                        </tr>
                                        {
                                            (selectedCount !== 0 && selectedTax?.GST != 0) ? <>
                                                <tr style={bStyle}>
                                                    <td style={{ color: 'black', textAlign: 'initial', paddingBottom: 15 }}><span>{selectedTax?.GST != 0 ? 'GST' : ''} ({selectedTax?.GST != 0 ? selectedTax?.GST : ''} %)</span></td>

                                                    <th style={{ color: 'black', textAlign: 'right' }}>${selectedTax?.GST != 0 ? gstAmount : ''} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}
                                                    </th>
                                                </tr>
                                            </> : ''
                                        }
                                        {
                                            (selectedCount !== 0 && selectedTax?.HST != 0) ? <>
                                                <tr style={bStyle}>
                                                    <td style={{ color: 'black', textAlign: 'initial', paddingBottom: 15 }}><span>{selectedTax?.HST != 0 ? 'HST' : ''} ({selectedTax?.HST != 0 ? selectedTax?.HST : ''} %)</span></td>

                                                    <th style={{ color: 'black', textAlign: 'right' }}>${selectedTax?.HST != 0 ? hstAmount : ''} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}
                                                    </th>
                                                </tr>
                                            </> : ''
                                        }
                                        {
                                            (selectedCount != 0 && selectedTax?.PST != 0) ? <>
                                                <tr style={bStyle}>
                                                    <td style={{ color: 'black', textAlign: 'initial', paddingBottom: 15 }}><span>{selectedTax?.PST != 0 ? 'PST' : ''} ({selectedTax?.PST != 0 ? selectedTax?.PST : ''} %)</span></td>

                                                    <th style={{ color: 'black', textAlign: 'right' }}>${selectedTax?.PST != 0 ? pstAmount : ''} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}
                                                    </th>
                                                </tr>
                                            </> : ''
                                        }
                                        {
                                            (selectedCount != 0 && selectedTax?.QST != 0) ? <>
                                                <tr style={bStyle}>
                                                    <td style={{ color: 'black', textAlign: 'initial', paddingBottom: 15 }}><span>{selectedTax?.QST != 0 ? 'QST' : ''} ({selectedTax?.QST != 0 ? selectedTax?.QST : ''} %)</span></td>

                                                    <th style={{ color: 'black', textAlign: 'right' }}>${selectedTax?.QST != 0 ? qstAmount : ''} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}
                                                    </th>
                                                </tr>
                                            </> : ''
                                        }
                                        {(billingDetail?.tax > 0 && userDetail?.userInfo?.country_id == 2) ? <tr>
                                            <td scope="row">Total Tax ({selectedTax?.Final_tax}  %)</td>
                                            <th className="text-right">${(parseFloat(gstAmount) + parseFloat(hstAmount) + parseFloat(pstAmount) + parseFloat(qstAmount)).toFixed(2)} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}
                                                {/* {userDetail?.userInfo?.country_id==2?'CAD':'USD'} */}
                                            </th>
                                        </tr> : ""}
                                        {(billingDetail?.tax > 0 && userDetail?.userInfo?.country_id == 2) ?
                                            <tr style={bStyle}>
                                                <td scope="row">Total Amount</td>
                                                <th className="text-right"> ${(parseFloat(calculatedTotal) + parseFloat(gstAmount) + parseFloat(hstAmount) + parseFloat(pstAmount) + parseFloat(qstAmount)).toFixed(2)} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}</th>
                                            </tr>
                                            : ""}
                                        {billingDetail?.discount == 0 ? "" : <tr>
                                            <td scope="row">Promo</td>
                                            <th className="text-right">{billingDetail?.promo_code} <span className="badge badge-pill badge-primary py-2 px-4">Applied</span></th>
                                        </tr>}
                                    </tbody>
                                    {billingDetail?.discount == 0 ? "" : <tbody className="border-bottom">
                                        {/* {totalDic!==0?totalDic:billingDetail?.discount} */}
                                        <tr>
                                            <td scope="row">Promo Applied</td>
                                            <th className="text-right"><p className="text-big font-weight-bold text-primary m-0">
                                                <Link to="#">${totalDic !== 0 ? totalDic : billingDetail?.discount} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'} off for {billingDetail?.plan_name} plan</Link></p></th>
                                        </tr>
                                    </tbody>}
                                    <tbody>
                                        <tr>
                                            <th scope="row">Grand Total</th>
                                            <td className="text-right"><p className="text-big font-weight-bold text-primary"> <Link to="#">${billingDetail?.total_billable_amt < 0 ? 0 : billingDetail?.total_billable_amt?.toFixed(2)} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}</Link></p></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h2 className="mb-4 text-center">Your Details</h2>
                                <table className="table text-left table-borderless">
                                    <tbody>
                                        <tr style={bStyle}>
                                            <td className='pb-0'>Billed to</td>
                                            <th className="text-right pb-0">{billingDetail?.user_name}</th>
                                        </tr>
                                        <tr style={bStyle}>
                                            <th colSpan={2} className="text-right pb-0">{billingDetail?.email_id}</th>
                                        </tr>
                                        {userDetail?.userInfo?.organization != null ?
                                            <tr>
                                                <td className='pb-0'>Organization</td>
                                                <th className="text-right pb-0">{userDetail?.userInfo?.organization}</th>
                                            </tr> : ''}
                                    </tbody>
                                </table>
                                <p>Notice anything wrong? <Link to="/contact">Contact us</Link> and we'll happy to help</p>
                            </div>
                            <div className="modal-footer text-center">
                                <button onClick={generatePDF} className="btn btn-outline-primary btn-block col-md-2" id="download-btns">Download</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModulePurchased