import React from 'react'
import { Link } from "react-router-dom";

function AccountNav() {
  return (
    <nav id="sidebar" className="sidebar-wrapper bg-white shadow p-4">
        <div className="sidebar-content">
            <div className="d-flex text-left align-items-center mb-1">
                <i className="fa fa-compass mr-2"></i>
                <Link className="nav-link disabled sidebar-text" to={'/'}>Dashboard</Link>
            </div>
            <div className="d-flex text-left align-items-center mb-1">
                <i className="fa fa-cog mr-2"></i>
                <Link className="nav-link disabled sidebar-text" to={'/'}>My Account</Link>
            </div>
            <div className="d-flex text-left align-items-center mb-1">
                <i className="fa fa-bookmark-o mr-2"></i>
                <Link className="nav-link disabled sidebar-text" to={'/'}>My Drug List</Link>
            </div>
            <div className="d-flex text-left align-items-center mb-1">
                <i className="fa fa-university mr-2"></i>
                <Link className="nav-link disabled sidebar-text" to={'/'}>My Favaourites</Link>
            </div>
            <div className="d-flex text-left align-items-center mb-1">
                <i className="fa fa-table mr-2"></i>
                <Link className="nav-link disabled sidebar-text" to={'/'}>My Tables</Link>
            </div>
            <div className="d-flex text-left align-items-center mb-1">
                <i className="fa fa-shopping-basket mr-2"></i>
                <Link className="nav-link disabled sidebar-text" to={'/'}>My Cart</Link>
            </div>
            <div className="d-flex text-left align-items-center mb-1">
                <i className="fa fa-flag-checkered mr-2"></i>
                <Link className="nav-link disabled sidebar-text" to={'/'}>My Plans</Link>
            </div>
            <div className="d-flex text-left align-items-center mb-1">
                <i className="fa fa-history mr-2"></i>
                <Link className="nav-link disabled sidebar-text" to={'/'}>My Payment History</Link>
            </div>
            
        </div>
    </nav>
  )
}

export default AccountNav;