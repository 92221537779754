import axios from "axios";
import jwt_decode from "jwt-decode";
import { createHashHistory } from "history";
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import {  Urls1 } from "../Urls/apiUrl";

const history = createHashHistory();
export const AUTENTICATE_API = {
  createUser,
  professionList,
  countryList,
  stateList,
  UserLogin,
  areaInterestList,
  userVerification,
  getInTouch,
  otpResend,
  getPromoCode,
  applyPromoCode,
  billingDetail,
  makePayment,
  ContactSendMail,
  getForgotOtp,
  verifyCode,
  changeUserPassword,
  update_EmailOrPassword,
  Verified_emailUpdate,
  totalSubscriptions,
  validate_postalCode,
  Drug_count,
  guestnews_list,
  getNewsDetails,
  guestnews_listByUser,
  guestDrugDetails,
  guestDrugIcons
 };

 function guestnews_listByUser(id) {
  return axios.get(Urls1 + `api/web/customernews_list/${id}`)
  .then((response)=>{
    return response.data;
  })
  .catch((error) => {
    if (error === "Unauthorized") {
      history.push("/signin");
      return [];
    }
    if (error.response !== undefined)
      if (error.response.status === 401 ) {
        toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
      localStorage.clear();
      setTimeout(()=>{
        window.location.href="/#/signin"
      },2000)
      }
  });
}

 function getNewsDetails(id) {
  return axios.get(Urls1 + `api/web/webnews_detail/${id}`)
  .then((response)=>{
    return response.data;
  })
  .catch((error) => {
    if (error === "Unauthorized") {
      history.push("/signin");
      return [];
    }
    if (error.response !== undefined)
      if (error.response.status === 401 ) {
        toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
      localStorage.clear();
      setTimeout(()=>{
        window.location.href="/#/signin"
      },2000)
      }
  });
}

function guestDrugDetails() {
  return axios.get(Urls1 + `api/web/guest_generic_details/semaglutide`)
  .then((response)=>{
    return response.data;
  })
  .catch((error) => {
    if (error === "Unauthorized") {
      history.push("/signin");
      return [];
    }
    if (error.response !== undefined)
      if (error.response.status === 401 ) {
        toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
      localStorage.clear();
      setTimeout(()=>{
        window.location.href="/#/signin"
      },2000)
      }
  });
}

function guestDrugIcons() {
  return axios.get(Urls1 + `api/web/Guest_iconList`)
  .then((response)=>{
    return response.data;
  })
  .catch((error) => {
    if (error === "Unauthorized") {
      history.push("/signin");
      return [];
    }
    if (error.response !== undefined)
      if (error.response.status === 401 ) {
        toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
      localStorage.clear();
      setTimeout(()=>{
        window.location.href="/#/signin"
      },2000)
      }
  });
}

 function guestnews_list() {
  return axios.get(Urls1 + `api/web/guestnews_list`)
  .then((response)=>{
    return response.data;
  })
  .catch((error) => {
    if (error === "Unauthorized") {
      history.push("/signin");
      return [];
    }
    if (error.response !== undefined)
      if (error.response.status === 401 ) {
        toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
      localStorage.clear();
      setTimeout(()=>{
        window.location.href="/#/signin"
      },2000)
      }
  });
}

 function Drug_count() {
  return axios.get(Urls1 + `api/web/Drug_count`)
  .then((response)=>{
    return response.data;
  })
  .catch((error) => {
    if (error === "Unauthorized") {
      history.push("/signin");
      return [];
    }
    if (error.response !== undefined)
      if (error.response.status === 401 ) {
        toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
      localStorage.clear();
      setTimeout(()=>{
        window.location.href="/#/signin"
      },2000)
      }
  });
}

 function validate_postalCode(data) {
  return axios.get(Urls1 + `api/web/validate_postalCode/${data}`)
  .then((response)=>{
    return response.data;
  })
  .catch((error) => {
    if (error === "Unauthorized") {
      history.push("/signin");
      return [];
    }
    if (error.response !== undefined)
      if (error.response.status === 401 ) {
        toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
      localStorage.clear();
      setTimeout(()=>{
        window.location.href="/#/signin"
      },2000)
      }
  });
}

 function totalSubscriptions(data) {
  return axios.get(Urls1 + `api/web/totalSubscriptions`)
  .then((response)=>{
    return response.data;
  })
  .catch((error) => {
    if (error === "Unauthorized") {
      history.push("/signin");
      return [];
    }
    if (error.response !== undefined)
      if (error.response.status === 401) {
        toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
      localStorage.clear();
      setTimeout(()=>{
        window.location.href="/#/signin"
      },2000)
      }
  });
}

 function Verified_emailUpdate(data) {
  
  return axios.post(Urls1 + `api/web/Verified_emailUpdate`,data,{headers: { Authorization: getToken()}})
  .then((response)=>{
    return response.data;
  })
  .catch((error) => {
    if (error === "Unauthorized") {
      history.push("/signin");
      return [];
    }
    if (error.response !== undefined)
      if (error.response.status === 401) {
        toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
      localStorage.clear();
      setTimeout(()=>{
        window.location.href="/#/signin"
      },2000)
      }
      if (error.response.status === 400) {
        toast.error(error?.response?.data?.message, { autoClose: 800, position: toast.POSITION.TOP_RIGHT });
      }
  });
}

 function update_EmailOrPassword(data) {
  return axios.post(Urls1 + `api/web/update_EmailOrPassword`,data,{headers: { Authorization: getToken()}})
  .then((response)=>{
    return response.data;
  })
  .catch((error) => {
    if (error === "Unauthorized") {
      history.push("/signin");
      return [];
    }
    if (error.response !== undefined)
      if (error.response.status === 401) {
        toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
      localStorage.clear();
      setTimeout(()=>{
        window.location.href="/#/signin"
      },2000)
      }
      if (error.response.status === 400) {
        toast.error(error?.response?.data?.message, { autoClose: 800, position: toast.POSITION.TOP_RIGHT });
      }
      if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
  });
}

 function changeUserPassword(requestOptions) {
  return axios.post(Urls1 + `api/web/Userchange_forgotPassword`,requestOptions )
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
       if (error.response.status === 401) {
        toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
      localStorage.clear();
      setTimeout(()=>{
        window.location.href="/#/signin"
      },2000)
       }else if(error.response.status === 400){
        //  toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        // toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

 function verifyCode(requestOptions) {
  return axios.post(Urls1 + `api/web/verifyForgot_opt`,requestOptions )
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
       if (error.response.status === 401) {
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

 function getForgotOtp(requestOptions) {
  return axios.post(Urls1 + `api/web/forgot_password`,requestOptions )
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
       if (error.response.status === 401) {
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

 function ContactSendMail(requestOptions) {
  return axios.post(Urls1 + `api/web/sendmail`,requestOptions )
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
       if (error.response.status === 401) {
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}
  function createUser(requestOptions) {
    return axios.post(Urls1 + `api/web/customer_register`,requestOptions)
     .then((response) => {
       return response.data;
     })
     .catch((error) => {
  
       if (error == "Unauthorized") {
         return [];
       }
       if (error.response != undefined)
         if (error.response.status === 401) {

           toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
         }else if(error.response.status === 400){
           toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
         }else if(error.response.status ===409){
          toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
         }
     });
  }


  function makePayment(data) {
    return axios.post(Urls1 + `api/web/payment`,data,{headers: { Authorization: getToken()}} )
     .then((response) => {
       return response.data;
     })
     .catch((error) => {
  
       if (error == "Unauthorized") {
         return [];
       }
       if (error.response != undefined)
         if (error.response.status === 401) {
          toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
         }else if(error.response.status === 400){
           toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
         }else if(error.response.status ===409){
          toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
         }
     });
  }


  function professionList(){
    return axios.get(Urls1 + `api/web/profession_list`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error === "Unauthorized") {
          history.push("/signin");
          return [];
        }
        if (error.response !== undefined)
          if (error.response.status === 401) {
            history.push("/signin");
            return [];
          }
      });
  }
  function countryList(){
    return axios.get(Urls1 + `api/web/get_Country`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error === "Unauthorized") {
          history.push("/signin");
          return [];
        }
        if (error.response !== undefined)
          if (error.response.status === 401) {
            history.push("/signin");
            return [];
          }
      });
  }
  function stateList(id){
    return axios.get(Urls1 + `api/web/get_state/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error === "Unauthorized") {
          history.push("/signin");
          return [];
        }
        if (error.response !== undefined)
          if (error.response.status === 401) {
            history.push("/signin");
            return [];
          }
      });
  }


  function UserLogin(requestOptions) {
    return axios.post(Urls1 + 'api/web/customer_login', requestOptions)
     .then((response) => {
       return response.data;
     })
     .catch((error) => {
      if (error == "Unauthorized") {
         return [];
       }
       if (error.response != undefined)
         if (error.response.status === 401) {
           toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
         }else if(error.response.status === 400){
           toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
         }
     });
 }
 function otpResend(email){
  return axios.get(Urls1 + `api/web/otp_resned/${email}`)
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    if (error === "Unauthorized") {
      history.push("/signin");
      return [];
    }
    if (error.response !== undefined)
      if (error.response.status === 401) {
        history.push("/signin");
        return [];
      }
  });
 }
 
   function areaInterestList () {
    return axios.get(Urls1 + `api/v1/ActiveAreaintrest_list`)
    .then((response)=>{
      return response.data;
    })
    .catch((error) => {
      if (error === "Unauthorized") {
        history.push("/signin");
        return [];
      }
      if (error.response !== undefined)
        if (error.response.status === 401) {
          history.push("/signin");
          return [];
        }
    });
  }

  function userVerification(requestOptions) {
    return axios.post(Urls1 + "api/web/customer_otpverify", requestOptions)
     .then((response) => {
       return response.data;
     })
     .catch((error) => {
  
       if (error == "Unauthorized") {
         return [];
       }
       if (error.response != undefined)
         if (error.response.status === 401) {
           toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
         }else if(error.response.status === 400){
           toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
         }
     });
  }
  function getInTouch(requestOptions){
    return axios.post(Urls1 + `api/web/sendMailGetIn_Touch`,requestOptions )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
 
      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
        }else if(error.response.status === 400){
          toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
        }else if(error.response.status ===409){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
        }
    });
  }
  function getPromoCode (promocode) {
    return axios.get(Urls1 + `api/web/varify_user_promo/${promocode}`,{headers: { Authorization: getToken()}})
    .then((response)=>{
      return response.data;
    })
    .catch((error) => {
      if (error === "Unauthorized") {
        history.push("/signin");
        return [];
      }
      if (error.response !== undefined)
      if (error.response.status === 401) {
        toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
        localStorage.clear();
        setTimeout(()=>{
          window.location.href="/#/signin"
        },2000)
     }
    });
  }

 
  function applyPromoCode(requestOptions){
    return axios.post(Urls1 + `api/web/apply_promo`,requestOptions ,{headers: { Authorization: getToken()}})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
 
      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
        }else if(error.response.status === 400){
          toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
        }else if(error.response.status ===409){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
        }
    });
  }

  function billingDetail (id) {
    return axios.get(Urls1 + `api/web/billing_data/${id}`,{headers: { Authorization: getToken()}})
    .then((response)=>{
      return response.data;
    })
    .catch((error) => {
      if (error === "Unauthorized") {
        history.push("/signin");
        return [];
      }
      if (error.response !== undefined)
        if (error.response.status === 401) {
          toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
        localStorage.clear();
        setTimeout(()=>{
          window.location.href="/#/signin"
        },2000)
        }
    });
  }
 
  function getToken() {
    var t = localStorage.getItem("setUser");
    t=JSON.parse(t)
    if(t!==null){
    if (t === undefined || t == null) {
      return "InvalidUser";
    }
    const decoded = jwt_decode(t?.token);
    if (
      t!==null || t!==undefined && decoded.email_id === t?.userInfo?.email_id &&
      decoded.staff_id == t?.userInfo?.staff_id
    ) {
      return "Bearer " + t?.token;
    } else {
      return "InvalidUser";
    }
    }else{
      localStorage.clear();
      setTimeout(()=>{
        window.location.href="/#/signin"
      },2000)
    }
  }
