
import React from "react";
import Carousel from "react-elastic-carousel";
import { AUTENTICATE_API } from "../services/Api/authenticationApi";
import { PostBox } from "./Box/Box";

class Carasual extends React.Component {
    constructor(props) {
        super(props);
            this.state = {
            newsData: [],
            newsDataPopular:[]
        }
        this.breakPoints = [
            { width: 1, itemsToShow: 1 },
            { width: 550, itemsToShow: 2 },
            { width: 768, itemsToShow: 3 },
            { width: 1200, itemsToShow: 4 },
        ]
    }

    componentDidMount() {
        this.guestnews_list();
        if(localStorage.getItem('setUser')!=null){
            let info = localStorage.getItem("setUser")
            let userInfo = JSON.parse(info);
            this.guestnews_listByUser(userInfo?.userInfo?.user_id);
        }    
    }
    guestnews_list = () => {
        AUTENTICATE_API?.guestnews_list().then(res => {
            if (res && res?.data) {
                this.setState({ newsData: res?.data });
            }
        })
    }

    guestnews_listByUser = (id) => {
        AUTENTICATE_API?.guestnews_listByUser(id).then(res => {
            if (res && res?.count>0) {
                var fl=res?.data?.filter(fl=>fl.is_published==1);
                this.setState({ newsDataPopular: fl },()=>{
                    let id=[];
                    let otherNews=[];
                    this.state.newsDataPopular?.map(val=>{  
                        id.push(val.news_id)
                    });
                    this.state.newsData?.map(vals=>{
                        if(id?.includes(vals?.news_id)==false){
                            otherNews?.push(vals)
                        }
                    });
                    let final=this.state.newsDataPopular.concat(otherNews);
                    this.setState({newsData:final})
                });
            }
        })
    }

    render() {
        return (
            <div>
                {this.state.newsData?.length>0?
                <> 
                <h2>Dietetics News &amp; Articles</h2><br/>
            <Carousel breakPoints={this.breakPoints}>
                {this.state.newsData?.map((val, i) =>
                    <div key={i}>
                        <PostBox
                            date={val?.created_dt}
                            description={val?.description}
                            category={val?.title}
                            className="bg-image-1"
                            icon={`${val?.image}`}
                            news_id={val?.news_id}
                        />
                    </div>)}
            </Carousel>
            <div className="row row-20 justify-content-sm-center offset-top-20">
             <a href='#/news' className="btn btn-width-200 btn-primary offset-top-13 mb-cls" >More News</a>
             </div>
                </>
                :""
                }
        </div>
        );
    }

}

export default Carasual