import { ActionType } from "../constant/actionType"

export const setFlag=()=>{
    let info = localStorage.getItem("setUser");
    let userInfo = JSON.parse(info);
    return{
        type:ActionType.SET_FLAG,
        payload: userInfo?.userInfo?.country_view_id
    }
}


export const setLocatState=()=>{
    let info = localStorage.getItem("setUser");
    let userInfo = JSON.parse(info);
    return{
        type:ActionType.SET_LOCAL,
        payload: userInfo
    }
}