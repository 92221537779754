
import  React  from 'react';
class MyTables extends React.Component{
    constructor(props){
        super(props)
        this.state={}
    }
    componentDidMount(){

    } 

    render(){
        return <>
        <h3>My Tables</h3>
        </>
    }
}
 export default MyTables;