import React from "react";
import PageHeaderTable from "../../../components/PageHeader/headertable";
import PageHeader from "../../../components/PageHeader/PageHeader";
import Table from 'react-bootstrap/Table';
const PH = () => {

    return (
        <div style={{ textAlign: 'initial' }}>

            <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                title="pH&nbsp;And&nbsp;Acid&nbsp;Content&nbsp;Of&nbsp;Beverages" />

            <div className="container mt-4">
                {/* <h6 ><p style={{marginTop:"-15px",marginLeft:-13,fontWeight:'700px',fontSize:16,color:'#484343'}}><b>pH and ACID CONTENT OF BEVERAGES</b></h6> */}
                <p style={{ marginTop: "-10px", marginLeft: -13, fontWeight: '400px', fontSize: 14, color: '#484343' }}>&nbsp;<b>Active peptic ulcer or GERD:</b> Some juices and most carbonated beverages have a pH low
                    enough&nbsp;(&lt; 3.5) to activate pepsin. Acid content is comparable to or exceeds the basal gastric
                    secretion.Avoidance of extrinsic sources of acid may decrease patient discomfort.</p>
                <br />
                <Table striped bordered hover style={{ width: '50%', margin: 'auto', marginTop: 20 }}>
                    <tbody>
                        <tr >
                            <td><b>Item</b></td>
                            <td><b>&nbsp;pH</b></td>
                            <td><b>&nbsp;mEq acid/mL</b></td>
                        </tr>
                        <tr >
                            <td><b>&nbsp;Fruits/Vegetables</b></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Vinegar (cider)</td>
                            <td>&nbsp;3.1-3.2</td>
                            <td>&nbsp;0.6-0.64</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Grapefruit, orange, pineapple</td>
                            <td>&nbsp;3.4-3.8</td>
                            <td>&nbsp;0.12-0.17</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Cranberry</td>
                            <td>&nbsp;2.6-2.8</td>
                            <td>&nbsp;0.08</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Grape, apple, apricot nectar</td>
                            <td>&nbsp;3.3-3.8</td>
                            <td>&nbsp;0.055-0.096</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Orange drink</td>
                            <td>&nbsp;2.6</td>
                            <td>&nbsp;0.055</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Tomato  </td>
                            <td>&nbsp;4.3</td>
                            <td>&nbsp;0.049-0.059</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Prune  </td>
                            <td>&nbsp;4.1-4.2</td>
                            <td>&nbsp;0.031-0.036</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Pear nectar</td>
                            <td>&nbsp;3.7</td>
                            <td>&nbsp;0.021-0.022</td>
                        </tr>

                        {/* <tr style={{borderBottom:0,borderTop:0,borderRight:0}}>
        <td>&nbsp;</td>
        <td><b>&nbsp;</b></td>
        <td><b>&nbsp;</b></td>
    </tr> */}
                        <tr >
                            <td><b>&nbsp;Milk</b></td>
                            <td><b>&nbsp;</b></td>
                            <td><b>&nbsp;</b></td>
                        </tr>
                        <tr >
                            <td>&nbsp;Skim</td>
                            <td>&nbsp;6.5-6.7</td>
                            <td>&nbsp;0.007-0.009</td>
                        </tr>
                        <tr >
                            <td>&nbsp;half &amp; half cream</td>
                            <td>&nbsp;6.6-6.7</td>
                            <td>&nbsp;0.005-0.007</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Whole</td>
                            <td>&nbsp;6.6-6.7</td>
                            <td>&nbsp;0.005-0.006</td>
                        </tr>

                        {/* <tr style={{borderBottom:0,borderTop:0,borderRight:0}}>
        <td>&nbsp;</td>
        <td><b>&nbsp;</b></td>
        <td><b>&nbsp;</b></td>
    </tr> */}
                        <tr >
                            <td><b>&nbsp;Carbonated Beverages</b></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr >
                            <td>&nbsp;orange soda</td>
                            <td>&nbsp;2.8</td>
                            <td>&nbsp;0.056-0.058</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Cola (average)</td>
                            <td>&nbsp;2.4</td>
                            <td>&nbsp;0.037-0.057</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Diet cola (average)</td>
                            <td>&nbsp;2.9</td>
                            <td>&nbsp;0.042</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Soda (open x 1 hr)</td>
                            <td>&nbsp;2.9-3.1</td>
                            <td>&nbsp;0.057-0.082</td>
                        </tr>

                        <tr >
                            <td>&nbsp;7-Up</td>
                            <td>&nbsp;3-3.1</td>
                            <td>&nbsp;0.048-0.055</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Ginger ale  </td>
                            <td>&nbsp;2.7-2.9</td>
                            <td>&nbsp;0.041-0.054</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Club soda  </td>
                            <td>&nbsp;3.7</td>
                            <td>&nbsp;0.046</td>
                        </tr>

                        <tr >
                            <td><b>&nbsp;Other Beverages</b></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>




                        <tr >
                            <td>&nbsp;Coffee (whole)</td>
                            <td>&nbsp;4.9-5.1</td>
                            <td>&nbsp;0.004-0.006</td>
                        </tr>

                        <tr >
                            <td>&nbsp;Sanka (instant)</td>
                            <td>&nbsp;5.1-5.4</td>
                            <td>&nbsp;0.004-0.006</td>
                        </tr>

                        <tr >
                            <td>&nbsp;Cocoa (instant)</td>
                            <td>&nbsp;6.7-6.9</td>
                            <td>&nbsp;0.001-0.002</td>
                        </tr>

                        <tr >
                            <td>&nbsp;Tea (instant)</td>
                            <td>&nbsp;6.8-6.9</td>
                            <td>&nbsp;0.001-0.002</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Tap water</td>
                            <td>&nbsp;7.6-8.2</td>
                            <td>&nbsp;--</td>
                        </tr>

                    </tbody>

                </Table>
                <br />
                <br />
                {/* <div>
<b>Reference: </b>
<a target={"_blank"} href="https://apjcn.nhri.org.tw/server/APJCN/8/1/64.pdf">
Reddy A, et al. The pH of beverages in the United States. J Am Dent Assoc 2016; 147(4): 255-263.
    </a>

</div> */}

                <p style={{ marginTop: "-20px", marginLeft: -20, fontWeight: '400px', fontSize: 14, color: '#484343' }}><b>Reference: </b>
                    <a target={"_blank"} href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4808596/pdf/nihms731821.pdf">
                        Reddy A, et al. The pH of beverages in the United States. J Am Dent Assoc 2016; 147(4): 255-263.
                    </a></p>
            </div>
        </div>
    );

}

export default PH;