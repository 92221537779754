import axios from "axios";
import jwt_decode from "jwt-decode";
import { createHashHistory } from "history";
import { toast, ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css';

// import { authHeader } from "./../_helfers/auth-header";
import { Urls1 } from "../Urls/apiUrl";
const history = createHashHistory();



export const TableAPI = {
  InteractionApi,
  getDrugsByTherapeuticClassApi,

};


function InteractionApi(obj) {

  var interaction = obj.interaction;
  var flag = obj.flag;
  let c_it;
  let m_idss = [];
  let m_id;
  if (localStorage.getItem("setUser") != null) {
    let info = localStorage.getItem("setUser")
    let userInfo = JSON.parse(info);
    c_it = userInfo?.userInfo?.country_view_id !== "" ? userInfo?.userInfo?.country_view_id : userInfo?.userInfo?.country_id
    if (userInfo?.userInfo?.topudModule != "" && userInfo?.userInfo?.topudModule != undefined) {
      m_id = userInfo?.userInfo?.includeModule;
      let t_id = userInfo?.userInfo?.topudModule;
      m_idss = t_id?.split(',')
      m_idss.push(m_id);
      m_idss = [...new Set(m_idss)];
    } else {
      m_idss = m_id
    }
  }

  return axios.get(Urls1 + `api/web/Fruit_Intractions?country_id=${c_it}&flag=${flag}&interaction=${interaction}&module_ids=${m_idss?.length > 0 ? m_idss.toString() : 1}`, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {

      if (error == "Unauthorized") {
        return [];
      }
      if (error.response != undefined)

        if (error.response.status === 401) {
          toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/#/signin"
          }, 2000)
        } else if (error.response.status === 400) {
          toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
        } else if (error.response.status === 409) {
          toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
        }
    });

}

function getDrugsByTherapeuticClassApi(obj) {
  const therapeuticClass = obj?.therapeutic_class;
  let c_it;
  let m_idss = [];
  let m_id;

  if (localStorage.getItem("setUser") != null) {
    let info = localStorage.getItem("setUser");
    let userInfo = JSON.parse(info);
    c_it = userInfo?.userInfo?.country_view_id !== "" ? userInfo?.userInfo?.country_view_id : userInfo?.userInfo?.country_id;
    if (userInfo?.userInfo?.topudModule !== "" && userInfo?.userInfo?.topudModule !== undefined) {
      m_id = userInfo?.userInfo?.includeModule;
      let t_id = userInfo?.userInfo?.topudModule;
      m_idss = t_id?.split(',');
      m_idss.push(m_id);
      m_idss = [...new Set(m_idss)];
    } else {
      m_idss = m_id;
    }
  }

  return axios.get(Urls1 + `api/web/DNI_High_Risk?therapeutic_class=${therapeuticClass}&country_id=${c_it}&module_ids=${m_idss?.length > 0 ? m_idss.toString() : 1}`, { headers: { Authorization: getToken() } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      handleApiError(error);
    });
}



function getToken() {
  var t = localStorage.getItem("setUser");
  t = JSON.parse(t)
  if (t !== null) {
    if (t === undefined || t == null) {
      return "InvalidUser";
    }
    const decoded = jwt_decode(t?.token);
    if (
      t !== null || t !== undefined && decoded.email_id === t?.userInfo?.email_id &&
      decoded.user_id == t?.userInfo?.user_id
    ) {
      return "Bearer " + t?.token;
    } else {
      return "InvalidUser";
    }
  } else {
    // toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
    localStorage.clear();
    setTimeout(() => {
      window.location.href = "/#/signin"
    }, 2000)
  }
}

