import React from "react";
import PageHeaderTable from "../../../components/PageHeader/headertable";
import PageHeader from "../../../components/PageHeader/PageHeader";
import Table from 'react-bootstrap/Table';
import BackIcon from '../../../assets/images/previousBtn.png';

const Osmolalities = () => {

    return (
        <div style={{ textAlign: 'initial' }}>
            {/* <PageHeaderTable  style={{marginTop:'50px', height:'20px',marginTop:'50px'}} 
    bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
    title="OSMOLALITIES OF SELECTED BEVERAGES AND FOODS"
       description={""}/> */}
            <PageHeaderTable style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                title="Osmolalities&nbsp;Of&nbsp;Selected&nbsp;Beverages&nbsp;And&nbsp;Foods" />
            <div className="container mt-4 mobile-margin-left-magnesium">
                <div className='d-flex d-lg-block'>
                    <img
                        src={BackIcon}
                        alt="Back"
                        className="d-lg-none d-md-none"
                        onClick={() => { window.location.href = '/#/' }}
                        style={{
                            position: 'relative',
                            top: '-85px',
                            left: '-15px',
                            width: '8%',
                            height: '8%',
                            cursor: 'pointer'
                        }}
                    />
                </div>
                {/* <h6><p style={{marginTop:"-15px",marginLeft:-25,fontWeight:'700px',fontSize:16,color:'#484343'}}><b>OSMOLALITIES OF SELECTED BEVERAGES AND FOODS</b></h6> */}
                <p style={{ marginTop: "-10px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Osmolality refers to the number and size of particles per kilogram of water.Expressed in osmoles or milliosmoles per kg water.
                    Extracellular fluid is 280 to 300 mOsm/kg water.<br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Normal serum is 275-325 mOsm/kg.Patients with limited gastrointestinal tolerance should receive hyposmolar or mildly hyperosmolar liquids (&#62;400 mOsm/kg).<br />&nbsp;&nbsp;&nbsp;&nbsp;Because of high osmolality some food/liquids may need to be diluted initially.
                </p>

                {/* <p style={{marginTop:"-8px",marginLeft:-25,fontWeight:'400px',fontSize:12,color:'#484343'}}>Patients with limited gastrointestinal tolerance should receive hyposmolar or mildly
hyperosmolar liquids (&lt; 400 mOsm). Because of high osmolality some food/liquids may need to
be diluted initially. */}
                {/* <table border = "1" width="500" height = "20" align="center"> */}
                <Table striped bordered hover style={{ width: '50%', margin: 'auto', marginTop: 20 }}>
                    <tbody>
                        <tr style={{ fontSize: 14, color: '#484343' }} >
                            <td><b>&nbsp;Beverage</b></td>
                            <td><b>&nbsp;mOsm/kg</b></td>
                            <td><b>&nbsp;Suggested&nbsp;Dilution</b></td>
                        </tr>
                        <tr>
                            <td>&nbsp;<b>Juices</b></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;Prune</td>
                            <td>&nbsp;1076</td>
                            <td>&nbsp;1:3</td>
                        </tr>
                        <tr>
                            <td>&nbsp;Cranberry</td>
                            <td>&nbsp;836</td>
                            <td>&nbsp;1:3</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Pineapple</td>
                            <td>&nbsp;772</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;Apple</td>
                            <td>&nbsp;705</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;Tomato</td>
                            <td>&nbsp;619</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Grapefruit</td>
                            <td>&nbsp;619</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr >
                            <td>&nbsp;Orange</td>
                            <td>&nbsp;601</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;V8</td>
                            <td>&nbsp;578</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;Cranberry, low-calorie</td>
                            <td>&nbsp;287</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td><b>&nbsp;Coffee/Tea (1 cup)</b></td>
                            <td><b>&nbsp;</b></td>
                            <td><b>&nbsp;</b></td>
                        </tr>
                        <tr>
                            <td>&nbsp;Coffee with 1 tsp sugar</td>
                            <td>&nbsp;128</td>
                            <td><b>&nbsp;</b></td>
                        </tr>
                        <tr>
                            <td>&nbsp;Tea with 1 tsp sugar</td>
                            <td>&nbsp;106</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;Coffee</td>
                            <td>&nbsp;83</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;Tea</td>
                            <td>&nbsp;8</td>
                            <td>&nbsp;</td>
                        </tr>
                        {/* <tr style={{borderBottom:0,borderRight:0}}>
        <td>&nbsp;</td>
        <td><b>&nbsp;</b></td>
        <td><b>&nbsp;</b></td>
    </tr> */}
                        <tr>
                            <td><b>&nbsp;Enteral Formulas</b></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;Isotonic (Choice &nbsp;DM,Isocal,Jevity,Osmolite,Ultracal)</td>
                            <td>&nbsp;270-310</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;1 calorie/mL (Boost,Ensure,Resource)</td>
                            <td>&nbsp;430-670</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;1.5 calorie/mL (Boost Plus,Ensure &nbsp;Plus,Resource Plus)</td>
                            <td>&nbsp;600-690</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;2 calories/mL (Nepro,Nutren,Novasource)</td>
                            <td>&nbsp;665-790</td>
                            <td>&nbsp;</td>
                        </tr>
                        {/* <tr>
        <td>&nbsp;</td>
        <td><b>&nbsp;</b></td>
        <td><b>&nbsp;</b></td>
    </tr> */}
                        <tr>
                            <td><b>&nbsp;Broth</b></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;▼ sodium, low-fat chicken</td>
                            <td>&nbsp;452</td>
                            <td><b>&nbsp;</b></td>
                        </tr>
                        <tr>
                            <td>&nbsp;Regular chicken</td>
                            <td>&nbsp;389</td>
                            <td><b>&nbsp;</b></td>
                        </tr>
                        {/* <tr>
        <td>&nbsp;</td>
        <td><b>&nbsp;</b></td>
        <td><b>&nbsp;</b></td>
    </tr> */}
                        <tr>
                            <td><b>&nbsp;Milk/Milk products</b></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>




                        <tr>
                            <td>&nbsp;Human milk</td>
                            <td>&nbsp;277-303</td>
                            <td><b>&nbsp;</b></td>
                        </tr>

                        <tr>
                            <td>&nbsp;ice cream</td>
                            <td>&nbsp;1150</td>
                            <td><b>&nbsp;</b></td>
                        </tr>

                        <tr>
                            <td>&nbsp;Carnation Instant Breakfast</td>
                            <td>&nbsp;389</td>
                            <td><b>&nbsp;</b></td>
                        </tr>

                        <tr>
                            <td>&nbsp;With non-fat milk</td>
                            <td>&nbsp;617</td>
                            <td><b>&nbsp;</b></td>
                        </tr>
                        <tr>
                            <td>&nbsp;With whole milk</td>
                            <td>&nbsp;653</td>
                            <td><b>&nbsp;</b></td>
                        </tr>
                        <tr>
                            <td>&nbsp;Eggnog</td>
                            <td>&nbsp;695</td>
                            <td><b>&nbsp;</b></td>
                        </tr>
                        <tr>
                            <td>&nbsp;Lactaid non-fat milk</td>
                            <td>&nbsp;375</td>
                            <td><b>&nbsp;</b></td>
                        </tr>
                        <tr>
                            <td>&nbsp;Lactaid whole milk</td>
                            <td>&nbsp;412</td>
                            <td><b>&nbsp;</b></td>
                        </tr>
                        <tr>
                            <td>&nbsp;Non-fat milk</td>
                            <td>&nbsp;280</td>
                            <td><b>&nbsp;</b></td>
                        </tr>
                        <tr>
                            <td>&nbsp;Whole milk</td>
                            <td>&nbsp;277</td>
                            <td><b>&nbsp;</b></td>
                        </tr>

                        {/* <tr>
        <td>&nbsp;</td>
        <td><b>&nbsp;</b></td>
        <td><b>&nbsp;</b></td>
    </tr> */}
                        <tr>
                            <td><b>&nbsp;Soft Drinks</b></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>




                        <tr>
                            <td>&nbsp;Cola</td>
                            <td>&nbsp;714</td>
                            <td><b>&nbsp;</b></td>
                        </tr>

                        <tr>
                            <td>&nbsp;Gatorade</td>
                            <td>&nbsp;280-360</td>
                            <td><b>&nbsp;</b></td>
                        </tr>
                        <tr>
                            <td>&nbsp;Ginger ale</td>
                            <td>&nbsp;565</td>
                            <td><b>&nbsp;</b></td>
                        </tr>
                        <tr>
                            <td>&nbsp;Diet ginger ale/cola</td>
                            <td>&nbsp;43-50</td>
                            <td><b>&nbsp;</b></td>
                        </tr>


                        <tr>
                            <td><b>&nbsp;Water Ice</b></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;Cherry</td>
                            <td>&nbsp;1064</td>
                            <td>&nbsp;1:3</td>
                        </tr>


                    </tbody>

                </Table>
                <br />
                <br />


                <div>
                    <p style={{ marginTop: "-20px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}><b>Reference: </b>
                        <a target={"_blank"} href="https://doi.org/10.1177/088453368700200606">
                            Bell SJ et al. Osmolality of Beverages Commonly provided on Clear and Full Liquid Menu. Nutr Clin Pract 1987; 2(6): 241-244.
                        </a></p>

                </div>


            </div>
        </div>
    );

}

export default Osmolalities;