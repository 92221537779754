import React, { useState } from "react";
import PageHeader from "../../../components/PageHeader/headertable";
import BackIcon from '../../../assets/images/previousBtn.png';
const VitaminKSources = () => {


    return (
        <div style={{ textAlign: 'initial' }}>
            {/* <PageHeaderTable  style={{marginTop:'50px', height:'20px',marginTop:'50px'}} 
    bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
    title="VITAMIN K SIGNIFICANT DIETARY SOURCES*"
       description={""}/> */}
            <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                title="Vitamin&nbsp;K&nbsp;Significant&nbsp;Dietary&nbsp;Sources" />
            <div className="container mt-4 mobile-margin-left-magnesium">
                <div className='d-flex d-lg-block'>
                    <img
                        src={BackIcon}
                        alt="Back"
                        className="d-lg-none d-md-none"
                        onClick={() => { window.location.href = '/#/' }}
                        style={{
                            position: 'relative',
                            top: '-85px',
                            left: '-15px',
                            width: '8%',
                            height: '8%',
                            cursor: 'pointer'
                        }}
                    />
                </div>
                {/* <h6 ><p style={{marginTop:"-20px",marginLeft:-25,fontWeight:'700px',fontSize:16,color:'#484343'}}><b>VITAMIN K SIGNIFICANT DIETARY SOURCES*</b></p></h6>
            <br/> */}
                <p style={{ marginTop: "-3px", marginLeft: -25, fontWeight: '500px', fontSize: 15, color: '#484343' }}><b>Vegetables</b></p>

                <p style={{ marginTop: "-12px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}>(Higher concentrations of Vitamin K are found in the outer leaves and peels of vegetables).
                    Highest sources: cooked spinach, collards, kale, turnip greens, beet greens, mustard greens.Raw forms of these vegetables are lower in Vitamin K, per equal measure.</p>
                <br />
                <p style={{ marginTop: "-20px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}>Other sources: asparagus, bran, broccoli, Brussels sprouts, cabbage (green raw), chayote leaf,
                    chickpeas (garbanzo beans), chive (raw), coriander leaf, cucumber peel, endive, green tomato,
                    lettuce, lentils, mint, mung beans, nettle leaves, oats, purslane, romaine lettuce, scallions (green
                    onions), seaweed (extremely high levels), soybeans, Swiss chard, watercress.</p>
                <p style={{ marginTop: "-3px", marginLeft: -25, fontWeight: '500px', fontSize: 15, color: '#484343' }}><b>Fats & Oils</b></p>
                <p style={{ marginTop: "-12px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}>Canola oil, soybean oil
                    (Exposure to fluorescent light and sunlight rapidly destroys vitamin K in oils)</p>
                <p style={{ marginTop: "-3px", marginLeft: -25, fontWeight: '500px', fontSize: 15, color: '#484343' }}><b>Other</b></p>
                <p style={{ marginTop: "-12px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}>Beef liver, chicken liver, pork liver, egg yolk, green tea leaves, algae (purple laver and hijiki),
                    apples with green peel.</p>
                <br />
                <p style={{ marginTop: "-20px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}>*50 mcg or more Vitamin K/100 grams</p>
                <br />
                <p style={{ marginTop: "-20px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}><b>Reference:</b>&nbsp;<br /><a target={"_blank"} href="https://www.nal.usda.gov/legacy/fnic/vitamin-k">https://www.nal.usda.gov/legacy/fnic/vitamin-k</a></p>
            </div>
        </div>
    );

}

export default VitaminKSources;