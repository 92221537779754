import React from 'react'
import { Link } from 'react-router-dom'
import { DummyImage, useDummyImage } from 'react-simple-placeholder-image'

const SingleNews = () => {
  return (
    <>
      {/* <!-- Parallax--> */}
      <section className="section section-height-800 parallax-container context-dark bg-gray-darkest text-xl-left" data-parallax-img="./images/blog/post-06-770x480.jpg" data-preset='{"title":"Breadcrumbs","category":"main","id":"breadcrumbs"}'>
        <div className="parallax-content">
          <div className="bg-overlay-black">
            <div className="container section-80 section-md-95 section-lg-top-120 section-lg-bottom-150">
              <div className="d-none d-lg-block">
                <h1>Single News</h1>
              </div>
              {/* <!-- List Inline--> */}
              <ul className="list-inline list-inline-dashed list-white text-big p offset-md-top-13">
                <li><a href="/">Home</a></li>
                <li>Single News</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-md-bottom-120 text-left novi-background bg-cover" data-preset='{"title":"Blog Classic","category":"blog","id":"blog-classic"}'>
          <div className="container">
            <div className="row mt-3">
              
            </div>
            <div className="row ">               
              <div className=" article-wrapper">
                <article className="article-item bounding-fix post-classic">
                  <div className="post-body">
                    <div className='col-lg-10 pl-lg-0 d-inline-block'>
                      <p className="post-title text-big font-weight-bold text-uppercase text-spacing-100 offset-top-20"><a href="/">Section 1.10.33 of de Finibus Bonorum et Malorum, written by Cicero in 45 BC</a></p>
                    </div>
                    <div className='col-lg-2 pr-lg-0 text-right d-inline-block'>
                      <Link className='btn btn-primary' to={'/news'}> Back </Link>
                    </div>
                      {/* <!-- List Inline--> */}
                      <ul className="list-inline list-inline-22 list-inline-dashed-vertical font-weight-bold p">
                          <li>
                              <span className="icon novi-icon icon-xs material-icons-ico material-icons-event text-middle text-gray"></span>
                              <span className="text-middle inset-left-7 post-meta">January 16, 2019</span>
                          </li>
                          <li>
                              <span className="icon novi-icon icon-xs material-icons-ico material-icons-person text-middle text-gray"></span>
                              <span className="text-regular inset-left-4 text-gray text-middle">By:</span>
                              <a className="text-middle link-decoration-none text-hover-primary text-gray-light inset-left-4" href="/">Lorem Ipsum</a>
                          </li>
                      </ul>
                      
                      <DummyImage width={1140} height={300} placeholder='Placeholder image [1140x300] for News!' className='my-3' fgColor='#c8c8c8' />

                      <p className="offset-top-20">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged</p>
                      <p className="offset-top-20">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged</p>
                      <p className="offset-top-20">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged</p>
                      <p className="offset-top-20">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged</p>
                      <div className="group-sm offset-top-30">
                          <a className="btn btn-tag btn-primary-gray-outline" href="/">Sugar</a>
                          <a className="btn btn-tag btn-primary-gray-outline" href="/">Diet</a>
                          <a className="btn btn-tag btn-primary-gray-outline" href="/">Honey</a>
                      </div>
                  </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SingleNews