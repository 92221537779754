import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DummyImage } from "react-simple-placeholder-image";
import { toast } from "react-toastify";
import { PLAN_SELECTION } from "../../services/Api/PlanselectionApi";

const BillingInfo = () => {
let userDetails =  JSON.parse(localStorage.getItem('setUser'));
let personalDetails =userDetails?.userInfo
//const [personalDetails,setPersonalDetails] =useState("")
// useEffect(() => {
//   setPersonalDetails(userDetails?.userInfo);
// }, []);

const onSubmit=(e)=>{
  e.preventDefault()
  window.location.href="#/confirmation" 
}
 

  return (
    <section className="section section-md-bottom-50 bg-gray-lighter novi-background bg-cover">
      <div className="container">
        <div className="row pt-4">
          <div className="col">
              <ul class="nav bg-white py-3 shadow-sm justify-content-center">
                <li class="nav-item">
                    <Link class="nav-link active" to={'/'}><i class="fa-solid fa-check rounded-check"></i> Choose your plan</Link>
                </li>
                <li class="nav-item">
                    <Link class="nav-link active" to={'/'}><i class="fa-solid fa-check rounded-check"></i> Select your modules</Link>
                </li>
                <li class="nav-item">
                    <Link class="nav-link disabled" to={'/'}>Billing Information</Link>
                </li>
                <li class="nav-item">
                    <Link class="nav-link disabled" to={'/'}>Confirmation &amp; Checkout</Link>
                </li>
              </ul>
          </div>
        </div>
        <div class="pricing-header px-3 pt-md-5 mx-auto text-center">
          <h5 className="mb-5">Current Total : $ 9.99</h5>
          <h1>Billing Information</h1>
          <p className="text-danger bold">(Payment Handled by Stripe)</p>
        </div>
        <div className="row justify-content-sm-center align-items-center">
          <div className='col-lg-12'>
              <form className="rd-mailform text-left offset-top-20  p-5" >
                <div className="row row-fix row-narrow-12 row-12">
                  <div className="col-sm-6">
                    <div className="form-wrap">
                      <input className="form-input" id="bill-full-name" type="text" name="firstName" value={personalDetails?.first_name} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-wrap">
                      <input className="form-input" id="bill-full-name" type="text" name="lastName" value={personalDetails?.last_name} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-wrap">
                      <input className="form-input" id="bill-email" type="email" name="email"  value={personalDetails?.email_id} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-wrap">
                     
                      <input className="form-input" id="bill-address" type="text" name="address" placeholder="Address" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-wrap">
                      <select className="form-control" id="exampleFormControlSelect1">
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-wrap">
                      <input className="form-input" id="bill-postal-code" type="text" name="zipCode" placeholder="Zip/Postal Code"  value={personalDetails?.zip_code} />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <h6>Promo Code</h6>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-wrap">
                      <input className="form-input" id="promo-code" type="text" name="promoCode"/>
                      {/* <i class="fa fa-spinner fa-spin" style="font-size:24px; color:green;"></i> */}
                    </div>
                  </div>
                  <div className="col-sm-12 offset-top-10">
                    <button className="btn btn-primary btn-block" onClick={onSubmit}>Reviews</button>
                  </div>
                </div>
              </form>
            </div>
        </div>
      </div>
    </section>
  );
};

export default BillingInfo;
