import React from 'react'

function HoverBox(props) {
  return (
    <div className="box box-sm box-hover bg-default novi-background">
        <div className="box-hover-inner" >
          <span className={`icon novi-icon icon-lg fl-great-icon-set-ico text-primary ${props.className}`}></span>
            <p className="text-big font-weight-bold text-primary">{props.heading}</p>
            <div className="box-hover-hidden">
            <p className="offset-top-13">{props.description}</p>
            <p className="text-big font-weight-bold offset-top-5 text-primary">. . .</p>
            </div>
        </div>
    </div>
  )
}
function PostBox(props) {
  return (
  <div className="">
      <div className="post-box-xs-img-wrap" style={{background:'rgb(229 230 231 / 92%)',height:230,marginLeft:4}}>
        <div>
          <img src={props.icon} id="newsImages"  style={{cursor:'pointer'}}
           onClick={(e)=>{
            window.location.href=`#/news/news-details/${props.news_id}`
          }}
          />
        </div>
        <div className="post-box-xs-caption bg-default" style={{background:'#e5e6e791'}}>
        <ul className="list-inline list-inline-22 list-inline-dashed-vertical font-weight-bold">
          <li><a className="text-gray text-hover-primary">
            <span className="text-middle inset-right-7">{props.category.substring(0,30)+"..."}</span>
            <span style={{color:'#126E94'}} id="newsCatURL1"
             onClick={(e)=>{
              window.location.href=`#/news/news-details/${props.news_id}`
            }}
            >More</span>
          </a>
          </li>
        </ul>
        {/* <div className="">
          <p className="offset-top-13" style={{color:'#484746'}}>
          <span id="grapft" dangerouslySetInnerHTML={{ __html: props.description?.substring(0,60)+'...'}}
           onClick={(e)=>{
            window.location.href=`#/news/news-details/${props.news_id}`
          }}
          ></span>
          <div id="moreNews" style={{marginTop:props.description?.length>60?'-10px':'',color:'#126E94',cursor:'pointer'}}>More...</div>
          </p>
        </div> */}
      </div>
      </div>
      
  </div>
  )
} 
export {HoverBox, PostBox} ; 