
import  React from 'react';
class MySearchDetails extends React.Component{
    constructor(props){
        super(props);
        this.state={}
    }
    componentDidMount(){

    }

    render(){
        return <>
        <h3>Details</h3>
        </>
    }
}

export default MySearchDetails