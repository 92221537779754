import axios from "axios";
import jwt_decode from "jwt-decode";
import { createHashHistory } from "history";
import { toast, ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css';

// import { authHeader } from "./../_helfers/auth-header";
import {  Urls1 } from "../Urls/apiUrl";
const history = createHashHistory();



export const SEARCH_API = {
  genericSearch,
  getUserPlan ,
  getPaymentHisory,
  getSingleGeneric,
  AddtoCart_userdrug,
  getMyDrugList,
  getUserDetails,
  updatePreferredCountry,
  removeDrugList,
  getModuleByUser,
  upgrade_UserTopup,
  user_drugDetail_visibility,
  getViewList,
  getModulesByIds,
  updateAddOnModule,
  getReciept,
  getIcons,
  getOtp,
  verifyOtp,
  changePassword,
  searchGenericUpdateCount
 };

 function searchGenericUpdateCount(data) {
  debugger
  return axios.post(Urls1+`api/web/genericserach_count_data`,data)
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
       if (error.response.status === 401) {
        //  toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

 function changePassword(data) {
  return axios.post(Urls1+`api/web/upgrade_UserTopup`,data)
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
       if (error.response.status === 401) {
        //  toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

 function verifyOtp(data) {
  return axios.post(Urls1+`api/web/upgrade_UserTopup`,data)
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
       if (error.response.status === 401) {
        //  toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

 function getOtp(data) {
  return axios.post(Urls1+`api/web/upgrade_UserTopup`,data)
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
       if (error.response.status === 401) {
        //  toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}


  
function getIcons() {
  return axios.get(Urls1+`api/web/generics_iconList`,{headers: { Authorization: getToken()}})
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
      
        if (error.response.status === 401) {
          // toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

 
function getReciept(data) {
  return axios.get(Urls1+`api/web/Generatebill_by_paymentID?Strip_id=${data}`,{headers: { Authorization: getToken()}})
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
      
        if (error.response.status === 401) {
          // toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

function updateAddOnModule(data) {
  return axios.post(Urls1+`api/web/upgrade_UserTopup`,data,{headers: { Authorization: getToken()}})
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
      
        if (error.response.status === 401) {
          // toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

function getModulesByIds(id) {
  return axios.get(Urls1+`api/web/get_addonModule_price?module_ids=${id}`,{headers: { Authorization: getToken()}})
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
      
        if (error.response.status === 401) {
          // toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

 function getViewList(id) {
  return axios.get(Urls1+`api/web/user_drugDetail_GetVisibility/${id}`,{headers: { Authorization: getToken()}})
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
      
        if (error.response.status === 401) {
          // toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

 function user_drugDetail_visibility(obj) {
  return axios.post(Urls1+`api/web/user_drugDetail_visibility`,obj,{headers: { Authorization: getToken()}})
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
    
        if (error.response.status === 401) {
          // toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

 function upgrade_UserTopup(obj) {
  return axios.post(Urls1+`api/web/upgrade_UserTopup`,obj)
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
    
        if (error.response.status === 401) {
          // toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

 function getModuleByUser(user_id) {
  return axios.get(Urls1+`api/web/topup_planList_ByUser/${user_id}`,{headers: { Authorization: getToken()}})
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
      
        if (error.response.status === 401) {
          // toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}
 


 function removeDrugList(user_drug_id) {
  return axios.delete(Urls1+`api/web/remove_userdrug?user_drug_id=${parseInt(user_drug_id)}`,{headers: { Authorization: getToken()}})
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
      
        if (error.response.status === 401) {
          // toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

 function updatePreferredCountry(user_id,country_view_id) {
  return axios.patch(Urls1+`api/web/updateUser_viewCountry?user_id=${user_id}&country_view_id=${country_view_id}`,{},{headers: { Authorization: getToken()}})
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
   
        if (error.response.status === 401) {
          // toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

function getUserDetails(requestOptions) {
  return axios.get(Urls1+`api/web/Users_Data_List/${requestOptions}` ,{headers: { Authorization: getToken()}})
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
    
        if (error.response.status === 401) {
          // toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

 function getMyDrugList(requestOptions,country_id) {
  return axios.get(Urls1 + `api/web/user_druglist_byId?user_id=${requestOptions}&country_id=${country_id==2?country_id:1}`,{headers: { Authorization: getToken()}} )
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
      
        if (error.response.status === 401) {
          // toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

 function AddtoCart_userdrug(requestOptions) {
  return axios.post(Urls1 +`api/web/AddtoCart_userdrug`,requestOptions,{headers: { Authorization: getToken()}})
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
      
        if (error.response.status === 401) {
          // toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

 function getSingleGeneric(requestOptions) {
  return axios.get(Urls1 + `api/web/Generic_User_drugdetail_list/${requestOptions}`,{headers: { Authorization: getToken()}} )
  // return axios.get(`https://dni.tactionsoft.com/api/api/v1/Generic_DataExport/${requestOptions}` )
   .then((response) => {
     return response.data;
   })
   .catch((error) => {
     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
       
        if (error.response.status === 401) {
          // toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}

 function getPaymentHisory(requestOptions) {
  return axios.get(Urls1 + `api/web/user_payment_hostory/${requestOptions}`,{headers: { Authorization: getToken()}} )
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
       
        if (error.response.status === 401) {
          // toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   }); 
} 
 function getUserPlan(requestOptions) {
    return axios.get(Urls1 + `api/web/My_Plans/${requestOptions}`,{headers: { Authorization: getToken()}} )
     .then((response) => {
       return response.data;
     })
     .catch((error) => {
  
       if (error == "Unauthorized") {
         return [];
       }
       if (error.response != undefined)
        
          if (error.response.status === 401) {
            // toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
            localStorage.clear();
            setTimeout(()=>{
              window.location.href="/#/signin"
            },2000)
         }else if(error.response.status === 400){
           toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
         }else if(error.response.status ===409){
          toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
         }
     });
  }

 function genericSearch(requestOptions) {
   let c_it;
   let m_idss=[];
  let m_id;
   if(localStorage.getItem("setUser")!=null){
    let info = localStorage.getItem("setUser")
    let userInfo = JSON.parse(info);
    c_it=userInfo?.userInfo?.country_view_id!==""?userInfo?.userInfo?.country_view_id:userInfo?.userInfo?.country_id
     if(userInfo?.userInfo?.topudModule!="" && userInfo?.userInfo?.topudModule!=undefined){
      m_id=userInfo?.userInfo?.includeModule;
      let t_id=userInfo?.userInfo?.topudModule;
      m_idss=t_id?.split(',')
      m_idss.push(m_id);
      m_idss = [...new Set(m_idss)];
     }else{
      m_idss=userInfo?.userInfo?.includeModule
     }
   }
  // return axios.get(Urls1 + `api/web/generics_searching/?countryView=${c_it}&generic=${requestOptions}` )
  return axios.get(Urls1 + `api/web/search_for_generic/?countryView=${c_it==2?c_it:1}&generic=${requestOptions}&module_ids=${m_idss?.length>0?m_idss.toString():1}`,{headers: { Authorization: getToken()}})
   .then((response) => {
     return response.data;
   })
   .catch((error) => {

     if (error == "Unauthorized") {
       return [];
     }
     if (error.response != undefined)
        if (error.response.status === 401) {
          toast.error('Unauthorized User', { autoClose: 500, position: toast.POSITION.TOP_RIGHT });
          localStorage.clear();
          setTimeout(()=>{
            window.location.href="/#/signin"
          },2000)
       }else if(error.response.status === 400){
         toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }else if(error.response.status ===409){
        toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
       }
   });
}
  
 
function getToken() {
  var t = localStorage.getItem("setUser");
  t=JSON.parse(t)
  if(t!==null){
  if (t === undefined || t == null) {
    return "InvalidUser";
  }
  const decoded = jwt_decode(t?.token);
  if (
    t!==null || t!==undefined && decoded.email_id === t?.userInfo?.email_id &&
    decoded.user_id == t?.userInfo?.user_id
  ) {
    return "Bearer " + t?.token;
  } else {
    toast.error(error?.response?.data?.message, { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
    return "InvalidUser";
  }
  }else{
    localStorage.clear();
    setTimeout(()=>{
      window.location.href="/#/signin"
    },2000)
  }
}
