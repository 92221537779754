import { React, useState, useEffect } from 'react';
import { AUTENTICATE_API } from '../../services/Api/authenticationApi';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha, LoadCanvasTemplateNoReload } from 'react-simple-captcha';
import { SEARCH_API } from '../../services/Api/searching';
import XIcon from '../../assets/images/Xicon.png';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useNavigate } from "react-router-dom";

function Contact({ props }) {
  const [user, setUserData] = useState('');
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [errmsg, setreqMsg] = useState("");
  const [capt, setCapt] = useState("");
  const [deanEmail, setDeanEmail] = useState("dniteam@drugnutritioninteractions.com");
  const [flagOfSuccess, setFlagOfSuccess] = useState(false)
  const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;

  useEffect(() => {
    document.addEventListener('keydown', function (event) {
      if (event.keyCode == 123) {
        return false;
      } else if (event.ctrlKey && event.shiftKey && event.keyCode == 73) {
        return false;
      } else if (event.ctrlKey && event.keyCode == 85) {
        return false;
      }
    }, false);

    if (document.addEventListener) {
      document.addEventListener('contextmenu', function (e) {
        e.preventDefault();
      }, false);
    } else {
      document.attachEvent('oncontextmenu', function () {
        if (deanEmail != '') {
          setDeanEmail('')
        } else {
          setDeanEmail('dniteam@drugnutritioninteractions.com')
        }
        window.event.returnValue = false;
      });
    }
    if (reloadCount <= 0) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }
    if (flagOfSuccess == false) {
      loadCaptchaEnginge(6);
    }
    if (localStorage.getItem('setUser') != null) {
      let By = localStorage.getItem("setUser");
      By = JSON.parse(By);
      getUserDetails(By?.userInfo?.user_id);
    }
    if (localStorage.getItem('speak') != null) {
      setReason(localStorage.getItem('speak'))
    }
  }, [])

  const getUserDetails = (id) => {
    SEARCH_API?.getUserDetails(id).then(res => {
      setUserData(res?.data[0])
      if (res?.data?.length > 0) {
        setTimeout(() => {
          setEmail(res?.data[0]?.email_id);
          setFirstName(res?.data[0]?.first_name + " " + res?.data[0]?.last_name)
        }, 1000)
      }
    })
  }

  const handleChange = (e) => {
    setMessage("");
    setreqMsg("");
    if (e.target) {
      setReason("");
      setReason(e.target.value)
    }

  }


  const submitContact = (e) => {
    e.preventDefault();
    let c_id = '';
    let u_id = "";
    if (localStorage.getItem("setUser") != null) {
      let By = localStorage.getItem("setUser");
      By = JSON.parse(By);
      c_id = By?.userInfo?.country_id;
      u_id = user?.user_id
    }

    var emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
    if ((Email != "") && (!(emailfilter.test(Email)))) {
      setreqMsg("Please enter the valid email");
      setTimeout(() => {
        setreqMsg('')
      }, 2000)
      return false;
    }

    let captcha = document.getElementById('captcha_input').value;
    if (validateCaptcha(captcha) === false) {
      setreqMsg('Invalid Captcha.')
      setTimeout(() => {
        setreqMsg("");
      }, 2000)
      return false;
    }
    if (firstName !== '' && Email !== '' && Reason !== '' && message !== '' && capt !== '') {
      let obj = {
        FirstName: firstName,
        LastName: lastName,
        Email: Email,
        Reason: Reason,
        message: message,
        user_id: u_id,
        country_id: c_id
      }
      AUTENTICATE_API.ContactSendMail(obj).then(res => {
        if (res['reps']['response'].includes('250 2.0.0 OK') == true) {
          setFlagOfSuccess(true)
          setFirstName("");
          setEmail("");
          setLastName("");
          setReason("");
          setMessage("");
          setreqMsg("");
          setCapt('');
          toast.success(`Thank you for contacting DNI.`, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
          if (localStorage.getItem('speak') != null) {
            localStorage.removeItem('speak')
          }
        }
      })
    } else {
      setFlagOfSuccess(false)
      setreqMsg("All fields are required*");
      setTimeout(() => {
        setreqMsg("");
      }, 4000)
    }
  }

  return (
    <div>
      <div>
        <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
          bgimg=""
          title="Contact DNI"
          description='&nbsp;&nbsp;Get in touch with the DNI team' />
        <section className="section pt-5 pb-3 novi-background bg-cover " >
          <div className='container'>
            <div className="row offset-top-0 card-bs">
              <div className='col-lg-6  p-0  contact-bg d-flex flex-column justify-content-between'>
                <h3 className='text-white text-left mb-0 mt-4 ml-5'>Get in touch with the DNI team</h3>
                <div className="text-left text-white ml-5 pb-5" >
                  <div className="address d-flex align-items-center mb-4">
                    <div className='mr-3 p-1'>
                      <span className="icon novi-icon icon-l fa fa-envelope text-center"></span>
                    </div>
                    <div>
                      <span className='mb-0 text-big font-weight-bold'>Email:</span>
                      &nbsp;<span className='mb-0 mt-0' id="deanEmail"
                        onClick={(e) => { window.open(`mailto:${deanEmail}`, '_blank'); }}
                        onCopy={(e) => {
                          e.preventDefault()
                          return false;
                        }}
                        onContextMenu={() => { return false }}>{deanEmail}
                      </span>
                    </div>
                  </div>
                  <div className="ml-2 email d-flex align-items-center mb-4">
                    <div className='mr-3'>
                      <span className="icon novi-icon icon-l fa fa-phone text-center"></span>
                    </div>
                    <div >
                      <span className='mb-0 text-big font-weight-bold'>Phone:</span>
                      &nbsp; <span className='mb-0 mt-2'>+1 604-644-7704</span>
                    </div>
                  </div>
                  <div className="location d-flex align-items-center mb-4">
                    <div className='mr-3 p-2'>
                      <span className="icon novi-icon icon-l fa fa-map text-center"></span>
                    </div>
                    <div>
                      <span className='mb-0 text-big font-weight-bold'>Location:</span>
                      &nbsp; <span className='mb-0 mt-2'>Richmond, BC, Canada</span>
                    </div>
                  </div>
                  <div className="website d-flex align-items-center mb-4">
                    <div>
                      <p className='mb-0 ml-2 font-weight-bold'>A DNI team member will be in contact with you shortly</p>
                    </div>
                  </div>
                </div>
                <div style={{ marginBottom: 30 }}>
                  <ul className="list-inline text-white pl-1">
                    <li className="list-inline-item" style={{ cursor: 'pointer' }}>
                      <a target="_blank" rel="noopener noreferrer" href="https://x.com/dninteractions ">
                        <img src={XIcon} alt="X Icon" style={{ width: '27.86px', height: '27px', marginTop: '-16px' }} className='icon novi-icon icon-lt ext-center fa-twitter1' />
                      </a>
                    </li>
                    <li className="list-inline-item" style={{ cursor: 'pointer' }}>
                      <a target="_blank" href="https://www.instagram.com/dninteractions">
                        <span className="icon novi-icon icon-l fa fa-instagram text-center fa-twitter1"></span>
                      </a>
                    </li>
                    <li className="list-inline-item" style={{ cursor: 'pointer' }}>
                      <a target="_blank" href="https://www.linkedin.com/company/dninteractions">
                        <span className="icon novi-icon icon-l fa fa-linkedin text-center fa-twitter1"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {flagOfSuccess == false ?
                <div className='col-lg-6'>
                  <form className="rd-mailform text-left offset-top-20  p-1">
                    <div className="row row-fix row-narrow-12 row-12 justify-content-sm-center">
                      <div className="col-sm-12">
                        <div className="form-wrap">
                          <input className="form-input" style={{ height: 40 }} onChange={(e) => {
                            setFirstName(e.target.value)
                          }} id="name" type="text" value={firstName} name="firstName" placeholder='Name' />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-wrap">
                          <input className="form-input" style={{ height: 40 }} onChange={(e) => {
                            setEmail(e.target.value)
                          }} id="contact-us-email" type="email" name="Email" value={Email} placeholder='Email' />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <select className="form-control" onChange={handleChange} value={Reason} name="reason" id="exampleFormControlSelect1">
                          <option value="Arr">Topic</option>
                          {/* <option value="Inquiry">Inquiry</option> */}
                          {/* <option value="Add Drug">Add Drug</option> */}
                          <option value="Request Drug Addition">Request Drug Addition</option>

                          <option value="Billing Inquiry">Billing Inquiry</option>
                          <option value="General Inquiry">General Inquiry</option>
                          <option value="Speaker Inquiry">Speaker Inquiry</option>
                        </select>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-wrap">
                          <textarea className="form-input" onChange={(e) => {
                            setMessage(e.target.value)
                          }} id="contact-us-message" value={message} name="message" placeholder='Message to DNI Team' defaultValue={""} />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group d-md-flex">
                          <div className="w-50 text-left">
                            <div><LoadCanvasTemplate /></div>
                          </div>
                          <div>
                            <div>
                              <div><input type="text" onChange={(e) => {
                                setCapt(e.target.value)
                              }} style={{ height: 40, width: 300 }} className="form-input" id="captcha_input" value={capt} name="captcha_input" placeholder="Enter Captcha"></input></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="offset-top-10">

                      <button className="" id="contact-submit" type="submit" onClick={submitContact}>Send</button>
                      <span style={{ color: 'red', marginLeft: 20 }}> {errmsg} </span>
                      <br /><br />
                    </div>
                  </form>
                </div>
                : <section className="section pt-5 pb-3 novi-background bg-cover" >
                  <div className='container'>
                    <div className="row offset-top-70">
                      <div className='col-lg-12  p-5 ml-3'>
                        <h3 style={{ color: '#AEC550' }}>
                          Thank you for contacting DNI
                        </h3>
                        <button style={{ background: '#9BBD3F', border: '2px solid #9BBD3F', color: 'white', borderRadius: 20 }}
                          onClick={(e) => {
                            history.back()
                          }}
                        >Continue</button>
                      </div>
                    </div>
                  </div>
                </section>
              }
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Contact
