import React, { useState } from "react";
import PageHeaderTable from "../../../components/PageHeader/Headertables";
import BackIcon from '../../../assets/images/previousBtn.png';


const Gluton = () => {

    return (
        <div style={{ textAlign: 'initial' }}>
            {/* <PageHeaderTable  style={{marginTop:'50px', height:'20px',marginTop:'50px'}} 
    bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
    title="POTENTIAL GLUTEN CONTAINING INGREDIENTS OF MEDICATION"
    //    description={"POTENTIAL GLUTEN CONTAINING INGREDIENTS OF MEDICATION"}
       /> */}
            <PageHeaderTable style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                title="Potential&nbsp;Gluten&nbsp;Containing&nbsp;Ingredients&nbsp;Of&nbsp;Medication" />
            <div className="container mt-4 mobile-margin-left-magnesium">
                <div className='d-flex d-lg-block'>
                    <img
                        src={BackIcon}
                        alt="Back"
                        className="d-lg-none d-md-none"
                        onClick={() => { window.location.href = '/#/' }}
                        style={{
                            position: 'relative',
                            top: '-85px',
                            left: '-15px',
                            width: '8%',
                            height: '8%',
                            cursor: 'pointer'
                        }}
                    />
                </div>
                <p style={{ marginTop: "-10px", marginLeft: -25, fontWeight: '700px', fontSize: 15, color: '#484343' }}><b>POTENTIAL GLUTEN CONTAINING INGREDIENTS OF MEDICATION</b></p>

                <p style={{ marginTop: "-3px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}>It is IMPERATIVE that persons with gluten sensitivity such as CELIAC DISEASE/SPRUE/DH
                    avoid <b>WHEAT, RYE, OATS, BARLEY</b> and all derivatives of these gluten grains if intestinal
                    damage is to be prevented. <i>Inactive pharmaceutical ingredients</i> produced from these grains
                    may contain a small but significant amount of gluten. The source of each ingredient, if not
                    specified, must be checked with the manufacturer. Ingredients derived from corn, rice, potato
                    and tapioca are permitted.&nbsp;<i>A parenteral formulation may be an alternative in an emergency until
                        a safe gluten-free formulation can be determined.</i></p>
                <br />
                <p style={{ marginTop: "-20px", marginLeft: -25, fontWeight: '600px', fontSize: 14, color: '#484343' }}><b>Potential sources of gluten which may be contained in medication are listed below.</b></p>

                <br />
                <u><h6><p style={{ marginTop: "-10px", marginLeft: -25, fontWeight: '600px', fontSize: 14, color: '#484343' }}>Inactive ingredients MORE LIKELY to be a source of gluten</p></h6></u>
                <br />

                <p style={{ marginTop: "-20px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}><b>Dextri-Maltose</b> May contain barley malt
                    <br />
                    <b> Dusting Powder</b><br />
                    <b> Flour</b><br />
                    <b>Gluten</b><br />
                    <b>Pregelatinized Starch</b><br />
                    <b>Starch</b></p>
                <br />
                <u><h6><p style={{ marginTop: "-20px", marginLeft: -25, fontWeight: '600px', fontSize: 14, color: '#484343', fontFamily: 'Open Sans", Helvetica, Arial, sans-serif' }}>Inactive ingredients LESS LIKELY to be a source of gluten</p></h6></u>
                <p style={{ marginTop: "-8px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}><b>Caramel Coloring:</b> Usually from corn. May rarely contain barley malt or starch hydrolysates.
                    <b><br />Dextrin:</b> Usually from corn; can be made from wheat.
                    <b><br />Sodium Starch Glycolate:</b>&nbsp;Usually from potato; can be made from any starch.</p>
                <br />
                <u><h6><p style={{ marginTop: "-10px", marginLeft: -25, fontWeight: '600px', fontSize: 14, color: '#484343' }}>Inactive ingredients LEAST UKELY to be a source of gluten</p></h6></u>

                <p style={{ marginTop: "-5px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}><b>Alcohol:</b> Theoretically, the distillation process separates alcohol from gluten, but alcohol may

                    cause problems in some celiacs.
                    <b><br />Maltodextrin:</b> Usually made from com or rice, rarely wheat (outside the US). Certified gluten
                    free if made in the US.</p>

                <br />
                <p style={{ marginTop: "-20px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}><b>References:</b><br /><a target={"_blank"} href="https://www.fda.gov/drugs/ensuring-safe-use-medicine/medications-and-gluten">https://www.fda.gov/drugs/ensuring-safe-use-medicine/medications-and-gluten</a><br />
                    <a target={"_blank"} href="https://gluten.org/wp-content/uploads/2019/10/EDU_CD-Medications-2.pdf">https://gluten.org/wp-content/uploads/2019/10/EDU_CD-Medications-2.pdf</a><br />
                    <a target={"_blank"} href="https://www.beyondceliac.org/living-with-celiac-disease/gluten-in-medication/">https://www.beyondceliac.org/living-with-celiac-disease/gluten-in-medication/</a>

                </p>

            </div>
        </div>
    );

}

export default Gluton;