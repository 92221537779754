import React from 'react';
import ReactPlayer from 'react-player/youtube';

const YouTubePlayer = ({ url }) => {
    return (
        <div className="player-wrapper">
            <ReactPlayer
                className="react-player"
                url={url}
                width="100%"
                height="100%"
                controls={true} // Show player controls
            />
        </div>
    );
};

export default YouTubePlayer;