import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import store from './redux/store'; 
import { Provider } from 'react-redux';
// import('jquery/src/jquery').then(res=>{}); 

const root = ReactDOM.createRoot(document.getElementById('root'));




// .tile-icon img {
//   height: 45px !important;  
//   width: 45px !important;
// }
root.render(
  <HashRouter>
    <Provider store={store}>
    <App />
    </Provider>
      <ToastContainer style={{ zIndex: 999 }} />
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
