import React, { useState } from "react";
import PageHeader from "../../../components/PageHeader/headertable";
import BackIcon from '../../../assets/images/previousBtn.png';
const MagnesiumSources = () => {


    return (
        <div style={{ textAlign: 'initial' }}>
            {/* <PageHeaderTable  style={{marginTop:'50px', height:'20px',marginTop:'50px'}} 
    bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
    title="Magnesium (Mg) Significant Dietary Sources*"
       description={""}/> */}
            <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                title="Magnesium&nbsp;(Mg)&nbsp;Significant&nbsp;Dietary&nbsp;Sources*" />
            <div className="container mt-4 mobile-margin-left-magnesium">
                <div className='d-flex d-lg-block'>
                    <img
                        src={BackIcon}
                        alt="Back"
                        className="d-lg-none d-md-none"
                        onClick={() => { window.location.href = '/#/' }}
                        style={{
                            position: 'relative',
                            top: '-85px',
                            left: '-15px',
                            width: '8%',
                            height: '8%',
                            cursor: 'pointer'
                        }}
                    />
                </div>

                {/* <h6 ><p style={{ marginLeft:-25,marfontWeight:'700px',fontSize:16,color:'#484343'}}><b>MAGNESIUM (Mg) SIGNIFICANT DIETARY SOURCES*</b></p></h6>
            <br/> */}
                <p style={{ marginLeft: -10, marginLeft: -25, fontWeight: '500px', fontSize: 15, color: '#484343' }}><b>Fruits and Vegetables</b></p>

                <p style={{ marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343', marginTop: "-12px" }}>Avocado, banana, adzuki beans, beet greens, blackeye peas (cowpeas), cassava (raw),
                    {/* <br/> */}
                    chickpeas (garbanzo beans), figs (dried), great northern beans, kidney beans, lentils, lima
                    {/* <br/> */}
                    beans, lupins, mung beans, navy beans, okra, pigeonpeas, pink beans, pinto beans, potato with
                    {/* <br/> */}
                    skin (baked or microwaved), potato flour, raisins, seaweed, soybeans, spinach, Swiss chard,
                    {/* <br/> */}
                    white beans, yellow beans.</p>
                <br />
                <p style={{ marginLeft: -25, fontWeight: '500px', fontSize: 15, color: '#484343', marginTop: "-20px" }}><b>Grains and Grain Products</b></p>
                <p style={{ marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343', marginTop: "-12px" }}>(More than 80% of the Mg is lost by removal of the germ and outer layers of cereal grains).
                    Amaranth, barley, buckwheat, buckwheat flour, bulgur, granola, millet, oats (whole grain), oat
                    bran, rice (brown), rice (wild), rice bran, rye flour, triticale flour, wheat bran, wheat germ, whole
                    wheat flour, whole wheat pasta, High bran cereals (eg All Bran, 100% Bran, Bran Buds, Bran
                    Chex, bran flakes, raisin bran).</p>
                <br />
                <p style={{ marginLeft: -25, fontWeight: '500px', fontSize: 15, color: '#484343', marginTop: "-20px" }}><b>Nuts and Seeds</b></p>
                <p style={{ marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343', marginTop: "-12px" }}>Pumpkin seeds, peas, lentils, sunflower seeds, sesame seeds, almonds, cashews, flax seeds,
                    hazel nuts, brazil nuts, peanuts, black walnuts, pistachios, soybeans, English walnuts,
                    macadamia nuts, pecans</p>
                <br />

                <p style={{ marginLeft: -25, fontWeight: '500px', fontSize: 15, color: '#484343', marginTop: "-20px" }}><b>Other</b></p>
                <p style={{ marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343', marginTop: "-12px" }}>Molasses, malt syrup, hummus, peanut butter, soybean products: sauce, flour, natto, miso,
                    tempeh, raw tofu, oysters, shrimp, halibut, yeast, dry milk.</p>
                <br />
                <p style={{ marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343', marginTop: "-15px" }}>*30 mg or more magnesium/100 g</p>
                <br />
                <p style={{ marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343', marginTop: "-25px" }}><b>Reference:</b>&nbsp;<br /><a target={"_blank"} href="https://www.nal.usda.gov/legacy/fnic/magnesium ">https://www.nal.usda.gov/legacy/fnic/magnesium</a></p>
            </div>
        </div>
    );

}

export default MagnesiumSources;