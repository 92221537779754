import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { AUTENTICATE_API } from '../../services/Api/authenticationApi'
import { UserContext } from '../../App'

function Logout() {
    
  const {state,dispatch} = useContext(UserContext)

  const [email,setemail]=useState("")
  const [password,setpassword]=useState("")
  const[isVerified,setVerfied]= useState(false)
  const[verficationcode,setVerificationCode]=useState("")


  const handleChange =(e)=> {
    if(e.target.name === "verficationcode"){setVerificationCode('') } 
    if(e.target.name === "email"){setemail('') }
    if(e.target.name === "password"){setpassword('') }
   if (e && e.target && e.target.value){
      if (e.target.name === "email") {
        setemail(e.target.value)
      }else if(e.target.name==="password"){
        setpassword(e.target.value)
      }else if(e.target.name==="verficationcode"){
        setVerificationCode(e.target.value)
      }
    }
  }

 const handleVerify = (e) => {
    e.preventDefault()
      let obj = {
        email_id: email,
        verfication_code:verficationcode
     }
      AUTENTICATE_API.userVerification(obj).then(res=>{
        if(res?.success===false){
          toast.error(res?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
        } else{
          toast.success(res?.message, { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
          setTimeout(()=>{
            setVerfied(false);
            return  window.location.href="#/signin"
          },2000)
        }
      })
  }

  const handleLogin = (e) => {
    e.preventDefault()

   if(email !=='' && password !==''){
    let obj = {
      email_id: email,
      password: password,
    }
    AUTENTICATE_API.UserLogin(obj).then(res=>{
      if(res && res.status===200){
        if(res.data.Otp_Verify==1) { 
          setVerfied(false)
          let state={
            userInfo:res.data,
            token:res?.token
          }
          localStorage.setItem('state',JSON.stringify(state));
          dispatch({type:"USER",payload:false})
          toast.success("User logedIn successfully.", { autoClose: 2000, position: toast.POSITION.TOP_RIGHT });
          setTimeout(()=>{
           window.location.href='#/account';
          },2000)
      }else{
        setTimeout(()=>{
          setVerificationCode(res?.data?.verifyCode);
          setVerfied(true)
        },1000)
   }
 }
})
 }}
  return (
    <div>
      <section className="section section-50 section-lg-95 bg-light novi-background bg-cover" >
        <div className='container'>
          <div className="row align-items-center justify-content-center align-items-center offset-top-40 ">
          {isVerified===false?<>
          <div className='col-lg-6 p-5 bg-white card-bs'>
                <h2 className='text-left mb-4'>Login</h2>
                <form className="rd-mailform text-left offset-top-20 mb-5" data-form-output="form-output-global" data-form-type="contact">
                  <div className="row row-fix row-narrow-12 row-12 justify-content-sm-center">
                    <div className="col-sm-12">
                      <div className="form-group">
                       <input className="form-input" id="contact-us-email" type="text" name="email" placeholder='Email' onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-sm-12 mb-4">
                      <div className="form-wrap">
                       <input className="form-input" id="contact-us-password" type="password" name="password" placeholder='password' onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group d-md-flex">
                        <div className="w-50 text-left">
                          <div className="form-check">
                            <input type="checkbox" className="form-check-input mt-1" id="exampleCheck1" />
                            <label className="form-check-label" htmlFor="exampleCheck1">Remember me</label>
                          </div>
                        </div>
                        <div className="w-50 text-md-right">
                          <a href="#">Forgot Password?</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="offset-top-10">
                    <button className="btn btn-primary" onClick={handleLogin}>Login</button>
                  </div>
                </form>
                {/* <div className='login-api text-center'>
                  <h6 className='mb-3'>Login with</h6>
                  <ul className='list-inline'>
                    <li className='list-inline-item'><a className="icon novi-icon icon-l icon-circle fa fa-google text-danger" href="#">&nbsp;</a></li>
                    <li className='list-inline-item'><a className="icon novi-icon icon-l icon-circle fa fa-facebook text-info" href="#">&nbsp;</a></li>
                  </ul>
                </div> */}
            </div>
          </>:
           <div className='col-lg-6 p-5 bg-white card-bs'>
           <h2 className='text-left mb-4'>Login verification</h2>
           <form className="rd-mailform text-left offset-top-20 mb-5">
             <div className="row row-fix row-narrow-12 row-12 justify-content-sm-center">
               <div className="col-sm-12">
                 <div className="form-group">
                  <input className="form-input" id="" type="text" name="verficationcode" placeholder='Enter Verification Code'value={verficationcode} onChange={handleChange}/>
                 </div>
               </div>
               <div className="col-sm-12">
                 <div className="form-group d-md-flex">
                   {/* <div className="w-50 text-left">
                     <div className="form-check">
                       <input type="checkbox" className="form-check-input mt-1" id="exampleCheck1" />
                       <label className="form-check-label" htmlFor="exampleCheck1">Remember me</label>
                     </div>
                   </div> */}
                   {/* <div className="w-50 text-md-right">
                     <a href="#">Forgot Password?</a>
                   </div> */}
                 </div>
               </div>
             </div>
             <div className="offset-top-10">
               <button className="btn btn-primary" onClick={handleVerify}>Verify</button>
               &nbsp;<button className="btn btn-primary" onClick={handleLogin}>Reset Code</button>

             </div>
             
           </form>
           {/* <div className='login-api text-center'>
             <h6 className='mb-3'>Login with</h6>
             <ul className='list-inline'>
               <li className='list-inline-item'><a className="icon novi-icon icon-l icon-circle fa fa-google text-danger" href="#">&nbsp;</a></li>
               <li className='list-inline-item'><a className="icon novi-icon icon-l icon-circle fa fa-facebook text-info" href="#">&nbsp;</a></li>
             </ul>
           </div> */}
       </div> 
          }
            
          </div>
        </div>
      </section>
    </div>
  )
}

export default Logout