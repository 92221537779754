import React from 'react'
import { Link } from "react-router-dom";
import AccountNav from '../AccountNav';
import SearchBar from '../SearchBar';

function Topic() {
  return (
    <div>
        <section className="section py-4 bg-gray-lighter mb-5 novi-background bg-cover">
            <div className="container">
                <SearchBar />
                <div className='row account mb-5'>
                    <div className='col-lg-3 mb-3'>
                        <AccountNav />
                    </div>
                    <div className='col-lg-9 topic-check  text-left'>
                        <div className='bg-white shadow p-4'>
                            <h2 className='mb-0'>Topics</h2>
                            <p className='mb-4'>Vivamus ultricies non odio eu rutrum. Praesent ac urna ut risus tristique condimentum. Tincidunt sem. Aliquam erat volutpat. Quisque nulla lacus, varius convallis nisl nec, ultricies bibendum sem. Quisque vitae tempor ante. </p>
                            <div className='row'>
                                <div className='col-lg-12 mb-4'>
                                    <div className='row'>
                                        <div className='col-lg-3'>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="Topic 1" />
                                                <label className="form-check-label" htmlFor="inlineCheckbox1">Topic 1</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="Topic 2" />
                                                <label className="form-check-label" htmlFor="inlineCheckbox2">Topic 2</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="Topic 3" />
                                                <label className="form-check-label" htmlFor="inlineCheckbox3">Topic 3</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox4" value="Topic 4" />
                                                <label className="form-check-label" htmlFor="inlineCheckbox4">Topic 4</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox5" value="Topic 5" />
                                                <label className="form-check-label" htmlFor="inlineCheckbox5">Topic 5</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox6" value="Topic 6" />
                                                <label className="form-check-label" htmlFor="inlineCheckbox6">Topic 6</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox7" value="Topic 7" />
                                                <label className="form-check-label" htmlFor="inlineCheckbox7">Topic 7</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox8" value="Topic 8" />
                                                <label className="form-check-label" htmlFor="inlineCheckbox8">Topic 8</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox9" value="Topic 9" />
                                                <label className="form-check-label" htmlFor="inlineCheckbox9">Topic 9</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox10" value="Topic 10" />
                                                <label className="form-check-label" htmlFor="inlineCheckbox10">Topic 10</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox11" value="Topic 11" />
                                                <label className="form-check-label" htmlFor="inlineCheckbox11">Topic 11</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox12" value="Topic 12" />
                                                <label className="form-check-label" htmlFor="inlineCheckbox1">Topic 12</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox13" value="Topic 13" />
                                                <label className="form-check-label" htmlFor="inlineCheckbox13">Topic 13</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox14" value="Topic 14" />
                                                <label className="form-check-label" htmlFor="inlineCheckbox14">Topic 14</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-lg-12'>
                                    <Link to="/"><button className="btn btn-primary" type="submit">Save</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}


export default Topic;