import React, { useEffect, useState, createContext } from "react";
import { Link } from "react-router-dom";
import { DummyImage } from "react-simple-placeholder-image";
import { PLAN_SELECTION } from "../../services/Api/PlanselectionApi";
import ModuleSelection from "../ModuleSelection/ModuleSelection";

const PlanSelection = () => {
  const reloadCountM = Number(sessionStorage.getItem('reloadCountM')) || 0;
  const [planSelectionList, setPlanSelectionList] = useState("");
  const [userDetail, setuserDetail] = useState("");



  useEffect(() => {
    if (reloadCountM < 1) {
      sessionStorage.setItem('reloadCountM', String(reloadCountM + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCountM');
    }
    if (localStorage.getItem('setUser') != null) {
      const userDetail = JSON.parse(localStorage.getItem('setUser'));
      setuserDetail(userDetail);
    }
    PLAN_SELECTION.PlanSelectionList().then((response) => {
      setPlanSelectionList(response?.data);
    })
  }, []);
  const onSubmit = (id) => {
    window.location.href = `#/module-selection/${id}`
  }

  const getplanList = () => {
    return Object.entries(planSelectionList)?.map(([key, val]) => (
      <div className="col-md-3" key={val?.plan_id} style={{ margin: 'auto' }}>
        <div className="card mb-4 shadow"><br />
          <img src={val?.plan_img} width={225} height={140} id="set-plan-img"
            className='card-img-top border-bottom'
            bgColor='#f5f5f5' fgColor='#999999' />
          <div className="mt-3 bg-white" style={{ margin: 0 }}><h5>{val.plan_name}</h5></div>
          <div className="mt-4">
            <h2 className="" style={{ marginTop: '-11px', fontSize: '20px', fontFamily: "sans-serif" }}>
              {userDetail?.userInfo?.country_id === 2 ? val?.plan_price_can : val?.plan_price_us}
              {userDetail?.userInfo?.country_id === 2 ? " CAD" : " USD"} <small className="">{val?.plan_type == 1 ? "/mo" : "/yr"}</small>
            </h2>
            <ul className="list-unstyled mt-3 mb-4">
              <li>{val.description}</li>
            </ul>
            <button className="btn btn-primary" style={{ marginTop: '-5px' }} onClick={() => onSubmit(val?.plan_id)} >Choose plan</button><br /><br />
          </div>
        </div>
      </div>
    ))
  }
  return (
    <section className="section section-md-bottom-50 bg-gray-lighter novi-background bg-cover">
      <div className="container">
        <div className="row pt-4">
          <div className="col">
            <ul className="nav bg-white py-3 shadow-sm justify-content-center">
              <li className="nav-item">
                <Link className="nav-link disabled" to={'/'}> Choose your plan</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link disabled" to={'/'}>Select your modules</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link disabled" to={'/'}>Confirmation &amp; Checkout</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="pricing-header px-3 py-3 mx-auto text-center">
          <h2>Choose your plan</h2>
        </div>
        <div className="row px-3 mx-auto text-center" >
          <div className="col-md-3"  ></div>
          {planSelectionList?.length > 0 ? planSelectionList?.map((val, i) => <div className="col-md-3" id={i} >
            <div className="card shadow"><br />
              <img src={val?.plan_img} width={225} height={140} id="set-plan-img"
                className='card-img-top border-bottom'
                bgColor='#f5f5f5' fgColor='#999999' />
              <div className="mt-3 bg-white" style={{ margin: 0 }}><h5>{val.plan_name}</h5></div>
              <div className="mt-4">
                <h2 className="" style={{ marginTop: '-11px', fontSize: '20px', fontFamily: "sans-serif" }}>
                  $ {userDetail?.userInfo?.country_id === 2 ? val?.plan_price_can : val?.plan_price_us}
                  {userDetail?.userInfo?.country_id === 2 ? " CAD" : " USD"} <small className="">{val?.plan_type == 1 ? "/mo" : "/yr"}</small>
                </h2>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>{val.description}</li>
                </ul>
                <button className="btn btn-primary" style={{ marginTop: '-5px' }} onClick={() => onSubmit(val?.plan_id)} >Choose plan</button><br /><br />
              </div>
            </div>
          </div>) : ''}
        </div>
      </div>
    </section>
  );
};

export default PlanSelection;
