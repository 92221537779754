import moment from 'moment';
import React from 'react';
import NewsComp from '../../components/News';
import { AUTENTICATE_API } from '../../services/Api/authenticationApi';

class NewsDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news_id: '',
            newsDetail: '',
            newList: [],
            popNews: '',
            tags: '',
        }
    }
    componentDidMount() {
        let ids = window.location.href?.split("/"); 
        let finalID = ids[ids?.length-1];
        this.setState({ news_id: finalID }, () => {
            this.getNewsDetail(this.state.news_id);
        })
        setTimeout(() => {
            this.guestnews_list();
        }, 400)
        setTimeout(() => {
            if (localStorage.getItem('setUser') != null) {
                let info = localStorage.getItem("setUser")
                let userInfo = JSON.parse(info);
                this.guestnews_listByUsers(userInfo?.userInfo?.user_id);
            }
        }, 500)
    }

    getNewsDetail = (news_id) => {
        AUTENTICATE_API?.getNewsDetails(news_id).then(res => {
            if (res && res?.data) {
                AUTENTICATE_API.areaInterestList().then((response) => {
                    let data = res?.data[0]?.tags;
                    let ids = [];
                    let SelectMd = []
                    data?.map(vals => {
                        ids.push(parseInt(vals))
                    })
                    response?.data?.map((val) => {
                        if (ids?.includes(val.opt_id) == true) {
                            SelectMd.push(val?.opt_name);
                        }
                    });
                    this.setState({ tags: SelectMd });
                })
                this.setState({ newsDetail: res?.data[0] });
            }
        })
    }

    guestnews_list = () => {
        AUTENTICATE_API?.guestnews_list().then(res => {
            if (res && res?.data) {
                this.setState({ newList: res?.data });
            }
        })
    }

    guestnews_listByUsers = (id) => {
        AUTENTICATE_API?.guestnews_listByUser(id).then(res => {
            if (res && res?.data) {
                var fl = [];
                if (res?.data) {
                    let id = [];
                    let otherNews = [];
                    res?.data?.map(val => {
                        id.push(val.news_id);
                    });
                    console.log(this.state.newList)
                    // setTimeout(()=>{
                    //     this.state.newList?.map(vals => {
                    //         if (id?.includes(vals?.news_id) == true) {
                    //             otherNews?.push(vals);
                    //         } 
                    //     });
                    //     let final = res?.data.concat(otherNews);
                    //     final = [...new Set(final)];
                    //     console.log(final)
                    //     this.setState({newList:final}); 
                    // },500)
                }
            }
        })
    }

    render() {
        return <div>
            <section className="section search-bg2 section-height-800 parallax-container context-dark bg-gray-darkest text-xl-left" data-parallax-img="./images/banner/banner5.jpg">
                <div className="parallax-content">
                    <div className="bg-overlay-black">
                        <div className="container section-80 section-md-30 section-lg-top-10 section-lg-bottom-30">
                            <div className="d-none d-lg-block">
                                <h1 className='mt-4'  >DNI News</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Blog Classic-->*/}
            <section className="section section-10 section-md-bottom-30 text-left novi-background bg-cover" data-preset='{"title":"Blog Classic","category":"blog","id":"blog-classic"}'>
                <div className="container">
                    <div className="row row-fix justify-content-sm-center">
                        <div className="col-md-10 col-lg-8 row-40 article-wrapper">
                            <NewsComp
                                image={this.state.newsDetail?.Banner_img}
                                title={this.state.newsDetail?.title}
                                date={moment(this.state.newsDetail?.created_dt).format('MMMM D, YYYY')}
                                author={this.state.newsDetail?.author}
                                desc={this.state.newsDetail?.description}
                                newsTag={this.state.tags}
                                source={this.state.newsDetail?.source}
                                id={this.state.newsDetail?.news_id}
                            />
                        </div>
                        <div className="col-md-10 col-lg-4 offset-top-90 offset-md-top-0">
                            <aside className="inset-lg-left-20 row-50 aside-wrapper">
                                <div className="aside-item">
                                    <h3 className='mt-3'>Popular posts</h3><br /><br />
                                    {this.state.newList?.map((val, i) => <div key={i}>
                                        {i <= 10 ? <div className="post-wrapper row-30 offset-top-13">
                                            <div className="post-item" style={{ cursor: 'pointer', marginTop: '-20px' }} onClick={(e) => {
                                                this.setState({ newsDetail: val });
                                            }}>
                                                <div className="unit align-items-center flex-row unit-spacing-sm" style={{ marginTop: '-45px' }}>
                                                    <div className="unit-body">
                                                        <p className="font-weight-bold">
                                                            {val?.title?.substring(0, 70) + '...'}
                                                        </p>
                                                        <p style={{ marginTop: '6px', fontWeight: 'normal' }}>
                                                            <span id="grapft" dangerouslySetInnerHTML={{ __html: val?.description?.substring(0, 100) + '...' }}></span>
                                                        </p>
                                                        {i !== this.state.newList?.length - 1 ? <hr
                                                            style={{
                                                                marginTop: 0,
                                                                marginBottom: 0,
                                                                borderTop: '1px solid #9F9F9F',
                                                            }}
                                                        /> : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ''}
                                    </div>)}
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}



export default NewsDetail