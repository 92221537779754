import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AUTENTICATE_API } from "../../services/Api/authenticationApi";
import StripeCheckout from 'react-stripe-checkout';
import { SEARCH_API } from "../../services/Api/searching";
import PageHeader from '../../components/PageHeader/PageHeader'
import { PLAN_SELECTION } from "../../services/Api/PlanselectionApi";

const MyConfirmation = (props) => {
    const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;
    let userDetail = JSON.parse(localStorage.getItem('setUser'));
    const [taxs, setTax] = useState("")
    const [taxsData, setTaxData] = useState("")
    const [billingAm, setBillingAmy] = useState('')
    const [discountAmount, setDiscountAmount] = useState(0)
    const [modulesData, setModulesData] = useState([])
    const [mnames, setMnames] = useState([])
    const [mnameList, setMnameList] = useState([]);
    const [selectedCount, setSelectedTaxCount] = useState(0);
    let finalPrice = 0;
    let finalPay = 0;
    // const pKey = 'pk_test_51L8jKIHHBNPR960E7yEdqJSkx7cUbg06YEB4VgLkb4tX2z9e7UKPSydT8WDEAwIo7GT7ecx57md36cs1XWJXNkoO00L7AJRpQD';
    const pKey = 'pk_live_51P5BflHL8Pds5c9OUPMJ1OrU6b2W1fctNBSIT6YTXmeTGelEDMFTrx0Heke8NjtJ8HP1ImqghRGV9P4cic4tFrJh00SuBgv8Qf';
    // const Skey = 'sk_test_51L8jKIHHBNPR960E1qODcK5kGMmUyZ7QWJwDELqPPm2ZvLuhSw01z1OmrxmvMWH0HlVV2rIFIUjCc2S4mc2AeKyj00K9aUy2Xo';
    const { id } = useParams();
    const [products, setProduct] = useState({
        name: "",
        price: "",
        currency: "USD",
        user_id: userDetail?.userInfo?.user_id,
    });

    useEffect(() => {
        getModulesByIds(id);
        if (reloadCount < 1) {
            sessionStorage.setItem('reloadCount', String(reloadCount + 1));

        } else {
            sessionStorage.removeItem('reloadCount');
        }

    }, [modulesData != ""]);
    const getModulesByIds = (ids) => {
        SEARCH_API.getModulesByIds(ids).then((response) => {
            if (response && response?.data) {
                if (modulesData?.length > 0) {
                    for (let i = 0; i <= modulesData?.length - 1; i++) {
                        if (userDetail?.userInfo?.country_id == 2) {
                            finalPrice += modulesData[i]?.can_price;
                            setBillingAmy(finalPrice);
                        }
                        if (userDetail?.userInfo?.country_id != 2) {
                            finalPrice += modulesData[i]?.us_price
                            setBillingAmy(finalPrice)
                        }
                    }
                    setProduct({
                        price: finalPrice?.toFixed(2),
                        name: "planname",
                        user_id: userDetail?.userInfo?.user_id,
                        currency: "USD",
                    })
                }
                PLAN_SELECTION?.getTaxByProvinces(userDetail?.userInfo?.zip_code).then(res => {
                    setSelectedTaxCount(res?.count)
                    if (res && res?.count > 0) {
                        setTaxData(res?.data)
                        setTax(res?.data[0]?.Final_tax);
                        if (res?.data[0]?.Final_tax == 0) {
                            setProduct({
                                price: discountAmount?.toFixed(2),
                                name: "planname",
                                user_id: userDetail?.userInfo?.user_id,
                                currency: "USD",
                            })
                        } else {
                            let rowtax = finalPrice * res?.data[0]?.Final_tax;
                            let taxper = rowtax / 100;
                            finalPay = (finalPrice + taxper)
                            setProduct({
                                price: finalPay?.toFixed(2),
                                name: "planname",
                                user_id: userDetail?.userInfo?.user_id,
                                currency: "USD",
                            })
                        }
                    }
                })

                setMnameList(response?.data)
                setModulesData(response?.data);
                let ds = modulesData?.map(val => {
                    return " " + val?.module_name
                });
                setMnames(ds?.toString())
            }
        })
    }

    const makePayment = (token) => {
        let product = {
            currency: `USD`,
            name: mnames,
            price: parseFloat(products?.price),
            user_id: userDetail?.userInfo?.user_id,
        }
        let data = {
            token,
            product,
        }
        AUTENTICATE_API.makePayment(data).then(res => {
            if (res?.status == 200) {
                let mod_id = modulesData?.map(val => {
                    return val?.module_id
                })
                let obj = {
                    user_id: userDetail?.userInfo?.user_id,
                    billable_amt: billingAm,
                    tax: taxs,
                    total_billable_amt: parseFloat(products?.price),
                    plan_detail_id: parseInt(localStorage.getItem('plan_detail_id')),
                    module_id: mod_id?.toString()
                }
                if (obj) {
                    SEARCH_API?.updateAddOnModule(obj).then(res => {
                        if (res?.status == 200) {
                            let mod_ids = modulesData?.map(val => {
                                return val?.module_id
                            }).toString();
                            console.log(mod_ids);
                            let str = localStorage.getItem("setUser");
                            let pdata = JSON.parse(str);
                            pdata.userInfo.topudModule = mod_ids
                            localStorage.setItem("setUser", JSON.stringify(pdata));
                            localStorage.setItem("state", JSON.stringify(pdata));
                            toast.success('Payment successfull', { autoClose: 1000, position: toast.POSITION.TOP_RIGHT });
                            setTimeout(() => {
                                window.location.href = '#/account/payment-history';
                            }, 1000)
                        }
                    })
                }
            }
        })
    }


    return (
        <section className="section section-md-bottom-50 bg-gray-lighter novi-background bg-cover">
            <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                title="Payment confirmation"
                description={""} />
            <div className="container"><br /><br />
                <div className="row" >
                    <div className="col-lg-10" style={{ margin: 'auto' }}>
                        <div className="row justify-content-sm-center bg-white my-1 mx-auto h-100">
                            <div className="col-lg-5 d-flex flex-column contact-bg p-5">
                                <div className="text-left mt-5">
                                    <h3 className="text-white">Added Modules</h3>
                                    <div className="row" id='set-modules'>
                                        <span className="m-3" style={{ color: 'white', fontSize: 18 }}>{mnames}</span>
                                    </div>
                                    <p class="text-big font-weight-bold text-primary m-0">${billingAm != 0 ? billingAm : products?.price} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}</p>
                                    {/* <p className="text-white m-0">Module #1, Module #2, Module #3,</p>
                            <p className="text-white m-0">Module #1, Module #2</p> */}
                                </div>
                            </div>
                            <div className='col-lg-7'>
                                <h2 className="mb-4 mt-5">Make Payment</h2>

                                {mnameList?.length > 0 ? mnameList?.map((val, i) =>
                                    <div className="row" key={i}>
                                        <div className="col-lg-4">
                                            <strong id="paymentDetails">{val?.module_name}</strong>
                                        </div>
                                        <div className="col-lg-4">
                                        </div>
                                        <div className="col-lg-4">
                                            <strong className="mt-2">${userDetail?.userInfo?.country_id == 2 ? val?.can_price : val?.us_price} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}</strong>
                                        </div>
                                    </div>
                                ) : ''}

                                <div className="row">
                                    <div className="col-lg-4">
                                        <strong id="paymentDetails">Subtotal</strong>
                                    </div>
                                    <div className="col-lg-4">
                                    </div>
                                    <div className="col-lg-4">
                                        <strong className="mt-2">${billingAm} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}</strong>
                                    </div>
                                </div>

                                {
                                    (selectedCount !== 0 && taxsData[0]?.GST != 0) ? <>
                                        <div className="row">
                                            <div className="col-md-3" ><strong id="paymentDetails">{taxsData[0]?.GST != 0 ? 'GST' : ''}</strong></div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3" >
                                                <strong id="taxRate">
                                                    {taxsData[0]?.GST != 0 ? taxsData[0]?.GST : ''} %
                                                </strong>
                                            </div>
                                        </div>
                                    </> : ''
                                }
                                {
                                    (selectedCount !== 0 && taxsData[0]?.HST != 0) ? <>
                                        <div className="row">
                                            <div className="col-md-3" ><strong id="paymentDetails">{taxsData[0]?.HST != 0 ? 'HST' : ''}</strong></div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3" >
                                                <strong id="taxRate">{taxsData[0]?.HST != 0 ? taxsData[0]?.HST : ''} %</strong>
                                            </div>
                                        </div>
                                    </> : ''
                                }
                                {
                                    (selectedCount != 0 && taxsData[0]?.PST != 0) ? <>
                                        <div className="row">
                                            <div className="col-md-3" ><strong id="paymentDetails">{taxsData[0]?.PST != 0 ? 'PST' : ''}</strong></div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3" >
                                                <strong id="taxRate"> {taxsData[0]?.PST != 0 ? taxsData[0]?.PST : ''} %</strong>
                                            </div>
                                        </div>
                                    </> : ''
                                }
                                {
                                    (selectedCount != 0 && taxsData[0]?.QST != 0) ? <>
                                        <div className="row">
                                            <div className="col-md-3" ><strong id="paymentDetails">{taxsData[0]?.QST != 0 ? 'QST' : ''}</strong></div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3" >
                                                <strong id="taxRate">{taxsData[0]?.QST != 0 ? taxsData[0]?.QST : ''} %</strong>

                                            </div>
                                        </div>
                                    </> : ''
                                }
                                {
                                    (selectedCount != 0 && taxsData[0]?.Final_tax != 0) ? <>
                                        <div className="row" >
                                            <div className="col-md-3" ><strong id="paymentDetails">{taxsData[0]?.Final_tax != 0 ? 'Total Tax' : ''}</strong></div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3" ><strong>
                                                {taxsData[0]?.Final_tax != 0 ? taxsData[0]?.Final_tax : ''} %
                                            </strong>
                                            </div>
                                        </div>
                                    </> : ''
                                }

                                {/* <div className="row">
                                    <div className="col-lg-4">
                                        <strong id="paymentDetails">Tax</strong>
                                    </div>
                                    <div className="col-lg-4">
                                    </div>
                                    <div className="col-lg-4">
                                        <strong>{taxs} %
                                       
                                        </strong>
                                    </div>
                                </div> */}
                                <div className="row mt-2" style={{ borderBottom: "1px solid", borderTop: "1px solid" }}>
                                    <div className="col-lg-4">
                                        <strong id="paymentDetails">Total Amount</strong>
                                    </div>
                                    <div className="col-lg-4">
                                    </div>
                                    <div className="col-lg-4">
                                        <p style={{ marginTop: 9 }}>
                                            <strong >${discountAmount !== 0 ? discountAmount?.toFixed(2) : <strong className="mt-2">
                                                {products?.price}</strong>} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}</strong> </p>
                                    </div>
                                </div>

                                <div>
                                </div>
                                <div className="col-sm-12 mt-3">
                                    <StripeCheckout stripeKey={pKey}
                                        token={makePayment}
                                        name="Make Payment"
                                        amount={discountAmount !== 0 ? discountAmount?.toFixed(2) * 100 : products?.price * 100}
                                        currency='USD'
                                        email={userDetail?.userInfo?.email_id}
                                    >
                                        <button className="btn btn-primary btn-block col-md-12" style={{ margin: 'auto', fontSize: 11, padding: 10 }}>Checkout ${discountAmount !== 0 ? discountAmount?.toFixed(2) : products?.price} {userDetail?.userInfo?.country_id == 2 ? 'CAD' : 'USD'}</button>
                                    </StripeCheckout>
                                </div><br /><br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MyConfirmation;
