
import React from 'react';
import { SEARCH_API } from './../../services/Api/searching';
import logo from './../../assets/img/logo/dni-logo.png';
import { Link } from 'react-router-dom';
import html2pdf from 'html2pdf-fix-jspdf';
import moment from 'moment';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import PageHeader from '../../components/PageHeader/PageHeader';
import { PLAN_SELECTION } from '../../services/Api/PlanselectionApi';
// import ToggleSwitch from '../../components/common/ToggleSwitch';

class PaymentHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: '',
            paymentDetails: [],
            recieptData: "",
            subTotal: 0,
            taxVal: 0,
            grandTotal: 0,
            selectedCount: 0,
            selectedTax: 0,
            billingDetail: ''
        }
        this.bStyle = {
            borderTop: '1px solid #ded9d9eb',
            borderBottom: '1px solid #ded9d9eb'
        }

    }
    componentDidMount() {
        if (localStorage.getItem("state") != null) {
            let By = localStorage.getItem("setUser");
            By = JSON.parse(By);
            this.setState({ userInfo: By?.userInfo }, () => {
                this.getPaymentHisory(this.state.userInfo?.user_id);
                PLAN_SELECTION?.getTaxByProvinces(this.state.userInfo?.zip_code).then(res => {
                    if (res && res?.data?.length > 0)
                        this.setState({ taxVal: res?.data[0]?.Final_tax })
                });
            })
        }
    }


    getPaymentHisory(id) {

        SEARCH_API?.getPaymentHisory(id).then(res => {
            if (res && res?.data) {

                this.setState({ paymentDetails: res?.data }, () => {

                })
            }
        })
    }
    getReciept(val, e) {
        SEARCH_API.getReciept(val?.stripe_id).then(res => {
            this.setState({ recieptData: res?.data[0] }, () => {
                PLAN_SELECTION?.getTaxByProvinces(this.state.userInfo?.zip_code).then(res => {
                    this.setState({ selectedCount: res?.count })
                    if (res && res?.data) {
                        this.setState({ selectedTax: res?.data[0] }, () => {
                            // console.log('recieptData-----',this.state.recieptData)
                        })
                    }
                });

                this.setState({ recieptData: res?.data[0], billingDetail: res?.data[0] }, () => {

                    if (this.state.userInfo?.country_id == 1) {
                        this.setState({ taxVal: 0 })
                        this.setState({ subTotal: this.state.recieptData?.total_amt, grandTotal: this.state.recieptData?.total_amt });
                    } else {
                        let val = (this.state.recieptData?.total_amt * this.state.taxVal) / 100;
                        let fl = val?.toFixed(1)
                        let afTax = parseFloat(fl)
                        let finalVal = this.state.recieptData?.total_amt - afTax
                        let billableAmt = res?.data[0]?.billable_amt !== "null" ? res?.data[0]?.billable_amt : 0;
                        this.setState({ subTotal: billableAmt, grandTotal: this.state.recieptData?.total_amt });


                    }
                })
            })
        });
    }
    downloadReport(e) {
        setTimeout(() => {
            let element = document.getElementById('recDownload');
            document.getElementById('close-id').style.display = 'none';
            document.getElementById('download-btns').style.display = 'none'
            let opt = {
                margin: 0.3,
                filename: `payment-reciept-${moment(new Date()).format('YYYY-MM-DD')}`,
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 1 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
            };
            html2pdf(element, opt);

        }, 10)
        setTimeout(() => {
            document.getElementById('close-id').style.display = 'block';
            document.getElementById('download-btns').style.display = 'block'
        }, 1000)
    }

    render() {

        return <>
            <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                title="Payment History"
                description={""} />

            <div className='row '>
                <div className='col-md-8 p-4' style={{ margin: 'auto', textAlign: 'initial' }}>
                    <div className='bg-white shadow p-4'>
                        <h5 style={{ textAlign: 'center', background: '#A7CA56', color: 'whitesmoke' }}>Payment Information</h5>
                        <div id="paymentContainer">
                            {this.state.paymentDetails?.length ? this.state.paymentDetails?.map(val => <>
                                <div className='card-header' id="paymetHr">
                                    <div className='row'>
                                        <div className='col-md-3' id="keys">
                                            Name:
                                        </div>
                                        <div className='col-md-2' id="keys">
                                            :
                                        </div>
                                        <div className='col-md-5' id="vals">
                                            {val?.name}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3' id="keys">
                                            Email
                                        </div>
                                        <div className='col-md-2' id="keys">
                                            :
                                        </div>
                                        <div className='col-md-5' id="vals">
                                            {val?.receipt_mail}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3' id="keys">
                                            Total Amount
                                        </div>
                                        <div className='col-md-2' id="keys">
                                            :
                                        </div>
                                        <div className='col-md-5' id="vals">
                                            $ {val?.amount} {this.state.userInfo?.country_id == 2 ? "CAD" : "USD"}
                                        </div>
                                    </div>
                                    {this.state.taxVal == 0 ? "" :
                                        <div className='row'>
                                            <div className='col-md-3' id="keys">
                                                Tax Amount
                                            </div>
                                            <div className='col-md-2' id="keys">
                                                :
                                            </div>
                                            <div className='col-md-5' id="vals">
                                                {this.state.taxVal}%
                                            </div>
                                        </div>
                                    }

                                    <div className='row'>
                                        <div className='col-md-3' id="keys">
                                            Payment ID
                                        </div>
                                        <div className='col-md-2' id="keys">
                                            :
                                        </div>
                                        <div className='col-md-5' id="vals">
                                            {val?.payment_id != "null" && val?.payment_id !== '' ? val.payment_id : 'NA'}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3' id="keys">
                                            Payment Date
                                        </div>
                                        <div className='col-md-2' id="keys">
                                            :
                                        </div>
                                        <div className='col-md-5' id="vals">

                                            {val?.created_dt}

                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-3' id="keys">
                                            Payment Status
                                        </div>
                                        <div className='col-md-2' id="keys">
                                            :
                                        </div>
                                        <div className='col-md-4' id="vals">
                                            {val?.status === 'succeeded' ?
                                                <span style={{ background: '#619561', color: 'whitesmoke', borderRadius: '5px', padding: 4 }}>{val?.status}</span> :
                                                <span style={{ background: 'rgb(163 78 69)', color: 'whitesmoke', borderRadius: '5px', padding: 4 }}>{val?.status}</span>}
                                        </div>

                                        <div className='col-md-2'>
                                            <button className='btn btn-outline-success' id="view-reciept" onClick={this.getReciept.bind(this, val)} data-toggle="modal" data-target="#reciept">View Receipt</button>
                                        </div>
                                    </div>
                                </div>
                            </>) : <h5 style={{ margin: 'auto', textAlign: 'center', paddingTop: 10 }}>No record found !</h5>}
                        </div>
                        {/* <button className='btn btn-primary mt-4' onClick={(e)=>{
                            window.location.href="#/account"
                        }}>
                             &#60;&#60; Go Back
                        </button> */}
                    </div>
                </div>
            </div>

            <Popup isOpen={this.state.openModals} position="right center">
                <div>Popup content here !!</div>
            </Popup>

            <div className="modal fade" id="reciept" tabindex="-1" aria-labelledby="reciept" >
                <div id="test1" >
                    <div className="modal-dialog modal-lg modal-dialog-centered mt-5" >
                        <div className="modal-content p-3">
                            <div className="modal-body text-center" id='recDownload'>
                                <button type="button" className="close" id='close-id' data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                {/* <div className='text-center mb-5'> */}
                                <img className="m-auto" src={logo} alt="" width={200} />
                                {/* </div> */}<br /><br />
                                <h3 className="">Thank You For Your Purchase!</h3>
                                <p> <b><Link to="#"><strong style={{ fontSize: 20 }}>ORDER SUMMARY</strong></Link></b></p>
                                <p className='lead'>{this.state.recieptData?.created_dt}</p>
                                <table className="table text-left table-borderless">
                                    <tbody className="border-bottom">
                                        <tr style={this.bStyle}>
                                            <td scope="row">Plan Details</td>
                                            <th className="text-right">{this.state.recieptData?.description == "Student" ? <span>
                                                Student(HIV, Oncology)
                                            </span> : this.state.recieptData?.description}</th>
                                        </tr>
                                        <tr style={this.bStyle}>
                                            <td scope="row">Subtotal</td>
                                            <th className="text-right">${this.state.subTotal == 0 ? 0 : this.state.subTotal} {this.state.userInfo?.country_id == 2 ? "CAD" : "USD"}</th>
                                        </tr>
                                        {/* {this.state.paymentDetails[0]?.tax!=0?<tr>
                                            <td scope="row">Tax</td>
                                            <th className="text-right">{this.state.paymentDetails[0]?.tax} %
                                            </th>
                                        </tr>
                                            :''
                                        } */}

                                    </tbody>
                                    {/* <tbody className="border-bottom">
                                        <tr>
                                            <td scope="row">Total Amount</td>
                                            <th className="text-right">${this.state.grandTotal==0?0:this.state.grandTotal } {this.state.userInfo?.country_id==2?"CAD":"USD"}</th>
                                        </tr>
                                    </tbody> */}
                                    {/* {this.state.taxVal==0?"":
                                   <tbody className="border-bottom">
                                   <tr>
                                       <td scope="row">Tax Amount</td>
                                       <th className="text-right">{this.state.taxVal}%</th>
                                   </tr>
                               </tbody>
                                    } */}


                                    {
                                        (this.state.selectedCount !== 0 && this.state.selectedTax?.GST != 0) ? <>
                                            <tr style={this.bStyle}>
                                                <td style={{ color: 'black', textAlign: 'initial', paddingBottom: 15 }}><span>{this.state.selectedTax?.GST != 0 ? 'GST' : ''} ({this.state.selectedTax?.GST != 0 ? this.state.selectedTax?.GST : ''} %)</span></td>

                                                <th style={{ color: 'black', textAlign: 'right' }}>${this.state.selectedTax?.GST != 0 ? ((this.state.subTotal * this.state.selectedTax?.GST) / 100)?.toFixed(2) : ''} {this.state.userInfo?.country_id == 2 ? 'CAD' : 'USD'}
                                                </th>
                                            </tr>
                                        </> : ''
                                    }
                                    {
                                        (this.state.selectedCount !== 0 && this.state.selectedTax?.HST != 0) ? <>
                                            <tr style={this.bStyle}>
                                                <td style={{ color: 'black', textAlign: 'initial', paddingBottom: 15 }}><span>{this.state.selectedTax?.HST != 0 ? 'HST' : ''} ({this.state.selectedTax?.HST != 0 ? this.state.selectedTax?.HST : ''} %)</span></td>

                                                <th style={{ color: 'black', textAlign: 'right' }}>${this.state.selectedTax?.HST != 0 ? ((this.state.subTotal * this.state.selectedTax?.HST) / 100)?.toFixed(2) : ''} {this.state.userInfo?.country_id == 2 ? 'CAD' : 'USD'}
                                                </th>
                                            </tr>
                                        </> : ''
                                    }
                                    {
                                        (this.state.selectedCount != 0 && this.state.selectedTax?.PST != 0) ? <>
                                            <tr style={this.bStyle}>
                                                <td style={{ color: 'black', textAlign: 'initial', paddingBottom: 15 }}><span>{this.state.selectedTax?.PST != 0 ? 'PST' : ''} ({this.state.selectedTax?.PST != 0 ? this.state.selectedTax?.PST : ''} %)</span></td>

                                                <td style={{ color: 'black', textAlign: 'right' }}>${this.state.selectedTax?.PST != 0 ? ((this.state.subTotal * this.state.selectedTax?.PST) / 100)?.toFixed(2) : ''} {this.state.userInfo?.country_id == 2 ? 'CAD' : 'USD'}
                                                </td>
                                            </tr>
                                        </> : ''
                                    }
                                    {
                                        (this.state.selectedCount != 0 && this.state.selectedTax?.QST != 0) ? <>
                                            <tr style={this.bStyle}>
                                                <td style={{ color: 'black', textAlign: 'initial', paddingBottom: 15 }}><span>{this.state.selectedTax?.QST != 0 ? 'QST' : ''} ({this.state.selectedTax?.QST != 0 ? this.state.selectedTax?.QST : ''} %)</span></td>

                                                <th style={{ color: 'black', textAlign: 'right' }}>${this.state.selectedTax?.QST != 0 ? ((this.state.subTotal * this.state.selectedTax?.QST) / 100)?.toFixed(2) : ''} {this.state.userInfo?.country_id == 2 ? 'CAD' : 'USD'}
                                                </th>
                                            </tr>
                                        </> : ''
                                    }
                                    {(this.state.selectedTax?.Final_tax > 0 && this.state.userInfo?.country_id == 2) ? <tr style={this.bStyle}>
                                        <td scope="row">Total Tax ({this.state.selectedTax?.Final_tax}  %)</td>
                                        <th className="text-right">${((this.state.subTotal * this.state.selectedTax?.Final_tax) / 100)?.toFixed(2)} {this.state.userInfo?.country_id == 2 ? 'CAD' : 'USD'}
                                            {/* {userDetail?.userInfo?.country_id==2?'CAD':'USD'} */}
                                        </th>
                                    </tr> : ""}
                                    <tbody>
                                        <tr>
                                            <th scope="row">Grand Total</th>
                                            <td className="text-right">  <p> <b>
                                                <Link to="/contact"><strong style={{ fontSize: 20 }}>${this.state.grandTotal == 0 ? 0 : this.state.grandTotal} {this.state.userInfo?.country_id == 2 ? "CAD" : "USD"}</strong></Link>
                                            </b></p></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h2 className="mb-4 text-center">Your Details</h2>
                                <table className="table text-left table-borderless">
                                    <tbody>
                                        <tr style={this.bStyle}>
                                            <td className='pb-0'>Billed to</td>
                                            <th className="text-right pb-0">{this.state.userInfo?.first_name + " " + this.state.userInfo?.last_name}</th>
                                        </tr>
                                        <tr style={this.bStyle}>
                                            <th colSpan={2} className="text-right pb-0">{this.state.userInfo?.email_id}</th>
                                        </tr>
                                        {this.state.userInfo?.organization != null ?
                                            <tr>
                                                <td className='pb-0'>Organization</td>
                                                <th className="text-right pb-0">{this.state.userInfo?.organization}</th>
                                            </tr> : ''}
                                    </tbody>
                                </table>
                                <p>Notice anything wrong? <Link to="/contact">Contact us</Link> and we'll happy to help</p>
                            </div>
                            <div className="modal-footer text-center">
                                <button className="btn btn-outline-primary btn-block" id='download-btns' style={{ width: 100 }} onClick={this.downloadReport.bind(this)}>Download</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default PaymentHistory