import React, { useEffect, useState } from 'react';
import "./CustInputRange.scss";

const CustInputRangeSerum = (props) => {
  const { setInput, inputVal } = props;

  let Scr = 0;
  let age = "";
  //-----minimum_values_of_male----//
  let age_value = 0.9938 ** age;
  let ScrValue = Scr;
  let minimummaleScr = ScrValue / 0.9;
  let minimum_male_value = Math.min(minimummaleScr, 1)
  let powerofminmaleValue = (minimum_male_value) ** 3.3;
  let minimum_male_Scr = powerofminmaleValue;
  //-----maximum_values_of_male----//
  let maximumScrMale = ScrValue / 0.9;
  let maximum_male_value = Math.max(maximumScrMale, 1)
  let powerofmaxmaleValue = (maximum_male_value) ** 0.83
  let maximum_male_Scr = powerofmaxmaleValue;
  let egfr_male = 142 * minimum_male_Scr * maximum_male_Scr * age_value;
  //-----minimum_values_of_female----//
  let minimumFemaleScr = ScrValue / 0.7;
  let minimum_female_value = Math.min(minimumFemaleScr, 1)
  let powerofminfemaleValue = (minimum_female_value) ** 4.15
  let minimum_female_Scr = powerofminfemaleValue;
  //-----maximum_values_of_female----//
  let maximumFemaleScr = (ScrValue / 0.7);
  let maximum_female_value = Math.max(maximumFemaleScr, 1)
  let powerofmaxfemaleValue = (maximum_female_value) ** 4.15
  let maximum_female_Scr = powerofmaxfemaleValue;
  let egfr_female = 142 * maximum_female_Scr * minimum_female_Scr * 1.012 * age_value;
  const [newval, setNewVal] = useState("")
  const [maxSrcValue, setmaxSrcValue] = useState(0)
  const [egfrm, setegfrm] = useState("")
  const [egfrf, setegfrf] = useState("")
  const updateRangeDisplay = (stepsVal) => {
    props.egfr("");
    props.ErSms("");
    if (!isNaN(stepsVal) == true) {
      if (props.Tab == "mg/dL") {
        if (stepsVal >= 21) {
          props.ErSms("Allowed Only Less Than 20.");
        } else if (stepsVal.length > 3) {
          props.ErSms("Please Enter Valid Input.");
        }
        else {
          setInput(stepsVal);
        }
      } else if (props.Tab == "μmol/L") {
        if (stepsVal >= 1768) {
          props.ErSms("Allowed Only Less Than 1728.");
        } else {
          setInput(stepsVal);
        }
      }
    } else {
      props.ErSms("Only digits are allowed");
    }
  }



  return (
    <div className="form-group row customSlider">

      <div className='col-12'>
        <div className="ticks">
          <span className="tick">{props.minVal} {props.metric && props.metric}</span>
          <span className="tick">{props.maxVal} {props.metric && props.metric}</span>
        </div>
        <input type="range" style={{ width: '100%', marginLeft: '0px' }} step={props.Tab == "mg/dL" ? 0.1 : 1} min={props.minVal} max={props.maxVal} defaultValue={props.minVal} value={(inputVal) ? inputVal : props.minVal} onChange={(event) => updateRangeDisplay(event.target.value)} />
      </div>
      <div className='col-7'>
        <label className='form-check-label' style={{ color: '#484343', fontSize: 15 }}>Serum Creatinine <span style={{ color: 'black', fontSize: 13 }}>{"  " + props.Label}</span></label>
      </div>
      <div className='col-5 text-right'>
        <input type="text" style={{ width: '100px', height: '38px' }} CalculateEgfr={props.CalculateEgfr} value={(inputVal == "0.0") ? "" : inputVal} name="inputVal" onChange={(e) => updateRangeDisplay(e.target.value)} />
      </div>
    </div>
  )
}

export default CustInputRangeSerum;