import React, { Component } from 'react';
import BackIcon from '../../assets/images/previousBtn.png'

const PrivacyPolicy = () => {
    return (
        <>
            <section className="section search-bg2 section-height-800 parallax-container context-dark bg-gray-darkest text-xl-left" data-parallax-img="./images/home/resized/about-news.jpg">
                <div className="parallax-content">
                    <div className="bg-overlay-black">
                        <div className="container section-80 section-md-30 section-lg-top-10 section-lg-bottom-30" style={{ paddingTop: 6 }}>
                            <div className="d-none d-lg-block">
                                <h1 className='mt-4'>Privacy Policy</h1>
                            </div>
                            <div className='d-flex d-lg-block'>
                                <img src={BackIcon} alt="Back" className="d-lg-none d-md-none" onClick={() => { window.location.href = '/#/' }} style={{ width: '8%', height: "8%", marginTop: '97px' }} />
                                {/* <ul className="list-inline list-inline-dashed list-white text-big p offset-md-top-8">
                            <li>Learn More about the DNI team</li>
                        
                        </ul> */}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <div className='col-md-7 mt-5' style={{ textAlign: 'justify', color: 'black', margin: 'auto' }}>
                <p style={{ textAlign: 'center', margin: '12pt 0cm 0cm', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>&nbsp;</span><strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>PRIVACY POLICY</span></strong></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Elbe’s Drug Nutrition Interactions Ltd.<strong> </strong>and our corporate affiliates (the “<strong>Company</strong>”, “<strong>we</strong>”, “<strong>us</strong>”, “<strong>our</strong>”) respect and uphold individual rights to privacy and the protection of personal information while balancing such rights with our need to process your personal information to manage our business and deliver our services to you.&nbsp; Based on applicable privacy and data protection principles common to Canada and other jurisdictions to which we are subject to, we have developed this privacy policy (the “<strong>Policy</strong>”) to explain our practices for processing your personal information. This Policy does not apply to our employees’ personal information or any anonymized information. </span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>This Policy describes our practices with respect to how we may collect, use, disclose, retain, dispose and otherwise process (collectively, “<strong>process</strong>”) and how you may correct, update and access your personal information that you provide as a user of (a) the website operated at www.drugnutritioninteractions.com and other locations from time to time‎ (the “<strong>Website</strong>”,<strong> </strong>(b) any front-end software we provide you that interacts or interfaces with the Website (the “<strong>Software</strong>”), excluding third party software that may interact with the Website or the Service as defined below, and (c) the services we provide through the Website and/or the Software (collectively, the “<strong>Service</strong>”). This Policy references the general Terms of Use for the Company delivered to you through email prior to your use of our Website, Software or Service, and forms an integral part of it. All capitalized terms not otherwise defined herein have the meaning provided in our Terms of Use.</span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', textIndent: '0cm', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>PLEASE READ THIS PRIVACY POLICY CAREFULLY.&nbsp; </span></strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>By using our Website, Software, or Service, or otherwise by choosing to provide us with your personal information, you acknowledge and consent to the processing of your personal information in Canada<strong> </strong>in accordance with this Policy and as may be further identified when the personal information is collected.&nbsp; If you are located or residing outside of Canada, please be aware that our Website, Software and Service are intended for and directed to users in Canada, and the privacy laws and principles in Canada may differ and not offer the same level of protection as those in your location or residing country/region.&nbsp; Through your continued use of our Website, Software or Service, you are transferring your personal information to Canada and you expressly consent to that transfer. We will use this consent as the legal basis for such data transfer, unless otherwise stated in this Policy. </span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>If you do not consent to the processing of your personal information in accordance with this Policy, please do not access or continue to use any of the Website, Software or Service or otherwise provide any personal information to us. </span></p>
                <ol style={{ marginBottom: '0cm', marginTop: '16px' }}>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Personal Information</span></strong></li>
                </ol>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>For the purposes of this Policy, “<strong>personal information</strong>” means any identifiable information about an individual, including but not limited to an individual’s name, home address, telephone number, email address, except any other information otherwise exempted by the applicable laws of Canada and other jurisdictions.&nbsp; For example, in Canada, personal information does not include any business contact information that is processed solely to communicate with that person about his or her employment or profession. </span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>When you use our Website, Software or Service, we may collect the following personal information from you: </span></p>
                <ul style={{ marginBottom: '0cm', marginTop: '16px' }}>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Contact information such as name, email address, country, zip code, organization;</span></li>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Unique identifiers such as username, account number, and password;</span></li>
                </ul>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Your personal information may be collected when: </span></p>
                <ul style={{ marginBottom: '0cm', marginTop: '16px' }}>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>You register for an account with us;</span></li>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>We collect data from third parties or publicly available sources.</span></li>
                </ul>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>We only collect personal information that we need.&nbsp; We encourage you to not provide us with any personal information beyond what is necessary and as requested by us.</span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', textIndent: '0cm', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Times New Roman", serif', fontStyle: 'italic', textDecoration: 'underline' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Collection from Third Parties</span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', textIndent: '0cm', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Times New Roman", serif', fontStyle: 'italic', textDecoration: 'underline' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif', fontStyle: 'normal' }}>We do not knowingly collect your personal information from a third party unless you consent or we are otherwise exempted, required or permitted by applicable laws to do so.&nbsp; </span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', textIndent: '0cm', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Times New Roman", serif', fontStyle: 'italic', textDecoration: 'underline' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif', fontStyle: 'normal' }}>If we collect your personal information from a third party, we will only process that information for the specific purpose for which it was provided to us in accordance with this Policy and the policy under which that information was collected.&nbsp; </span><strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>&nbsp;</span></strong></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><em><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Links to Other Sites</span></em></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>To the extent that our Website contains links to other sites, the owners of those sites are responsible for the privacy practices or content of those other sites. We do not endorse and will not be responsible for the privacy practices on third party applications.</span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', textIndent: '0cm', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Times New Roman", serif', fontStyle: 'italic', textDecoration: 'underline' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Information about Minors</span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>This site is intended solely for users who are not minors (18 years of age and over or the applicable legal age in your jurisdiction).</span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>We do not knowingly collect personal information about any minor. No such minor, nor any parent or guardian as it relates to such minor, should submit such minor’s personal information to us through the Website, Software or otherwise for any reason and under any circumstances.&nbsp; </span></p>
                <p style={{ margin: '0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>&nbsp;</span></p>
                <ol style={{ marginBottom: '0cm', marginTop: '16px' }} start={2}>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Purpose for Which Personal Information is Processed</span></strong></li>
                </ol>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>We may process your personal information for the following purposes (the <strong>“Purposes</strong>”): </span></p>
                <ul style={{ marginBottom: '0cm', marginTop: '16px' }}>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>To verify and authenticate your identity;</span></li>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>To ensure that the Website, Software or Service is optimized for your use and benefit;</span></li>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>To analyze user experience and improve the Website;</span></li>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>To operate, maintain and provide to you the Service, features and functionality of the Website or Software;</span></li>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>To communicate with you to provide you services, contacts, materials and/or recommendations for your needs as identified by you through email or the Website;</span></li>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>To carry out our obligations arising from any contracts entered into between you and us;</span></li>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>To comply with internal policies and procedures and other legal, accounting, or security requirements;</span></li>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>To share your personal information with our employees, contractors, consultants and other third party service providers such as application hosting providers, advertising agencies, payment processors or customer service agencies (“<strong>Third Party Processors</strong>”) who require this information to assist us with establishing, maintaining and managing our relationship with you and optimizing and providing the Website, Software or Service for your use and benefit. Please note that we may change or add Third Party Processors at any time, in our sole discretion, either in Canada or elsewhere. We encourage you to reference this Policy from time-to-time, to obtain updated information. Given the nature of cloud services, personal information may be stored outside Canada, anywhere in the world, and may be accessible to foreign courts, law enforcement and national security authorities in the jurisdiction(s) where it is transferred or stored. </span></li>
                </ul>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>We will only process your personal information for the Purposes for which we intend to process such information.&nbsp; Otherwise, we will not process your personal information without your consent.</span></p>
                <ol style={{ marginBottom: '0cm', marginTop: '16px' }} start={3}>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Disclosure of Your Personal Information </span></strong></li>
                </ol>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>We may disclose your personal information for the Purposes as described in this Policy in the following ways: </span></p>
                <ul style={{ marginBottom: '0cm', marginTop: '16px' }}>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>To our employees and contractors;</span></li>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>To our business partners;</span></li>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>To our service providers including application hosting providers, advertising agencies, payment processors and customer service agencies; and</span></li>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>To law enforcement, government or regulatory bodies, or other lawful authorities.</span></li>
                </ul>
                <p style={{ margin: '0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>&nbsp;</span></p>
                <p style={{ margin: '0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Your personal information that we collect may be processed outside of Canada but only in relation to the Purposes. As a result, your personal information may be accessible to law enforcement and regulatory authorities in accordance with other jurisdictions’ applicable laws.</span></p>
                <p style={{ margin: '0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>&nbsp;</span></strong></p>
                <ol style={{ marginBottom: '0.0001pt', marginTop: '0px' }} start={4}>
                    <li style={{ margin: '0cm 0cm 0.0001pt 0px', textAlign: 'justify', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Legal Basis for Processing Your Personal Information </span></strong></li>
                </ol>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><em><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Consent</span></em></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>We will process your personal information only with your knowledge and consent, except where exempted, required or permitted by </span><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>applicable laws</span><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>. The form of consent may vary depending on the circumstances and the type of information being requested.&nbsp; Your consent may be express with clear options to say “yes” or “no”, such as by being asked to check a box to indicate your consent, or implied, such as when you provide us with your address through a form or email seeking information and we use those means to respond to your request.&nbsp; Your consent can also be provided by your authorized representative.&nbsp; Taking into account the sensitivity of your personal information, purposes of collection, and your reasonable expectations, we will obtain the form of consent that is appropriate to the personal information being processed.&nbsp; By using our Website, Software, or Service, or otherwise by choosing to provide us with your personal information, you acknowledge and consent to the processing of your personal information in accordance with this Policy and as may be further identified when the personal information is collected.&nbsp; When we process your personal information for a new purpose, we will document that new purpose and ask for your consent again.</span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>If you do not consent to the processing of your personal information in accordance with this Policy, please do not access or continue to use any of the Website, Software or Service or otherwise provide any personal information to us.&nbsp; </span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>You may refuse to provide consent or may notify us at any time that you wish to withdraw or change your consent to the processing of your personal information without penalty, subject to legal or contractual restrictions and reasonable notice by (i) changing your privacy preferences through the Website or Software, (ii) deleting your account with the Website or Software and stopping use of the Website or Software, (iii) opting out of the use of your personal information by contacting our Privacy Officer (see Section 10 below).&nbsp;&nbsp; However, if you withdraw or change your consent, we may not be able to provide you with the Service through the Website or Software.</span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><em><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Other Legal Bases </span></em></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Aside from consent, we may also process your personal information under other legal bases, as permitted by the applicable laws.&nbsp; </span></p>
                <ol style={{ marginBottom: '0cm', marginTop: '16px' }} start={5}>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Security of Personal Information</span></strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}> </span></li>
                </ol>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>The security of your personal information is important to us. We protect personal information using physical, technological and organizational safeguards. We regularly review our practices to ensure they align with reasonable industry practices appropriate to the level of sensitivity to safeguard personal information against loss or theft, unauthorized access, alteration or disclosure.</span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>However, no method of transmission over the Internet, or method of electronic storage, is completely secure; as such, despite our safeguards and protocols, we cannot fully guarantee the security of your personal information and you should always exercise caution when disclosing personal information over the Internet.</span></p>
                <ol style={{ marginBottom: '0cm', marginTop: '16px' }} start={6}>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Requests for Access to and Correction of Personal Information</span></strong></li>
                </ol>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Applicable privacy laws allow, to varying degrees, individuals the right to access and/or request the correction of errors or omissions in his or her personal information that is in our custody or under our control. You may request access to and review of your personal information in our possession. However, access may be declined where permitted or required by applicable law.</span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>You may request that we change or delete your personal information in our possession. We reserve the right not to change any personal information if we do not agree that it is inaccurate or outdated, but will append any alternative text the individual concerned believes appropriate.</span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>If access cannot be provided, we will notify the individual making the request within 30 days, in writing, of the reasons for the refusal. </span></p>
                <ol style={{ marginBottom: '0cm', marginTop: '16px' }} start={7}>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>CASL Policy </span></strong></li>
                </ol>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>We are </span><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>committed to compliance with </span><a target={"_blank"} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} href="http://laws-lois.justice.gc.ca/eng/acts/E-1.6/index.html"><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Canada’s Anti-Spam Legislation</span></a><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}> (“<strong>CASL</strong>”).&nbsp;&nbsp; Any electronic communication we send to outside parties is protected by a range of business procedures, processes and policies to ensure that such communication is done in compliance with CASL. In our electronic communications with outside parties, we comply with the rules established by CASL and enforced by various Canadian authorities including the Canadian Radio-television and Telecommunications Commission. CASL regulates, and our policies generally apply to, each commercial electronic message (a “<strong>CEM</strong>”) that we send. A CEM is an electronic message sent to an electronic address that, among its purposes, encourages participation in a commercial activity.</span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>In addition to adopting and updating this Policy, </span><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>we undertake</span><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}> various transparency initiatives to ensure we comply with CASL, which include:</span></p>
                <ul style={{ marginBottom: '0cm', marginTop: '16px' }}>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Consent</span></strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>— we do not send you CEMs without your consent. This consent typically must be “express” (expressly acknowledged by you), but in certain circumstances can be “implied” or specifically exempt from consent requirements.&nbsp; We modified or adopted our sign-up, registration and consent forms in order to ensure that your consent is meaningful (i.e. informed and freely given) as per CASL.&nbsp; When we collect your electronic contact information, you will know the exact purposes behind the collection.</span></li>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Content</span></strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>— we adopted processes to ensure that our CEMs contain the following requirements prescribed under CASL, which will usually be in the footer of the CEM. We will:</span>
                        <ul style={{ listStyleType: 'circle', marginBottom: '0cm', marginTop: '16px' }}>
                            <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Identify ourselves as the party sending the CEM, and whether we are sending the message on our own behalf or on behalf of someone else;</span></li>
                            <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Provide you with our contact information; and</span></li>
                            <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Set out a clear, working unsubscribe mechanism or preference centre that is easy to use, automatic, and at no cost to you (other than your own cost of connecting to the Internet).</span></li>
                        </ul>
                    </li>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Clarity</span></strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>— we ensured that each aspect of a CEM, including its header, content, or any links or URLs in the CEM) conveys the appropriate information, whether viewed individually or taken as a whole, so that you always know what you are clicking on.</span></li>
                </ul>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>If you receive a CEM from us but believe that you should not have or no longer wish to receive CEMs, we will aim to respect your preferences in a timely manner once you update them through our unsubscribe mechanism. CASL requires us to process unsubscribe requests within 10 business days.&nbsp; If you have any questions or concerns about our unsubscribe options</span><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>, you may contact us at the address indicated in Section </span><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>10</span><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}> below.</span></p>
                <ol style={{ marginBottom: '0cm', marginTop: '16px' }} start={8}>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Retention of Your Personal Information</span></strong></li>
                </ol>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>We generally keep personal information for only as long as it is needed to accomplish the purposes for which it was collected, or as needed for authorized or legitimate purposes.&nbsp; More specifically, we retain personal information as long as necessary for the fulfillment of the identified purposes for its collection or as otherwise necessary to comply with applicable laws or protect our interests. When personal information is no longer necessary or relevant for the identified purposes, or is required to be retained by applicable laws, we will take steps to have it deleted, destroyed, erased, aggregated or made anonymous. We use reasonable industry practices to ensure we have adequate controls, schedules and practices for information and records retention and destruction which apply to personal information. </span></p>
                <ol style={{ marginBottom: '0cm', marginTop: '16px' }} start={9}>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>Updates or Changes to this Policy</span></strong></li>
                </ol>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>This Policy was last updated on February 1, 2023. We will occasionally update this Policy and revise the "last updated" date appearing in this paragraph. </span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>If we make any material changes we will either (a) notify you by email (sent to the email address listed in your account), or (b) provide a notice on the Website or otherwise through the Service before the change becomes effective. Any change to this Policy will apply to existing information, as well as information collected onwards from the date that this Policy is posted or on the date as specified in the notification. We encourage you to periodically review this page for the latest information on our privacy practices to ensure you are aware of any changes.&nbsp; Your continued use of the Service signifies your acceptance of any changes to this Policy.</span></p>
                <ol style={{ marginBottom: '0cm', marginTop: '16px' }} start={10}>
                    <li style={{ margin: '12pt 0cm 0cm 0px', textAlign: 'justify', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><a name="_Ref19785869" target={"_blank"} style={{ cursor: 'pointer' }} /><strong><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif', cursor: 'pointer' }}>Contact Information for Privacy Officer</span></strong></li>
                </ol>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>You can direct any questions or concerns regarding our compliance with this Policy and our processing of your personal information to our Privacy Officer by emailing </span><a target={"_blank"} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} href="mailto:privacy@drugnutritioninteractions.com">privacy@drugnutritioninteractions.com</a><span style={{ color: 'blue', textDecoration: 'underline' }}>. </span><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>If you are not satisfied with our Privacy Officer’s response to your question or concern, you may be able to file a complaint under applicable privacy laws.&nbsp; Our Privacy Officer will provide you with the contact information to do so if requested. We strive to offer an accessible and simple complaint procedure. We will promptly investigate all complaints received, and if a complaint is justified, we will take the necessary steps to resolve the issue in question.</span></p>
                <p style={{ margin: '12pt 0cm 0cm', textAlign: 'justify', fontSize: '12pt', fontFamily: '"Times New Roman", serif' }}><em><span style={{ fontSize: '11.0pt', fontFamily: 'Calibri, sans-serif' }}>End of Policy </span></em></p>
            </div>
        </>
    );
}

export default PrivacyPolicy;