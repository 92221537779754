import React, { useState } from "react";
import PageHeader from "../../../components/PageHeader/PageHeader";
import Table from 'react-bootstrap/Table';
const Usinsuline = () => {
        const [bools, setBools] = useState(false);
        const [heights, setHeights] = useState(600)
               $(document).ready(function () {
                $(".search").keyup(function () {
                        var searchTerm = $(".search").val();
                        if(searchTerm==""){
                                window.location.reload();
                                }
                                
                        var listItem = $('.results tbody').children('tr');
                        var searchSplit = searchTerm.replace(/ /g, "'):containsi('")

                        $.extend($.expr[':'], {
                                'containsi': function (elem, i, match, array) {
                                        return (elem.textContent || elem.innerText || '').toLowerCase().indexOf((match[3] || "").toLowerCase()) >= 0;
                                }
                        });

                        $(".results tbody tr").not(":containsi('" + searchSplit + "')").each(function (e) {
                                $(this).attr('visible', 'false');
                        });

                        $(".results tbody tr:containsi('" + searchSplit + "')").each(function (e) {
                                $(this).attr('visible', 'true');
                        });

                        var jobCount = $('.results tbody tr[visible="true"]').length;
                        $('.counter').text(jobCount + ' item');

                        if (jobCount == '0') { $('.no-result').show(); }
                        else { $('.no-result').hide(); }
                });
        });

        return (
                <div style={{ textAlign: 'initial' }}>
                        {/* <PageHeaderTable style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                                bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                                title="Insulin Products Table (USA)"
                                description={""} /> */}
                        <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                                bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                                title="Insulin Products Table (USA)" />

                        <div className="container mt-1" >
                                <br />
                                {/* <div className="row">
                                        <div className="col-md-4 input-group">
                                                <button id='static-search'><i className="fa fa-search" aria-hidden="true"></i></button> <input type='text' placeholder="Search.." onChange={myFunction} id="myInput" />
                                        </div>
                                </div> */}
                                <div className="form-group pull-left">
                                        <input type="text" className="search form-control" placeholder="Search...." />
                                </div>
                              
                                <Table striped bordered hover className="results" id="table_insulin" style={{ width: "100%" }}>
                                        <thead className="header" >
                                                <tr style={{ height: "40px" }}>
                                                        <td style={{ width: "40%", textAlign: 'center' }}><b>&nbsp;Insulin&nbsp;</b></td>
                                                        <td style={{ width: "15%", textAlign: 'center' }}><b>&nbsp;&nbsp;&nbsp;Brand&nbsp;&nbsp;&nbsp;</b></td>
                                                        <td style={{ width: "15%", textAlign: 'center' }}><b>&nbsp;&nbsp;&nbsp;Onset&nbsp;&nbsp;&nbsp;</b></td>
                                                        <td style={{ width: "10%", textAlign: 'center' }}><b>&nbsp;&nbsp;&nbsp;Peak&nbsp;Effect&nbsp;&nbsp;&nbsp;</b></td>
                                                        <td style={{ width: "10%", textAlign: 'center' }}><b>&nbsp;Duration&nbsp;</b></td>
                                                        <td style={{ width: "100%", textAlign: 'center' }}><b>&nbsp;Analog/Biosimilar&nbsp;</b></td>
                                                </tr>
                                        </thead>
                                        <tbody>
                                                <tr >
                                                        <b>&nbsp;Rapid-acting/Bolus&nbsp;insulin</b>
                                                </tr>
                                                <tr >
                                                       
                                                        <td id="whiteBorder">insulin&nbsp;aspart</td>
                                                        
                                                        <td>Fiasp</td>
                                                        <td>16-22 min</td>
                                                        <td>1.5-2.2 hr</td>
                                                        <td>5-7 hr</td>
                                                        <td>analog†</td>
                                                </tr>
                                                <tr >
                                                <td style={{backgroundColor:"white",color:"white"}} id="whiteBorder">insulin&nbsp;aspart</td>
                                                
                                                        <td>Novolog</td>
                                                        <td>12-18min</td>
                                                        <td>1-3 hr</td>
                                                        <td>3.7.2&nbsp;hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr >
                                                       
                                                        <td>insulin&nbsp;glulisine</td>
                                                        <td>Apidra</td>
                                                        <td> 12-30min</td>
                                                        <td>1.6-2.8hr</td>
                                                        <td>3-4hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr >


                                                        <td id="whiteBorderGray">insulin&nbsp;lispro</td>
                                                        <td>Admelog</td>
                                                        <td>30-45 min</td>
                                                        <td>2.1 hr</td>
                                                        <td>6.9 hr</td>
                                                        <td>biosimilar</td>
                                                </tr>
                                                <tr >
                                                        <td style={{backgroundColor:"white",color:"white"}} id="whiteBorder">insulin&nbsp;lispro</td>
                                                        <td>Humalog</td>
                                                        <td>30-45 min</td>
                                                        <td>0.75-2.5 hr</td>
                                                        <td>3.5-4.75 hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr >
                                                        <td style={{backgroundColor:"white",color:"white"}} id="whiteBorder">insulin&nbsp;lispro</td>
                                                        <td>Lyumjev</td>
                                                        <td>15-30 min</td>
                                                        <td>2-2.9 hr</td>
                                                        <td>4.6-7.3 hr</td>
                                                        <td>biosimilar</td>
                                                </tr>
                                                <tr >
                                                        <td>
                                                                insulin inhalation</td>
                                                        <td>Afrezza</td>
                                                        <td>12 min</td>
                                                        <td>35-55 min</td>
                                                        <td>1.5-4.5 hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr >
                                                       <b><>&nbsp;Short-acting/bolus insulins</></b>
                                                </tr>
                                                <tr >
                                                        <td id="whiteBorder">Insulin regular</td>
                                                        <td>Humulin R</td>
                                                        <td>30 min*</td>
                                                        <td>1.5-3.5 hr*</td>
                                                        <td>8 hr*</td>
                                                        <td></td>
                                                </tr>
                                                <tr >
                                                        <td style={{backgroundColor:"white",color:"white"}} id="whiteBorder">Insulin regular</td>
                                                        <td>Myxredlin</td>
                                                        <td>21 min<sup style={{fontSize:8,fontWeight:'bold'}}>‡</sup></td>
                                                        <td>--</td>
                                                        <td>--</td>
                                                        <td></td>
                                                </tr>
                                                <tr >
                                                        <td style={{backgroundColor:"white",color:"white"}} id="whiteBorder">Insulin regular</td>
                                                        <td>Novolin R</td>
                                                        <td>30 min*</td>
                                                        <td>1.5-3.5 hr*</td>
                                                        <td>8 hr*</td>
                                                        <td></td>
                                                </tr>
                                                <tr></tr>
                                                <tr >
                                                       <b>&nbsp;Intermediate-acting&nbsp;insulin</b>
                                                </tr>
                                                <tr >

                                                        <td id="whiteBorderGray">Insulin NPH</td>

                                                        <td>Humulin N</td>
                                                        <td>1-2&nbsp;hr</td>
                                                        <td>4-12 hr</td>
                                                        <td>14-24 hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr >
                                                        <td style={{backgroundColor:"white",color:"white"}} id="whiteBorderGray">Insulin NPH</td>
                                                        <td>Novolin N</td>
                                                        <td>1-2 hr</td>
                                                        <td>4-12 hr</td>
                                                        <td>14-24 hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr >

                                                        <b>&nbsp;Long-acting/basal&nbsp;insulin</b> 
                                                </tr>
                                                <tr >
                                                        <td>insulin degludec</td>
                                                        <td>Tresiba</td>
                                                        <td>1 hr</td>
                                                        <td>9-12 hr</td>
                                                        <td>42 hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr >
                                                        <td>insulin detemir</td>
                                                        <td>Levemir</td>
                                                        <td>3-4 hr</td>
                                                        <td>3-9 hr</td>
                                                        <td>6-23 hr*</td>
                                                        <td></td>
                                                </tr>



                                                <tr >
                                                        <td  id="whiteBorder">insulin glargine</td>

                                                        <td>Basaglar</td>
                                                        <td>3-4&nbsp;hr</td>
                                                        <td>no peak</td>
                                                        <td>24+ hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr >
                                                        <td style={{backgroundColor:"white",color:"white"}} id="whiteBorder">insulin glargine</td>
                                                        <td>Lantus</td>
                                                        <td>3-4 hr</td>
                                                        <td>no peak</td>
                                                        <td>24+ hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr >
                                                        <td style={{backgroundColor:"white",color:"white"}} id="whiteBorder">insulin glargine</td>
                                                        <td>Toujeo</td>
                                                        <td>6 hr</td>
                                                        <td>no peak</td>
                                                        <td>24+ hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr >
                                                        <td style={{backgroundColor:"white",color:"white"}} id="whiteBorder">insulin glargine</td>
                                                        <td>Rezvoglar</td>
                                                        <td>3-4 hr</td>
                                                        <td>no peak</td>
                                                        <td>24+ hr</td>
                                                        <td>biosimilar</td>
                                                </tr>
                                                <tr >
                                                        <td style={{backgroundColor:"white",color:"white"}} id="whiteBorder">insulin glargine</td>
                                                        <td>Semglee</td>
                                                        <td>3-4 hr</td>
                                                        <td>no peak</td>
                                                        <td>24+ hr</td>
                                                        <td>biosimilar#</td>
                                                </tr>
                                                <tr >

                                                        <b>&nbsp;Pre-mixed&nbsp;insulins</b> 
                                                </tr>
                                                <tr >
                                                        <td>biphasic insulin &nbsp;aspart</td>
                                                        <td>Novolog&nbsp;Mix&nbsp;70/30&nbsp;</td>
                                                        <td>10-20 min</td>
                                                        <td>1.8-3.6 hr</td>
                                                        <td>24 hr</td>
                                                        <td></td>
                                                </tr>


                                                <tr >
                                                        <td id="whiteBorder">biphasic insulin&nbsp;lispro</td>

                                                        <td>Humalog&nbsp;Mix&nbsp;50/50&nbsp;</td>
                                                        <td>15-30 min</td>
                                                        <td>0.8-4.8 hr</td>
                                                        <td>14-24 hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr >
                                                        <td style={{backgroundColor:"white",color:"white"}} id="whiteBorder" >biphasic insulin&nbsp;lispro</td>
                                                        <td>Humalog&nbsp;Mix&nbsp;75/25</td>
                                                        <td>15-30 min</td>
                                                        <td> 1-6.5 hr</td>
                                                        <td>14-24 hr</td>
                                                        <td></td>
                                                </tr>

                                                <tr >
                                                        <td id="whiteBorderGray">biphasic insulin&nbsp;
                                                                NPH/insulin<br />
                                                                regular</td>

                                                        <td>Humulin 70/30</td>
                                                        <td>30 min</td>
                                                        <td>2-12 hr</td>
                                                        <td>18-24 hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr >
                                                        <td style={{backgroundColor:"white",color:"white"}} id="whiteBorderGray">biphasic insulin&nbsp;
                                                                NPH/insulin<br />
                                                                regular</td>
                                                        <td>Novolin 70/30</td>
                                                        <td>30 min</td>
                                                        <td>2-12 hr</td>
                                                        <td>18-24 hr</td>
                                                        <td></td>
                                                </tr>
                                                <tr >

                                                        <b>&nbsp;Basal&nbsp;insulin/GLP-1&nbsp;agonist&nbsp;combination&nbsp;products</b> 
                                                </tr>
                                                <tr >
                                                        <td>insulin degludec/liraglutide</td>
                                                        <td>Xultophy 100/3.6</td>
                                                        <td colSpan={3}>refer to individual agents</td>
                                                        <td></td>
                                                </tr>
                                                <tr >
                                                        <td>insulin glargine/lixisenatide</td>
                                                        <td>Soliqua 100/33</td>
                                                        <td colSpan={3}>refer to individual agents</td>
                                                        <td></td>
                                                </tr>
                                        </tbody>
                                </Table>
                        </div><br />
                       <Table>
                       <div className="container">
                                <h6 style={{ fontSize: 14 }}><b><u>Footnotes:</u></b></h6>
                                <br />
                                <p style={{ fontWeight: '400px', fontSize: 14, color: '#484343' }}>† onset/peak effect occurs earlier in comparative studies<br />
                                        * with subcutaneous administration<br />
                                        ‡ following start of IV infusion<br />
                                        Δ dose-dependent<br />
                                        # Semglee deemed interchangeable with Lantus</p>
                                <br />
                                <p style={{ fontWeight: '400px', fontSize: 14, color: '#484343' }}>As with all insulin therapies, the duration of action may vary in different individuals or in the same
                                        individual according to dose, injection site, blood flow, temperature and level of physical activity.</p>
                                <br />
                                <p style={{ fontSize: 14,fontWeight: '400px',color: '#484343' }}><b>References:</b><br/>
                                UpToDate. Accessed December 21, 2021.<br />
                                Respective US Prescribing Information</p>

                        </div>
                       </Table>
                </div>

        );

}

export default Usinsuline;