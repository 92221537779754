import React from 'react'

function Calculator() {
  return (
    <div>
      <section className="section section-height-800 parallax-container context-dark bg-gray-darkest text-xl-left" data-parallax-img="./images/banner/banner5.jpg">
        <div className="parallax-content">
          <div className="bg-overlay-black">
              <div className="container section-80 section-md-95 section-lg-top-120 section-lg-bottom-150">
                <div className="d-none d-lg-block">
                    <h1>Calculator</h1>
                </div>
                <ul className="list-inline list-inline-dashed list-white text-big p offset-md-top-13">
                    <li><a href="/">Home</a></li>
                    <li>Calculator</li>
                </ul>
              </div>
          </div>
        </div>
      </section>
      <section className="section section-50 section-lg-95 novi-background bg-cover">
        <div className="container">
          <div className="row justify-content-sm-center align-items-center offset-top-40">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="tiles" >
                <a className="tile" href="/"><img src="./images/calculator/bmi.jpg" className='img-fluid' alt="calculator"/>
                  <div className="details">
                    <span className="title mb-3">Body Mass Index Calculator</span>
                    <span className="info">Quisque vel felis lectus donec vitae dapibus magna</span>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="tiles">
                <a className="tile" href="/"><img src="./images/calculator/bmi.png" className='img-fluid' alt="calculator"/>
                  <div className="details">
                    <span className="title mb-3">Segmental Weights Calculator</span>
                    <span className="info">Quisque vel felis lectus donec vitae dapibus magna</span>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="tiles">
                <a className="tile" href="/"><img src="./images/calculator/bmi.png" className='img-fluid' alt="calculator"/>
                  <div className="details">
                    <span className="title mb-3">Ideal Body Weight Calculator</span>
                    <span className="info">Quisque vel felis lectus donec vitae dapibus magna</span>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="tiles">
                <a className="tile" href="/"><img src="./images/calculator/bmi.jpg" className='img-fluid' alt="calculator"/>
                  <div className="details">
                    <span className="title mb-3">Calorie/Fluid/Protein Requirement Calculator</span>
                    <span className="info">Quisque vel felis lectus donec vitae dapibus magna</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Calculator