import React, { useEffect, useState } from 'react'
import firstImg from "../../assets/home/first_slider.jpg";
import SecondImg from "../../assets/home/second_slider.jpg";
import thirdImg from "../../assets/home/third_slider.jpg";



class Swiper extends React.PureComponent {
    constructor(props){
        super(props);
        this.state={
            SwiperData:{}
        }
    }
    componentDidMount(){
        this.setState({SwiperData:this.props});
    }
    
    // const[prop,setProp]=useState("");
    // console.log(props)
    // const reloadCount12 = Number(sessionStorage.getItem('reloadCount12')) || 0;
    // useEffect(()=>{
    //     setProp(props)
    //     if(reloadCount12<1) {
    //         sessionStorage.setItem('reloadCount12', String(reloadCount12 + 1));
    //         // window.location.reload();
    //       } else {
    //         sessionStorage.removeItem('reloadCount12');
    //       }
    // },[prop])
  render(){
    return (
        <div className="swiper-slide" data-slide-bg={this.state.SwiperData.bgImg}>
            <div className="swiper-slide-caption">
                <div className="container">
                    <div className="row row-fix justify-content-sm-center justify-content-lg-start text-lg-left">
                    <div className="col-md-10 col-lg-8 col-xl-5">
                        <div className="text-big-80 font-accent" style={{color:'white'}} data-caption-animate="fadeInDown" data-caption-delay="50" data-caption-duration="700">{this.state.SwiperData.sliderHeading}</div>
                            <p className="text-big font-weight-bold offset-top-13" style={{color:'white'}} data-caption-animate="fadeIn" data-caption-delay="200" data-caption-duration="700">
                                {this.state.SwiperData.sliderTxt}
                            </p>
                            {localStorage.getItem('setUser')!=null?"": <a className="btn btn-white-outline offset-top-20 offset-sm-top-13 offset-lg-top-30" href={this.state.SwiperData.btnHref} data-caption-animate="fadeInUp" data-caption-delay="50" data-caption-duration="700">{this.state.SwiperData.btnText}</a>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
  }
}

function OwlCarousel(props) {
    // debugger
     useEffect(()=>{
        
     },[])
    return (
        <div className="item">
            <p className="text-big font-weight-bold text-primary">{props.heading}</p>
            <p>{props.text}</p>
            <blockquote className="quote text-left offset-top-20 offset-md-top-25">
                <div className="offset-top-20">
                    <p className="font-weight-bold"><a className="link-decoration-none text-hover-primary text-gray" href={props.href}>{props.name}</a></p>
                </div>
                <div className="offset-top-0">
                    <p className="text-gray">{props.designation}</p>
                </div>
            </blockquote>
        </div>
    )
  }

export { Swiper,OwlCarousel };