import React, { useEffect, useState } from 'react'
import CustInputRange from '../../../components/common/CustInputRange/CustInputRange'
import PageHeader from '../../../components/PageHeader/PageHeader'
import { CircleSlider } from "react-circle-slider";
import CustInputRangeHeight2 from '../../../components/common/CustInputRange/CustInputRangeHeight2';
import CustInputRangeWeight2 from '../../../components/common/CustInputRange/CustInputRangeWeight2';
import CustInputRangeProteinRequirement from '../../../components/common/CustInputRange/CustInputRangeProteinRequirement';
import CustInputRangeAge2 from '../../../components/common/CustInputRange/CustInputRangeAge2';

function CalorieReqCalc() {
  // const [sliderValue, setSliderValue] = useState(105);
  const [HiIn, setHiIn] = useState('(in)');
  const [weIn, setweIn] = useState('(lb)');
  const [proteinIn, setProteinIn] = useState('(grams/day)');
  const [ErrMsg, setErrMsg] = useState('');
  const [HeightRange, setHeightRange] = useState("");
  const [WeightRange, setWeightRange] = useState("");
  const [inputValAge, setinputValAge] = useState("");
  const [inputValProtein, setinputValProtein] = useState("");
  const [inputValWeight, setinputValWeight] = useState("");
  const [inputValHeight, setinputValHeight] = useState("");
  const [minValHeight, setMinValHeight] = useState(39);
  const [maxValHeight, setMaxValHeight] = useState(83);
  const [minValWeight, setMinValWeight] = useState(77);
  const [maxValWeight, setMaxWalWeight] = useState(700);
  const [minValProtein, setMinValProtein] = useState(0.8);
  const [maxValProtein, setMaxWalProtein] = useState(2.0);
  const [Tab, setTab] = useState('Imperial');
  const [gender, setGender] = useState("male");
  const [framesize, setFrameSize] = useState("lightly_Active");
  const [calorie, setCalorie] = useState("");
  const [fluid, setFluid] = useState("");
  const [Protein, setProtein] = useState("");


  // const [protein, setProtein] = useState("");

  var cal = "";
  var pro = "";
  var flu = "";
  var fl = "";
  var prot = "";
  var cal1 = "";
  var cal2 = "";
  var cal3 = "";
  var cal4 = "";





  //  let CR = (10*inputValWeight)+(6.25*inputValHeight)-(5*inputValAge-161);

  const onChangeHandler = (e) => {
    setErrMsg("");
    if (e && e.target && e.target.value) {
      if (e.target.name === "gender") {
        setGender(e.target.value)
      }
      if (e.target.name === "framesize") {
        setFrameSize(e.target.value);
      }
      if (e.target.name == "chkPrvCheck") {
        if (e.target.checked == true)
          // setFrameSize(e.target.value)
          setTab('Metric');
        setinputValHeight((inputValHeight * 2.54).toFixed(1));
        setinputValWeight((inputValWeight / 2.22).toFixed(1));
        setHiIn("(cm)");
        setweIn("(kg)");
        setMinValHeight(100);
        setMaxValHeight(210);
        setMinValWeight(35);
        setMaxWalWeight(318);
      }
      if (e.target.checked == false) {
        setTab('Imperial');
        setinputValHeight((inputValHeight / 2.54).toFixed(1));
        setinputValWeight((inputValWeight * 2.22).toFixed(1));
        setHiIn("(in)");
        setweIn("(lb)");
        setMinValHeight(39);
        setMaxValHeight(83);
        setMinValWeight(77);
        setMaxWalWeight(700);
      }
    }
  }
  const calculate = () => {
    setErrMsg("");
    if (Tab == "Imperial") {
      if (inputValWeight == "" && inputValHeight == "" && inputValProtein == "" && inputValAge == "") {
        setErrMsg("Please Enter the weight & height & Protein!...");
        return false;
      }
      if (inputValAge == "") {
        setErrMsg("Please Enter the age");
        return false;
      } if (inputValAge < 18) {
        setErrMsg("Please enter Age value above 18");
        return false;
      } if (inputValAge > 110) {
        setErrMsg("Allowed Only Less than 110");
        return false;

      } if (inputValProtein == "") {
        setErrMsg("Please Enter Protein value");
        return false;
      } if (inputValProtein <= 0.7) {
        setErrMsg("Please enter Protein Requirement value above 0.8 ");
        return false;
      }
      if (inputValWeight == "") {
        setErrMsg("Please Enter the weight , height & Protein !...");
        return false;
      } else if (inputValWeight < 77) {
        setErrMsg("Please Enter Valid Weight!...");
        return false;
      }
      else if (inputValHeight < 39) {
        setErrMsg("Please Enter Valid Height");
        return false;
      }
      pro = (inputValProtein * inputValWeight);
      prot = (pro / 2.22).toFixed(0);
      flu = (30 * inputValWeight)
      fl = (flu / 2.22).toFixed(0);
      setProtein(prot);
      setFluid(fl);
      if (gender == "male") {
        if (framesize == "lightly_Active") {
          // var weight = (inputValWeight/2.2);
          // var height = (inputValHeight/2.54);
          cal = (((10 * inputValWeight / 2.22) + (6.25 * inputValHeight / 2.54) - (5 * inputValAge) + 5) * 1.4).toFixed(0)
          setCalorie(cal);
        } else if (framesize == "moderatelyActive") {
          cal = (((10 * inputValWeight) + (6.25 * inputValHeight) - (5 * inputValAge) + 5) * 1.55).toFixed(0)

          setCalorie(cal);
        } else if (framesize == "active") {
          cal = (((10 * inputValWeight) + (6.25 * inputValHeight) - (5 * inputValAge) + 5) * 1.725).toFixed(0)
          setCalorie(cal);
        } else if (framesize == "veryActive") {
          cal = (((10 * inputValWeight) + (6.25 * inputValHeight) - (5 * inputValAge) + 5) * 1.9).toFixed(0)
          setCalorie(cal);
        }
      } if (gender == "female") {
        if (framesize == "lightly_Active") {
          cal = (((10 * inputValWeight) + (6.25 * inputValHeight) - (5 * inputValAge) - 161) * 1.37).toFixed(0)

          setCalorie(cal);
        } else if (framesize == "moderatelyActive") {
          cal = (((10 * inputValWeight) + (6.25 * inputValHeight) - (5 * inputValAge) - 161) * 1.55).toFixed(0)

          setCalorie(cal);
        } else if (framesize == "active") {
          cal = (((10 * inputValWeight) + (6.25 * inputValHeight) - (5 * inputValAge) - 161) * 1.725).toFixed(0)

          setCalorie(cal);
        } else if (framesize == "veryActive") {
          cal = (((10 * inputValWeight) + (6.25 * inputValHeight) - (5 * inputValAge) - 161) * 1.9).toFixed(0)
          setCalorie(cal);
        }
      }
    } else if (Tab == "Metric") {
      if (inputValWeight == "" && inputValHeight == "" && inputValProtein == "") {
        setErrMsg("Please Enter the Age, weight & height & Protein!...");
        return false;
      } else if (inputValAge == "") {
        setErrMsg("Please Enter the age");
        return false;
      } else if (inputValAge < 18) {
        setErrMsg("Please enter Age value above 18.");
        return false;
      } else if (inputValAge > 110) {
        setErrMsg("Allowed Only Less Than 110");
        return false;
      }
      else if (inputValWeight < 35) {
        setErrMsg("Please enter a valid weight. *");
        return false;
      }
      else if (inputValHeight < 100) {
        setErrMsg("Please enter a valid height. *");
        return false;
      } else if (inputValProtein <= 0.7) {
        setErrMsg("Please enter Protein Requirement value above 0.8");
        return false;
      }
      pro = (inputValProtein * inputValWeight).toFixed(0);
      flu = (30 * inputValWeight).toFixed(0);
      setProtein(pro);
      setFluid(flu);

      if (gender == "male") {
        if (framesize == "lightly_Active") {
          cal = (((10 * inputValWeight) + (6.25 * inputValHeight) - (5 * inputValAge) + 5) * 1.4).toFixed(0)
          setCalorie(cal);
        } else if (framesize == "moderatelyActive") {
          cal = (((10 * inputValWeight) + (6.25 * inputValHeight) - (5 * inputValAge) + 5) * 1.5).toFixed(0)
          setCalorie(cal);
        } else if (framesize == "active") {
          cal = (((10 * inputValWeight) + (6.25 * inputValHeight) - (5 * inputValAge) + 5) * 1.725).toFixed(0)
          setCalorie(cal);
        } else if (framesize == "veryActive") {
          cal = (((10 * inputValWeight) + (6.25 * inputValHeight) - (5 * inputValAge) + 5) * 1.9).toFixed(0)
          setCalorie(cal);
        }
      } else if (gender == "female") {
        if (framesize == "lightly_Active") {
          cal = (((10 * inputValWeight) + (6.25 * inputValHeight) - (5 * inputValAge) - 161) * 1.4).toFixed(0)
          setCalorie(cal);
        } else if (framesize == "moderatelyActive") {
          cal = (((10 * inputValWeight) + (6.25 * inputValHeight) - (5 * inputValAge) - 161) * 1.5).toFixed(0)
          setCalorie(cal);
        } else if (framesize == "active") {
          cal = (((10 * inputValWeight) + (6.25 * inputValHeight) - (5 * inputValAge) - 161) * 1.725).toFixed(0)
          setCalorie(cal);
        } else if (framesize == "veryActive") {
          cal = (((10 * inputValWeight) + (6.25 * inputValHeight) - (5 * inputValAge) - 161) * 1.9).toFixed(0)
          setCalorie(cal);
        }
      }
    }
  }

  // useEffect(() => {

  //   const meta = document.createElement('meta');
  //   meta.name = 'viewport';
  //   meta.content = 'width=device-width, initial-scale=0.5';
  //   const existingMeta = document.querySelector('meta[name="viewport"]');
  //   if (existingMeta) {
  //     existingMeta.remove();
  //   }


  //   document.head.appendChild(meta);


  //   return () => {
  //     meta.remove();
  //   };
  // }, []);


  return (
    <div>
      <PageHeader style={{ marginTop: '30px', height: '500px' }}
        bgimg="https://img.freepik.com/free-photo/adult-child-holding-kidney-shaped-paper-textured-blue-background-world-kidney-day-national-organ-donor-day-charity-donation-concept_49149-1211.jpg"
        title="Protein/Calorie/Fluid Requirement Calculator"
      // description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."} 
      />

      <section className="section section-50 section-lg-95 novi-background bg-cover">
        <div className='container' style={{ marginTop: '-120px' }}>
          <div className="row offset-top-40 card-bs">
            <div className='col-lg-6 text-left p-5 d-flex flex-column justify-content-between'>

              <div className='row mb-3 pt-3 border-bottom'>
                <div className='col-12'>
                  <div className="custom-control mb-3 pl-0">
                    <h6 className='d-inline-block pr-2' value="Imperial" name="tab">Imperial</h6>
                    <h6 className='d-inline-block custom-switch'>
                      <input type="checkbox" className="custom-control-input" name="chkPrvCheck" id="chkPrv" onChange={onChangeHandler} />
                      <label className="custom-control-label" htmlFor="chkPrv">&nbsp;</label>
                    </h6>
                    <h6 className='d-inline-block' value="Metric" name="tab1">Metric</h6>
                  </div>
                </div>
              </div>

              <fieldset className='py-2 mb-3'>

                <CustInputRangeAge2 label={HiIn} Tab={Tab} setTab={setTab} inputValAge={(inputValAge)} setinputValAge={setinputValAge} minVal={18} maxVal={110} ErSms={setErrMsg} calorie={setCalorie} fluid={setFluid} stepsVal={1} />
                <CustInputRangeHeight2 label={HiIn} Tab={Tab} setTab={setTab} inputValHeight={(inputValHeight)} setInputValHeight={setinputValHeight} minValHeight={minValHeight} maxValHeight={maxValHeight} stepsVal={0.1} ErSms={setErrMsg} calorie={setCalorie} fluid={setFluid} />
                <CustInputRangeWeight2 label={weIn} Tab={Tab} setTab={setTab} inputValWeight={(inputValWeight)} setinputValWeight={setinputValWeight} minVal={minValWeight} maxVal={maxValWeight} stepsVal={0.1} ErSms={setErrMsg} calorie={setCalorie} />
                <CustInputRangeProteinRequirement label={proteinIn} Tab={Tab} setTab={setTab} inputValProtein={inputValProtein} setinputValProtein={setinputValProtein} minVal={minValProtein} maxVal={maxValProtein} stepsVal={0.1} ErSms={setErrMsg} />
              </fieldset>
              <fieldset className='p-2 mb-3 bg-light'>
                <div className="row">
                  <div className='col-3'>
                    <label className='col-form-label font-weight-bold'>Gender</label>
                  </div>

                  <div className="col-9 pt-2">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="gender" id="male" value="male" onChange={onChangeHandler} ErSms={setErrMsg} defaultChecked="true" />
                      <label className="form-check-label" htmlFor="male">Male</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="gender" id="female" value="female" onChange={onChangeHandler} ErSms={setErrMsg} />
                      <label className="form-check-label" htmlFor="female">Female</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className='col-3'>
                    <label className='col-form-label font-weight-bold'>Activity Level?</label>
                  </div>
                  <div className='col-9 pt-2'>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="framesize" id="lightly_Active" value="lightly_Active" onChange={onChangeHandler} defaultChecked="true" />
                      <label className="form-check-label" htmlFor="small">Light</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="framesize" id="moderatelyActive" value="moderatelyActive" onChange={onChangeHandler} />
                      <label className="form-check-label" htmlFor="medium">Moderate</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="framesize" id="active" value="active" onChange={onChangeHandler} />
                      <label className="form-check-label" htmlFor="active">Active</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="framesize" id="veryActive" value="veryActive" onChange={onChangeHandler} />
                      <label className="form-check-label" htmlFor="very_active">Very Active</label>
                    </div>
                  </div>
                </div>
              </fieldset>

              <div className='row'>
                <div className='col text-lg-left text-center'>
                  <div style={{ color: 'red' }}> {ErrMsg} </div>
                  <button className='btn btn-primary' onClick={calculate}>Calculate</button>
                </div>
              </div>
            </div>




            <div className='col-lg-6 p-5 d-flex align-items-center' style={{ background: "#a6c550" }}>
              <div className='col-12'>
                <h4 className='display-4'><span className='badge sliderValue mb-3 text-white' style={{ background: "#a6c550", lineHeight: "30px" }}>Protein Requirement</span></h4>
                <h2 className='text-primary'><span className='badge bg-white py-2 px-3' style={{ background: "#a6c550", lineHeight: "30px" }}>{Protein == "" ? 0 : Protein} grams/day</span></h2>
                <h4 className='display-4'><span className='badge sliderValue mb-3 text-white' style={{ background: "#a6c550", lineHeight: "30px" }}>Calorie Requirement</span></h4>
                <h2 className='text-primary'><span className='badge bg-white py-2 px-3' style={{ background: "#a6c550", lineHeight: "30px" }}>{calorie > 0 ? calorie : 0} kCal/day</span></h2>
                <h4 className='display-4'><span className='badge sliderValue mb-3 text-white' style={{ background: "#a6c550", lineHeight: "30px" }}>Fluid Requirement</span></h4>
                <h2 className='text-primary'><span className='badge bg-white py-2 px-3' style={{ background: "#a6c550", lineHeight: "30px" }}>{fluid == "" ? 0 : fluid} mL/day</span></h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div><b>References: </b>Mifflin MD, St Jeor ST, Hill LA, Scott BJ, Daugherty SA, Koh YO. A new predictive equation for resting energy expenditure in healthy individuals. Am J Clin Nutr. 1990;51(2):241-7. </div> */}
      <div style={{ marginTop: '-46px', color: '#484343' }}><b>Reference: </b><span><a target="_blank" href=" https://pubmed.ncbi.nlm.nih.gov/2305711/">Mifflin MD, St Jeor ST, Hill LA, Scott BJ, Daugherty SA, Koh YO. A new predictive equation for resting energy expenditure in healthy individuals. Am J Clin Nutr. 1990;51(2):241-7. </a></span> </div>
    </div>
  )
}

export default CalorieReqCalc