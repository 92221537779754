// import React from "react";
// import PageHeaderTable from "../../../components/PageHeader/headertable";
// import PageHeader from "../../../components/PageHeader/PageHeader";
// const Caffeine = () => {

//    return (
//       <div style={{ textAlign: 'initial' }}>

//              <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
//                     bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
//                     title="Caffeine&nbsp;Content&nbsp;Of&nbsp;Foods&nbsp;And&nbsp;Beverages"  />

//          <div className="container mt-4">
//             {/* <p style={{ fontWeight: '700px', fontSize: 18, color: '#484343' }}><b>CAFFEINE CONTENT OF FOODS AND BEVERAGES</b></p> */}
//             {/* <div className="table-responsive"> */}
//             <table border="1%" width="490" align="center" height = "20" >
//  <tr>  
//                   <td style={{color: '#484343' }}>&nbsp;<b>Food Item</b></td>
//                   <td style={{color: '#484343' }}>&nbsp;<b>Caffeine&nbsp;content<br/>&nbsp;(mg&nbsp;range)</b></td>
//                </tr>
//                {/* <tr style={{borderBottom:0,borderTop:0,borderRight:0,borderLeft:0}}> */}
//                   <tr style={{color: '#484343' }}>&nbsp;<b>Coffee (5 ounce cup)</b></tr>
//                   {/* <td><b></b></td>
//                </tr> */}

//                <tr style={{color: '#484343' }}>
//                   <td >&nbsp;Brewed: drip</td>
//                   <td >&nbsp;70-130</td>
//                </tr>
//                <tr style={{color: '#484343' }}>
//                   <td>&nbsp;Decaffeinated</td>
//                   <td> &nbsp;2-3</td>
//                </tr>
//                <tr style={{color: '#484343' }}>
//                   <td>&nbsp;Starbucks Coffee Frappuccino</td>
//                   <td> &nbsp;82</td>
//                </tr>

//                <tr style={{color: '#484343' }}>
//                <b>&nbsp;Tea/Tea products</b>

//                </tr>
//                <tr>
//                   <td style={{color: '#484343' }}>&nbsp;Iced Tea</td>
//                   <td style={{color: '#484343' }}> &nbsp;65-75</td>
//                </tr>
//                <tr> 
//                   <td style={{color: '#484343' }}>&nbsp;nstant (5 ounce cup)</td>
//                   <td style={{color: '#484343' }}> &nbsp;25-35</td>
//                </tr>
//                <tr>
//                   <td style={{color: '#484343' }}>&nbsp;Leaf or Bag</td>
//                   <td style={{color: '#484343' }}>&nbsp;30-50</td>
//                </tr>
//                {/* <tr>
//                   <td>&nbsp;</td>
//                   <td>&nbsp;</td>
//                </tr> */}
//                {/* <tr>
//                   <td>&nbsp;</td>
//                   <td>&nbsp;</td>
//                </tr> */}
//                <tr style={{color: '#484343' }}>
//                   &nbsp;<b>Energy Drinks</b>

//                </tr>
//                <tr>
//                   <td style={{color: '#484343' }}>&nbsp;SoBe Power/Energy/
//                      &nbsp;Adrenaline Rush (16 ounce)</td>
//                   <td style={{color: '#484343' }}> &nbsp;86-115</td>
//                </tr>
//                <tr>
//                   <td style={{color: '#484343' }}>&nbsp;SoBe Power, KMX, Red Devil, Glaceau
//                      &nbsp;Vitamin Water</td>
//                   <td style={{color: '#484343' }}> &nbsp;32-48</td>
//                </tr>
//                <tr >
//                   <td style={{color: '#484343' }}>&nbsp;Red Bull, E Maxx, SoBe No Fear, Monster
//                      &nbsp;Energy</td>
//                   <td style={{color: '#484343' }}> &nbsp;64-80</td>
//                </tr>
//                <tr >
//                   <td style={{color: '#484343' }}>&nbsp;Rip It</td>
//                   <td style={{color: '#484343' }}> &nbsp;100</td>
//                </tr>
//              <tr>
//                   <td style={{color: '#484343' }}><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Spike Shooter</p></td>
//                   <td style={{color: '#484343' }}><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;300</p></td>
//                </tr>
//                {/* <tr style={{borderBottom:0,borderTop:0,borderRight:0,borderLeft:0}}> */}
//                   <tr><p style={{ fontWeight: '500px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.5}}><b>&nbsp;Soft Drinks (12 ounces)
//                      (diet or regular)</b></p></tr>
//                   {/* <td><p> &nbsp;</p></td> */}
//                {/* </tr> */}
//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Jolt Cola</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;72 mg</p></td>
//                </tr>
//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Diet Pepsi Max</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;69</p></td>
//                </tr>
//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Mountain Dew Live Wire</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;48.2</p></td>
//                </tr>
//                <tr >
//                   <td style={{color: '#484343' }}>&nbsp;Mello Yello, Mountain Dew, Coca Cola, Dr
//                      &nbsp;Pepper, Mr. &nbsp;Pibb, &nbsp;Pibb Xtra, &nbsp;<br/>
//                    &nbsp;Nitro Water,
//                      &nbsp;RC Cola, 
//                      &nbsp;Shasta Cola, Pepsi One, Slice
//                      &nbsp;(Orange), Water Joe</td>
//                   <td style={{color: '#484343' }}> &nbsp;33-48</td>
//                </tr>
//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Big Red, Pepsi Cola</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;27</p></td>
//                </tr>
//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Celeste Cola</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;18-20</p></td>
//                </tr>
//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8 }}>&nbsp;Caffeine-Free Diet Coke, club soda,
//                      <br/>&nbsp;Gatorade, Sierra Mist Sprite, Fanta (all
//                      &nbsp;flavors),<br/>&nbsp;Fresca, PowerAde, ginger ale,<br />
//                      &nbsp;root beer, 7-Up, tonic water, seltzer,
//                      &nbsp;sparkling water</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8 }}> &nbsp;0</p></td>
//                </tr>
//                {/* <tr style={{borderBottom:0,borderTop:0,borderRight:0,borderLeft:0}}> */}
//                   <tr><p style={{ fontWeight: '500px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.5}}><b>&nbsp;Chocolate or Coffee Products</b></p></tr>
//                   {/* <td><p> &nbsp;</p></td> */}
//                {/* </tr> */}
//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Cocoa (5 ounces)</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;2-15</p></td>
//                </tr>
//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Cocoa, dry (1 ounce)</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;6</p></td>
//                </tr>
//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Chocolate milk (8 ounce)</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;8</p></td>
//                </tr>
//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Milk Chocolate (1 ounce)</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;1-15</p></td>
//                </tr>


//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Dark chocolate, semi-sweet (1 ounce)</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;5-35</p></td>
//                </tr>
//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Baking chocolate (1 ounce)</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;57</p></td>
//                </tr>
//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Coffee or chocolate enteral products</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;2-10</p></td>
//                </tr>
//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Chocolate flavored syrup (2 T)</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;5</p></td>
//                </tr>





//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Chocolate malted-milk powder (3 T)</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;8</p></td>
//                </tr>
//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Chocolate chips, semi-sweet (2 ounces)</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;12-15</p></td>
//                </tr>
//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Chocolate pudding (5 ounce can)</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;7</p></td>
//                </tr>
//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Chocolate ice cream (1 cup)</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;4-8</p></td>
//                </tr>




//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Chocolate cereal (cold) 100 grams</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;1-11</p></td>
//                </tr>
//              <tr>
//                   <td style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Coffee ice cream</td>
//                   <td style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}><p > &nbsp;30-60</p></td>
//                </tr>
//              <tr>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}>&nbsp;Dannon coffee yogurt (1 cup)</p></td>
//                   <td><p style={{ fontWeight: '400px', fontSize: 14, color: '#484343',paddingTop: 8,lineHeight:0.1}}> &nbsp;45</p></td>
//                </tr>
//                {/* <tr>
//                   <td>&nbsp;</td>
//                   <td>&nbsp;</td>
//                </tr> */}
//             </table>
//             <br/>
//             <div className="row">
//                <div className="col-md-3"></div>
//                <div className="col-md-5">
//                <br />
//                <p style={{ marginTop: "-20px",marginLeft:-310,fontWeight: '400px', fontSize: 14, color: '#484343' }}><b>&nbsp;References: </b><br />&nbsp;
//                   <a target={"_blank"} href="https://www.cspinet.org/caffeine-chart">https://www.cspinet.org/caffeine-chart</a>
//                   <br />
//                   &nbsp;
//                   <a target={"_blank"} href="https://www.caffeineinformer.com/">https://www.caffeineinformer.com/</a>
//                </p>
//                </div>
//                <div className="col-md-4"></div>
//             </div>
//          </div>
//       </div>
//       // </div>
//    );

// }

// export default Caffeine;
import React from "react";
import PageHeaderTable from "../../../components/PageHeader/headertable";
import PageHeader from "../../../components/PageHeader/PageHeader";
import Table from 'react-bootstrap/Table';
const Caffeine = () => {

   return (

      <div style={{ textAlign: 'initial' }}>

         <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
            bgimg="https:https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
            title="Caffeine Content of Food & Beverages" />


         <Table striped bordered hover style={{ width: '40%', margin: 'auto', marginTop: 20 }}>
            {/* <tbody>
        
      </tbody> */}
            <tbody>
               <tr>
                  <td><b>Food Item</b></td>
                  <td><b>Caffeine&nbsp;content</b></td>
               </tr>
               <tr>
                  <td><b>Coffee (5 ounce cup)</b></td>
                  <td></td>
               </tr>
               <tr>
                  <td>Brewed: drip</td>
                  <td> 70-130</td>

               </tr>
               <tr>
                  <td>Decaffeinated</td>
                  <td>2-3</td>
               </tr>
               <tr>
                  <td>Starbucks Coffee Frappuccino</td>
                  <td>82</td>
               </tr>
               <tr>
                  <td><b>Tea/Tea&nbsp;products</b></td>
                  <td></td>
               </tr>

               <tr>
                  <td>Iced Tea</td>
                  <td>65-75</td>
               </tr>
               <tr>
                  <td>Instant (5 ounce cup)</td>
                  <td>25-35</td>
               </tr>
               <tr>
                  <td>Leaf or Bag</td>
                  <td>30-50</td>
               </tr>
               <tr>
                  <td><b>Energy Drinks</b></td>
                  <td></td>
               </tr>

               <tr>
                  <td>SoBe Power/Energy/  Adrenaline Rush (16 ounce)</td>
                  <td>86-115</td>
               </tr>
               <tr>
                  <td>SoBe Power, KMX, Red Devil, Glaceau  Vitamin Water</td>
                  <td>32-48</td>
               </tr>
               <tr>
                  <td>Red Bull, E Maxx, SoBe No Fear, Monster  Energy</td>
                  <td>64-80</td>
               </tr>
               <tr>
                  <td>Rip It</td>
                  <td>100</td>
               </tr>
               <tr>
                  <td>Spike Shooter</td>
                  <td>300</td>
               </tr>
               <tr>
                  <td>
                     <b> Soft Drinks (12 ounces) (diet or regular)</b>
                  </td>
                  <td></td>
               </tr>
               <tr>
                  <td>Jolt Cola</td>
                  <td>72 mg</td>
               </tr>
               <tr>
                  <td>Diet Pepsi Max</td>
                  <td> 69</td>
               </tr>
               <tr>
                  <td>Mountain Dew Live Wire</td>
                  <td> 48.2</td>
               </tr>
               <tr>
                  <td >Mello Yello, Mountain Dew, Coca Cola, Dr
                     Pepper, <br />Mr. Pibb, Pibb Xtra,
                     Nitro Water,
                     RC Cola,
                     Shasta Cola, <br />Pepsi One, Slice
                     (Orange), Water Joe</td>
                  <td > 33-48</td>
               </tr>
               <tr>
                  <td>Big Red, Pepsi Cola</td>
                  <td> 27</td>
               </tr>
               <tr>
                  <td>Celeste Cola</td>
                  <td> 18-20</td>
               </tr>

               <tr>
                  <td >
                     Caffeine-Free Diet Coke, club soda,
                     Gatorade,Sierra Mist Sprite, <br />Fanta(all
                     flavors),Fresca, PowerAde, ginger ale,
                     root beer,<br />7-Up, tonic water, seltzer,
                     sparkling water
                  </td>
                  <td > 0</td>
               </tr>
               <tr>

                  <td><b>Chocolate or Coffee Products</b></td>
                  <td></td>
               </tr>

               <tr>
                  <td>Cocoa (5 ounces)</td>
                  <td> 2-15</td>
               </tr>
               <tr>
                  <td>Cocoa, dry (1 ounce)</td>
                  <td> 6</td>
               </tr>
               <tr>
                  <td>Chocolate milk (8 ounce)</td>
                  <td> 8</td>
               </tr>
               <tr>
                  <td>Milk Chocolate (1 ounce)</td>
                  <td> 1-15</td>
               </tr>


               <tr>
                  <td>Dark chocolate, semi-sweet (1 ounce)</td>
                  <td> 5-35</td>
               </tr>
               <tr>
                  <td>Baking chocolate (1 ounce)</td>
                  <td> 57</td>
               </tr>
               <tr>
                  <td>Coffee or chocolate enteral products</td>
                  <td> 2-10</td>
               </tr>
               <tr>
                  <td>Chocolate flavored syrup (2 T)</td>
                  <td> 5</td>
               </tr>





               <tr>
                  <td>Chocolate malted-milk powder (3 T)</td>
                  <td> 8</td>
               </tr>
               <tr>
                  <td>Chocolate chips, semi-sweet (2 ounces)</td>
                  <td> 12-15</td>
               </tr>
               <tr>
                  <td>Chocolate pudding (5 ounce can)</td>
                  <td> 7</td>
               </tr>
               <tr>
                  <td>Chocolate ice cream (1 cup)</td>
                  <td> 4-8</td>
               </tr>




               <tr>
                  <td>Chocolate cereal (cold) 100 grams</td>
                  <td> 1-11</td>
               </tr>
               <tr>
                  <td >Coffee ice cream</td>
                  <td > 30-60</td>
               </tr>
               <tr>
                  <td>Dannon coffee yogurt (1 cup)</td>
                  <td> 45</td>
               </tr>
            </tbody>

         </Table>
         <Table>
            <div>
               <p style={{ marginLeft: 150 }}>&nbsp;&nbsp;References:<br />
                  <a target={"_blank"} href="https://www.cspinet.org/caffeine-chart">&nbsp;&nbsp;https://www.cspinet.org/caffeine-chart</a> <br />
                  <a target={"_blank"} href="https://www.caffeineinformer.com/">&nbsp;&nbsp;https://www.caffeineinformer.com/</a></p>
            </div>
         </Table>
      </div>



   );

}

export default Caffeine;