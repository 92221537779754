import React from "react"
import './userguide.css'
import logo from '../../assets/images/logo/dni-logo.png'
import rowimg from '../../assets/images/custup.png'
import banimg from '../../assets/images/ban-img.png'
import ViewCustom1 from "../../assets/images/cust2.png"
import ViewCustom2 from "../../assets/images/cusss.png"
import danger from '../../assets/images/danger.png'
import { SEARCH_API } from "../../services/Api/searching"
import html2pdf from "html2pdf-fix-jspdf";
import moment from "moment"
import BackIcon from '../../assets/images/previousBtn.png'



class UsersGuide extends React.Component {
    constructor(pops) {
        super();
        this.state = {
            userInfo: "",
            "PharmClassImg": "",
            "TheraClassImg": "",
            "RoutesImg": "",
            "DoseFormsImg": "",
            "HowtoTakeImg": "",
            "DietImg": "",
            "GrapefruitInteractionsImg": "",
            "AlcoholInteractionsImg": "",
            "NaturalproductinteractionsImg": "",
            "WarningsImg": "",
            "RenalwarningsImg": "",
            "ProteinbindingImg": "",
            "DialysiswarningsImg": "",
            "HepaticwarningsImg": "",
            "PregnancywarningsImg": "",
            "BreastfeedingWarningsImg": "",
            "OralAdverseEffectsImg": "",
            "GIAdverseEffectsImg": "",
            "NutritionalAdverseEffectsImg": "",
            "OtherAdverseEffectsImg": "",
            "LabParametersImg": "",
            "MonitoringImg": "",
            "CrossReferenceImg": "",
            "CitationsImg": "",
            "UsesImg": '',
            'LactationImg': '',
            'AdverseImg': '',
            iconListData: '',
            "otherandlabImg": ''
        }
    }

    componentDidMount() {
        if (localStorage.getItem('setUser') != null) {
            let user = localStorage.getItem("setUser");
            user = JSON.parse(user);
            this.setState({ userInfo: user?.userInfo });
            this.getIconList();
        }
    }
    getIconList() {
        SEARCH_API?.getIcons().then(res => {
            if (res && res?.data) {
                this.setState({ iconListData: res?.data }, () => {
                    this?.setState({ "PharmClassImg": this.state.iconListData?.filter(fl => fl?.body_system == "Pharm Class") })
                    this?.setState({ "TheraClassImg": this.state.iconListData?.filter(fl => fl?.body_system == "Thera Class") })
                    this?.setState({ "RoutesImg": this.state.iconListData?.filter(fl => fl?.body_system == "Routes") })
                    this?.setState({ "UsesImg": this.state.iconListData?.filter(fl => fl?.body_system == "Uses") })
                    this?.setState({ "HowtoTakeImg": this.state.iconListData?.filter(fl => fl?.body_system == "How to Take") })
                    this?.setState({ "DietImg": this.state.iconListData?.filter(fl => fl?.body_system == "Diet") })
                    this?.setState({ "GrapefruitInteractionsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Grapefruit Interactions") })
                    this?.setState({ "AlcoholInteractionsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Alcohol Interactions") })
                    this?.setState({ "NaturalproductinteractionsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Natural product interactions") })
                    this?.setState({ "WarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Warnings") })
                    this?.setState({ "DoseFormsImg": this.state.iconListData?.filter(fl => fl?.body_system == "DoseForms") })
                    this?.setState({ "RenalwarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Renal warnings") })
                    this?.setState({ "ProteinbindingImg": this.state.iconListData?.filter(fl => fl?.body_system == "Protein binding") })
                    this?.setState({ "DialysiswarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Dialysis warnings") })
                    this?.setState({ "HepaticwarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Hepatic warnings") })
                    this?.setState({ "PregnancywarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Pregnancy warnings") })
                    this?.setState({ "BreastfeedingWarningsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Breastfeeding Warnings") })
                    this?.setState({ "OralAdverseEffectsImg": this.state.iconListData?.filter(fl => fl?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Oral Adverse Effects" : "Oral Adverse Effects")) })
                    this?.setState({ "GIAdverseEffectsImg": this.state.iconListData?.filter(fl => fl?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust GI Adverse Effects" : 'GI Adverse Effects')) })
                    this?.setState({ "NutritionalAdverseEffectsImg": this.state.iconListData?.filter(fl => fl?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Nutritional Adverse Effects" : 'Nutritional Adverse Effects')) })
                    this?.setState({ "OtherAdverseEffectsImg": this.state.iconListData?.filter(fl => fl?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Other Adverse Effects" : "Other Adverse Effects")) })
                    this?.setState({ "LabParametersImg": this.state.iconListData?.filter(fl => fl?.body_system == (this.state.getGenericDetails?.placebo_adj == 1 ? "Adjust Lab Parameters" : 'Lab Parameters')) })
                    this?.setState({ "MonitoringImg": this.state.iconListData?.filter(fl => fl?.body_system == "Monitoring") })
                    this?.setState({ "CrossReferenceImg": this.state.iconListData?.filter(fl => fl?.body_system == "Cross Reference") })
                    this?.setState({ "CitationsImg": this.state.iconListData?.filter(fl => fl?.body_system == "Citations") })

                    this?.setState({ "otherandlabImg": this.state.iconListData?.filter(fl => fl?.body_system == "Adjust Oral Adverse Effects") })
                });
            }
        })
    }

    download() {

        setTimeout(() => {
            setTimeout(() => {
                let dt = moment(new Date()).format('DD-MMM-YYYY');
                let dtArr = dt.split('-');
                let finalDate = dtArr[0] + dtArr[1].toLocaleUpperCase() + dtArr[2];
                let fname = `DNI-${this.state.userInfo.country_view_id == 2 ? "Canada" : "USA"}-Guide${finalDate}`
                let element = document.getElementById('Guide');
                // let a= document.getElementById('Guide-download');
                //     a.remove();
                // console.log(element);
                // return
                const opt = {
                    jsPDF: {
                        unit: "mm",
                        format: "A4",
                        orientation: "portrait",
                        compress: true,
                    },
                    html2canvas: {
                        scale: 3,
                        useCORS: true,
                    },
                    image: { type: "png" },
                    margin: 2,
                    filename: fname,
                    pagebreak: { mode: ["avoid-all", "css"] },
                };

                html2pdf()
                    .set(opt)
                    .from(element)
                    .save()
                    .then(() => {
                        $(".card").css(
                            "box-shadow",
                            "0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12)"
                        );
                    });
            }, 1);
        }, 2000);
        setTimeout(() => {
            document.getElementById('Guide-download') ? document.getElementById('Guide-download').style.display = "block" : '';
        }, 3000);
    }

    render() {
        return <React.Fragment>
            <>
                <section className="section search-bg2 section-height-800 parallax-container context-dark bg-gray-darkest text-xl-left" data-parallax-img="./images/home/resized/about-news.jpg">
                    <div className="parallax-content">
                        <div className="bg-overlay-black">
                            <div className="container section-80 section-md-30 section-lg-top-10 section-lg-bottom-30" style={{ paddingTop: 6 }}>
                                <div className="d-none d-lg-block">
                                    <h1 className='mt-4'>DNI User Guide</h1>
                                </div>
                                <div className='d-flex d-lg-block'>
                                    <img src={BackIcon} alt="Back" className="d-lg-none d-md-none" onClick={() => { window.location.href = '/#/' }} style={{ width: '8%', height: "8%", marginTop: '97px' }} />
                                    {/* <ul className="list-inline list-inline-dashed list-white text-big p offset-md-top-8">
                    <li>Learn More about the DNI team</li>
                
                </ul> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <div className="text-left mt-5">
                    <div className="container">
                        <div className="row" style={{ fontSize: 18 }}>
                            <div className="col-md-9 " style={{ margin: 'auto' }}>
                                <button className="btn btn-primary " id="Guide-download"
                                    onClick={this.download.bind(this)}
                                >Download {this.state.userInfo?.country_view_id == 2 ? "Canada" : "USA"} guide </button>
                                <div className="col-md-12" id="Guide">
                                    <img className="head-logo mt-2" src={logo} />
                                    <div className="main-heading pt-2 pb-3">Display Guide and Tutorial</div>
                                    <div className="sub-heading pb-3">DNI is your at-a-glance guide to making sense of the nutritional impact of medications.<br />For greatest efficiency, review this guide to the icons and display cues built into the DNI site.</div>
                                    <div className="row" style={{ borderTop: "1px solid #000" }}>
                                        <div className="col-2 border-tab-1 text-center">
                                            <div className="first-line py-2">Icon</div>
                                        </div>
                                        <div className="col-10 border-tab">
                                            <div className="first-line py-2">Description and Display Interpretation</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <div className="py-2">▲, ▼</div>
                                        </div>
                                        <div className="col-10   border-tab">
                                            <div className="py-2"><b>Increase, Decrease</b> (adverse effect or lab parameter)</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <div className="py-2"><b>+, -</b></div>
                                        </div>
                                        <div className="col-10   border-tab">
                                            <div className="py-2"><b>Positive, Negative</b> (lab parameter)</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 border-tab">
                                            <div className="py-2"><strong>View Customization</strong></div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2 border-tab-1 text-center">
                                            <img className="py-3" src={ViewCustom1} />
                                            <b>or</b>
                                            <img className="py-3" src={ViewCustom2} width="200" />
                                        </div>
                                        <div className="col-10 border-tab">
                                            <div className="pt-3">From the <b>Customize my View</b> button on the drug detail screen or the <b>Hi, {this.state.userInfo?.first_name} </b> top level menu, under <a href="#/account/view_customization" target="_blank" id="user_guide_link">View Customization</a>,  you can hide the display of certain sections if you consistently do not use them (e.g. pregnancy and breastfeeding information may be less relevant to subscribers only working in geriatric care).</div>
                                            <div className="py-2 text-center">
                                                <img style={{ width: '36%' }} src={banimg} />
                                            </div>
                                            <div className="py-2">For the adverse effect and lab parameters sections, click the section name to toggle between selections of <b>Show All, Common</b> or <b>Hide</b> the adverse effects or lab parameters when viewing a drug. The rate at which an adverse effect or lab parameter is considered ‘common’ in DNI varies by drug to allow for different styles of adverse effect listings (placebo adjusted vs. unadjusted rates) and by therapeutic categories (e.g. ‘common’ rates for oncology drugs are typically higher than other drugs). Press the <b>Update</b> button to confirm your customization selections.</div><br />
                                            <div className="pb-3"><b>Important</b>: Any content you choose to hide is always easily available with a single click/tap on the   icon that appears at the end of each hidden section. View customization settings are retained by DNI for your account and automatically applied at future logins (however, clearing your browser cookies resets customization settings to default setting of <b>Show All</b>).</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 border-tab text-center">
                                            <img className="py-2" style={{ width: '69%' }} src={rowimg} />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                        </div>
                                        <div className="col-10   border-tab">
                                            <div className="py-2">On the view customization screen you may <b>change your country selection</b> (USA or Canada) to display the available drugs and relevant product labelling specific to each country. Press the <b>Update</b> button to confirm your country selection. Your country setting is retained by DNI and applied at future logins. (Note: clearing browser cookies may reset your country view settings).</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.UsesImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="border-btm px-3 py-2"><b>Indications/Uses</b></div>
                                            <div className="px-3 py-2">Labelled indications/uses with regulatory approval for the selected drug in your selected country appear <b>CAPITALIZED</b> in this section.</div>
                                            <div className="px-3">Indications/uses of a selected drug that are common or notable but not approved (i.e. off-label) in your selected country appear in this section in <b>lowercase.</b></div><br />
                                            <div className="px-3 pb-3">e.g. <b>TREATMENT OF GERD</b> (labelled indication)<br />
                                                <b>treatment of erosive esophagitis </b> (unapproved/off-label use)</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.PharmClassImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="border-btm px-3 py-2"><strong>Pharmacological Class</strong></div>
                                            <div className="pt-3 pb-2 px-3">The structural and/or functional class(es) of the selected drug</div>
                                            <div className="px-3 pb-3">e.g. ACE inhibitor, protease inhibitor, anthracycline</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.TheraClassImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="border-btm px-3 py-2"><strong>Therapeutic Category</strong></div>
                                            <div className="pt-3 pb-2 px-3">The therapeutic class(es) of the selected drug</div>
                                            <div className="px-3 pb-3">e.g. antihypertensive, HIV treatment, breast cancer treatment</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.RoutesImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="border-btm px-3 py-2"><strong>Route(s) of Administration</strong></div>
                                            <div className="pt-3 pb-2 px-3">The different ways the selected drug can be administered. Routes listed may not be available or approved for use with each dosage form or in your selected country.</div>
                                            <div className="px-3 pb-3">e.g. intravenous, oral, transdermal</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.DoseFormsImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="border-btm px-3 py-2"><strong>Dose Form(s)</strong></div>
                                            <div className="pt-3 pb-2 px-3">List of available dose forms for the selected drug in your selected country: e.g. tablet, extended release capsule, transdermal patch</div>
                                            <div className="px-3 pb-2">Nutritionally important non-medicinal ingredients (excipients) are listed when available for each dose form. Click the  icon after each dose form to view the list. <br />e.g. <span style={{ color: '#6363d4' }}>lactose, magnesium stearate, mannitol</span></div>
                                            <div className="px-3 pb-3"><strong>Important:</strong> Excipients are not standardized or regulated in generic dose forms and can vary considerably from the brand name product and between various generics. Always <a href={`${this.state.userInfo?.country_view_id == 2 ? 'https://health-products.canada.ca/dpd-bdpp/index-eng.jsp ' : 'https://dailymed.nlm.nih.gov/dailymed/'}`} target="_blank" id="user_guide_link" style={{ cursor: 'pointer' }}><b>check the manufacturer label</b></a>  the manufacturer label for non-medicinal ingredients in generic dose forms. DNI is unable to list the multitude of all available generic dose forms and their excipients.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.HowtoTakeImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="border-btm px-3 py-2"><strong>How to Take</strong></div>
                                            <div className="pt-3 pb-2 px-3">Instructions for proper administration of a drug dose form. This may include usual administration frequency, timing with regard to food or antacid use, whether to repeat a dose if vomiting occurs, dilution, injection/application site(s) or alternate administration methods.</div>
                                            <div className="px-3 pb-3">e.g. Take once daily at same time of day without regard to food. Food ▲ absorption 40%. Swallow tablet whole; do not break, crush or chew.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.DietImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="border-btm px-3 py-2"><strong>Diet Recommendations</strong></div>
                                            <div className="pt-3 pb-2 px-3">Drug-specific dietary recommendations and <a href="#/about" target="_blank" id="user_guide_link">expert-curated</a>, guideline-based Medical Nutrition Therapy and dietary recommendations for conditions treated by a drug and </div>
                                            <div className="px-3 pb-3">e.g. ▼ sodium diet. Consider DASH or Mediterranean diet. Avoid dehydration. Caution with salt substitutes and potassium supplements.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.GrapefruitInteractionsImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="border-btm px-3 py-2"><strong>Grapefruit/Related Fruit Interactions</strong></div>
                                            <div className="pt-3 pb-2 px-3">Impact of grapefruit or related fruits (such as pummelos, tangelos and Seville oranges) on drug absorption, with detailed information and manufacturer warnings (if available). These warnings are summarized in the <a href="#/my-table/Grapefruit-interactions" id="user_guide_link" target="_blank">Grapefruit Interactions Table</a>.</div>
                                            <div className="px-3 pb-3">e.g. grapefruit/related fruits ▲ absorption 2-15 fold. Avoid large quantities (1 L/day).</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.AlcoholInteractionsImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="border-btm px-3 py-2"><strong>Alcohol Interactions</strong></div>
                                            <div className="pt-3 pb-2 px-3">Impact of alcohol on drug absorption, therapeutic action, clinical or adverse effects. These warnings are summarized in the <a href="#/my-table/alcohol-interactions" id="user_guide_link" target="_blank">Alcohol Interactions Table</a>.</div>
                                            <div className="px-3 pb-3">e.g. Avoid alcohol; additive CNS depression, ▲ risk of overdose/death.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2 border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.NaturalproductinteractionsImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="border-btm px-3 py-2"><strong>Natural Product Interactions</strong></div>
                                            <div className="pt-3 pb-2 px-3">Impact of selected natural health products on drug absorption, therapeutic action, clinical or adverse effects. Natural product interaction listings are NOT comprehensive.</div>
                                            <div className="px-3 pb-3">e.g. Caution with St. John’s wort and supplements with sedative properties (e.g. kava, valerian, lavender).</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.WarningsImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="border-btm px-3 py-2"><strong>Warnings</strong></div>
                                            <div className="pt-3 pb-2 px-3">List of label warnings (contraindications, avoid, caution) for the selected drug from the manufacturer(s) in your selected country.</div>
                                            <div className="px-3 pb-3">e.g. Contraindicated with severe CHF. Avoid with patient under age 18 or with dehydration. Caution with patient over age 65, heart disease or cataracts.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.ProteinbindingImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="border-btm px-3 py-2"><strong>Protein Binding</strong></div>
                                            <div className="pt-3 pb-2 px-3">Percentage of drug/active metabolite bound to plasma proteins. Protein binding is a factor in altered drug action due to low albumin and drug removal via dialysis.</div>
                                            <div className="px-3 pb-3">e.g. 97-99% protein bound. Caution with ▼ albumin.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.RenalwarningsImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="border-btm px-3 py-2"><strong>Renal Warnings</strong></div>
                                            <div className="pt-3 pb-2 px-3">List of warnings for the selected drug for avoidance/caution or dose adjustment with various levels of renal impairment.</div>
                                            <div className="px-3 pb-3">e.g. Caution/▼ dose with mild or moderate renal impairment. Avoid with severe renal impairment/ESRD.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.DialysiswarningsImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="border-btm px-3 py-2"><strong>Dialysis Warnings</strong></div>
                                            <div className="pt-3 pb-2 px-3">Information about dialyzability of the selected drug (when available) and timing of administration in relation to hemodialysis or peritoneal dialysis.</div>
                                            <div className="px-3 pb-3">e.g. Partially removed by hemodialysis. Give after hemodialysis on dialysis days.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.HepaticwarningsImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="border-btm px-3 py-2"><strong>Hepatic Warnings</strong></div>
                                            <div className="pt-3 pb-2 px-3">List of warnings for the selected drug for avoidance/caution or dose adjustment with various levels of hepatic impairment.</div>
                                            <div className="px-3 pb-3">e.g. No dose adjustment required with mild hepatic impairment. ▼ dose with moderate or severe hepatic impairment.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.PregnancywarningsImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="border-btm px-3 py-2"><strong>Pregnancy Warnings</strong></div>
                                            <div className="pt-3 pb-2 px-3">List of warnings and recommended monitoring for use of the selected drug during pregnancy in your selected country. Includes known effects in humans and animals, manufacturer recommended monitoring of exposed neonates and contraception recommendations.</div>
                                            <div className="px-3 pb-3">e.g. Evidence of fetal harm; ▲ malformations and ▼ survival in animal studies below comparable human doses. Females of childbearing potential should use effective contraception during and for 4 weeks after treatment.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.BreastfeedingWarningsImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="px-3 py-2"><strong>Breastfeeding Warnings</strong></div>
                                            <div className="pt-1 pb-3 px-3">List of warnings and recommended monitoring for use of the selected drug during breastfeeding in your selected country.</div>
                                            <div className="px-3 pb-3">e.g. Drug present in breast milk. Avoid breastfeeding during and for 1 week after treatment. Monitor exposed infant for sedation and respiratory depression.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 border-tab">
                                            <div className="py-2"><strong>Adverse Effects (AEs) and Lab Parameters</strong></div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.otherandlabImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="px-3 py-2">A<span style={{ color: 'red' }}> small red wrench icon appears in the top right corner</span> of an AE/Labs category icon to indicate the rates (and the font appearance) are based on placebo-adjusted rates (i.e. rate is net of the incidence with placebo).</div>
                                            <div className="pt-1 pb-3 px-3">e.g. if dizziness occurs with the selected drug at a rate of 24%, but is reported to occur with placebo at a rate of 15%, the net placebo-adjusted rate is (24%-15%) = 9%. The adverse effect listing will appear as: <span style={{ color: 'red' }}>dizziness</span> (display for a rate of 5-9.9% - see below).</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="px-3 py-2">In these sections, frequency of occurrence of an AE/lab parameter change is denoted both by the font display and sorted in descending order of reported frequency. This lets you quickly obtain a picture of the most common AEs for a drug at a glance. </div>
                                            <div className="pt-1 pb-3 px-3">
                                                AEs/Labs with <span style={{ fontSize: 17, fontWeight: 'bold' }}>20% or higher incidence:</span> <span style={{ color: 'red' }}><b>RED/BOLD/UPPERCASE</b></span><br />
                                                AEs/Labs with <span style={{ fontSize: 17, fontWeight: 'bold' }}>10-19.9% incidence:</span> <span style={{ color: 'red' }}>RED/UPPERCASE</span><br />
                                                AEs/Labs with <span style={{ fontSize: 17, fontWeight: 'bold' }}>5-9.9% incidence:</span> <span style={{ color: 'red' }}>red/lowercase</span><br />
                                                AEs/Labs with <span style={{ fontSize: 17, fontWeight: 'bold' }}>0.1-4.9% incidence (or incidence not specified)</span>: black/lowercase<br />
                                                AEs/Labs with <span style={{ fontSize: 17, fontWeight: 'bold' }}>lower than 0.1% incidence</span> are prefaced by the designation <span style={{ fontSize: 17, fontWeight: 'bold' }}>‘Rare:’</span>
                                            </div>
                                            <div className="pt-1 pb-3 px-3">If an AE or Lab parameter change is considered <b><i>desirable</i></b> (e.g. lowering blood pressure with an antihypertensive drug, or lowering cholesterol with a cholesterol lowering drug), then the AE or lab parameter change appears <b><i>italicized</i></b> (in addition to any above coding based on rate).</div>
                                            <div className="pb-3 px-3">e.g. <span style={{ color: 'red' }}><b><i>▼ TRIGLYCERIDES</i></b></span>, <span style={{ color: 'red' }}><i>▼LDL, ▲HDL</i></span></div>
                                            <div className="pb-3 px-3">If an AE is usually considered mild or moderate severity but is listed by the manufacturer as ‘may be severe’ (or similar wording) the ^ symbol appears immediately after the AE. </div>
                                            <div className="pb-3 px-3">e.g. <span style={{ color: 'red' }}><b>NAUSEA^</b></span>, <span style={{ color: 'red' }}>vomiting^, diarrhea^</span>. </div>
                                            <div className="pb-3 px-3">AEs that are usually/always severe in nature (e.g. anaphylaxis, agranulocytosis) DO NOT display the ^ symbol.</div>
                                        </div>
                                    </div>

                                    {/* <div className="row">
                                    <div className="col-2  border-tab-1 text-center">
                                        <br />
                                    </div>
                                    <div className="col-10   border-tab p-0">
                                        <br />
                                    </div>
                                </div> */}

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={danger} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="px-3 py-2">Excessive vitamin/mineral supplementation may result in adverse effects or changes in a lab parameter that may cause toxicity. In this case, the adverse effect or lab parameter is followed immediately by this icon.</div>
                                            <div className="pt-1 pb-3 px-3">e.g. cheilitis<img src={danger} width="15" style={{ height: 14 }} />, stomatitis<img src={danger} width="15" style={{ height: 14 }} />, ▲calcium<img src={danger} width="15" style={{ height: 14 }} />.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <div className="my-3"><strong>false</strong></div>
                                            <div className="my-3"><strong>(test interference)</strong></div>
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="px-3 py-2">A drug may interfere with lab testing or cause falsely ▲ or ▼ lab parameter changes. If the direction of change is known, the designation ‘false’ appears before the direction of change and the affected lab parameter. If the direction of change is unspecified, the designation (test interference) appears after the Lab parameter (in addition to any incidence coding based on rate).</div>
                                            <div className="pt-1 pb-3 px-3">e.g. <span style={{ color: 'red' }}>false ▲FBG</span>, oGTT (test interference).</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.OralAdverseEffectsImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="px-3 py-2"><strong>Oral Adverse Effects</strong></div>
                                            <div className="pt-1 pb-3 px-3">List of adverse effects of the selected drug specific to the oral cavity.</div>
                                            <div className="px-3 pb-3">e.g. <span style={{ color: 'red' }}><b>STOMATITIS^</b></span>, <span style={{ color: 'red' }}>TASTE DISTURBANCE, dry mouth</span>, oral ulceration. <b>Rare:</b> tooth abscess.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.GIAdverseEffectsImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="px-3 py-2"><strong>GI Adverse Effects</strong></div>
                                            <div className="pt-1 pb-3 px-3">List of adverse effects of the selected drug specific to the rest of the gastrointestinal tract.</div>
                                            <div className="px-3 pb-3">e.g. <span style={{ color: 'red' }}><b>CONSTIPATION^</b></span>, <span style={{ color: 'red' }}>NAUSEA</span>, abdominal pain. <b>Rare:</b> GI bleeding.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.NutritionalAdverseEffectsImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="px-3 py-2"><strong>Nutrition Adverse Effects</strong></div>
                                            <div className="pt-1 pb-3 px-3">List of adverse effects of the selected drug impacting weight, appetite and nutritional status.</div>
                                            <div className="px-3 pb-3">e.g. <span style={{ color: 'red' }}><b>▲ APPETITE</b></span>, <span style={{ color: 'red' }}>▲ WEIGHT, hyperglycemia^</span>, hyponatremia. <b>Rare:</b> ketoacidosis.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.OtherAdverseEffectsImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="px-3 py-2"><strong>Other Adverse Effects</strong></div>
                                            <div className="pt-1 pb-3 px-3">List of adverse effects of the selected drug for all other body systems. </div>
                                            <div className="px-3 pb-3">e.g. <span style={{ color: 'red' }}><b><i>▼ BP</i></b></span>, <span style={{ color: 'red' }}><b>SOMNOLENCE,</b></span> <span style={{ color: 'red' }}>arthritis</span>, hypotension, ▼ visual acuity. <b>Rare:</b> hearing loss.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.LabParametersImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="px-3 py-2"><strong>Lab Parameters</strong></div>
                                            <div className="pt-1 pb-3 px-3">List of lab parameters affected by the selected drug. See <a href="#/my-table/lab-value" id="user_guide_link" target="_blank">Lab Values table</a> for long names and descriptions of lab parameters, including lists of common conditions that may result in abnormal lab parameters or affect lab parameter interpretation.</div>
                                            <div className="px-3 pb-3">e.g. <span style={{ color: 'red' }}><b><i>▼URIC ACID</i></b></span>, <span style={{ color: 'red' }}>▲SCr</span>, ▲urea, false ▲INR. <b>Rare:</b> ▼neutrophils.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.CrossReferenceImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="px-3 py-2"><strong>Cross References</strong></div>
                                            <div className="pt-1 pb-3 px-3">Links to related drugs in the same pharmacological or therapeutic class as the selected drug or to other drugs commonly used in combination with the selected drug. If the cross-referenced drug is available in your selected country and purchased specialty module(s)), clicking or tapping on a cross-reference link jumps directly to the selected drug detail screen.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                            <img className="my-3 uses" src={this.state.CitationsImg[0]?.iconImg} />
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="px-3 py-2">
                                                <strong>
                                                    {/*  */}
                                                    {this.state.userInfo?.country_view_id == 2 ? <span style={{ color: 'rgb(196 43 43)' }}>
                                                        Product Monographs, Citations and Guidelines (Canada)</span> : <span style={{ color: '#15678F' }}>
                                                        Prescribing Information, Citations and Guidelines (US)
                                                    </span>}
                                                    <br />

                                                </strong>
                                            </div>
                                            <div className="pt-1 pb-3 px-3">External links to the full, current manufacturer
                                                {this.state.userInfo?.country_view_id == 2 ? <span> product monograph </span> : <span> prescribing information </span>}
                                                for your selected country, references supporting specific statements in drug detail listing, expert-curated treatment guidelines and nutritional recommendations for major conditions treated by a drug. Clicking/tapping a hyperlink opens the citation in a new browser window. Institutional login may be required to view full-text of citations or guidelines.</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2  border-tab-1 text-center">
                                        </div>
                                        <div className="col-10   border-tab p-0">
                                            <div className="px-3 py-2"><strong>Questions? Contact DNI</strong></div>
                                            <div className="pt-1 pb-3 px-3">At DNI, we are here to assist you with accurate interpretation of the drug information provided. Please  <a href="https://web.drugnutritioninteractions.com/#/contact" id="user_guide_link" target="_blank">contact DNI</a> to get in touch with Dean and our team for any questions you may have about using the DNI site.</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 border-tab">
                                            <div className="py-2">
                                                <strong>
                                                    DNI Display Guide and Tutorial<br />
                                                    Last Updated June 2023<br />
                                                    Copyright © 2023 <a href="www.drugnutritioninteractions.com" id="user_guide_link" target="_blank">Drug Nutrition Interactions Ltd.</a><br />
                                                    <a href="https://web.drugnutritioninteractions.com/#/contact" id="user_guide_link" target="_blank">Questions? Contact the DNI Team</a>
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </React.Fragment>
    }
}

export default UsersGuide