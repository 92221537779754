import React, { useEffect, useState } from 'react'
import { HoverBox } from '../../components/Box/Box'
import Counter from '../../components/Counter/Counter'
import { OwlCarousel } from '../../components/Slider/Slider'
import Team from '../../components/Team'
import { AUTENTICATE_API } from '../../services/Api/authenticationApi'
import dean from "../../../src/assets/dean.jpg";
import Mary from "../../../src/assets/Mary.jpg";
import Laura from "../../../src/assets/Laura.jpg";
import Joshua_Buse from '../../../src/assets/jousha-headshot.jpg';
import Sherrill from '../../../src/assets/Sherrill.jpg';
import JoyBhimji from "../../assets/JoyBhimji.JPG";
import Amanda from "../../assets/AmandaBarton.jpg";
import KathyMcInnes from "../../assets/KathyMcInnes.jpg"
import MIchelle from "../../assets/MIchelle.jpg"
import Lisa from "../../assets/Lisa.jpg"
import { Link } from 'react-router-dom'
import BackIcon from '../../assets/images/previousBtn.png'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


function About() {

  const [subscriptions, setSubscriptions] = useState(0);
  const [drugCount, setDrugCount] = useState(0)
  const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;
  useEffect(() => {
    if (reloadCount <= 0) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }
    AUTENTICATE_API?.totalSubscriptions().then(res => {
      setSubscriptions(res?.subscriptions)
    })
    AUTENTICATE_API?.Drug_count().then(res => {
      setDrugCount(res?.count)
    })

    $('#owl-carousel').owlCarousel({
      loop: true,
      margin: 30,
      dots: true,
      nav: true,
      items: 2,
    })
  }, [])

  return (
    <div>
      <section className="section search-bg2 section-height-800 parallax-container context-dark bg-gray-darkest text-xl-left" data-parallax-img="./images/home/resized/about-news.jpg">
        <div className="parallax-content">
          <div className="bg-overlay-black">
            <div className="container section-80 section-md-30 section-lg-top-10 section-lg-bottom-30" style={{ paddingTop: 6 }}>
              <div className="d-none d-lg-block">
                <h1 className='mt-4'>About DNI</h1>
              </div>
              <div className='d-flex d-lg-block'>
                <img src={BackIcon} alt="Back" className="d-lg-none d-md-none" onClick={() => { window.location.href = '/#/' }} style={{ width: '8%', height: "8%", marginTop: '29%' }} />
                <ul className="list-inline list-inline-dashed list-white text-big p offset-md-top-8">
                  <li>Learn More about the DNI team</li>
                  {/* <li>About</li> */}
                </ul>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section className='section section-20 section-lg-30 novi-background bg-cover' data-preset='{"title":"team","category":"team","id":"team"}'>
        <div className='container' id='About'>
          <h3 className='mb-0'>Meet the DNI Team</h3>
          <div className="row justify-content-sm-center offset-top-20">
            {/* <!-- column  --> */}
            <div className="col-lg-3 mb-4">
              <Team
                img={dean}
                name="Dean Elbe, PharmD, BCPP "
                designation="Clinical Pharmacist/President"
                desc="Over 30 years of DNI experience. Author: Food-Medication Interactions™ 18th-19th Editions. Editor: Food-Medication Interactions™ 11th-17th Editions."
              />
            </div>
            <div className="col-lg-3 mb-4">
              <Team
                img={Mary}
                name="Mary Litchford PhD, RDN, LDN"
                designation="Senior Dietitian"
                desc="Over 35 years experience in clinical dietetics. Author of chapters on DNI and nutrition assessment in major textbooks."
              />
            </div>
            <div className="col-lg-3 mb-4">
              <Team
                img={Laura}
                name="Laura Finn, BS Pharm, BCGP, FASCP"
                designation="Clinical Pharmacist"
                desc="Geriatric pharmacy specialist, Editor: Food-Medication Interactions™, 19th Edition."
              />
            </div>
            <div className="col-lg-3 mb-4">
              <Team
                img={Joshua_Buse}
                name="Joshua Buse, PhD, DABCC"
                designation="Clinical Biochemist"
                desc="DNI Laboratory Values Table."
              />
            </div>
            <div className="col-lg-3 mb-4">
              <Team
                img={Sherrill}
                name="Sherrill Brown, DVM, PharmD, BCPS"
                designation="Clinical Pharmacist"
                desc="Drug Information Specialist with over 30 years of experience."
              />
            </div>
            <div className="col-lg-3 mb-4">
              <Team
                img={Amanda}
                name="Amanda Barton, PharmD, ACPR, ACPR2"
                designation="Clinical Pharmacist"
                desc="Pediatric Pharmacy Specialist with Advanced Residency Training."
              />
            </div>
            <div className="col-lg-3 mb-4">
              <Team
                img={JoyBhimji}
                name="Joy Bhimji, BScPharm"
                designation="Clinical Pharmacist"
                desc="Oncology Pharmacist with over 25 years experience."
              />
            </div>
            <div className="col-lg-3 mb-4">
              <Team
                img={KathyMcInnes}
                name="Kathy McInnes, BSc(Pharm), CSPI"
                designation="Pharmacist"
                desc="Drug and Poison Information specialist with over 35 years of experience."
              />
            </div>
            <div className="col-lg-3 mb-4">
              <Team
                img={MIchelle}
                name="Michelle Gnyra, BSc (Pharm), ACPR"
                designation="Clinical Pharmacist"
                desc="HIV pharmacy specialist with over 5 years experience."
              />
            </div>
            <div className="col-lg-3 mb-4">
              <Team
                img={Lisa}
                name="Lisa Westermark"
                designation="Business Manager"
                desc=""
              />
            </div>
          </div>
          {/* <p style={{color:'#585858',marginTop:'-12px'}}>
          <b>Disclaimer:</b> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ultrices, lacus vitae pellentesque faucibus,
           est neque luctus justo, ut viverra purus nunc mattis justo. Donec non euismod arcu. In nec eros sed ex pretium egestas. 
           Suspendisse potenti. Cras viverra imperdiet ligula, a rhoncus justo sagittis id. Suspendisse potenti.
          </p> */}
        </div>

      </section>
      <section style={{}} className="section search-about-bg2 context-dark parallax-container " data-parallax-img="./images/home/resized/about-pink.jpg" data-preset='{"title":"Our Skills","category":"about","id":"our-skills"}'>
        <div className="parallax-content">
          <div className="bg-overlay-pink">
            <div className="container section-30 section-md-32">
              <div className="row justify-content-sm-center">
                <div className="col-md-8 col-lg-12">
                  <div className="row row-65 justify-content-sm-center" >
                    <div className="col-sm-3 col-lg-3 col-xl-3">
                      <Counter
                        icon="fa fa-calendar-check-o"
                        count="206"
                        // countertxt="of years of practice experience of the team members"
                        counterbrtxt="Team Years of Experience"
                      />
                    </div>
                    <div className="col-sm-3 col-lg-3 col-xl-3">
                      <Counter
                        icon="fa fa-users"
                        count={subscriptions}
                        // countertxt="of active subscriptions"
                        counterbrtxt="DNI Subscribers"
                      />
                    </div>
                    <div className="col-sm-3 col-lg-3 col-xl-3">
                      <Counter
                        icon="fa fa-table"
                        count="14"
                        // countertxt="of separate tables in the logged in site"
                        counterbrtxt="Supplementary Tables"
                      />
                    </div>
                    <div className="col-sm-3 col-lg-3 col-xl-3">
                      <Counter
                        icon="fl-great-icon-set-band14"
                        count={drugCount}
                        // countertxt="of dni_generic"
                        counterbrtxt="Unique Medications"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="section section-50 section-lg-50 novi-background bg-cover">
        <div className="container">
          <h3 style={{marginTop:'-20px'}}>DNI Testimonials</h3>
          <div className="row justify-content-sm-center align-items-center offset-top-40">
            <div className="col-md-10 col-lg-8 col-xl-6">
              <div style={{marginTop:'-20px'}} className="row row-30 justify-content-sm-center">
                <div className="col-sm-6"><img className="img-responsive center-block" src="./images/home/resized/testimonials1.jpg" width="269" height="278" alt="" /></div>
                <div className="col-sm-6 offset-top-30 offset-xs-top-0"><img className="img-responsive center-block" src="./images/home/resized/testimonials2.jpg" width="269" height="278" alt="" /></div>
              </div>
            </div>
            <div  className="col-md-10 col-lg-8 col-xl-6 offset-top-40 offset-lg-top-0 text-left">
              <div className="owl-carousel" data-items="1" data-stage-padding="15" data-margin="30" data-dots="true" data-mouse-drag="false" data-pagination-className=".owl-custom-pagination" data-nav="false">
                <OwlCarousel 
                  heading = "The DNI site is Next Level"
                  text ="Dean and the DNI team have created a wonderful online resource focused on the needs of dietitians that goes light years beyond any text book and is sure to become the foremost resource covering drug-nutrition interactions. A DNI subscription is an essential tool for every dietetic professional."
                  name="Jacky Borner"
                  designation="RD, LDN, FADA"
                  btnHref="#"
                />
                <OwlCarousel 
                  heading = "Awesome Resource for Dietitians"
                  text ="I can’t thank you enough for the DNI site. Living without up to date drug-nutrition interaction information for the past few years has been difficult. Things change so fast and it’s so crucial that the DNI team keeps up to date with all the new drug approvals."
                  name="Patricia Dunnett"
                  designation="RD, LDN, FADA"
                  btnHref="#"
                />
                <OwlCarousel 
                  heading = "Best Resource for Dietetics Students"
                  text =" Dietetics students academic lives just got easier. Not only does the DNI site provide a dietitian friendly snapshot of drug knowledge, but the links to evidence-based medical nutrition therapy and monitoring make creating nutritional care plans a snap. A required resource!"
                  name="Darla Carleton"
                  designation="RD, LDN, MEd"
                  btnHref="#"
                />
              </div>
            </div>
          </div>
          <div className="row row-fix justify-content-sm-center offset-top-25">
            <div className="col-md-6 col-xl-4">
              <div className="section-hidden">
                <div className="text-both-lines">
                  <div className="owl-custom-pagination">
                    <div className="owl-dot" data-owl-item="0"></div>
                    <div className="owl-dot" data-owl-item="1"></div>
                    <div className="owl-dot" data-owl-item="2"></div>
                  </div>
                </div>
              </div>
            </div>            
          </div>

        </div>
      </section> */}
      <section>
        <div className="container">
          <h3 style={{ marginTop: '20px' }}>DNI Testimonials</h3>
          <div className="row pb-5 mb-5">
            <div className="col-xl-7 col-12">
              <img className="mr-md-3 mb-4 mb-md-0" src="./images/home/resized/testimonials1.jpg" />
              <img src="./images/home/resized/testimonials2.jpg" />
            </div>
            <div className="col-xl-5 col-12 my-auto">
              {/* <div className="contain">
            <div id="owl-carousel" className="owl-carousel owl-theme">
                <div className="item">
                  <div className="heading">The DNI site is Next Level</div>
                  <div className="text">Dean and the DNI team have created a wonderful online resource focused on the needs of dietitians that goes light years beyond any text book and is sure to become the foremost resource covering drug-nutrition interactions. A DNI subscription is an essential tool for every dietetic professional.</div>
                  <div className="name">Jacky Borner</div>
                  <div className="designation">RD, LDN, FADA</div>
                </div>
                <div className="item">
                  <div className="heading">The DNI site is Next Level</div>
                  <div className="text">Dean and the DNI team have created a wonderful online resource focused on the needs of dietitians that goes light years beyond any text book and is sure to become the foremost resource covering drug-nutrition interactions. A DNI subscription is an essential tool for every dietetic professional.</div>
                  <div className="name">Jacky Borner</div>
                  <div className="designation">RD, LDN, FADA</div>
                </div>
                <div className="item">
                  <div className="heading">The DNI site is Next Level</div>
                  <div className="text">Dean and the DNI team have created a wonderful online resource focused on the needs of dietitians that goes light years beyond any text book and is sure to become the foremost resource covering drug-nutrition interactions. A DNI subscription is an essential tool for every dietetic professional.</div>
                  <div className="name">Jacky Borner</div>
                  <div className="designation">RD, LDN, FADA</div>
                </div>
            </div>
          </div> */}
              <div id="carouselExampleIndicators" class="carousel slide carousel-make" data-ride="carousel">
                <ol class="carousel-indicators">
                  <hr className="border-left" />
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active point-1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1" ></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2" class=" point-2"></li>
                  <hr className="border-right" />
                </ol>
                <div class="carousel-inner text-center text-xl-left pt-5 pt-md-0">
                  <div class="carousel-item active mb-5 mt-5 mt-xl-0">
                    <div className="heading text-big font-weight-bold text-primary"></div>
                    <div className="text py-3">Wow! Dean and his team have created a comprehensive resource for LTC RDNs!  Having DNI as a platform that covers interactions, labs, calculators, and the latest drug/nutrition news is incredibly valuable. Inclusion of warnings for various conditions like Renal, Dialysis, Hepatic, along with side effects is crucial for informed decision-making. The convenience of finding this information with just a few keystrokes makes it even more appealing. DNI is definitely a powerful tool for RDNs to have in their arsenal!</div>
                    <div className="name pb-2 link-decoration-none text-hover-primary text-gray">Pamela Brummit, MA, RD, LD</div>
                    <div className="designation text-gray">Professional Development Coordinator & Past Chair, DHCC</div>
                  </div>
                  <div class="carousel-item mb-5 mt-5 mt-xl-0">
                    <div className="heading text-big font-weight-bold text-primary"></div>
                    <div className="text py-3">DNI provides one-stop shopping for the registered dietitian nutritionist (RDN) that needs information on medications. The website (accessible via computer, tablet, or mobile device) provides comprehensive information about medications, including but not limited to routes of administration, recommended dose, how to take, diet and nutrition concerns, and adverse effects. The website provides information on interpretation of laboratory values, medication's interactions with alcohol and grapefruit, and much, much more. DNI is a valuable addition to the RDN's electronic resource library.</div>
                    <div className="name pb-2 link-decoration-none text-hover-primary text-gray">Liz Friedrich, MPH, RDN, CSG, LDN, FAND, NWCC </div>
                    <div className="designation text-gray">President, Friedrich Nutrition Consulting</div>
                  </div>
                  <div class="carousel-item mb-5 mt-5 mt-xl-0">
                    <div className="heading text-big font-weight-bold text-primary"></div>
                    <div className="text py-3">What an amazing resource to have access to! DNI has up to date product information with nutrient interactions, lab monitoring, supplement interactions, GI side effects (or really any drug information you were looking for). Thank you for putting this into an easy to access online format. I have been using the handbook format for most of my career, and switching to DNI was so easy!</div>
                    <div className="name pb-2 link-decoration-none text-hover-primary text-gray">Lorrie Chow, RD</div>
                    <div className="designation text-gray">Clinical Dietitian, BC Children's Hospital</div>
                  </div>
                </div>
                {/* <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-10 section-lg-10 novi-background bg-cover">
        <div className="container">
          <h3 style={{ marginTop: '-40px' }}>Speaking Engagements</h3>
          <p style={{ fontSize: 16, marginTop: '10px' }}>
            Dean and DNI Team members are available to speak at your next Conference or Event (Live Session or Webinar) - <Link to='/contact'
              onClick={(e) => { localStorage.setItem('speak', 'Speaker Inquiry') }}
            >Contact</Link>  DNI for Rates and Availability
          </p>
        </div>
      </section>
    </div>
  )
}

export default About