import React, { useState } from "react";
import PageHeaderTable from "../../../components/PageHeader/headertable";
import PageHeader from "../../../components/PageHeader/headertable";
import BackIcon from '../../../assets/images/previousBtn.png';

const PotassiumSources = () => {


    return (
        <div style={{ textAlign: 'initial' }}>
            {/* <PageHeaderTable  style={{marginTop:'50px', height:'20px',marginTop:'50px'}} 
    bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
    title="POTASSIUM (K+) SIGNIFICANT DIETARY SOURCES"
       description={""}/> */}
            <PageHeader style={{ marginTop: '50px', height: '20px', marginTop: '50px' }}
                bgimg="https://www.truthaboutweight.global/shared/content-pages/hero-images/support-section/bmi_calculator_hero.jpg"
                title="Potassium&nbsp;(K)&nbsp;Significant&nbsp;Dietary&nbsp;Sources" />
            <div className="container mt-4 mobile-margin-left-magnesium">
                <div className='d-flex d-lg-block'>
                    <img
                        src={BackIcon}
                        alt="Back"
                        className="d-lg-none d-md-none"
                        onClick={() => { window.location.href = '/#/' }}
                        style={{
                            position: 'relative',
                            top: '-85px',
                            left: '-15px',
                            width: '8%',
                            height: '8%',
                            cursor: 'pointer'
                        }}
                    />
                </div>
                {/* <h6 ><p style={{marginLeft:-25,fontWeight:'700px',fontSize:15,color:'#484343'}}><b>POTASSIUM (K+) SIGNIFICANT DIETARY SOURCES</b></p></h6>
            <br/> */}
                <p style={{ marginTop: "-1px", marginLeft: -25, fontWeight: '500px', fontSize: 15, color: '#484343' }}><b>Vegetables and Fruits</b></p>

                <p style={{ marginTop: "-5px", marginLeft: -25, fontWeight: '500px', fontSize: 14, color: '#15678F' }}><b>Standard servings provide approximately 140-300 mg potassium</b></p>
                <br />
                <p style={{ marginTop: "-30px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}>apple, (raw, with skin), asparagus, artichoke. bamboo shoots (raw), beets (cooked &amp; drained),
                    broccoli, cauliflower, celery (Pascal type, raw 2 stalks), kale, mushrooms (raw, boiled), okra
                    pods (cooked), potato chips, pumpkin (cooked), seaweed, spinach, squash (winter), sweet
                    potato (canned), tomato, tomato juice, tomato paste, turnip greens, vegetable juice <br />(6 ounces)</p>
                <br />
                <p style={{ marginTop: "-20px", marginLeft: -25, fontWeight: '500px', fontSize: 14, color: '#15678F' }}><b>Standard servings provide approximately 300-500 mg potassium</b></p>
                <p style={{ marginTop: "-10px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}>apricots (raw), avocado (1/3 whole), banana (1 small), beans (adzuki, black, kidney, lentil, lima,
                    pinto, red, soy, white), Brussels sprouts (raw, cooked), cantaloupe, carrots, carrot juice, dried
                    fruit (apple, apricots, dates, pear, peach, prune, raisins), grapefruit juice, honeydew, kiwifruit,
                    orange juice, orange (1), pineapple juice, potato, prune juice (5 ounces), strawberries, sweet
                    potato (fresh baked), tangerine juice</p>
                <br />
                <p style={{ marginTop: "-20px", marginLeft: -25, fontWeight: '500px', fontSize: 15, color: '#484343' }}><b>Cereal and Breads</b></p>
                <p style={{ marginTop: "-7px", marginLeft: -25, fontWeight: '500px', fontSize: 14, color: '#15678F' }}><b>Serving sizes average approximately 200-300 mg potassium</b></p><br />
                <p style={{ marginTop: "-30px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}>All Bran, Bran Flakes, Mueslix, Raisin Bran, Bread (pumpernickel, 2 slices), Cheerios Quaker
                    100% natural cereal with raisins, Fiber One with Raisin Bran Clusters</p>


                <p style={{ marginTop: "-3px", marginLeft: -25, fontWeight: '500px', fontSize: 15, color: '#484343' }}><b>Dairy foods</b></p>
                <p style={{ marginTop: "-7px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#15678F' }}><b>Serving sizes average approximately 200-400 mg potassium</b></p>
                <p style={{ marginTop: "-7px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}>cottage cheese (low fat, 1 cup), milk (plain 1%, whole or fat free, 1 cup), milk (evaporated, 1/2
                    cup), ricotta cheese (whole milk or part skim, 1 cup), yogurt (plain, 1 cup)</p>
                <br />
                <p style={{ marginTop: "-20px", marginLeft: -25, fontWeight: '500px', fontSize: 15, color: '#484343' }}><b>Miscellaneous</b></p>
                <p style={{ marginTop: "-10px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#15678F' }}><b>Serving sizes average approximately 100-200 mg potassium</b></p>
                <p style={{ marginTop: "-10px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}>almonds (12-14), Brazil nuts (5 medium), cashews, chestnuts (4 large), coffee (10 ounces),
                    filberts, hazelnuts, fruitcake, molasses (1/2 Tablespoon), peanuts, peanut butter, pecans, potato
                    (mashed, from granules), pumpkin seeds, tea (14 ounces), tofu, sunflower seeds, walnuts</p>
                <br />
                <p style={{ marginTop: "-20px", marginLeft: -25, fontWeight: '400px', fontSize: 14, color: '#484343' }}><b>Reference:&nbsp;</b><br /><a target={"_blank"} href="https://www.nal.usda.gov/legacy/fnic/potassium">https://www.nal.usda.gov/legacy/fnic/potassium</a></p>
            </div>
        </div>
    );

}

export default PotassiumSources;