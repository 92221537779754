import React, { useState } from 'react';
import "./CustInputRange.scss";

const CustInputRangeAge = (props) => {
  const { setInputAge, inputValAge } = props;

  const updateRangeDisplay = (stepsVal) => {
    props.ErSms("");
    if (!isNaN(stepsVal) == true) {
      if (stepsVal >= 111) {
        props.ErSms("Allowed Only Less Than 110.");
        return false;
      }
      if (stepsVal?.includes('.') == true) {
        props.ErSms("please Enter valid Input");
        return false;
      } if (stepsVal.length > 3) {
        props.ErSms("please Enter valid Input");
        return false;
      }
      else {
        setInputAge(stepsVal);
      }
    } else {
      props.ErSms("Only digits are allowed.");
      return false;
    }
  }


  return (
    <div className="form-group row customSlider">

      <div className='col-12'>
        <div className="ticks">
          <span className="tick">{props.minVal} {props.metric && props.metric}</span>
          <span className="tick">{props.maxVal} {props.metric && props.metric}</span>
        </div>
        <input type="range" style={{ width: '100%', marginLeft: '0px' }} step={1} value={(inputValAge) ? inputValAge : props.minVal} min={props.minVal} max={props.maxVal} defaultValue={inputValAge} onChange={(event) => updateRangeDisplay(event.target.value)} />

      </div>
      <div className='col-7'>
        <label className='form-check-label' style={{ color: '#484343', fontSize: 15 }}>{props.label && props.label}</label>
      </div>
      <div className='col-5 text-right'>
        <input type="text" style={{ width: '100px', height: '38px' }} CalculateEgfr={props.CalculateEgfr} value={inputValAge} name="inputValAge" onChange={(e) => updateRangeDisplay(e.target.value)} />
      </div>
    </div>
  )
}

export default CustInputRangeAge;